import Vue from "vue";
import * as constants from '@/constants/constants'
import Router from "vue-router";
import ListPage from "../pages/ListPage";
import ProjectPage from "../pages/ProjectPage";
import Error404 from "../pages/Error/404";
import Error500 from "../pages/Error/500";
import * as check from './modules/check'
import store from "@/store/index";
import { getCookie } from "@/components/modules/getCookie";
import * as utils from '@/utils/utils';

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      name: constants.pageName.projectList,
      path: '/',
      component: ListPage,
      meta: { requiresAuth: true }
    },
    {
      name: constants.pageName.project,
      path: '/project',
      component: ProjectPage,
      meta: { requiresAuth: true },
      beforeEnter: async (to, from, next) => {
        // 現場を開く権限がある場合のみ開く
        const isAllowed = await check.isAllowedToOpenSite(to.query.siteId, store.getters.loginData.user_id);
        if (isAllowed) next()
        else next("/")
      }
    },
    {
      name: constants.pageName.error404,
      path: '/404',
      component: Error404,
      meta: { requiresAuth: false, useFrame: false }
    },
    {
      name: constants.pageName.error500,
      path: '/500',
      component: Error500,
      meta: { requiresAuth: false, useFrame: false }
    },
  ],
});

function isLogin() {
  // ログイン情報がstoreに格納されていればログイン中と判断
  if (Object.keys(store.state.loginData).length) {
    return true;
  }
  return false;
}

// 遷移先確定前の処理
router.beforeEach((to, from, next) => {
  // 別タブで立ち上げたときRefresh機能適用
  if (!window.name) {
    window.name = 'init';
    window.location.replace("/login");
  }
  // ログイン状況をcookieから取得しstoreにセットする
  if (process.env.NODE_ENV === 'production') {
    const loginData = getCookie('LOGIN_DATA');
    if (!isLogin()) {
      if (loginData.user_id) {
        loginData.user_id = Number(utils.decrypt(loginData.user_id));
        store.dispatch('setLoginData', loginData);
      } else {
        window.location.replace("/login");
      }
    }
  } else {
    const mockUser = {
      user_id: 2,
      user_name: 'test.user',
      mail: 'test@cct-inc.co.jp',
      language: constants.language.ja
    };
    store.dispatch('setLoginData', mockUser);
  }

  // 遷移先のrequiresAuthがtrueかつproductionモードの時はログイン状況の確認をする
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (isLogin()) {
      next();
    } else {
      window.location.replace("/login");
    }
  } else {
    next();
  }
});

/**
 * ログイン後の画面が表示する前
 */
router.beforeEach((to, from, next) => {
  const scInfo = getCookie('LOGIN_DATA');

  if (to.path === '/') {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'sc_corporation_id': scInfo.scCorp,
      'sc_user_id': scInfo.scCorpUser
    });
  }
  next();
});

/**
 * ルーティングが完了した後に実行
 */
router.afterEach((to) => {
  if (to.path === '/') {
    window.dataLayer.push({
      'event': 'sc_open'
    });
  }
});

export default router;
