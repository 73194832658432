<!-- 断面データリスト -->
<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header
        style="font-size: 15px; background-color: #1e1e1e"
        class="pa-2"
      >
        <div class="d-flex align-center">
          <!-- メニューボタン -->
          <v-btn
            width="24px"
            plain
            min-width="24px"
            class="pa-0 mr-1"
            @click.stop="changeShawEditDialog"
          >
            <v-icon style="background-color: transparent">mdi-menu</v-icon>
          </v-btn>
          <div>{{$t("CIW0020300011")}}</div>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="pt-1">
        <!-- 点群リスト -->
        <v-list class="pa-0">
          <v-list-item
            v-for="point in createPointListForCrossSection"
            :key="point.pointId"
            class="data-list mb-1"
          >
            <v-list-item-title>
              <p class="ma-0 mb-2">{{ point.pointName }}</p>
              <!-- 線形リスト -->
              <v-list class="pa-0">
                <v-list-item
                  v-for="linear in point.linearList"
                  :key="linear.linearId"
                  class="data-list mb-1"
                >
                  <v-list-item-title>
                    <p class="ma-0 mb-2">{{ linear.linearName }}</p>
                    <!-- 断面リスト -->
                    <v-list class="pa-0">
                      <v-list-item
                        v-for="sectionFile in linear.sectionList"
                        :key="sectionFile.sectionId"
                        class="data-list mb-1"
                      >
                        <v-list-item-title>
                          <section-file
                            :selected="selected"
                            @add-checked="addChecked"
                            @delete-checked="deleteChecked"
                            :deleteMode="deleteMode"
                            :sectionFile="sectionFile"
                          ></section-file>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-list-item-title>
          </v-list-item>
        </v-list>
        <div v-if="deleteMode" class="d-flex justify-end mb-2">
          <v-btn
            class="mr-3"
            color="red lighten-1"
            width="100px"
            :disabled="this.selected.length === 0"
            @click="deleteSectionFile"
          >
          {{$t("CIW0020301001")}}
          </v-btn>
        </div>
      </v-expansion-panel-content>
      <v-dialog
        hide-overlay
        persistent
        no-click-animation
        v-model="showEditDialog"
        width="360px"
      >
        <section-edit-dialog
          @close="changeShawEditDialog"
          @change-delete-mode="changeDeleteMode"
          @all-delete="allDelete"
        />
      </v-dialog>
    </v-expansion-panel>
  </v-expansion-panels>

</template>

<script>
import * as cesiumCommon from "@/utils/cesium-common";
import SectionFile from "./SectionFile.vue"
import SectionEditDialog from "../SectionEditDialog.vue";
import * as utils from '@/utils/utils';
import { repositoryFactory } from "@/repositories/repository-factory";
import { i18n } from '@/i18n.js'
const sectionFileRepository = repositoryFactory.get('sectionFile');

export default {
  components: {
    SectionFile,
    SectionEditDialog
  },
  data() {
    return {
      showEditDialog: false,
      deleteMode: false,
      selected: [],
    }
  },
  computed: {
    sectionFileList() {
      return this.$store.getters['sectionFile/sectionFileList'];
    },
    createPointListForCrossSection() {

      const pointFileList = this.sectionFileList.reduce((pointFileList, sectionFile) => {

        let pointItem = pointFileList.find(pointFile => pointFile.pointId == sectionFile.pointId);
        if (!pointItem) {
          const pointFile = this.$store.getters['pointDataList']
            .flatMap(project => project.getPoints())
            .find(pointFile => pointFile.pointId == sectionFile.pointId);
          if (!pointFile) {
            return pointFileList;
          }
          pointItem = {
            pointId: pointFile.pointId,
            pointName: pointFile.pointName,
            linearList: [],
          };
          pointFileList.push(pointItem);
        }

        let linearItem = pointItem.linearList.find(linearFile => linearFile.linearId == sectionFile.linearId);
        if (!linearItem) {
          const linearFile = this.$store.getters['linearFile/linearFileList']
            .find(linearFile => linearFile.linearId == sectionFile.linearId);
          if (!linearFile) {
            return pointFileList;
          }
          linearItem = {
            linearId: linearFile.linearId,
            linearName: linearFile.linearName,
            sectionList: [],
          };
          pointItem.linearList.push(linearItem);
        }

        linearItem.sectionList.push(sectionFile);
        return pointFileList;
      }, []);

      return pointFileList.sort((a, b) => a.pointId < b.pointId ? -1 : a.pointId > b.pointId ? +1 : 0);
    },
  },
  watch: {
    sectionFileList(newValue) {
      if (newValue.length === 0) {
        this.deleteMode = false;
        this.showEditDialog = false;
      }
    }
  },
  methods: {
    // エディットダイアログの開閉を行う
    changeShawEditDialog() {
      if (this.deleteMode) {
        this.changeDeleteMode();
      }
      this.showEditDialog = !this.showEditDialog;
    },
    // チェックボックスがチェックされたとき
    addChecked(section) {
      this.selected.push(section);
    },
    // チェックボックスが外れたとき
    deleteChecked(section) {
      this.selected = this.selected.filter(selected => selected.sectionId !== section.sectionId);
    },
    // エディットダイアログで削除ボタン押された際の動作
    changeDeleteMode() {
      this.deleteMode = !this.deleteMode;
    },
    // 選択された断面ファイルを削除
    deleteSectionFile() {
      if (this.selected.length === 0) return;
      this.request(this.selected);
    },
    // 断面ファイルを全削除
    allDelete() {
      if (this.sectionFileList.length === 0) return;
      const deleteIdList = this.sectionFileList;
      this.request(deleteIdList);
    },
    async request(deleteIdList) {
      let successMessage = i18n.tc("SIW0020304002");
      let errorMessage = i18n.tc("SEW0020304001");

      const body = {
        user_id: this.$store.getters.loginData.user_id
      }
      try {
        for(let deleteItem of deleteIdList){
          const res = await sectionFileRepository.deleteSectionFileBySectionId(deleteItem.sectionId, body);
          if (res.status === 204) {
            if (deleteItem.isVisible) {
              window.dispatchEvent(new CustomEvent("onSetCrossSectionHidden"));
            }
            deleteItem.isVisible = false;
            const linear = this.$store.getters["linearFile/linearFileList"].find(
              linear => linear.linearId === deleteItem._linearId
            );
            if (linear) {
              cesiumCommon.remove3DSectionLines(linear._viewerLinearId);
            }
            //cesiumCommon.changeSectionVisible(deleteItem);
          } else {
            // 想定外のステータスコードが返却された場合エラーをスロー
            throw new Error(errorMessage);
          }
        }
        // エラーがなければ正常メッセージを出力する
        utils.showSnackBar('success', successMessage);
        this.selected.splice(0);
      } catch(e) {
        // エラーが発生したら処理終了
        console.error(e.message);
        if (utils.createErrorMessage(e)) {
          errorMessage = utils.createErrorMessage(e);
        }
        utils.showSnackBar('error', errorMessage);
      } finally {
        this.fetchSectionFileList();
      }
    },
    // 断面ファイルを取得
    fetchSectionFileList() {
      this.$store.dispatch('sectionFile/fetchSectionFileList', this.$route.query.siteId)
    },
  },
  created() {
    this.fetchSectionFileList();
  }
};
</script>

<style lang="sass" scoped>
.data-list
  height: auto
  min-height: auto
  display: block

::v-deep .data-list .v-list-item__title
  font-size: 14px

::v-deep .v-expansion-panel-content__wrap
  padding: 0 0 8px 0

::v-deep div.v-expansion-panel-content__wrap
  padding-bottom: 0px
</style>