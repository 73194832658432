// 作成・編集機能で一時的にデータを保存するstore
const state = {
    // 線形描画
    linearDrawing: {
      intermediatePitchSpacing: "",
      linearData: {}
    },
    // 平坦度条件作成
    conditionCreate: {
      isRangeSelectionMode: false,
      dataName: "",
      gridSize: "",
      selectShape: "",
      selectInfo: ""
    },
    //間引き地表面
    thinningGroundSurface: {
      selectInfo: ""
    },
    // 外周線編集値
    perimeterLineValues: {
      selectedElement: {},
      currentPerimeterLine: {},
    }
  };
  
  const getters = {
    linearDrawing: state => state.linearDrawing,
    conditionCreate: state => state.conditionCreate,
    thinningGroundSurface: state => state.thinningGroundSurface,
    perimeterLineValues: state => state.perimeterLineValues,
  };
  
  const mutations = {
    setLinearDrawing(state, properties) {
      const linearDrawing = Object.assign({}, state.linearDrawing);
      state.linearDrawing = Object.assign(linearDrawing, properties);
    },
    setConditionCreate(state, property) {
      for (const key of Object.keys(property)) {
        state.conditionCreate[key] = property[key];
      }
    },
    setThinningGroundSurface(state, property) {
      for (const key of Object.keys(property)) {
        state.thinningGroundSurface[key] = property[key];
      }
    },
    setPerimeterLineValues(state, object) {
      for (const key of Object.keys(object)) {
        state.perimeterLineValues[key] = object[key];
      }
    },
  };
  
  const actions = {
    setLinearDrawing({ commit }, property) {
      commit("setLinearDrawing", property);
    },
    setSectionEdit({ commit }, property) {
      commit("setSectionEdit", property);
    },
    setConditionCreate({ commit }, property) {
      commit("setConditionCreate", property);
    },
    setThinningGroundSurface({ commit }, property) {
      commit("setThinningGroundSurface", property);
    }
  };
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  }
