thinning-ground-surface.png<template>
  <v-card class="thinning-ground-surface-dialog px-4 pt-2 pb-4">
    <v-card-title class="pa-0 justify-space-between">
      <label>
        {{$t("CQW0020113001")}}
      </label>
      <close-button @click="close" size="20px"></close-button>
    </v-card-title>
    <v-card-text class="px-0 pt-1 pb-0 white--text">
      <v-container class="pa-0">
        <v-row class="ma-0">
          <validation-observer 
            class="d-block" 
            style="width: 100%" 
            ref="observer" 
            v-slot="{ invalid, pristine }">
            <v-col cols="12" class="my-3 pa-0">
              <editing-point-clouds
                :title="$t('CIW0020000008')">
              </editing-point-clouds>
            </v-col>
            <v-col cols="12" class="my-3 pa-0">
              <v-row class="ma-0">
                <v-col cols="7">
                  <v-row>
                    <!-- 幅 -->
                    <v-col cols="12" class="mt-3 pa-0">
                      <validation-provider :rules="{
                        required: true,
                        'number-format': true,
                        'decimal-size-limit': 2,
                        'min-value': 0.01,
                        'max-value': 100.99,
                      }" v-slot="{ errors }">
                        <div class="mb-1">
                          <label class="d-inline-block mr-2">{{$t("CQW0020113003")}}</label>
                          <span class="d-inline-block" style="font-size: 12px; color: #ff5252">{{ errors[0] }}</span>
                        </div>
                        <v-text-field
                          @reset.prevent="resetError()"
                          v-model="groundWidth"
                          :error-messages="errors"
                          class="text-black ma-0"
                          height="32px"
                          background-color="#BFBFBF"
                          outlined
                          solo
                          suffix="m"
                          >
                        </v-text-field>
                      </validation-provider>
                    </v-col>
                    <!-- 長さ -->
                    <v-col cols="12" class="mt-3 pa-0">
                      <validation-provider :rules="{
                        required: true,
                        'number-format': true,
                        'decimal-size-limit': 2,
                        'min-value': 0.02,
                        'max-value': 1000.99,
                      }" v-slot="{ errors }">
                        <div class="mb-1">
                          <label class="d-inline-block mr-2">{{$t("CQW0020113004")}}</label>
                          <span class="d-inline-block" style="font-size: 12px; color: #ff5252">{{ errors[0] }}</span>
                        </div>
                        <v-text-field
                          @reset.prevent="resetError()"
                          v-model="groundLength"
                          :error-messages="errors"
                          class="text-black ma-0"
                          height="32px"
                          background-color="#BFBFBF"
                          outlined
                          solo
                          suffix="m"
                          >
                        </v-text-field>
                      </validation-provider>
                    </v-col>
                    <!-- 厚み -->
                    <v-col cols="12" class="mt-3 pa-0">
                      <validation-provider :rules="{
                        required: true,
                        'number-format': true,
                        'decimal-size-limit': 3,
                        'min-value': 0.01,
                        'max-value': 100.999,
                      }" v-slot="{ errors }">
                        <div class="mb-1">
                          <label class="d-inline-block mr-2">{{$t("CQW0020113005")}}</label>
                          <span class="d-inline-block" style="font-size: 12px; color: #ff5252">{{ errors[0] }}</span>
                        </div>
                        <v-text-field
                          @reset.prevent="resetError()"
                          v-model="groundThickness"
                          :error-messages="errors"
                          class="text-black ma-0"
                          height="32px"
                          background-color="#BFBFBF"
                          outlined
                          solo
                          suffix="m"
                          >
                        </v-text-field>
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="5">
                  <p>{{ $t("CQW0020113011") }}</p>
                    <v-img
                      class="description-image"
                      @click="showItemDescription = true"
                      width="100%"
                      :src="descriptionImagePath"
                    ></v-img>
                </v-col>
              </v-row>
            </v-col>
            <!-- 図形選択 -->
            <!-- 次期開発で実装予定の為、今期は非表示 -->
            <!-- <v-col cols="12" class="mt-3 pa-0">
              <label class="mb-1 d-block">{{$t("CQW0020113006")}}</label>
              <div class="d-flex justify-space-between">
                <div
                  class="py-1 shape-selection-area d-flex justify-space-between range-type-area"
                  style="width: 270px">
                  <v-btn text @click="selectRangeType(cesiumSelectingType.polygon)">
                    <v-icon size="24px" left>mdi-pentagon</v-icon>
                    {{$t("CQW0020113007")}}
                  </v-btn>
                  <v-btn text @click="selectRangeType(cesiumSelectingType.box)">
                    <v-icon size="24px" left>mdi-square</v-icon>
                    {{$t("CQW0020113008")}}
                  </v-btn>
                  <v-btn text @click="selectRangeType(cesiumSelectingType.circle)">
                    <v-icon size="24px" left>mdi-circle</v-icon>
                    {{$t("CQW0020113009")}}
                  </v-btn>
                </div>
                <v-btn
                  class="rounded-lg ma-0 my-auto"
                  color="#4472c4"
                  width="115px"
                  height="45px"
                  depressed
                  @click="hide()">
                  {{$t("CQW0020113010")}}
                </v-btn>
              </div>
            </v-col> -->
            <v-col cols="12" class="mt-4 pa-0 d-flex justify-space-between">
              <v-btn
                class="rounded-lg"
                color="#ff5050"
                width="115px"
                height="45px"
                depressed
                @click="close">
                {{$t("CQW0010600005")}}
              </v-btn>
              <v-btn
                class="rounded-lg"
                color="#4472c4"
                width="115px"
                height="45px"
                depressed
                :disabled="pristine || invalid || isExecuteBtnDisabled"
                @click="execute(1)">
                {{$t("CQW0020109005")}}<br>{{$t("CQW0020110006")}}
              </v-btn>
              <v-btn
                class="rounded-lg"
                color="#4472c4"
                width="115px"
                height="45px"
                depressed
                :disabled="pristine || invalid || isExecuteBtnDisabled"
                @click="execute(2)">
                {{$t("CQW0020110007")}}<br>{{$t("CIW0020000005")}}
              </v-btn>
            </v-col>
          </validation-observer>
        </v-row>
      </v-container>
    </v-card-text>
    <!-- 解説画像 -->
    <v-dialog
      v-model="showItemDescription"
      max-width="600px"
    >
      <v-img
        @click="showItemDescription = false"
        width="100%"
        :src="descriptionImagePath"
      ></v-img>
    </v-dialog>
  </v-card>
</template>

<script>
import CloseButton from "@/components/common/CloseButton.vue";
import EditingPointClouds from "./EditingPointClouds.vue";
import * as utils from "@/utils/utils";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { repositoryFactory } from "@/repositories/repository-factory";
/* import * as cesiumCommon from "@/utils/cesium-common"; */
/* import * as constants from "@/constants/constants"; */
/* import Dialog from "@/components/modules/dialog"; */
import { i18n } from '../../../i18n.js'
/* import Cesium from '../../Cesium.vue' */
const thinningGroundSurfaceRepository = repositoryFactory.get('thinningGroundSurface');

export default {
  components: {
    CloseButton,
    EditingPointClouds,
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    showItemDescription: false,
    descriptionImagePath: require('@/assets/images/thinning-ground-surface.png'),
		groundWidth: '',
    groundLength: '',
    groundThickness: '',
    /* cesiumSelectingType: constants.cesiumSelectingType, */
	}),
  computed: {
    /**
     * 実行ボタンの活性制御
     * 以下の場合非活性
     *  編集チェックボックスが0件の場合
     *  範囲選択情報がない場合
     */
    isExecuteBtnDisabled() {
      return !this.editingPointClouds.length;
      //範囲修正は非活性に対応しています
      /* return !this.editingPointClouds.length || this.selectInfo === ''; */
    },
		editingPointClouds() {
      return this.$store.getters.editingPointClouds;
    },
    //次期開発非表示にしています
    /* selectInfo: {
      get() {
        return this.$store.getters['edit/thinningGroundSurface'].selectInfo;
      },
      set(value) {
        const property = {
          selectInfo: value
        }
        this.$store.dispatch('edit/setThinningGroundSurface', property);
      }
    } */
	},
  methods: {
    resetForm() {
      this.groundWidth = "";
      this.groundLength = "";
      this.groundThickness = "";
      this.selectInfo = '';
    },
    async resetError() {
      await this.$refs.observer.validate();
      await this.$refs.observer.reset();
    },
    /**
     * TODO : 範囲確認処理
     */
    checkRange() {

    },
    close() {
      this.resetForm();
      this.$emit("close");
    },
    //次期開発。非表示でコメントアウトしています
    /* hide() {
      const dialog = new Dialog("project");
      dialog.hide();
    }, */
    /* selectRangeType(type) {
      this.$store.dispatch("updateEditType", constants.editType.thinningGroundSurface);
      cesiumCommon.beginSelecting(constants.cesiumSelectingCameraType.cesium, type);
      this.rangeSelectionMode = true;
      // ダイアログを非表示にする
      this.hide();
    }, */
    // 上書き（保存）、別点群として保存処理を実施する。
    async execute(runValue) {
      const body = {
				user_id: this.$store.getters.loginData.user_id,
        ground_width: this.groundWidth,
        ground_length: this.groundLength,
				ground_thickness: this.groundThickness,
        execution_method: runValue,
      };
      this.request(body);
    },
    async request(body) {
      let successMessage = i18n.tc("CIW0020113001");
      let errorMessage = i18n.tc("CEW0020113002");

      try {
        this.$store.dispatch("updateIsLoading", true);
        for (const pointFile of this.editingPointClouds) {
          const res = await thinningGroundSurfaceRepository.createGroundByPointId(pointFile.pointId, body);
          if (!res.status === 201 && !res.status === 204) {
            // 想定外のステータスコードが返却された場合エラーをスロー
            throw new Error(errorMessage);
          }
        }
        await this.$store.dispatch("getPointDataList", this.$route.query.siteId)
        utils.showSnackBar('success', successMessage);
        // タイルセットを削除
        utils.allClearTileset();
        this.close();
      } catch (err) {
        console.error(err.message);
        if (utils.createErrorMessage(err)) {
          errorMessage = utils.createErrorMessage(err);
        }
        utils.showSnackBar('error', errorMessage);
      } finally {
        this.$store.dispatch("updateIsLoading", false);
      }
    },
  },
  mounted() {
    this.resetForm();
  }
};
</script>

<style lang="sass" scoped>
.thinning-ground-surface-dialog
  .v-card__title
    font-size: 16px

::v-deep .v-input__append-outer
  margin: 0

::v-deep .v-input__slot
  margin: 0
  padding: 0

.text-black ::v-deep input,
.text-black ::v-deep label,
.text-black ::v-deep .v-select__selection,
.text-black ::v-deep .v-input__append-inner i
  color: black

::v-deep .v-text-field input,
::v-deep .v-text-field.v-input .v-select__selections input
  padding: 0

.text-field-error
  border: solid 2px #ff5252

::v-deep .v-text-field__details
  display: none

::v-deep .v-text-field__suffix
  color: black

::v-deep div.v-radio
  div.v-input--selection-controls__input i.v-icon
    font-size: 14px
    color: #f0f0f0
  label.v-label
    font-size: 14px
    color: white

::v-deep div.v-input.v-text-field div.v-input__prepend-outer
  margin: auto 8px auto 1px
  color: white

::v-deep button.v-btn span.v-btn__content
  font-size: 13px

::v-deep div.shape-selection-area button.v-btn span.v-btn__content
  line-height: 100%

div.range-type-area
  border: 1px dashed white

.description-image
  cursor: pointer
</style>
