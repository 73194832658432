<!-- 点群ファイル -->
<template>
  <div class="data-list-title">
    <div class="d-flex align-center">
      <v-btn
        class="pa-0 mr-1"
        min-width="auto"
        plain
        height="auto"
        :disabled="isDisabledVisibleAndCheckbox"
        @click="changeVisibleState()"
      >
        <v-icon v-if="pointFile.isVisible">mdi-eye-outline</v-icon>
        <v-icon v-else>mdi-eye-off-outline</v-icon>
      </v-btn>
      <v-checkbox 
        v-if="!deleteMode"
        @click="changeEditingState()"
        class="my-0 mr-1 pa-0"
        :value="pointFile.isEditing"
        off-icon="mdi-checkbox-blank" 
        color="white" 
        :disabled="isDisabledVisibleAndCheckbox"
        hide-details
      >
      </v-checkbox>
      <v-checkbox 
        v-else
        :value="isChecked"
        @click="changeDeleteState()"
        :disabled="isDisabledDeleteCheckBox"
        off-icon="mdi-checkbox-blank" color="white" class="my-0 mr-1 pa-0"
        hide-details>
      </v-checkbox>
      <point-file-name :pointFile="pointFile"></point-file-name>
      <!-- アイコン表示エリア -->
      <div class="pl-3 ml-auto d-flex align-center">
          <!-- 虫眼鏡 -->
          <v-btn
            class="pa-0"
            min-width="auto"
            plain
            height="auto"
            :disabled="isDisabledVisibleAndCheckbox"
            @click="onMagnify()"
          >
            <v-icon v-if="pointFile.isVisible">mdi-magnify</v-icon>
            <!-- <v-icon v-if="checkVisibleState">mdi-magnify</v-icon> -->
            <!-- <v-icon v-else>mdi-eye-off-outline</v-icon> -->
          </v-btn>
        <!-- クリッピングアイコン -->
        <v-tooltip 
          v-if="pointFile.mode === 'clipping'"
          color="white"
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              class="ml-1"
              color="orange lighten-2"
              small
            >
            mdi-content-cut
            </v-icon>
          </template>
          <span class="black--text">{{ $t("CQW0020300020") }}</span>
        </v-tooltip>
        <!-- 未保存ステータス -->
        <v-tooltip 
          v-if="isUnSaved"
          color="white"
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon 
              v-bind="attrs"
              v-on="on"
              class="ml-1"
            > mdi-content-save-alert-outline
            </v-icon>
          </template>
          <span class="black--text">{{ $t("CQW0020300022") }}</span>
        </v-tooltip>
        <!-- 処理ステータス -->
        <div 
          v-if="pointFile.processStatus && pointFile.processStatus !== 10"
        >
          <edit-processing-status
            class="ml-1"
            @error-clear="clearProcessError"
            :status="pointFile.processStatus"
            :message="pointFile.processMessage">
          </edit-processing-status>
        </div>
        <!-- アップロードステータス -->
        <div v-if="pointFile.assetId && (showUploadProgress || isUploadError)">
          <progress-circle
            class="ml-1"
            :showUploadProgress="showUploadProgress"
            :isUploadError="isUploadError"
            @update-upload-progress="(value) => { showUploadProgress = value }"
            @update-upload-error-status="(value) => { isUploadError = value }"
            @error-clear="deletePointData"
            :file="pointFile"
          ></progress-circle>
        </div>
      </div>
    </div>
    <!-- 外周線 -->
    <perimeter-line
      class="mt-2 ml-4"
      v-if="pointFile.perimeterLine" 
      :perimeterLine="pointFile.perimeterLine"
      :allowedToShowPerimeterLine="pointFile.isVisible"
      >
    </perimeter-line>
    <!-- 計測データ -->
    <calculation-data-list 
      class="mt-2 ml-4"
      v-if="pointFile.flats.length" 
      :flatnessDataList="pointFile.flats"
      :allowedToShowFlatnessResults="pointFile.isVisible"
      >
    </calculation-data-list>
    <!-- 点数データ -->
    <number-of-point-data-list 
      class="mt-2 ml-4"
      v-if="pointFile.measure.length" 
      :measureDataList="pointFile.measure"
      :allowedToShowMeasuredResults="pointFile.isVisible"
      >
    </number-of-point-data-list>
  </div>
</template>

<script>
import * as utils from '@/utils/utils';
import * as constants from '@/constants/constants';
import EditProcessingStatus from '@/components/common/EditProcessingStatus.vue';
import PointFileName from "./PointFileName.vue";
import CalculationDataList from "./CalculationDataList.vue";
import NumberOfPointDataList from "./NumberOfPointDataList.vue";
import PerimeterLine from "./PerimeterLine.vue";
import * as cesiumCommon from '@/utils/cesium-common';
import ProgressCircle from "./ProgressCircle.vue";
import { i18n } from '@/i18n.js'
import { repositoryFactory } from "@/repositories/repository-factory";
const messageRepository = repositoryFactory.get('message');
const pointRepository = repositoryFactory.get('point');

export default {
  components: {
    EditProcessingStatus,
    PointFileName,
    ProgressCircle,
    CalculationDataList,
    NumberOfPointDataList,
    PerimeterLine 
  },
  props: {
    selected: Array,
    pointFile: Object,
    deleteMode: Boolean
  },
  data() {
    return {
      showUploadProgress: true,
      isUploadError: false
    }
  },
  computed: {
    isLoading: {
      get() {
        return this.$store.getters.isLoading;
      },
      set(value) {
        this.$store.dispatch("updateIsLoading", value);
      }
    },
    isAssetUploaded() {
      return this.pointFile.isAssetUploaded;
    },
    isUnSaved() {
      return this.$store.getters.unSavedDataList.includes(this.pointFile.pointId);
    },
    isChecked() {
      return this.selected.includes(this.pointFile.pointId);
    },
    /**
     * チェックボックスと可視不可視アイコンの活性制御を行う
     * 以下の場合非活性
     *  タイリング中、アセットがアップロードされていない、点群編集処理中、処理エラー(赤〇)状態のもの
     */
    isDisabledVisibleAndCheckbox() {
      return this.pointFile.tilingProgress 
          || this.pointFile.isAssetUploaded ===constants.assetUploadStatus.PENDING
          || this.pointFile.isAssetUploaded ===constants.assetUploadStatus.ERROR 
          || this.$store.getters.beginSelecting
          || this.pointFile.processStatus === 99;
    },
    /**
     * 削除チェックボックスの活性制御を行う
     * ・断面ファイルと紐づいている場合非活性
     */
    isDisabledDeleteCheckBox() {
      return !!this.$store.getters['sectionFile/sectionFileList']
            .find(section => section.pointId === this.pointFile.pointId);
    }
  },
  watch: {
    // アップロードステータスがPENDINGになれば再度ポーリング開始
    isAssetUploaded(newValue) {
      if (newValue === constants.assetUploadStatus.PENDING) {
        this.showUploadProgress = true;
        this.isUploadError = false;
      }
    },
  },
  methods: {
    /**
     * 編集処理エラーを解除する
     * 解除に失敗した場合は通知を表示する
     */
    async clearProcessError() {
      let successMessage = i18n.tc("CIW0020300018")
      let errorMessage = i18n.tc("CEW0020300019")
      const body = {
        user_id: this.$store.getters.loginData.user_id
      };
      try {
        this.$store.dispatch("updateIsLoading", true);
        const res = await messageRepository.updatePointMessageById(this.pointFile.pointId, body);
        if (res.status === 204) {
          // データリストを最新化
          await this.$store.dispatch("getPointDataList", this.$route.query.siteId)
          utils.showSnackBar('success', successMessage);
        } else {
          // 想定外のステータスコードが返却された場合エラーをスロー
          throw new Error(errorMessage);
        }
      } catch (e) {
        console.error(e.message);
        if (utils.createErrorMessage(e)) {
          errorMessage = utils.createErrorMessage(e);
        }
        utils.showSnackBar('error', errorMessage);
      } finally {
        this.$store.dispatch("updateIsLoading", false);
      }
    },
    /**
     * 点群の可視状態を変更する
     */
    changeVisibleState() {
      if(this.pointFile.isVisible) {
        cesiumCommon.visibilityOffNormal(this.pointFile);
        // 不可視にする場合は編集対象からも外す
        this.pointFile.isEditing = false;
      } else {
        cesiumCommon.visibilityOnNormal(this.pointFile);
      }
    },
    /**
     * 点群をデフォルト視点に戻す
     */
    onMagnify() {
      cesiumCommon.visibilityOn(this.pointFile);
    },
    /**
     * 編集チェックボタンの状態を切り替える
     */
    changeEditingState() {
      if(this.pointFile.isEditing) {
        this.pointFile.isEditing = false;
      } else {
        // 編集対象にする場合、可視化されていなければ同時に可視化する
        if ((!this.pointFile.isVisible)
        ) {
          cesiumCommon.visibilityOnNormal(this.pointFile);
        }
        this.pointFile.isEditing = true;
      }
    },
    changeDeleteState(){
      if (this.isChecked) this.$emit('delete-checked', this.pointFile.pointId)
      else this.$emit('add-checked', this.pointFile.pointId);
    },
    /**
     * 点群ファイルを削除する
     */
    async deletePointData() {
      let successMessage = i18n.tc("SIW0020301001");
      let errorMessage = i18n.tc("SEW0020301001");
      const body = {
        point_ids: [this.pointFile.pointId],
        user_id: this.$store.getters.loginData.user_id
      };
      try {
        this.$store.dispatch("updateIsLoading", true);
        const res = await pointRepository.deletePointsByPointIds(body);
        if (res.status !== 200) {
          // 想定外のステータスコードが返却された場合エラーをスロー
          throw new Error(errorMessage);
        }
        await this.$store.dispatch("getPointDataList", this.$route.query.siteId),
        // エラーがなければ正常メッセージを出力する
        utils.showSnackBar('success', successMessage);
      } catch (err) {
        // エラーが発生したら処理終了
        console.error(err.message);
        if (utils.createErrorMessage(err)) {
          errorMessage = utils.createErrorMessage(err);
        }
        utils.showSnackBar('error', errorMessage);
      } finally {
        this.$store.dispatch("updateIsLoading", false);
      }
    },
  },
  beforeDestroy() {
    // ファイルが削除されたらCesium上から削除
    cesiumCommon.removeTileset(this.pointFile);
  }
}
</script>

<style lang="sass" scoped>
.data-list-title
  font-size: 14px
  margin-bottom: 4px
  white-space: normal
  word-break: break-all

.data-list-title ::v-deep .v-text-field .v-input__control .v-input__slot
  min-height: auto

::v-deep .v-input__slot .v-input--selection-controls__input
  margin-right: 0
  height: auto

// チェックボックスホバー時の背景色の変化を無効化
::v-deep div.v-input--selection-controls__ripple
  display: none

</style>