<!-- 点数データリスト -->
<template>
  <div>
    <!-- 計測データリスト -->
    <v-list class="ml-4 pa-0" style="font-size: 14px">
      <!-- 点数データ -->
      <v-subheader class="pr-0 white--text align-center justify-space-between">
      {{$t("CQW0020002004")}}
      </v-subheader>
      <!-- 点数結果ファイル -->
      <v-list-item
        v-for="measureData in measureDataList"
        :key="measureData.id"
        class="data-list ml-3 pr-0"
      >
        <v-list-item-title>
          <measure-data
            :measureData="measureData"
            :labelFormatter="labelFormatter"
            :allowedToShowMeasuredResults="allowedToShowMeasuredResults"
          ></measure-data>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { i18n } from "@/i18n";
import { round } from "@/utils/utils";
import MeasureData from "./MeasureData.vue";

const resultItemDefinitions = [
  { resultKey: "Num(/1m2)", messageKey: "CQW0020125008" },
  { resultKey: "Num(/0.25m2)", messageKey: "CQW0020125009" },
  { resultKey: "Num(/0.01m2)", messageKey: "CQW0020125010" },
];

const DecimalPlaces = 3;  // ラベルに表示する小数点以下桁数

export default {
  components: {
   MeasureData 
  },
  props: {
    measureDataList: Array,
    allowedToShowMeasuredResults: Boolean,
  },
  methods: {
    labelFormatter(measureData) {
      return resultItemDefinitions
      .map(item => i18n.t(item.messageKey) + ": " + round(measureData.measureInfo[item.resultKey], DecimalPlaces))
      .join("\n");
    }
  },
};
</script>

<style lang="sass" scoped>
.data-list
  height: auto
  min-height: auto
  display: block

.v-subheader
  height: 28px
</style>