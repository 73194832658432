import Repository from "./repository";

const resource = "/point-edit";
export default {
  findBySiteId(siteId, payload) {
    return Repository.post(`${resource}/${siteId}`, payload);
  },
  saveBySiteId(siteId, payload) {
    return Repository.put(`${resource}/save/${siteId}`, payload);
  },
  goByPointId(pointId, payload) {
    return Repository.post(`${resource}/go/${pointId}`, payload);
  },
  backByPointId(pointId, payload) {
    return Repository.post(`${resource}/back/${pointId}`, payload);
  },
};
