var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"dashboard-export-dialog px-4 pt-2 pb-4"},[_c('loading',{attrs:{"parentLoading":_vm.isLoading}}),_c('v-card-title',{staticClass:"pa-0 justify-space-between"},[_c('label',[_vm._v(" "+_vm._s(_vm.$t("CQW0020108001"))+" ")]),_c('close-button',{attrs:{"size":"20px"},on:{"click":function($event){return _vm.$emit('close')}}})],1),_c('v-card-text',{staticClass:"px-0 pt-1 pb-0 white--text"},[_c('v-container',{staticClass:"pa-0"},[_c('v-row',{staticClass:"ma-0"},[_c('validation-observer',{ref:"observer",staticClass:"d-block",staticStyle:{"width":"100%"},scopedSlots:_vm._u([{key:"default",fn:function({ invalid, pristine }){return [_c('v-col',{staticClass:"my-3 pa-0",attrs:{"cols":"12"}},[_c('label',{staticClass:"mb-1 d-block"},[_vm._v(_vm._s(_vm.$t("CQW0020107002")))]),_c('v-select',{staticClass:"text-black",attrs:{"height":"32px","background-color":"#BFBFBF","items":_vm.folderList,"item-text":"name","item-value":"id","hide-details":"","solo":""},model:{value:(_vm.selectedFolder),callback:function ($$v) {_vm.selectedFolder=$$v},expression:"selectedFolder"}})],1),_c('v-col',{staticClass:"my-3 pa-0",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":{
              required: true,
            }}},[_c('label',{staticClass:"mb-1 d-block"},[_vm._v(_vm._s(_vm.$t("CQW0020107003")))]),_c('v-select',{staticClass:"text-black",attrs:{"height":"32px","background-color":"#BFBFBF","items":_vm.dataList,"item-text":"text","item-value":"value","hide-details":"","solo":""},model:{value:(_vm.selectedData),callback:function ($$v) {_vm.selectedData=$$v},expression:"selectedData"}})],1)],1),_c('v-col',{staticClass:"my-3 pa-0",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":{
              required: true,
            }}},[_c('label',{staticClass:"mb-1 d-block"},[_vm._v(_vm._s(_vm.$t("CQW0020107008")))]),_c('v-select',{staticClass:"text-black",attrs:{"height":"32px","background-color":"#BFBFBF","items":_vm.outputExtensionList,"item-text":"text","item-value":"value","hide-details":"","solo":""},model:{value:(_vm.selectedOutputExtension),callback:function ($$v) {_vm.selectedOutputExtension=$$v},expression:"selectedOutputExtension"}})],1)],1),_c('v-col',{staticClass:"my-3 pa-0",attrs:{"cols":"12"}},[_c('label',{staticClass:"mb-1 d-block"},[_vm._v(_vm._s(_vm.$t("CQW0020108006")))]),_c('v-sheet',{staticClass:"d-flex"},[_c('validation-provider',{attrs:{"rules":{
                required: true,
              }}},[_c('v-menu',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({staticClass:"text-black mr-4",attrs:{"readonly":"","height":"32px","background-color":"#BFBFBF","hide-details":"","solo":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}],null,true)},[_c('v-date-picker',{attrs:{"no-title":"","color":"primary"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('validation-provider',{attrs:{"rules":{
                required: true,
              }}},[_c('v-menu',{attrs:{"top":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({staticClass:"text-black",attrs:{"readonly":"","height":"32px","background-color":"#BFBFBF","hide-details":"","solo":""},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.timeMenu),callback:function ($$v) {_vm.timeMenu=$$v},expression:"timeMenu"}},[_c('time-picker',{attrs:{"time":_vm.time},on:{"set-time":(newTime) => { _vm.time = newTime },"confirm":function($event){_vm.timeMenu = false}}})],1)],1)],1)],1),_c('v-col',{staticClass:"mt-4 pa-0",attrs:{"cols":"4"}},[_c('v-btn',{staticClass:"rounded-pill",attrs:{"color":"primary","width":"135px","height":"35px","depressed":"","disabled":pristine || invalid},on:{"click":_vm.register}},[_vm._v(" "+_vm._s(_vm.$t("CIW0020109006"))+" ")])],1)]}}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }