/**
 * サイト内のページ名
 */
export const pageName = Object.freeze({
  // プロジェクトリスト
  projectList: 'ProjectList',
  // プロジェクト
  project: 'Project',
  error404: 'Error404',
  error500: 'Error500'
})

/**
 * Smart Constructionの言語コードを変換する
 *  ja: 日本語, en: 英語、 fr: フランス語, 'de': ドイツ語, 'cs-CZ': チェコ語
 */
export const language = Object.freeze({
  'ja': 'ja',
  'en': 'en',
  'fr-FR': 'fr',
  'de-DE': 'de',
  'cs-CZ': 'cz'
})

/**
 * サーバー側EXEの処理ステータス
 */
export const exeProcessStatus = Object.freeze({
  succeeded: 10,
  request: 20,
  start: 30,
  cancelNG: 40,
  error: 99
})

/**
 * 編集機能
 */
export const editType = Object.freeze({
  clipping: 0,
  unnecessaryRemoval: 1,
  holeInterpolation: 2,
  thinningGroundSurface: 3,
  flatnessConditionCreate: 4,
  numberOfPoints: 5,
  crossSectionEdit: 8,
  perimeterLine: 9,
});

/**
 * 現場のロック状況
 */
export const lockState = Object.freeze({ 
  // 自分ががロック取得中
  edit: "edit", 
  // 誰もロックしていない
  noEdit: "noEdit",
  // 他人がロック取得中
  view: "view" 
})

/**
 * viewerのモード
 */
export const viewerMode = Object.freeze({
  // 範囲選択
  selecting: 'selecting',
  // 線形作成
  lineCreation: 'lineCreation',
  // 線形編集
  SIMAEdit: 'SIMAEdit',
  // 断面線編集
  crossSectionEdit: 'crossSectionEdit',
  // 距離描画
  addPointsForDistanceCalculation: 'addPointsForDistanceCalculation'
})

/**
 * viewerの範囲選択機能の区分
 *  polygon: 多角形, box: 矩形, circle: 円
 */
export const cesiumSelectingType = Object.freeze({
  polygon: 1,
  box: 2,
  circle: 3
})

/**
 * 断面編集モード
 */
export const crossSectionViewerMode = Object.freeze({
  // 移動
  move: "move",
  // 追加
  add: "add",
  // 削除
  delete: "delete"
})

/**
 * viewerの範囲選択機能のカメラの区分
 *  canvas: 立体, cesium: 平面
 */
export const cesiumSelectingCameraType = Object.freeze({
  canvas: 'canvas',
  cesium: 'cesium'
})

/**
 * 背景地図選択の区分
 *  0：衛星写真, 1：衛星写真 + 地名表示, 2：地図 + 道路標示
 */
export const backgroundMapType = Object.freeze({
  satellite: 0,
  satelliteAndPlace : 1,
  mapAndRoad: 2
})

/**
 * アセットのアップロード状況
 */
export const assetUploadStatus = Object.freeze({
  PENDING: 'PENDING',
  DONE: 'DONE',
  ERROR: 'ERROR'
});

/**
 * 点群ファイルに使用可能な拡張子
 */
export const pointEnableExtensions = Object.freeze([
  'las',
  'laz',
  'csv',
  'txt'
]);

/**
 * デザインファイルに使用可能な拡張子
 */
export const designEnableExtensions = Object.freeze([
// 開発途中のため使用可能な拡張子はxmlに限定
  // 'dxf', 
  'xml', 
  // 'ttm', 
  // 'svd', 
  // 'dsz', 
  // 'tp3', 
  // 'tn3'
]);

/**
 * 線形ファイルに使用可能な拡張子
 */
export const linearEnableExtensions = Object.freeze([
  'sim'
]);

/**
 * 点群作成に使用可能な拡張子
 */
export const pointCreateEnableExtensions = Object.freeze([
  'xml'
]);

/**
 * 点群合成に使用可能な拡張子
 */
export const pointSyntheticEnableExtensions = Object.freeze([
  'las'
]);