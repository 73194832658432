// プロジェクトデータ
import Point from './Point';

export default class Project {
  constructor(id, name, points) {
    this.id = id;
    this.name = name;
    this.setPoints(points)
  }

  // getter
  getId() {
    return this.id;
  }

  getName() {
    return this.name;
  }

  getPoints() {
    return this.children ? this.children : [];
  }

  // setter
  setId(id) {
    this.id = id;
  }

  setName(name) {
    this.name = name;
  }

  setPoints(points) {
    const objArray = [];
    for (const pointData of points) {
      const target = this.getPoints().find(point =>
        pointData.point_id === point.pointId
      );
      // リストに存在する場合は更新、しない場合は追加
      if (target) {
        target.update(
            pointData?.point_name,
            pointData?.mode,
            pointData?.req_info,
            pointData?.asset_id,
            pointData?.created_at,
            pointData?.process_status,
            pointData?.process_status_at,
            pointData?.process_message,
            pointData?.is_process_cancel,
            pointData?.flats,
            pointData?.measure,
            pointData?.perimeterLine
          );
        objArray.push(target);
      } else {
        const point = new Point(
          pointData?.point_id,
          pointData?.point_name,
          pointData?.mode,
          pointData?.req_info,
          pointData?.asset_id,
          pointData?.created_at,
          pointData?.process_status,
          pointData?.process_status_at,
          pointData?.process_message,
          pointData?.is_process_cancel,
          pointData?.flats,
          pointData?.measure,
          pointData?.perimeterLine
        )
        objArray.push(point)
      }
    }
    this.children = objArray
  }

  // functions
  update(name, points) {
    this.setName(name);
    this.setPoints(points);
  }

  getPointByPointId(pointId) {
    return this.getPoints().find(point => point.pointId === pointId);
  }

  getDesignByDesignId(designId) {
    return this.getDesigns().find(design => design.designId === designId);
  }
}