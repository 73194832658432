<template>
  <v-card>
    <v-card-title class="justify-space-between" style="font-size: 18px">
      {{$t("SIW0020302002")}}
      <close-button @click="$emit('close')" size="18px"></close-button>
    </v-card-title>
    <v-card-text class="py-1" style="font-size: 18px">
      <v-container class="pa-0 pt-2">
        <v-row class="my-0" align="center">
          <v-col
            class="pt-0 d-flex align-center justify-space-between"
            cols="12"
          >
            <p
              class="ma-0 mr-2 pa-0 d-inline-block"
            >{{$t("SIW0020301003")}}</p>
            <div>
              <v-btn
               class="mr-2"
               color="red lighten-1"
               width="110px"
               @click="changeDeleteMode"
              >
              {{$t("CIW0020301001")}}
              </v-btn>
              <v-btn
                color="red accent-4"
                width="110px"
                @click="allDelete"
              >
                {{$t("CIW0020301002")}}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import CloseButton from "@/components/common/CloseButton.vue";

export default {
  components: {
    CloseButton,
  },
  methods: {
    changeDeleteMode() {
      this.$emit("change-delete-mode");
    },
    allDelete() {
      this.$emit("all-delete");
    },
  },
};
</script>

<style lang="sass" scoped>
::v-deep .v-btn__content
  font-size: 18px
  line-height: 100%

.sortButton__normal
  color: black

.sortButton__selected
  color: white
</style>
