import Repository from "./repository";

const resource = "/clipping";
export default {
  createByPointId(pointId, payload) {
    return Repository.post(`${resource}/${pointId}`, payload);
  },
  deleteBySiteId(siteId, payload) {
    return Repository.delete(`${resource}/${siteId}`, { data: payload });
  }
};
