<template>
  <v-navigation-drawer permanent :mini-variant.sync="this.mini" mini-variant-width="48" width="380"
    mobile-breakpoint="0" color="panel_sub" dark clipped app>
    <v-list-item :class="this.mini ? 'px-0' : ''">
      <v-list-item-avatar v-if="mini" width="48px" class="ma-0">
        <v-icon @click.stop="toggleToolPanelMini()">
          icon-Chevron-Right
        </v-icon>
      </v-list-item-avatar>
      <v-list-item-title class="data-list-title">
        <span @click="gotoList()" class="hover-blue">{{$t("CIW0010100001")}}</span>
        <v-icon>icon-Chevron-Right</v-icon> {{ site.site_name }}
      </v-list-item-title>
      <v-btn icon @click.stop="toggleToolPanelMini()">
        <v-icon> icon-Chevron-Left </v-icon>
      </v-btn>
    </v-list-item>
    <v-tabs vertical background-color="panel_sub" style="width: 100%">
      <v-tab class="px-0">
        <v-icon> icon-Home </v-icon>
      </v-tab>
      <v-tab class="px-0">
        <v-icon> icon-Setting </v-icon>
      </v-tab>
      <!-- 点群編集 -->
      <v-tab-item class="panel_main">
        <v-card class="pb-5 panel_main project">
          <v-card-title
            style="background-color: #1e1e1e"
            class="mt-3 pr-3 d-flex justify-space-between align-center">
            {{$t("CIW0020300002")}}
            <v-btn 
              :disabled="$store.getters.isLoading"
              class="pa-0"
              height="22px"
              width="22px"
              min-width="0"
              @click="fetchProjectList();"
              icon
            >
            <v-icon color="#B1B8B8">mdi-sync</v-icon>
            </v-btn>
          </v-card-title>
          <!-- 背景地図 -->
          <v-expansion-panels class="map-selection">
            <v-expansion-panel>
              <v-expansion-panel-header style="font-size: 14px" class="pa-2">
                <div class="d-flex align-center">
                  <!-- 背景地図ONの状態 -->
                  <v-btn
                    v-if="$store.getters.backgroundMap.visibility"
                    width="24px"
                    min-width="24px"
                    plain
                    class="pa-0 mr-1"
                    @click.stop="changeGlobeVisibility(false)"
                  >
                    <v-icon>mdi-eye-outline</v-icon>
                  </v-btn>
                  <!-- 背景地図OFFの状態 -->
                  <v-btn
                    v-else
                    width="24px"
                    min-width="24px"
                    plain
                    class="pa-0 mr-1"
                    @click.stop="changeGlobeVisibility(true)"
                  >
                    <v-icon>mdi-eye-off-outline</v-icon>
                  </v-btn>
                  <div>{{$t("CIW0020300003")}}</div>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <map-selection></map-selection>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <!-- 点群データ -->
          <div class="mt-3 project_data">
            <v-card-subtitle
              class="pr-3 d-flex justify-space-between align-center"
              style="font-size: 15px; background-color: #1e1e1e"
            >
            <span>{{$t("CIW0020000008")}}</span>
            </v-card-subtitle>
            <v-expansion-panels
              v-model="pointListPanel"
              multiple
            >
              <point-file-list
                :pointData="file"
                :pointListPanel="pointListPanel"
                v-for="(file, index) in $store.getters.pointDataList"
                :panelIndex="index"
                @open-panel="openPointDataPanel(index)"
                :key="file.id"
              ></point-file-list>
            </v-expansion-panels>
            <!-- 設計データ -->
            <design-file-list class="mt-3"/>
            <!-- 線形データ -->
            <linear-file-list class="mt-3"/>
            <!-- 断面データ -->
            <section-file-list class="mt-3"/>
            <!-- 計測条件データ -->
            <calculation-condition-list class="mt-3"/>
          </div>
        </v-card>
      </v-tab-item>
      <!-- 各種設定 -->
      <v-tab-item class="panel_main">
        <v-card class="panel_main">
          <v-card-title class="py-1">
            {{ $t("CIW0020000061") }}
          </v-card-title>
          <!-- 単位 -->
          <v-card-text>
            <v-card-subtitle class="px-0">
              {{ $t("CIW0020000062") }}
            </v-card-subtitle>
            <v-simple-table dense>
              <tbody>
                <tr>
                  <td style="width: 40%">
                    {{ $t("CIW0020000044") }}
                  </td>
                  <td>{{ $t("CIW0020000064") }}</td>
                </tr>
                <tr>
                  <td>
                    {{ $t("CIW0020000065") }}
                  </td>
                  <td>{{ $t("CIW0020000066") }}</td>
                </tr>
                <tr>
                  <td>
                    {{ $t("CIW0020000067") }}
                  </td>
                  <td>{{ $t("CIW0020000068") }}</td>
                </tr>
                <tr>
                  <td>
                    {{ $t("CIW0020000069") }}
                  </td>
                  <td>
                    <v-select v-model="unit" :items="unitTypes" item-text="label" item-value="value" dense hide-details
                      class="px-1"></v-select>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <!-- 座標系 -->
            <v-card-subtitle class="px-0">
            {{ $t("CQW0020900004") }}
            </v-card-subtitle>
            <v-simple-table dense>
              <tbody>
                <tr>
                  <td>rotation</td>
                  <td>
                    {{ truncateDecimals(site.rotation, 5) }}
                  </td>
                </tr>
                <tr>
                  <td>origin_easting</td>
                  <td>
                    {{ truncateDecimals(site.origin_easting, 5) }}
                  </td>
                </tr>
                <tr>
                  <td>origin_northing</td>
                  <td>
                    {{ truncateDecimals(site.origin_northing, 5) }}
                  </td>
                </tr>
                <tr>
                  <td>origin_latitude</td>
                  <td>
                    {{ truncateDecimals(site.origin_latitude, 5) }}
                  </td>
                </tr>
                <tr>
                  <td>origin_longitude</td>
                  <td>
                    {{ truncateDecimals(site.origin_longitude, 5) }}
                  </td>
                </tr>
                <tr>
                  <td>scale_factor</td>
                  <td>
                    {{ truncateDecimals(site.scale_factor, 5) }}
                  </td>
                </tr>
                <tr>
                  <td>vertical_offset</td>
                  <td>
                    {{ truncateDecimals(site.vertical_offset, 5) }}
                  </td>
                </tr>
                <tr>
                  <td>incline_x</td>
                  <td>
                    {{ truncateDecimals(site.incline_x, 5) }}
                  </td>
                </tr>
                <tr>
                  <td>incline_y</td>
                  <td>
                    {{ truncateDecimals(site.incline_y, 5) }}
                  </td>
                </tr>
                <tr>
                  <td>toMeters</td>
                  <td>
                    {{ truncateDecimals(site.to_meters, 5) }}
                  </td>
                </tr>
                <tr>
                  <td style="width: 40%">
                    COORDINATE_SYSTEM
                  </td>
                  <td>
                    {{ site.coordinate_system }}
                  </td>
                </tr>
                <tr>
                  <td>VERTICAL_SHIFT</td>
                  <td>
                    {{ site.vertical_shift }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <v-btn
              class=".rounded-lg mr-5"
              color="#ff5050"
              width="115px"
              height="25px"
              depressed
              @click="showCoordinateChangeConfirmDialog()"
              >{{ $t("CIW0020000071") }}</v-btn
            >
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
    <list-dialog
      :isShow="dialog.isShow"
      :name="dialog.name"
      :item="dialog.item"
      :event="dialog.event"
      v-bind="dialog.attributes"
      v-on="dialog.handlers"
    ></list-dialog>
  </v-navigation-drawer>
</template>

<script>
import "@/assets/icons/style.css";
import "earthbrain-viewer";
import PointFileList from "./DataList/PointFileList.vue";
import DesignFileList from "./DataList/DesignFileList.vue";
import LinearFileList from "./DataList/LinearFileList.vue";
import SectionFileList from "./DataList/SectionFileList.vue";
import CalculationConditionList from "./DataList/CalculationConditionList.vue";
import MapSelection from "./MapSelection.vue";
import { i18n } from '@/i18n.js'
import * as utils from '@/utils/utils';
import ListDialog from '@/components/List/ListDialog.vue';
import * as cesiumCommon from '@/utils/cesium-common';

export default {
  name: "ToolPanel",
  components: {
    PointFileList,
    DesignFileList,
    LinearFileList,
    SectionFileList,
    CalculationConditionList,
    MapSelection,
    ListDialog
  },
  data: () => ({
    mini: false,
    unit: "ratio",
    pointListPanel: [],
    dialog: {
      isShow: false,
      name: '',
      attribute: {},
      handlers: {},
      item: null,
      event: null
    },
  }),
  computed: {
    site() {
      return this.$store.getters.site;
    },
    unitTypes() {
      return [
        {
          label: "%",
          value: "percent",
        },
        {
          label: this.$t("CQW0020000018"),
          value: "ratio",
        },
      ];
    }
  },
  methods: {
    /**
     * 小数点以下の指定した桁数nを超える部分を切り捨てる
     * @param {*} num フォーマット対象の数字
     * @param {*} n 小数点以下の桁数
     */
    truncateDecimals(num, n) {
      const regex = /^[+-]?\d+(\.\d+)?$/;
      // フォーマット対象が数値形式かチェック
      if (regex.test(num)) 
      {
        // 小数点以下の桁数がnより多い場合は余分な桁数を切り捨てる
        if (num.indexOf('.') !== -1 && num.split('.')[1].length > n) {
          return parseFloat(num).toFixed(n);
        } 
        // 小数点なし or 小数点以下の桁数がn以下の数値は形式だけ変換する
        return parseFloat(num);
      }
      // 数値でない場合はそのまま返す
      return num;
    },
    changeGlobeVisibility(visibility) {
      cesiumCommon.changeGlobeVisibility(visibility);
    },
    async fetchProjectList() {
      let successMessage = i18n.tc("CQW0020300012")
      let errorMessage = i18n.tc("CQW0020300013")
      try {
        this.$store.dispatch("updateIsLoading", true);
        await Promise.all([
          this.$store.dispatch("getPointDataList", this.$route.query.siteId),
          this.$store.dispatch('designFile/fetchDesignFileList', this.$route.query.siteId),
          this.$store.dispatch('linearFile/fetchLinearFileList', this.$route.query.siteId),
          this.$store.dispatch('sectionFile/fetchSectionFileList', this.$route.query.siteId),
          this.$store.dispatch('flatness/fetchFlatnessConditionList', this.$route.query.siteId)
        ]);
        // エラーがなければ正常メッセージを出力する
        utils.showSnackBar('success', successMessage);
      } catch (e) {
        // エラーが発生したら処理終了
        console.error(e);
        if (utils.createErrorMessage(e)) {
          errorMessage = utils.createErrorMessage(e);
        }
        utils.showSnackBar('error', errorMessage);
      } finally {
        this.$store.dispatch("updateIsLoading", false);
      }
    },
    showCoordinateChangeConfirmDialog() {
      const dialogHandlers = {
        'ok': this.closeDialog,
        'close': this.closeDialog,
        'cancel': this.closeDialog
      }
      this.showDialog('coordinate-update-dialog', {}, dialogHandlers)
    },
    showDialog(name, attributes, handlers, item, event) {
      setTimeout(()=>{
        this.dialog = {
          isShow: true,
          name,
          attributes,
          handlers,
          item,
          event
        }
      }, 50)
    },
    closeDialog() {
      this.dialog = {
        attributes: {},
        handlers: {},
        isShow: false,
        name: '',
        item: null,
        event: null
      }
    },
    openPointDataPanel(panelIndex) {
      this.pointListPanel = [...this.pointListPanel, panelIndex];
    },
    toggleToolPanelMini() {
      this.mini = !this.mini;
    },
    gotoList() {
      const event = new Event("popstate");
      window.dispatchEvent(event);
    }
  },
  mounted() {
    console.log("ToolPanel Mounted func");
    const siteId = this.$route.query.siteId;
    this.$store.dispatch("getPointDataList", siteId);
    this.$store.dispatch("fetchSite", siteId);
  },
};
</script>

<style scoped>
.v-tab {
  min-width: 48px !important;
  width: 48px;
}

td {
  padding: 0px 8px 0px 8px !important;
}

.hover-blue:hover {
  color: #1976d2;
}

.highlight {
  background-color: #004f93 !important;
  border: #ffffff solid 1px;
}

.Doing {
  background-color: #0064ba !important;
  border: #0064ba solid 2px;
}

.Doing:hover {
  background-color: #0059a6 !important;
  border: #0059a6 solid 2px;
}

.list-doing:hover {
  background-color: #0064ba !important;
}

.list-delayed:hover {
  background-color: #b90007;
}

::v-deep .v-expansion-panel-content__wrap {
  padding: 0 0 8px 0;
}

::v-deep .v-window.v-item-group.theme--dark.v-tabs-items {
  overflow: visible;
}

::v-deep .v-list-item-group .v-list-item.v-item--active {
  color: #2196f3 !important;
  opacity: 0.9;
}

::v-deep .v-list-item-group .v-item--active .v-image__image--cover {
  border-radius: 50%;
  border: solid 2px #2196f3;
}

::v-deep .v-navigation-drawer__content {
  overflow: hidden;
}

::v-deep .v-window.v-item-group.theme--dark.v-tabs-items {
  overflow: auto;
}

::v-deep .panel_main .v-icon.v-icon {
  font-size: 1.3rem;
}

::v-deep .v-window-item.panel_main {
  height: calc((100vh - 192px) - 48px);
  overflow: auto;
}

.project {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.project_data {
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1;
}

</style>
