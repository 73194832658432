import Repository from "./repository";

const resource = "/thinning";
export default {
  createDesignByPointId(pointId, payload) {
    return Repository.put(`${resource}/design/${pointId}`, payload);
  },
  createLatticeByPointId(pointId, payload) {
    return Repository.put(`${resource}/lattice/${pointId}`, payload);
  }, 
};
