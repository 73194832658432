import Repository from "./repository";

const resource = "/message";
export default {
  // 点群メッセージ確認
  updatePointMessageById(pointId, payload) {
    return Repository.put(`${resource}/point/${pointId}`, payload);
  },
  // 平坦度メッセージ確認
  updateFlatMessageById(pointId, payload) {
    return Repository.put(`${resource}/flat/${pointId}`, payload);
  },
  // 計測データメッセージ確認
  updateMeasureMessageById(pointId, payload) {
    return Repository.put(`${resource}/measure/${pointId}`, payload);
  },
  // 断面ファイルメッセージ確認
  updateSectionFileMessageById(sectionId, payload) {
    return Repository.put(`${resource}/section/${sectionId}`, payload);
  }
};
