<!-- 平坦度計算条件作成 -->
<template>
  <v-card class="project-dialog px-4 pt-2 pb-4">
    <v-card-title class="pa-0 justify-space-between">
      <label>
        {{$t("CQW0020126001")}}
      </label>
      <close-button @click="$emit('close')" size="20px"></close-button>
    </v-card-title>
    <v-card-text class="px-0 pt-1 pb-1 white--text">
      <v-container class="pa-0">
        <v-row class="ma-0">
          <validation-observer 
            class="d-block" 
            style="width: 100%" 
            ref="observer" 
            v-slot="{ invalid, pristine }">
            <!-- データ名 -->
            <v-col cols="12" class="mt-3 pa-0">
              <validation-provider :rules="{
                required: true,
                'data-name-length': 100
              }" v-slot="{ errors }">
                <div class="mb-1">
                  <label class="d-inline-block mr-2">{{$t("CQW0020104006")}}</label>
                  <span class="d-inline-block" style="font-size: 12px; color: #ff5252">{{ errors[0] }}</span>
                </div>
                <v-text-field @reset.prevent="resetError()" v-model.trim="dataName" :error-messages="errors"
                  class="text-black ma-0" height="32px" background-color="#BFBFBF" outlined solo></v-text-field>
              </validation-provider>
            </v-col>
            <!-- グリッドサイズ -->
            <v-col cols="12" class="mt-3 pa-0">
              <validation-provider :rules="{
                required: true,
                'number-format': true,
                'decimal-size-limit': 2,
                'min-value': 0.5,
                'max-value': 100.9,
              }" v-slot="{ errors }">
                <div class="mb-1">
                  <label class="d-inline-block mr-2">{{$t("CQW0020126003")}}</label>
                  <span class="d-inline-block" style="font-size: 12px; color: #ff5252">{{ errors[0] }}</span>
                </div>
                <v-text-field
                  @reset.prevent="resetError()"
                  v-model="gridSize"
                  :error-messages="errors"
                  class="text-black ma-0"
                  height="32px"
                  background-color="#BFBFBF"
                  outlined
                  solo
                  suffix="m"
                  >
                </v-text-field>
              </validation-provider>
            </v-col>
            <!-- 図形選択 -->
            <v-col cols="12" class="mt-3 pa-0">
              <label class="mb-1 d-block">{{$t("CIW0020000015")}}</label>
              <div class="d-flex justify-space-between range-type-area">
                <div
                  class="py-1 shape-selection-area d-flex justify-space-between "
                  style="width: 270px">
                  <v-btn text @click="selectRangeType(cesiumSelectingType.polygon)">
                    <v-icon size="24px" left>mdi-pentagon</v-icon>
                    {{$t("CQW0020113007")}}
                  </v-btn>
                  <v-btn text @click="selectRangeType(cesiumSelectingType.box)">
                    <v-icon size="24px" left>mdi-square</v-icon>
                    {{$t("CQW0020113008")}}
                  </v-btn>
                  <v-btn text @click="selectRangeType(cesiumSelectingType.circle)">
                    <v-icon size="24px" left>mdi-circle</v-icon>
                    {{$t("CQW0020113009")}}
                  </v-btn>
                </div>
              </div>
            </v-col>
            <v-col cols="12" class="mt-4 pa-0 d-flex justify-end">
             <v-btn
                class="mr-10 rounded-lg"
                color="#ff5050"
                width="115px"
                height="45px"
                depressed
                @click="$emit('close')">
                {{$t("CQW0010600005")}}
              </v-btn>
              <v-btn
                class="rounded-lg"
                color="#4472c4"
                width="115px"
                height="45px"
                depressed
                :disabled="pristine || invalid || isSaveBtnDisabled"
                @click="save">
                {{$t("CIW0020000005")}}
              </v-btn>
            </v-col>
          </validation-observer>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import CloseButton from "@/components/common/CloseButton.vue";
import Dialog from "@/components/modules/dialog";
import * as utils from "@/utils/utils";
import * as cesiumCommon from "@/utils/cesium-common";
import * as constants from "@/constants/constants";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { repositoryFactory } from "@/repositories/repository-factory";
import { i18n } from '../../../i18n.js'
const measureRepository = repositoryFactory.get('measure');

export default {
  components: {
    CloseButton,
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    cesiumSelectingType: constants.cesiumSelectingType
	}),
  computed: {
    isSaveBtnDisabled() {
      if (this.selectInfo === "") return true;
      else return false;
    },
    dataName: {
      get() {
        return this.$store.getters['edit/conditionCreate'].dataName;
      },
      set(value) {
        const property = {
          dataName: value
        }
        this.$store.dispatch('edit/setConditionCreate', property);
      }
    },
    gridSize: {
      get() {
        return this.$store.getters['edit/conditionCreate'].gridSize;
      },
      set(value) {
        const property = {
          gridSize: value
        }
        this.$store.dispatch('edit/setConditionCreate', property);
      }
    },
    selectShape: {
      get() {
        return this.$store.getters['edit/conditionCreate'].selectShape;
      },
      set(value) {
        const property = {
          selectShape: value
        }
        this.$store.dispatch('edit/setConditionCreate', property);
      }
    },
    selectInfo: {
      get() {
        return this.$store.getters['edit/conditionCreate'].selectInfo;
      },
      set(value) {
        const property = {
          selectInfo: value
        }
        this.$store.dispatch('edit/setConditionCreate', property);
      }
    }
  },
  methods: {
    resetForm() {
      this.dataName = '';
      this.gridSize = '';
      this.selectShape = '';
      this.selectInfo = '';
      this.resetError();
    },
    async resetError() {
      await this.$refs.observer.validate();
      await this.$refs.observer.reset();
    },
    selectRangeType(type) {
      this.selectShape = type;
      this.$store.dispatch("updateEditType", constants.editType.flatnessConditionCreate);
      cesiumCommon.beginSelecting(constants.cesiumSelectingCameraType.cesium, type);
      this.isRangeSelectionMode = true;
      // ダイアログを非表示にする
      const dialog = new Dialog("cesium");
      dialog.hide();
    },
    async save() {
      const body = {
        condition_name: this.dataName,
        grid_size: this.gridSize,
        select_shape: this.selectShape,
        select_info: this.selectInfo,
				user_id: this.$store.getters.loginData.user_id,
      };
      await this.request(body);
    },
    async request(body) {
      let successMessage = i18n.tc("CIW0020126002");
      let errorMessage = i18n.tc("CEW0020126004");
      const siteId = this.$route.query.siteId;
      try {
        this.$store.dispatch("updateIsLoading", true);
        const res = await measureRepository.crateFlatConditionBySiteId(siteId, body);
        if (res.status === 201) {
          utils.showSnackBar('success', successMessage);
          this.$emit('close');
        } else {
          // 想定外のステータスコードが返却された場合エラーをスロー
          throw new Error(errorMessage);
        }
        this.$store.dispatch('flatness/fetchFlatnessConditionList', this.$route.query.siteId)
        this.resetForm();
      } catch (err) {
        console.error(err);
        if (utils.createErrorMessage(err)) {
          errorMessage = utils.createErrorMessage(err);
        }
        utils.showSnackBar('error', errorMessage);
      } finally {
        this.$store.dispatch("updateIsLoading", false);
      }
    },
  },
  mounted() {
    this.resetForm();
  }
};
</script>

<style lang="sass" scoped>
.project-dialog
  .v-card__title
    font-size: 16px

::v-deep .v-input__append-outer
  margin: 0

::v-deep .v-input__slot
  margin: 0
  padding: 0

.text-black ::v-deep input,
.text-black ::v-deep label,
.text-black ::v-deep .v-select__selection,
.text-black ::v-deep .v-input__append-inner i
  color: black

::v-deep .v-text-field input,
::v-deep .v-text-field.v-input .v-select__selections input
  padding: 0

.text-field-error
  border: solid 2px #ff5252

::v-deep .v-text-field__details
  display: none

::v-deep .v-text-field__suffix
  color: black

:v-deep div.v-radio
  div.v-input--selection-controls__input i.v-icon
    font-size: 14px
    color: #f0f0f0
  label.v-label
    font-size: 14px
    color: white

::v-deep div.v-input.v-text-field div.v-input__prepend-outer
  margin: auto 8px auto 1px
  color: white

::v-deep button.v-btn span.v-btn__content
  font-size: 13px

::v-deep div.shape-selection-area button.v-btn span.v-btn__content
  line-height: 100%

div.range-type-area
  border: 1px dashed white
</style>
