<!-- 設計データリスト -->
<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header
        style="font-size: 15px; background-color: #1e1e1e"
        class="pa-2"
      >
        <div class="d-flex align-center">
          <!-- メニューボタン -->
          <v-btn
            width="24px"
            plain
            min-width="24px"
            class="pa-0 mr-1"
            @click.stop="changeShawEditDialog"
          >
            <v-icon style="background-color: transparent">mdi-menu</v-icon>
          </v-btn>
          <div>{{$t("CIW0020000010")}}</div>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="pt-1">
        <!-- 設計データリスト -->
        <v-list class="pa-0" style="font-size: 14px">
          <v-list-item
            v-for="designFile in designFileList"
            :key="designFile.designId"
            class="data-list mb-3"
          >
            <v-list-item-title>
              <design-file
                :selected="selected"
                @add-checked="addChecked"
                @delete-checked="deleteChecked"
                :deleteMode="deleteMode"
                :designFile="designFile"
              ></design-file>
            </v-list-item-title>
          </v-list-item>
        </v-list>
        <div v-if="deleteMode" class="d-flex justify-end mb-2">
          <v-btn
            class="mr-3"
            color="red lighten-1"
            width="100px"
            :disabled="this.selected.length === 0"
            @click="deleteDesignFile"
          >
          {{$t("CIW0020301001")}}
          </v-btn>
        </div>
      </v-expansion-panel-content>
      <v-dialog
        hide-overlay
        persistent
        no-click-animation
        v-model="showEditDialog"
        width="360px"
      >
        <design-edit-dialog
          @close="changeShawEditDialog"
          @change-delete-mode="changeDeleteMode"
          @all-delete="allDelete"
        />
      </v-dialog>
    </v-expansion-panel>
  </v-expansion-panels>

</template>

<script>
import DesignFile from "./DesignFile.vue"
import DesignEditDialog from "../DesignEditDialog.vue";
import * as utils from '@/utils/utils';
import { repositoryFactory } from "@/repositories/repository-factory";
import { i18n } from '../../../i18n.js'
const designDataRepository = repositoryFactory.get('designData');

export default {
  components: {
    DesignFile,
    DesignEditDialog
  },
  data() {
    return {
      showEditDialog: false,
      deleteMode: false,
      selected: [],
    }
  },
  computed: {
    designFileList() {
      return this.$store.getters['designFile/designFileList']
    }
  },
  watch: {
    designFileList(newValue) {
      if (newValue.length === 0) {
        this.deleteMode = false;
        this.showEditDialog = false;
      }
    }
  },
  methods: {
    // エディットダイアログの開閉を行う
    changeShawEditDialog() {
      if (this.deleteMode) {
        this.changeDeleteMode();
      }
      this.showEditDialog = !this.showEditDialog;
    },
    // チェックボックスがチェックされたとき
    addChecked(designId) {
      this.selected.push(designId);
    },
    // チェックボックスが外れたとき
    deleteChecked(designId) {
      this.selected = this.selected.filter(id => id !== designId);
    },
    // エディットダイアログで削除ボタン押された際の動作
    changeDeleteMode() {
      this.deleteMode = !this.deleteMode;
    },
    // 選択された設計ファイルを削除
    deleteDesignFile() {
      if (this.selected.length === 0) return;
      this.request(this.selected);
    },
    // 設計ファイルを全削除
    allDelete() {
      if (this.designFileList.length === 0) return;
      const deleteIdList = this.designFileList.map(designFile => designFile.designId);
      this.request(deleteIdList);
    },
    async request(deleteIdList) {
      let successMessage = i18n.tc("SIW0020302001");
      let errorMessage = i18n.tc("SEW0020302001");
      const successDesignIdList = []

      const body = {
        deleted_by: this.$store.getters.loginData.user_id
      }
      try {
        for(const designId of deleteIdList){
          const res = await designDataRepository.deleteByDesignId(designId, body);
          if (res.status === 204) {
            successDesignIdList.push(designId);
          } else {
            // 想定外のステータスコードが返却された場合エラーをスロー
            throw new Error(errorMessage);
          }
        }
        // エラーがなければ正常メッセージを出力する
        utils.showSnackBar('success', successMessage);
        this.selected.splice(0);
      } catch(e) {
        // エラーが発生したら処理終了
        console.error(e.message);
        if (utils.createErrorMessage(e)) {
          errorMessage = utils.createErrorMessage(e);
        }
        utils.showSnackBar('error', errorMessage);
      } finally {
        // データリストのリフレッシュ
        this.fetchDesignFileList();
      }
    },
    // 設計ファイルを取得
    fetchDesignFileList() {
      this.$store.dispatch('designFile/fetchDesignFileList', this.$route.query.siteId)
    },
  },
  created() {
    this.fetchDesignFileList();
  }
};
</script>

<style lang="sass" scoped>
.data-list
  height: auto
  min-height: auto
  display: block

::v-deep .v-expansion-panel-content__wrap
  padding: 0 0 8px 0

::v-deep div.v-expansion-panel-content__wrap
  padding-bottom: 0px
</style>