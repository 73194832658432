<template>
  <list-panel />
</template>

<script>
import ListPanel from "../components/List/ListPanel.vue";

export default {
  name: "ListPage",

  components: {
    ListPanel,
  },
  data: () => ({}),
  mounted() {},
  destoroyed() {},
  methods: {},
};
</script>

<style scoped></style>
