// 断面ファイル系のstore
import Section from "../../models/Section";
import { repositoryFactory } from "@/repositories/repository-factory";
const sectionFileRepository = repositoryFactory.get('sectionFile');

const state = {
  sectionFileList: [],
  // モニタリング
  monitorIntervalId: null,
  selectedLineIndex: -1,
};

const getters = {
  sectionFileList: state => state.sectionFileList,
  monitorIntervalId: state => state.monitorIntervalId,
  /**
   * 表示中の断面ファイルを返却する
   * @param {*} state 
   * @returns 断面ファイル
   */
  visibleSectionFile: state => state.sectionFileList.find(section => section.isVisible),
  selectedLineIndex: state => state.selectedLineIndex,
};

const mutations = {
  fetchSectionFileList(state, sectionFileList) {
    state.sectionFileList = sectionFileList;
  },
  // モニタリングID設定
  setMonitorIntervalId(state, id) {
    state.monitorIntervalId = id;
  },
  // モニタリング終了
  clearMonitorIntervalId(state) {
    state.monitorIntervalId = null;
  },
  // 選択断面インデックス
  setSelectedLineIndex(state, index) {
    state.selectedLineIndex = index;
  },
  // 断面ファイル追加
  addSectionFiles(state, sectionFiles) {
    state.sectionFileList.push(...sectionFiles);
  },
};

const actions = {
  async fetchSectionFileList({ state, commit, rootGetters }, siteId) {
    const updateProjects = async () => {
      const body = {
        user_id: rootGetters.loginData.user_id
      }
      const res = await sectionFileRepository.findBySiteId(siteId, body);
      let secStatusArr = [];
      if (res.status === 200) {
        const sectionList = [];
        const invalidStatuses = [20, 30, 40];
        for (const section of res.data) {
          if (invalidStatuses.includes(section.status)) {
            secStatusArr.push(section.section_id);
          }
          const target = state.sectionFileList.find(sectionFile =>
            section.section_id === sectionFile.sectionId
          );
          // リストに存在する場合は更新、しない場合は追加
          if (target) {
            target.update(
              section.section_name,
              section.section_info,
              section.section_info_epsg4978,
              section.surrounding_points,
              section.point_id,
              section.linear_id,
              section.left_side_width,
              section.right_side_width,
              section.status,
              section.message
            );
            sectionList.push(target);
          } else {
            const newSection
              = new Section(
                section.section_id,
                section.section_name,
                section.section_info,
                section.section_info_epsg4978,
                section.surrounding_points,
                section.point_id,
                section.linear_id,
                section.left_side_width,
                section.right_side_width,
                section.status,
                section.message
              );
            sectionList.push(newSection);
          }
        }
        commit("fetchSectionFileList", sectionList);
      }
      return secStatusArr;
    }

    // 初期一回は実施する。
    let sectionIdsForProject = await updateProjects();

    const existingIntervalId = rootGetters.monitorIntervalId;
    if (existingIntervalId) {
      // 実行中のモニタリングがある場合、停止する。
      clearInterval(existingIntervalId);
      commit('clearMonitorIntervalId');
    }

    if (sectionIdsForProject.length) {
      console.log("*** Start section status Monitoring ***");
      // モニタリング開始
      let sectionCount  = sectionIdsForProject.length;
      const monitorIntervalId = setInterval(async () => {
        const body = {
          section_id: JSON.stringify(sectionIdsForProject)
        }
        const res = await sectionFileRepository.monitoringBySectionFile(body);
        if(sectionCount  !== res.data.length) {
          // ステータスが[10, 99]に変更になった場合に実施する。
          sectionCount  = res.data.length;
          sectionIdsForProject = await updateProjects();
          if (!sectionIdsForProject.length) {
            console.log("*** End section status Monitoring ***");
            clearInterval(monitorIntervalId);
            commit('clearMonitorIntervalId');
          }
        }
      }, 10000);
      commit('setMonitorIntervalId', monitorIntervalId);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};