import lockRepository from "./lock-repository";
import designDataRepository from "./design-data-repository";
import pointRepository from "./point-repository";
import pointEditRepository from "./point-edit-repository";
import linearFileRepository from "./linear-file-repository";
import anyLinearFileRepository from "./anylinear-file-repository";
import sectionFileRepository from "./section-file-repository";
import clippingRepository from "./clipping-repository";
import unnecessaryRemovalRepository from "./unnecessary-removal-repository";
import projectListRepository from "./project-list-repository";
import holeInterpolationRepository from "./hole-interpolation-repository";
import gridInterpolationRepository from "./grid-interpolation-repository";
import thinningRepository from "./thinning-repository";
import dashboardExportRepository from "./dashboard-export-repository";
import browserDownloadRepository from "./browser-download-repository";
import siteRepository from "./site-repository";
import coordinateUploadRepository from "./coordinate-upload-repository";
import thinningGroundSurfaceRepository from "./thinning-ground-surface-repository";
import messageRepository from "./message-repository";
import adjustmentRepository from "./adjustment-repository";
import createRepository from "./create-repository";
import measureRepository from "./measure-repository";
import flatRepository from "./flat-repository";
import dashboardEspgRepository from "./dashboard-espg-repository";
import measurePointsRepository from "./measure-points-repository";
import userRepository from "./user-repository";
import perimeterLineRepository from "./perimeter-line-repository";

const repositories = {
  lock: lockRepository,
  designData: designDataRepository,
  point: pointRepository,
  pointEdit: pointEditRepository,
  linearFile: linearFileRepository,
  anyLinearFile: anyLinearFileRepository, 
  sectionFile: sectionFileRepository,
  clipping: clippingRepository,
  unnecessaryRemoval: unnecessaryRemovalRepository,
  projectList: projectListRepository,
  holeInterpolation: holeInterpolationRepository,
  gridInterpolation: gridInterpolationRepository,
  thinning: thinningRepository,
  dashboardExport: dashboardExportRepository,
  browserDownload: browserDownloadRepository,
  site: siteRepository,
  coordinateUpload: coordinateUploadRepository,
  thinningGroundSurface: thinningGroundSurfaceRepository,
  message: messageRepository,
  adjustment: adjustmentRepository,
  create: createRepository,
  measure: measureRepository,
  flat: flatRepository,
  dashboardEspg: dashboardEspgRepository,
  measurePoints: measurePointsRepository,
  user: userRepository,
  perimeterLine: perimeterLineRepository
}

export const repositoryFactory = {
  get: name => repositories[name]
}