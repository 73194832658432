<template>
  <div>
    <label class="mb-1 d-block">{{ title }}</label>
    <div class="pa-2 select-file-area">
      <v-list v-if="$store.getters.editingPointClouds.length !== 0" class="pa-0">
        <v-list-item
          class="pa-1"
          v-for="editingPointCloud in $store.getters.editingPointClouds" :key="editingPointCloud.pointId"
        >
            <v-list-item-content class="pa-0">
              <v-list-item-title>{{ editingPointCloud.pointName }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action class="my-0">
              <close-button
                class="align-self-start"
                @click="editingPointCloud.isEditing = false"
                size="20px"
              >
              </close-button>
            </v-list-item-action>
        </v-list-item>
      </v-list>
      <p v-else class="ma-0 text-center no-data"><i>No Data</i></p>
    </div>
  </div>
</template>

<script>
import CloseButton from "@/components/common/CloseButton.vue";

export default {
  components: {
    CloseButton
  },
  props: {
    title: String
  }
}
</script>

<style lang="sass" scoped>
div.select-file-area
  border: 1px dashed white
  div.v-list-item__title
    font-size: 14px

div.v-list-item
  min-height: 0px

.no-data
  color: rgba(255, 255, 255, 0.7)
</style>