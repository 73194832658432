<template>
  <div>
    <project-page-tab></project-page-tab>
    <tool-panel ref="tool_panel" />
    <v-overlay :value="!this.$store.getters.projectPageEnabled" z-index="5"/>
  </div>
</template>

<script>
// import { useRouter } from 'vue-router'
import ToolPanel from "../components/Project/ToolPanel.vue";
import ProjectPageTab from "../components/Project/ProjectPageTab.vue";

export default {
  name: "ProjectPage",
  components: {
    ToolPanel,
    ProjectPageTab,
  },
  methods: {
    /**
     * ブラウザの戻る進むでプロジェクトページに遷移しないようにする
     * 戻るボタンからルートページに戻る際はリロードする
     */
    setUpBrowserBackEvent() {
      window.addEventListener("popstate", function() {
        window.history.pushState(null, null, "/");
        window.location.href = window.location.origin;
      });
    }
  },
  mounted() {
    this.setUpBrowserBackEvent();
  }
};
</script>

<style scoped></style>
