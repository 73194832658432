import Vue from "vue";
import Vuex from "vuex";
import processingResultNotification from './modules/processing-result-notification';
import lock from './modules/lock';
import linearFile from './modules/linear-file';
import sectionFile from './modules/section-file';
import designFile from './modules/design-file';
import dialog from './modules/dialog';
import flatness from './modules/flatness';
import edit from './modules/edit';
import Project from '@/models/Project';
import { repositoryFactory } from "@/repositories/repository-factory";
import * as constants from "@/constants/constants";
const pointRepository = repositoryFactory.get('point');
const siteRepository = repositoryFactory.get('site');
const pointEditRepository = repositoryFactory.get('pointEdit');

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    propertyDialog: false,
    // 現場情報
    site: {},
    // viewerのモード（範囲選択、線形作成）など
    mode: "",
    editType: "",
    isLoading: false,
    // ログインユーザー情報
    loginData: {},
    // データリストに表示するプロジェクトリスト
    pointDataList: [],
    deletePointClouds: [],
    // 背景地図
    backgroundMap: {
      visibility: true,
      layerType: constants.backgroundMapType.satellite
    },
    // 点群表示設定
    pointCloudDisplaySettings: {
      pointSize: 1,
      coloringType: "Normal", // HACK: enum にした方が良い
      shadingType: 1,
    },
    // 表示中の現場で未保存の点群のIDリスト
    unSavedDataList: [],
    // 現在ズームされている点群
    zoomedPointCloud: "",
    // モニタリング
    monitorIntervalId: null,
    // プロジェクトページ操作有効フラグ
    projectPageEnabled: true,
  },
  getters: {
    site: state => state.site,
    mode: state => state.mode,
    editType: state => state.editType,
    isLoading: state => state.isLoading,
    pointDataList: state => state.pointDataList,
    // Cesium上にタイリングされている点群データ
    tilingPointClouds: state => state.pointDataList
                                .flatMap( project => project.getPoints() )
                                .filter( pointFile => pointFile.isTiling),
    // 可視不可視アイコンが可視状態になっている点群データ
    visiblePointClouds: state => state.pointDataList
                                .flatMap( project => project.getPoints() )
                                .filter( pointFile => pointFile.isVisible),
    // 編集中のチェックマークがonの点群データ
    editingPointClouds: state => state.pointDataList
                                .flatMap( project => project.getPoints() )
                                .filter( pointFile => pointFile.isEditing),
    // 平可視不可視アイコンが可視状態になっている平坦度データ
    visibleFlats: state => state.pointDataList
                    .flatMap( project => project.getPoints() )
                    .flatMap( point => point.flats )
                    .filter( flat => flat.isVisible ),
    // 可視不可視アイコンが可視状態になっている点数データ
    visibleMeasurements: state => state.pointDataList
                          .flatMap( project => project.getPoints() )
                          .flatMap( point => point.measure )
                          .filter( measure => measure.isVisible ),
    loginData: state => state.loginData,
    backgroundMap : state => state.backgroundMap,
    pointCloudPointSize: state => state.pointCloudDisplaySettings.pointSize,
    pointCloudColoringType: state => state.pointCloudDisplaySettings.coloringType,
    pointCloudShadingType: state => state.pointCloudDisplaySettings.shadingType,
    unSavedDataList: state => state.unSavedDataList,
    zoomedPointCloud: state => state.zoomedPointCloud,
    monitorIntervalId: state => state.monitorIntervalId,
    projectPageEnabled: state => state.projectPageEnabled,
  },
  mutations: {
    updateSite(state, site) {
      state.site = site;
    },
    updateMode(state, mode) {
      state.mode = mode;
    },
    updateEditType(state, editType) {
      state.editType = editType;
    },
    setPointDataList(state, pointDataList) {
      state.pointDataList = pointDataList;
    },
    setLoginData(state, loginData) {
      state.loginData = loginData;
    },
    updateIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    updateBackgroundMap(state, property) {
      for (const key of Object.keys(property)) {
        state.backgroundMap[key] = property[key];
      }
    },
    setPointCloudPointSize(state, pointSize) {
      state.pointCloudDisplaySettings.pointSize = pointSize;
    },
    setPointCloudColoringType(state, coloringType) {
      state.pointCloudDisplaySettings.coloringType = coloringType;
    },
    setPointCloudShadingType(state, shadingType) {
      state.pointCloudDisplaySettings.shadingType = shadingType;
    },
    setUnSavedDataList(state, unSavedDataList) {
      state.unSavedDataList = unSavedDataList;
    },
    setZoomedPointCloud(state, zoomedPointCloud) {
      state.zoomedPointCloud = zoomedPointCloud;
    },
    // モニタリングID設定
    setMonitorIntervalId(state, id) {
      state.monitorIntervalId = id;
    },
    // モニタリング終了
    clearMonitorIntervalId(state) {
      state.monitorIntervalId = null;
    },
    updatePerimeterLine(state, perimeterLine) {
      for (const project of state.pointDataList) {
        const point = project.children.find(point => point.perimeterLine?.perimeterLineId === perimeterLine.perimeterLineId);
        if (point) {
          Object.assign(point.perimeterLine, perimeterLine);
          return;
        }
      }
    },
    hideAllPerimeterLines(state) {
      for (const project of state.pointDataList) {
        for (const point of project.children) {
          if (point.perimeterLine?.isVisible) {
            point.perimeterLine.isVisible = false;
          }
        }
      }
    },
    setProjectPageAbility(state, ability) {
      state.projectPageEnabled = ability;
    }
  },
  actions: {
    /**
     * 現場情報を取得
     * @param {*} param0 
     */
    async fetchSite({ commit }, siteId) {
      try {
        const res = await siteRepository.getSiteBySiteName(siteId);
        commit("updateSite", res.data);
      } catch (e) {
        console.error(e.message);
        commit("updateSite", {});
      }
    },
    updateMode({ commit }, mode) {
      commit("updateMode", mode);
    },
    updateEditType({ commit }, editType) {
      commit("updateEditType", editType);
    },
    async getPointDataList({ dispatch }, siteId) {
      dispatch("fetchProjectList",siteId),
      dispatch("fetchUnSavedData",siteId)
    },
    updateIsLoading({ commit }, isLoading) {
      commit("updateIsLoading", isLoading);
    },
    updateBackgroundMap({ commit }, property) {
      commit("updateBackgroundMap", property);
    },
    /**
     * プロジェクトデータを取得する
     */
    async fetchProjectList({ commit, dispatch, rootGetters }, siteId) {
      const updateProjects = async () => {
        const projects = [];
        const pointIds = [];
        const flatsIds = [];
        const measureIds = [];
        const perimeterLineIds = [];
        const invalidStatuses = [20, 30, 40];
        const body = {
          user_id: rootGetters.loginData.user_id
        }
        try {
          const res = await pointRepository.findBySiteId(siteId, body);
          if (res.status === 201) {
            for (const projectData of res.data.projects) {
              projectData.points.forEach(row => {
                // 点群データ
                if (invalidStatuses.includes(row.process_status)) {
                  pointIds.push(row.point_id);
                }

                // 平坦度データ
                if (row.flats?.length) {
                  row.flats.forEach(flatRow => {
                    if (invalidStatuses.includes(flatRow.status)) {
                      flatsIds.push(flatRow.flat_id);
                    }
                  });
                }

                // 計測データ
                if (row.measure?.length) {
                  row.measure.forEach(measureRow => {
                    if (invalidStatuses.includes(measureRow.status)) {
                      measureIds.push(measureRow.measure_id);
                    }
                  });
                }

                // 外周線
                if (row.perimeterLine) {
                  if (invalidStatuses.includes(row.perimeterLine.status)) {
                    perimeterLineIds.push(row.perimeterLine.perimeter_line_id);
                  }
                }
              });

              const target = rootGetters.pointDataList.find(project =>
                projectData.project_id === project.getId()
              );
              // リストに存在する場合は更新、しない場合は追加
              if (target) {
                target.update(
                  projectData.project_name,
                  projectData.points
                );
                projects.push(target);
              } else {
                const project = new Project(
                  projectData.project_id,
                  projectData.project_name,
                  projectData.points
                );
                projects.push(project);
              }
            }
            commit("setPointDataList", projects);
          }
          return { pointIds, flatsIds, measureIds, perimeterLineIds };
        } catch (e) {
          console.error(e.message);
          throw e;
        }
      }

      // 初期一回は実施する。
      let projectIds = await updateProjects();
      const existingIntervalId = rootGetters.monitorIntervalId;
      if (existingIntervalId) {
        // 実行中のモニタリングがある場合、停止する。
        clearInterval(existingIntervalId);
        commit('clearMonitorIntervalId');
      }

      if (projectIds.pointIds?.length ||
        projectIds.flatsIds?.length ||
        projectIds.measureIds?.length ||
        projectIds.perimeterLineIds?.length
      ) {
        // モニタリング開始
        const monitorIntervalId = setInterval(async () => {
          console.log("*** Start point status monitoring ***");
          const body = Object.fromEntries(
            Object.entries(projectIds).map(([key, value]) => {
              return [`${key.replace('Ids', '_ids')}`, value && value.length > 0 ? JSON.stringify(value) : undefined];
            }).filter((_, value) => value !== undefined)
          );

          const res = await pointRepository.monitoringByPointId(body);
          if (res.data.statusBool) {
            // ステータスが更新された場合
            projectIds = await updateProjects();
            await dispatch("fetchUnSavedData", siteId);
            if (Object.values(projectIds).every(arr => arr.length === 0)) {
              console.log("*** End point status monitoring ***");
              clearInterval(monitorIntervalId);
              commit('clearMonitorIntervalId');
            }
          }
        }, 10000);
        commit('setMonitorIntervalId', monitorIntervalId);
      }
    },
    /**
     * 未保存データが存在する点群のIDを取得する
     */
    async fetchUnSavedData({ commit, rootGetters}, siteId) {
      try {
        const body = {
          user_id: rootGetters.loginData.user_id
        }
        const res = await pointEditRepository.findBySiteId(siteId, body);
        if (res.status === 201) {
          const pointIdList = res.data.points.map(point => point.point_id);
          commit("setUnSavedDataList", pointIdList);
        }
      } catch (e) {
        console.error(e.message);
        commit("setUnSavedDataList", []);
        throw e;
      }
    },
    setLoginData({ commit }, user) {
      user.language = user.language 
                           ? constants.language[user.language]
                           : constants.language.ja
      commit("setLoginData", user);
    },
    setZoomedPointCloud({ commit }, setZoomedPointCloud) {
      commit("setZoomedPointCloud", setZoomedPointCloud);
    },
  },
  modules: {
    lock,
    processingResultNotification,
    linearFile,
    sectionFile,
    designFile,
    dialog,
    flatness,
    edit
  },
});

export default store;