<!-- 間引き設計面 -->
<template>
  <v-card class="thinning-design-dialog px-4 pt-2 pb-4">
    <loading :parentLoading="isLoading" />
    <v-card-title class="pa-0 justify-space-between">
      <label>
        {{$t("CQW0020114001")}}
      </label>
      <close-button @click="$emit('close')" size="20px"></close-button>
    </v-card-title>
    <v-card-text class="px-0 pt-1 pb-0 white--text">
      <v-container class="pa-0">
        <v-row class="ma-0">
          <validation-observer
            class="d-block"
            style="width: 100%"
            ref="observer"
            v-slot="{ invalid, pristine }"
          >
            <!-- 点群データ -->
            <v-col cols="12" class="my-3 pa-0">
              <editing-point-clouds
                :title="$t('CIW0020000008')">
              </editing-point-clouds>
            </v-col>
            <!-- 設計データを選択 -->
            <v-col cols="12" class="my-3 pa-0">
              <validation-provider :rules="{
                required: true,
              }" v-slot="{ }">
                <div class="mb-1">
                  <label class="mb-1 d-block">{{$t("CQW0020114003")}}</label>
                </div>
                <v-select
                  v-model="selectedDesignId"
                  class="text-black"
                  height="32px"
                  background-color="#BFBFBF"
                  :items="designList"
                  :item-text="item => item.designName"
                  :item-value="item => item.designId"
                  hide-details
                  solo
                >
                </v-select>
              </validation-provider>
            </v-col>
            <!-- 高さ
              SC_DESIGN-2365 [#要望]設計面間引きの仕様を変更し、上下方向と削除のみ機能に限定する
              将来のために残しておく
            <v-col cols="12" class="mt-3 pa-0">
              <validation-provider :rules="{
                required: true,
                'number-format': true,
                'decimal-size-limit': 3,
                'min-value': 0.001,
                'max-value': 9999.999,
              }" v-slot="{ errors }">
                <div class="mb-1">
                  <label class="d-inline-block mr-2">{{$t("CQW0020114004")}}</label>
                  <span class="d-inline-block" style="font-size: 12px; color: #ff5252">{{ errors[0] }}</span>
                </div>
                <v-text-field
                  @reset.prevent="resetError()"
                  v-model="height"
                  :error-messages="errors"
                  class="text-black ma-0"
                  height="32px"
                  background-color="#BFBFBF"
                  outlined
                  solo
                  suffix="m"
                  >
                </v-text-field>
              </validation-provider>
            </v-col>
            -->
            <!-- オフセット -->
            <v-col cols="12" class="mt-1 mb-3 pa-0">
              <v-radio-group
                v-model="upDownRadioGroup"
                class="justify-space-between ma-0 mb-1 pa-0"
                row
                hide-details=""
               >
                <v-radio
                  class="ma-0 radio-button"
                  color="#f0f0f0"
                  :label="$t('CQW0020114005')"
                  value="top"
                  on-icon="fa-solid fa-circle-dot"
                  off-icon="fa-solid fa-circle"
                  :ripple="false"
                ></v-radio>
                <v-radio
                  class="ma-0 radio-button"
                  color="#f0f0f0"
                  :label="$t('CQW0020114006')"
                  value="bottom"
                  on-icon="fa-solid fa-circle-dot"
                  off-icon="fa-solid fa-circle"
                  :ripple="false"
                ></v-radio>
              </v-radio-group>
              <!--
                SC_DESIGN-2365 [#要望]設計面間引きの仕様を変更し、上下方向と削除のみ機能に限定する
                将来のために残しておく
              <v-radio-group
                v-model="exclusionRadioGroup"
                class="justify-space-between ma-0 mb-1 pa-0"
                row
                hide-details=""
               >
                <v-radio
                  class="ma-0 radio-button"
                  color="#f0f0f0"
                  :label="$t('CQW0020114007')"
                  value="exclude"
                  on-icon="fa-solid fa-circle-dot"
                  off-icon="fa-solid fa-circle"
                  :ripple="false"
                ></v-radio>
                <v-radio
                  class="ma-0 radio-button"
                  color="#f0f0f0"
                  :label="$t('CQW0020114008')"
                  value="include"
                  on-icon="fa-solid fa-circle-dot"
                  off-icon="fa-solid fa-circle"
                  :ripple="false"
                ></v-radio>
              </v-radio-group>
              -->
            </v-col>
            <!-- 元の仕様と異なっていた為不要。次期開発で別機能実装のため、COとし残す -->
            <!-- <v-col cols="12" class="mt-3 pa-0">
              <validation-provider :rules="{
                required: true,
                numeric: true,
                max_decimal_digits: 3,
              }" v-slot="{ errors }">
                <div class="mb-1">
                  <label class="d-inline-block mr-2">{{$t("CIW0020000032")}}</label>
                  <span class="d-inline-block" style="font-size: 12px; color: #ff5252">{{ errors[0] }}</span>
                </div>
                <v-text-field
                  @reset.prevent="resetError()"
                  v-model="offsetX"
                  :error-messages="errors"
                  class="text-black ma-0 mb-3"
                  height="32px"
                  background-color="#BFBFBF"
                  outlined
                  solo
                  suffix="m"
                  >
                  <template v-slot:prepend>
                    {{$t("CQW0020114010")}}
                  </template>
                </v-text-field>
              </validation-provider>
              <validation-provider :rules="{
                required: true,
                numeric: true,
                max_decimal_digits: 3,
              }" v-slot="{ errors }">
                <v-text-field
                  @reset.prevent="resetError()"
                  v-model="offsetY"
                  :error-messages="errors"
                  class="text-black ma-0"
                  height="32px"
                  background-color="#BFBFBF"
                  outlined
                  solo
                  suffix="m"
                  >
                  <template v-slot:prepend>
                    {{$t("CQW0020114011")}}
                  </template>
                </v-text-field>
              </validation-provider>
            </v-col> -->
            <!-- ボタン -->
            <v-col cols="12" class="mt-4 pa-0 d-flex justify-space-between">
              <v-btn
                class="rounded-lg"
                color="#ff5050"
                width="115px"
                height="45px"
                depressed
                @click="$emit('close')">
                {{$t("CQW0010600005")}}
              </v-btn>
              <v-btn
                class="rounded-lg"
                color="#4472c4"
                width="115px"
                height="45px"
                depressed
                :disabled="pristine || invalid || !isExecutable"
                @click="execute(1)">
                {{$t("CQW0020109005")}}<br>{{$t("CQW0020110006")}}
              </v-btn>
              <v-btn
                class="rounded-lg"
                color="#4472c4"
                width="115px"
                height="45px"
                depressed
                :disabled="pristine || invalid || !isExecutable"
                @click="execute(2)">
                {{$t("CQW0020110007")}}<br>{{$t("CIW0020000005")}}
              </v-btn>
            </v-col>
          </validation-observer>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import CloseButton from "@/components/common/CloseButton.vue";
import EditingPointClouds from "./EditingPointClouds.vue";
import Loading from '../../common/LoadingOverLay.vue';
import { ValidationObserver } from "vee-validate";
import * as utils from '@/utils/utils';
import { repositoryFactory } from "@/repositories/repository-factory";
import { i18n } from '@/i18n.js'
const thinningRepository = repositoryFactory.get('thinning');

export default {
  components: {
    CloseButton,
    EditingPointClouds,
    ValidationObserver,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      height: "",
      offsetX: "",
      offsetY: "",
      uploadFileName: "",
      selectedDesignId: "",
      upDownRadioGroup: "top",
      exclusionRadioGroup: "exclude",
    }
  },
  computed: {
    isExecutable() {
      return this.editingPointClouds.length && this.selectedDesignId
    },
    editingPointClouds() {
      return this.$store.getters.editingPointClouds;
    },
    designList() {
      // 設計データの選択項目を作成する
      return this.$store.getters["designFile/designFileList"];
    }
  },
  methods: {
    resetForm() {
      this.selectedDesignId = "";
      this.upDownRadioGroup = "top",
      this.exclusionRadioGroup = "exclude",
      this.height = "";
      this.offsetX = "";
      this.offsetY = "";
      this.resetError();
    },
    async resetError() {
      await this.$refs.observer.validate();
      await this.$refs.observer.reset();
    },
    async execute(executionMethod) {
      this.isLoading = true;
      const body = { 
        design_id: this.selectedDesignId,
        height: this.height,						
        direction: this.upDownRadioGroup,
        category: this.exclusionRadioGroup,
        // 元の仕様と違ったためにUIから削除。パラメータとして0固定
        offsetX: "0",
        offsetY: "0",
        execution_method: executionMethod,
        user_id: this.$store.getters.loginData.user_id
      };
      await this.putRequest(body);

      this.isLoading = false;
      this.$emit('close');
    },
    async putRequest(body) {
      let successMessage = i18n.tc("CIW0020114001");
      let errorMessage = i18n.tc("CEW0020114002");
      let status;

      try {
        for (const pointFile of this.editingPointClouds) {
          const res = await thinningRepository.createDesignByPointId(pointFile.pointId, body);
          if (res.status === 201 || res.status === 204) {
            status = res.status;
          } else {
            utils.showSnackBar('error', errorMessage);
            return "";
          }
        }
        utils.showSnackBar('success', successMessage);
        this.resetForm();
        return status;
      } catch (e) {
        console.error(e.message);
        if (utils.createErrorMessage(e)) {
          errorMessage = utils.createErrorMessage(e);
        }
        utils.showSnackBar('error', errorMessage);
        return "";
      } finally {
        // 上書きに成功した点群を不可視にする
        if (status === 204 || status === 201) {
          utils.allClearTileset();
          // データリストを更新
          utils.refreshDataList(this.$route.query.siteId);
        }
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.thinning-design-dialog
  .v-card__title
    font-size: 16px

::v-deep .v-input__append-outer
  margin: 0

::v-deep .v-input__slot
  margin: 0
  padding: 0

.text-black ::v-deep input,
.text-black ::v-deep label,
.text-black ::v-deep .v-select__selection,
.text-black ::v-deep .v-input__append-inner i
  color: black

::v-deep .v-text-field input,
::v-deep .v-text-field.v-input .v-select__selections input
  padding: 0

.text-field-error
  border: solid 2px #ff5252

::v-deep .v-text-field__details
  display: none

::v-deep .v-text-field__suffix
  color: black

.radio-button
  width: calc(100% / 2)
  
::v-deep div.v-radio
  div.v-input--selection-controls__input i.v-icon
    font-size: 14px
    color: #f0f0f0
  label.v-label
    font-size: 14px
    color: white

::v-deep div.v-input.v-text-field div.v-input__prepend-outer
  margin: auto 8px auto 1px
  color: white

::v-deep button.v-btn span.v-btn__content
  font-size: 13px
</style>