<!-- 点群合成 -->
<template>
  <v-card class="point-synthetic-dialog px-4 py-2">
    <v-card-title class="pa-0 justify-space-between">
      <label>
        {{ $t("CIW0020000028") }}
      </label>
      <close-button @click="$emit('close')" size="20px"></close-button>
    </v-card-title>
    <v-card-text class="px-0 pt-1 pb-0 white--text">
      <v-container class="pa-0">
        <v-row class="ma-0">
          <validation-observer
            class="d-block"
            style="width: 100%"
            ref="observer"
            v-slot="{ invalid }"
          >
            <!-- フォルダを選択 -->
            <v-col cols="12" class="my-3 pa-0">
              <label class="mb-1 d-block">{{ $t("CQW0020107002") }}</label>
              <v-select
                v-model="selectedFolder"
                class="text-black"
                height="32px"
                background-color="#BFBFBF"
                :items="folderList"
                item-text="name"
                item-value="id"
                hide-details
                solo
              >
              </v-select>
            </v-col>
            <!-- データを選択 -->
            <v-col cols="12" class="my-3 pa-0">
              <label class="mb-1 d-block">{{ $t("CQW0020107003") }}</label>
              <v-select
                :label="$t('CQW0020117004')"
                v-model="selectedFiles"
                multiple
                class="text-black"
                height="32px"
                background-color="#BFBFBF"
                :items="pointFileList"
                item-text="text"
                item-value="value"
                hide-details
                solo
              >
                <template v-slot:selection="{ index }">
                  <span class="black--text" v-if="index === 0">{{
                    $t("CQW0020117004")
                  }}</span>
                </template>
              </v-select>
              <div v-if="selectedFiles.length">
                <div
                  v-for="point in selectedFiles"
                  :key="point.pointId"
                  class="pt-2 d-flex justify-space-between"
                  style="width: 95%"
                >
                  <span>{{ point.pointName}}</span>
                  <close-button
                    @click="deselectPoint(point.pointId)"
                    size="20px"
                  ></close-button>
                </div>
              </div>
            </v-col>

            <!-- データ名 -->
            <v-col cols="12" class="my-3 pa-0">
              <validation-provider
                :rules="{
                  required: true,
                  'data-name-length': maxFileNameLength
                }"
                v-slot="{ errors }"
              >
                <div class="mb-1">
                  <label class="d-inline-block mr-2">{{
                    $t("CQW0020104006")
                  }}</label>
                  <span
                    class="d-inline-block"
                    style="font-size: 12px; color: #ff5252"
                    >{{ errors[0] }}</span
                  >
                </div>
                <v-text-field
                  @reset.prevent="resetError()"
                  v-model.trim="uploadFileName"
                  :error-messages="errors"
                  class="text-black ma-0"
                  height="32px"
                  background-color="#BFBFBF"
                  outlined
                  solo
                ></v-text-field>
              </validation-provider>
            </v-col>

            <!-- アップロード先 -->
            <v-col cols="12" class="my-3 pa-0">
              <label class="mb-1 d-block">{{ $t("CQW0020104022") }}</label>
              <v-select
                v-model="selectedUploadDirectory"
                class="text-black"
                height="32px"
                background-color="#BFBFBF"
                :items="uploadDirectoryList"
                :item-value="(item) => ({ value: item.value, text: item.text })"
                hide-details
                solo
              >
              </v-select>
            </v-col>
            <v-col cols="12" class="mt-4 pa-0 d-flex justify-end">
              <v-btn
                class=".rounded-lg mr-5"
                color="#ff5050"
                width="115px"
                height="45px"
                depressed
                @click="$emit('close')"
                >{{ $t("CQW0010600005") }}</v-btn
              >
              <v-btn
                class=".rounded-lg"
                color="#4472c4"
                width="115px"
                height="45px"
                depressed
                :disabled="invalid || selectedFiles.length < 2"
                @click="synthetic()"
                >{{ $t("CQW0020117008") }}</v-btn
              >
            </v-col>
          </validation-observer>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import CloseButton from "@/components/common/CloseButton.vue";
import * as constants from '@/constants/constants';
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { repositoryFactory } from "@/repositories/repository-factory";
import * as utils from "@/utils/utils";
import { i18n } from "../../../i18n.js";
const createRepository = repositoryFactory.get("create");

export default {
  components: {
    CloseButton,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    showDialog: Boolean,
    uploadFrom: Object,
  },
  data: () => ({
    maxFileNameLength: 100,
    selectedFolder: "",
    selectedUploadDirectory: "",
    selectedFiles: [],
    uploadFileName: "",
    uploadDirectoryList: [],
    enableExtensions: constants.pointSyntheticEnableExtensions
  }),
  computed: {
    folderList() {
      return [
        { id: "", name: "" },
        ...this.$store.getters.pointDataList,
      ];
    },
    // プロジェクトID、プロジェクト名と点群ファイルの紐付きリストを作成
    projectList() {
      const projectList = [];
      for (const pointData of this.$store.getters.pointDataList) {
        const project = Object.assign({}, pointData)
        project.children
         = project.children
          .map(
            file => (
              {
                projectId: project.id,
                projectName: project.name,
                pointId: file.pointId,
                pointName: file.pointName
              }
            )
          )
        projectList.push(project)
      }
      return projectList
    },
    // データリストに表示するオブジェクトリストを作成
    pointFileList() {
      const pointFileList = [];
      for (const project of this.projectList) {
        // フォルダが選択されていない場合は全点群を表示
        if (!this.selectedFolder) {
          for (const pointFile of project.children) {
            pointFileList.push(
                { 
                  value: pointFile,
                  text: `${pointFile.pointName}（${pointFile.projectName}）`
                }
              );
          }
        } else if (project.id === this.selectedFolder) {
          // フォルダが選択されている場合は選択中のプロジェクトと紐づく点群ファイルを表示
          for (const pointFile of project.children) {
            pointFileList.push(
                {
                  value: pointFile,
                  text: pointFile.pointName
                }
            );
          }
        }
      }
      return pointFileList
    },
    acceptedExtensions() {
      return this.enableExtensions.map(ext => `.${ext}`).join(',');
    }
  },
  watch: {
    selectedFiles() {
      if (this.selectedFiles.length === 1) {
        this.uploadFileName = this.selectedFiles[0].pointName;
      } else {
        // 「{ファイル名A}_{ファイル名B}_{suffix}」の形にする
        const delimiterStr = '_';
        const suffix = this.$t("CQW0020117008");
        // 1番目と2番目に選択されたファイル名を使用する
        const fileNameA = this.getFileNameWithoutExtension(this.selectedFiles[0].pointName);
        const fileNameB = this.getFileNameWithoutExtension(this.selectedFiles[1].pointName);
        let uploadFileName = fileNameA 
                            + delimiterStr 
                            + fileNameB 
                            + delimiterStr 
                            + suffix;
        // 合成後のファイル名が文字数制限を超過する場合、文字数を調整する
        if (uploadFileName.length > this.maxFileNameLength) {
          // 1ファイル名あたりに使用可能な文字数を算出
          const availableFileNameLength 
            = (this.maxFileNameLength - (delimiterStr + delimiterStr + suffix).length) / 2
          // ファイル名を省略して合成
          uploadFileName = fileNameA.substr (
                              0, Math.ceil(availableFileNameLength)
                            )
                          + delimiterStr 
                          + fileNameB.substr(
                              0, Math.floor(availableFileNameLength)
                            ) 
                          + delimiterStr 
                          + suffix;
        }
        this.uploadFileName = uploadFileName;
      }
    }
  },
  methods: {
    /**
     * 指定した点群データの選択を解除する
     * @param {*} pointId 
     */
    deselectPoint(pointId) {
      this.selectedFiles
       = this.selectedFiles.filter(item => pointId !== item.pointId);
    },
    resetForm() {
      this.selectedFiles = [];
      this.resetError();
    },
    async resetError() {
      await this.$refs.observer.validate();
      await this.$refs.observer.reset();
    },
    // 合成押下時処理
    synthetic() {
      const body = {
        point_id: this.selectedFiles.map(item => item.pointId),
        user_id: this.$store.getters.loginData.user_id,
        point_name: this.uploadFileName,
        site_id: this.$route.query.siteId,
        project_name: this.selectedUploadDirectory.text,
        project_id: this.selectedUploadDirectory.value,
      };
      this.request(body);
    },
    async request(body) {
      let successMessage = i18n.tc("CIW0020117001");
      let errorMessage = i18n.tc("CEW0020117002");
      try {
        this.$store.dispatch("updateIsLoading", true);
        const res = await createRepository.pointSynthetic(body);
        if (res.status !== 201) {
          // 想定外のステータスコードが返却された場合エラーをスロー
          throw new Error(errorMessage);
        } 
        utils.allClearTileset();
        await this.$store.dispatch("getPointDataList", this.$route.query.siteId);
        utils.showSnackBar("success", successMessage);
        this.resetForm();
        this.$emit("close");
      } catch (e) {
        console.error(e);
        if (utils.createErrorMessage(e)) {
          errorMessage = utils.createErrorMessage(e);
        }
        utils.showSnackBar("error", errorMessage);
      } finally {
        this.$store.dispatch("updateIsLoading", false);
      }
    },
    setUploadDirectoryList() {
      let uploadDirectoryList = [];
      for (const project of this.$store.state.pointDataList) {
        uploadDirectoryList.push({ value: project.id, text: project.name });
      }

      const sysDateProject = this.$store.state.pointDataList.find(
        (project) => project.name === this.systemDate
      );
      if (!sysDateProject) {
        this.selectedUploadDirectory = { value: "", text: this.systemDate };
        uploadDirectoryList = [
          ...uploadDirectoryList,
          this.selectedUploadDirectory,
        ];
      } else {
        this.selectedUploadDirectory = {
          value: sysDateProject.id,
          text: sysDateProject.name,
        };
      }
      this.uploadDirectoryList = uploadDirectoryList;
    },
    /**
     * 拡張子を除いたファイル名を返却する
     * ・文字列中に . が存在しない場合は、拡張子なし・全てファイル名と判断する
     * ・最後の . が文字列の先頭にある場合は、拡張子なし・全てファイル名と判断する
     * ・最後の . が文字列の最後にある場合は、拡張子なし・全てファイル名と判断する
     * @param {*} fileNameStr 
     */
    getFileNameWithoutExtension(fileNameStr) {
      // 文字列中の最後の `.` の位置を取得
      const extensionIndex = fileNameStr.lastIndexOf('.');
      const hasExtension = extensionIndex > 0 && extensionIndex < fileNameStr.length - 1;
      // '.' 以前がファイル名
      const fileName =  hasExtension ? fileNameStr.substr(0, extensionIndex) : fileNameStr;
      return fileName;
    }
  },
  mounted() {
    const today = new Date();
    this.systemDate =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      today.getDate().toString().padStart(2, "0");
    this.setUploadDirectoryList();
  },
};
</script>

<style lang="sass" scoped>
.point-synthetic-dialog
  .v-card__title
    font-size: 16px
  p,span
    color: white

::v-deep .v-input__append-outer
  margin: 0

::v-deep .v-input__slot
  margin: 0
  padding: 0

.text-black ::v-deep input,
.text-black ::v-deep label,
.text-black ::v-deep .v-select__selection,
.text-black ::v-deep .v-input__append-inner i
  color: black

::v-deep .v-text-field input,
::v-deep .v-text-field.v-input .v-select__selections input
  padding: 0

.drop-area
  border: 2px dashed white

.enter
  border: 2px dashed #2196F3

.text-field-error
  border: solid 2px #ff5252

::v-deep .v-text-field__details
  display: none

.select-file-area
  border: 1px dashed white
</style>