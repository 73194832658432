<!-- 編集処理ステータスを表示する -->
<template>
  <!-- 処理ステータス -->
  <div class="d-flex align-center">
    <!-- エラー -->
    <div v-if="status === 99">
      <!-- エラークリアを使用する場合 -->
      <div v-if="useErrorClearing">
        <v-menu offset-x>
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip color="red" bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...menu }" class="process-error rounded-circle">
                  <v-icon class="process-error-icon" color="white" size="12px">
                    fa-exclamation
                  </v-icon>
                </v-btn>
              </template>
              <span class="white--text">{{ processMessage }}</span>
            </v-tooltip>
          </template>
          <v-list class="py-0" light dense>
            <v-list-item @click="clearProcessError">
              <v-list-item-title>{{ $t("CQW0020300017") }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <!-- エラークリアを使用しない場合 -->
      <div v-else>
        <v-tooltip 
          color="red"
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              icon
              v-bind="attrs"
              v-on="on"
              class="process-error rounded-circle"
            >
              <v-icon class="process-error-icon" color="white" size="12px">
                fa-exclamation
              </v-icon>
            </div>
          </template>
          <span class="white--text">{{ processMessage }}</span>
        </v-tooltip>
      </div>
    </div>
    <!-- 処理中 -->
    <div v-else-if="status && status !== 10">
      <v-tooltip 
        color="white"
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            class="rounded-circle"
            v-bind="attrs"
            v-on="on"
            size="20"
          >
          mdi-progress-upload
          </v-icon>
        </template>
        <span class="black--text">{{ processMessage }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import { i18n } from '@/i18n.js'

export default {
  props: {
    status: Number,
    message: String,
    useErrorClearing: { // trueの場合error-clearイベントを発火可能
      type: Boolean,
      default: true
    }
  },
  computed: {
    /**
     * 編集処理中または処理エラーの場合に表示するメッセージを返却する
     * 
     */
    processMessage() {
      let processMessage = "";
      if (this.status === 99) {
        processMessage
          = this.message 
          ? this.message : i18n.tc("CQW0020300015");
      } else {
        processMessage
          = this.message 
          ? this.message : i18n.tc("CQW0020300014");
      }
      return processMessage;
    },
  },
  methods: {
    /**
     * エラークリアイベントを発火する
     */
    clearProcessError() {
      this.$emit('error-clear');
    }
  }
};
</script>

<style lang="sass" scoped>
// 処理ステータスエラー
.process-error
  background-color: #F44336
  width: 18px
  height: 18px
  text-align: center
.process-error-icon
  line-height: 18px
</style>