<!-- 線形データ -->
<template>
  <div class="d-flex align-center data-list-title">
    <v-btn class="pa-0 mr-1" min-width="auto" plain height="auto">
      <v-icon v-if="linearFile.isVisible" @click="changeLinearVisible">mdi-eye-outline</v-icon>
      <v-icon v-else @click="changeLinearVisible">mdi-eye-off-outline</v-icon>
    </v-btn>
      <v-checkbox
        v-if="deleteMode"
        :disabled="isDisabledDeleteCheckBox"
        :value="isChecked"
        @click="changeCheckBox"
        off-icon="mdi-checkbox-blank"
        color="white"
        class="my-0 mr-1 pa-0"
        hide-details
      >
      </v-checkbox>

      <linear-file-name :linearFile="linearFile"></linear-file-name>
      <!-- アイコン表示 -->
      <div class="pl-3 ml-auto d-flex align-center">
        <!-- 虫眼鏡 -->
        <v-btn
          class="pa-0 mr-1"
          min-width="auto"
          plain
          height="auto"
          @click="zoomSIMALine()"
        >
          <v-icon v-if="linearFile.isVisible">mdi-magnify</v-icon>
        </v-btn>
      </div>
  </div>
</template>

<script>
import * as cesiumCommon from "@/utils/cesium-common";
import LinearFileName from './LinearFileName.vue';

export default {
  components: {
    LinearFileName 
  },
  props: {
    selected: Array,
    linearFile: Object,
    deleteMode: Boolean
  },
  data: () => ({
    counter: 0,
    clickCounter: 0,
    isNameEdit: false,
  }),
  computed: {
    isChecked() {
      return this.selected.find(linear => linear.linearId === this.linearFile.linearId);
    },
    /**
     * 削除チェックボックスの活性制御を行う
     * ・断面ファイルと紐づいている場合非活性
     */
    isDisabledDeleteCheckBox() {
      return !!this.$store.getters['sectionFile/sectionFileList']
            .find(section => section.linearId === this.linearFile.linearId);
    }
  },
  methods: {
    onClickFileName() {
      this.clickCounter++;
      if (this.clickCounter === 1) {
        setTimeout(()=>{
          if (this.clickCounter === 2) {
            this.isNameEdit = true;
          }
          this.clickCounter = 0;
        }, 400)
      }
    },
    changeLinearVisible() {
      cesiumCommon.changeLinearVisible(this.linearFile);
    }, 
    changeCheckBox() {
      if (this.isChecked) this.$emit('delete-checked', this.linearFile)
      else this.$emit('add-checked', this.linearFile);
    },
    editDataName(event) {
      event.target.dispatchEvent(new Event("blur"));
    },
    zoomSIMALine() {
      window['viewer'].zoomToRoute(this.linearFile.linearId);
    }
  },
  beforeDestroy() {
    if (this.linearFile.isVisible) {
      // データが削除されたらCesium上から削除
      this.changeLinearVisible();
    }
  }
}
</script>

<style lang="sass" scoped>
.data-list-title
  font-size: 14px
  margin-bottom: 4px
  white-space: normal
  word-break: break-all

.data-list-title ::v-deep .v-text-field .v-input__control .v-input__slot
  min-height: auto

::v-deep .v-input__slot .v-input--selection-controls__input
  margin-right: 0
  height: auto

.data-list-title-name .on-hover
  cursor: pointer
  background-color: rgba(245,245,245,0.2)

// チェックボックスホバー時の背景色の変化を無効化
::v-deep div.v-input--selection-controls__ripple
  display: none

</style>