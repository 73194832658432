
<template>
  <v-card class="confirm">
    <v-card-title class="pt-2 justify-space-between">
      <span>{{$t("CQW0020133001")}}</span>
      <close-button @click="$emit('close')" size="18px"></close-button>
    </v-card-title>
    <v-card-text class="py-0 pb-0" style="color: white">
      <p>
        {{$t("CQW0020133002")}}<br>
        {{$t("CQW0020133003")}}
      </p>
    </v-card-text>
    <v-card-actions class="pa-0 pb-5 pr-5 justify-end">
      <v-btn class="pa-3 mr-2 btn" color="#ff5050" width="125px" height="45px" @click="$emit('close')">
        {{$t("CQW0010600005")}}
      </v-btn>
      <v-btn class="pa-3 mr-1 btn" color="#4472c4" width="125px" height="45px" @click="unlock">
        {{$t("CQW0010600006")}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import CloseButton from "@/components/common/CloseButton.vue";

export default {
  components: {
    CloseButton
  },
  props: {
    project: Object
  },
  data() {
    return {};
  },
  methods: {
    unlock() {
      this.$store.dispatch(
        'lock/unLockState', 
        {
          siteId: this.project.siteId,
          loginData: this.$store.getters.loginData
        }
      );
      this.$emit('close');
    }
  },
};
</script>
<style lang="sass" scoped>
.confirm
  .v-card__title
    font-size: 16px

.confirm .btn
    font-size: 13px

.confirm
  p,span
    color: white

::v-deep .v-input__append-outer
  margin: 0

::v-deep .v-input__slot
  margin: 0
  padding: 0

.text-black ::v-deep input,
.text-black ::v-deep label,
.text-black ::v-deep .v-select__selection,
.text-black ::v-deep .v-input__append-inner i
  color: black

::v-deep .v-input__slot
  .v-text-field__slot
    .v-label
      color: gray
      opacity: 0.8

::v-deep .v-text-field input,
::v-deep .v-text-field.v-input .v-select__selections input
  padding: 0

.text-field-error
  border: solid 2px #ff5252

::v-deep .v-text-field__details
  display: none
</style>