// 平坦度計算条件
export default class FlatnessCondition{
  /** 
   * フィールド 
   */
  _flatnessConditionId;
  _flatnessConditionName;
  _selectInfo;
  _gridSize;

  /** 
   * コンストラクタ
   */
  constructor(flatnessConditionId, flatnessConditionName, selectInfo, gridSize) {
    this.flatnessConditionId = flatnessConditionId;
    this.flatnessConditionName = flatnessConditionName;
    this.selectInfo = selectInfo;
    this.gridSize = gridSize;
  }

  /** 
   * getters
   */
  get flatnessConditionId() {
    return this._flatnessConditionId;
  }

  get flatnessConditionName() {
    return this._flatnessConditionName;
  }

  get selectInfo() {
    return this._selectInfo;
  }

  get gridSize() {
    return this._gridSize;
  }

  /** 
   * setters
   */
  set flatnessConditionId(flatnessConditionId) {
    this._flatnessConditionId = flatnessConditionId;
  }

  set flatnessConditionName(flatnessConditionName) {
    this._flatnessConditionName = flatnessConditionName;
  }

  set selectInfo(selectInfo) {
    if (selectInfo) {
      try {
        if (typeof selectInfo === 'string') {
          this._selectInfo = JSON.parse(selectInfo);
        } else {
          this._selectInfo = selectInfo;
        }
      } catch (e) {
        this._selectInfo = [];
      }
    } else {
      this._selectInfo = [];
    }
  }

  set gridSize(gridSize) {
    this._gridSize = Number(gridSize);
  }

  /**
   * functions
   */
  update(flatnessConditionName, selectInfo, gridSize) {
    this.flatnessConditionName = flatnessConditionName;
    this.selectInfo = selectInfo;
    this.gridSize = gridSize;
  }
}