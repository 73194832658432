import { i18n } from "@/i18n";

/**
 * 整形済みメッセージを保持するエラークラス
 */
export class ApplicationError extends Error {

    static fromMessageCode(messageCode, cause) {
        return new ApplicationError(i18n.t(messageCode), { cause: cause }, messageCode);
    }

    static fromFormattedMessage(formattedMessage, cause) {
        return new ApplicationError(formattedMessage, { cause: cause });
    }

    constructor(formattedMessage, options, name) {
        super(formattedMessage, options);
        this.name = name ?? "ApplicationError";
    }
}
