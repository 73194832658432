<!-- 外周線編集 -->
<template>
  <v-card v-show="isVisible"><!-- v-show の指定は beforeUpdate が呼ばれるために必要 -->
    <v-card-title class="justify-space-between">
      <!-- TODO: 多言語化 -->
      <label>{{ $t("外周線編集") }}</label>
      <close-button @click="$emit('close')" size="20px"></close-button>
    </v-card-title>
    <v-container>
      <v-row>
        <v-col v-show="isMoveButtonVisible" align="center">
          <v-btn
            class=".rounded-lg"
            color="#4472c4"
            width="100px"
            height="45px"
            @click="move"
          >
            <!-- 移動 -->
            {{ $t("CIW0020125002") }}
          </v-btn>
        </v-col>
        <v-col v-show="isRemoveButtonVisible" align="center">
          <v-btn
            class=".rounded-lg"
            color="#4472c4"
            width="100px"
            height="45px"
            @click="remove"
          >
            <!-- 削除 -->
            {{ $t("SIW0020301003") }}
          </v-btn>
        </v-col>
        <v-col v-show="isAppendButtonVisible" align="center">
          <v-btn
            class=".rounded-lg"
            color="#4472c4"
            width="100px"
            height="45px"
            @click="add"
          >
            <!-- 追加 -->
            {{ $t("CIW0020125003") }}
          </v-btn>
        </v-col>
        <v-col v-show="isSaveButtonVisible" align="center">
          <v-btn
            class=".rounded-lg"
            color="#4472c4"
            width="100px"
            height="45px"
            @click="finish"
          >
            <!-- TODO: 多言語化 -->
            {{ $t("編集終了") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="showConfirmDialog"
      width="350px"
    >
      <confirm-dialog
        :title="$t('CIW0020000005')"
        :okButtonLabel="$t('CIW0020000005')"
        :okHandler="save"
        :cancelButtonLabel="$t('破棄')"
        :cancelHandler="restore"
        @close="()=>{ this.showConfirmDialog = false; }"
      >
        <!-- TODO: 多言語化 -->
        <template v-slot:contents>
          <p>{{ $t("変更を保存しますか？") }}</p>
        </template>
      </confirm-dialog>
    </v-dialog>
  </v-card>
</template>

<script>
import CloseButton from "@/components/common/CloseButton.vue";
import ConfirmDialog from '@/components/common/ConfirmDialog.vue';
import PerimeterLine from '@/models/PerimeterLine';
import { repositoryFactory } from "@/repositories/repository-factory";
import * as utils from '@/utils/utils';

const perimeterLineRepository = repositoryFactory.get('perimeterLine');

const PointHighlightStyle = {
  pixelSize: 10,
  color: {
    red: 1.0,
    green: 0,
    blue: 0,
  },
};

export default {
  components: {
    CloseButton,
    ConfirmDialog,
  },
  props: {
    isVisible: {
      type: Boolean,
      require: true,
      default: false,
    },
  },
  data() {
    return {
      isMoveButtonVisible: false,
      isRemoveButtonVisible: false,
      isAppendButtonVisible: false,
      isSaveButtonVisible: true,
      modificationHistories: [],
      showConfirmDialog: false,
    };
  },
  created() {
    this.initialize();
  },
  beforeUpdate() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (!this.isVisible) {
        return;
      }
      const perimterLineElement = this.$store.getters["edit/perimeterLineValues"].selectedElement;
      if (perimterLineElement) {
        switch (perimterLineElement.type) {
          case "Node":
            this.isMoveButtonVisible = true;
            this.isRemoveButtonVisible = true;
            this.isAppendButtonVisible = false;
            this.$emit("addDialogAttributes", {
              width: "400px",
            });
            break;
          case "Edge":
            this.isMoveButtonVisible = false;
            this.isRemoveButtonVisible = false;
            this.isAppendButtonVisible = true;
            this.$emit("addDialogAttributes", {
              width: "300px",
            });
            break;
        }
      }
      else {
        this.isMoveButtonVisible = false;
        this.isRemoveButtonVisible = false;
        this.isAppendButtonVisible = false;
        this.$emit("addDialogAttributes", {
          width: "200px",
        });
      }
    },
    add() {
      window["viewer"].enablePointHighlight({
        style: PointHighlightStyle,
      });
      let canceled = false;
      const cesiumElement = document.getElementById("Cesium");
      cesiumElement.addEventListener("click", (event) => {
        if (canceled) {
          return;
        }
        window["viewer"].disablePointHighlight();
        const coordinates = window["viewer"].pickPosition(event);
        if (!coordinates) {
          return;
        }
        const modification = {
          type: "Add",
          edge: this.$store.getters["edit/perimeterLineValues"].selectedElement,
          position: coordinates,
        };
        window["viewer"].modifyPerimeterLine(modification);
        this.modificationHistories.push(modification);
      }, { once: true });
      cesiumElement.addEventListener("contextmenu", () => {
        window["viewer"].disablePointHighlight();
        canceled = true;
      });
      this.$emit("close");
    },
    move() {
      window["viewer"].enablePointHighlight({
        style: PointHighlightStyle,
      });
      let canceled = false;
      const cesiumElement = document.getElementById("Cesium");
      cesiumElement.addEventListener("click", (event) => {
        if (canceled) {
          return;
        }
        window["viewer"].disablePointHighlight();
        const coordinates = window["viewer"].pickPosition(event);
        if (!coordinates) {
          return;
        }
        const modification = {
          type: "Move",
          node: this.$store.getters["edit/perimeterLineValues"].selectedElement,
          position: coordinates,
        };
        window["viewer"].modifyPerimeterLine(modification);
        this.modificationHistories.push(modification);
      }, { once: true });
      cesiumElement.addEventListener("contextmenu", () => {
        window["viewer"].disablePointHighlight();
        canceled = true;
      }, { once: true });
      this.$emit("close");
    },
    remove() {
      const modification = {
        type: "Remove",
        node: this.$store.getters["edit/perimeterLineValues"].selectedElement,
      };
      window["viewer"].modifyPerimeterLine(modification);
      this.modificationHistories.push(modification);
      this.$emit("close");
    },
    finish() {
      this.showConfirmDialog = true;
      this.$emit("close");
    },
    async save() {
      this.showConfirmDialog = false;
      // 外周線編集モード終了
      this.$store.dispatch("updateEditType", null);
      // 変更の保存
      const perimeterLineId = this.$store.getters["edit/perimeterLineValues"].currentPerimeterLine.perimeterLineId;
      const response = await perimeterLineRepository.updatePerimeterLine(perimeterLineId, this.modificationHistories);
      if (response.status === 200) {
        const perimeterLine = new PerimeterLine(
          response.data.perimeter_line_id,
          response.data.perimeter_line_info_epsg4978,
          response.data.status,
          response.data.message,
          );
        perimeterLine.isVisible = true;
        this.$store.commit("updatePerimeterLine", perimeterLine);
      }
      else {
        console.error(JSON.stringify(response));
        utils.showSnackBar('error', response.data);
      }
      // プロジェクトページを操作可能にする
      this.$store.commit("setProjectPageAbility", true);
    },
    restore() {
      this.showConfirmDialog = false;
      // 外周線編集モード終了
      this.$store.dispatch("updateEditType", null);
      // 変更前のデータの復元
      const currentPerimeterLines = this.$store.getters["edit/perimeterLineValues"].currentPerimeterLine.perimeterLineInfo;
      window["viewer"].setPerimeterLines(currentPerimeterLines);
      // プロジェクトページを操作可能にする
      this.$store.commit("setProjectPageAbility", true);
    },
  }
}
</script>
