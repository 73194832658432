// 設計ファイル系のstore
import Design from '@/models/Design';
import { repositoryFactory } from "@/repositories/repository-factory";
const designDataRepository = repositoryFactory.get('designData');

const state = {
  designFileList: []
};

const getters = {
  designFileList: state => state.designFileList,
  // Cesium上にタイリングされている設計データ
  tilingDesigns: state => state.designFileList
                          .filter( designFile => designFile.isTiling),
  // 可視不可視アイコンが可視状態になっている点群データ
  visibleDesigns: state => state.designFileList
                          .filter( designFile => designFile.isVisible)
};

const mutations = {
  updateDesignFileList(state, designFileList) {
    state.designFileList = designFileList;
  }
};

const actions = {
  async fetchDesignFileList({ getters, commit, rootGetters }, siteId) {
    let designs = [];
    const body = {
      user_id: rootGetters.loginData.user_id
    }
    try {
      const res = await designDataRepository.findBySiteId(siteId, body);
      if (res.status === 201) {
        for (const designData of res.data.designs) {
          const target = getters.designFileList.find(item =>
            item.designId === designData.design_id
          );
          // リストに存在する場合は更新、しない場合は追加
          if (target) {
            target.update(
              designData?.design_name,
              designData?.asset_id,
              designData?.created_at
            );
            designs.push(target);
          } else {
            const design = new Design(
              designData?.design_id,
              designData?.design_name,
              designData?.asset_id,
              designData?.created_at
            )
            designs.push(design);
          }
        }
        commit("updateDesignFileList", designs);
      }
    } catch (error) {
      console.error(error.message);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};