<!-- 計測結果 -->
<template>
  <div class="d-flex align-center data-list-title">
    <v-btn
      class="pa-0 mr-1"
      min-width="auto"
      plain
      height="auto"
      :disabled="!canShowMeasuredResults"
      @click="changeIsVisible(measureData)"
    >
      <v-icon v-if="measureData.isVisible">mdi-eye-outline</v-icon>
      <v-icon v-else>mdi-eye-off-outline</v-icon>
    </v-btn>
    <!-- データ名 -->
    <measure-data-name :measureData="measureData"></measure-data-name>
    <!-- アイコン表示エリア -->
    <div class="pl-3 ml-auto d-flex align-center">
      <!-- 処理ステータスー -->
      <div>
        <edit-processing-status
          class="ml-auto"
          @error-clear="clearProcessError"
          :message="measureData.message"
          :status="measureData.status">
        </edit-processing-status>
      </div>
      <!-- メニュー -->
      <v-menu 
        light
        offset-y 
        transition="slide-y-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-auto"
            icon
            v-bind="attrs"
            v-on="on"
            small
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item @click="showDialog = true">
            <v-list-item-icon class="mr-4">
              <v-icon>mdi-delete</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t("CIW0020002008") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <!-- 削除確認ダイアログ -->
    <v-dialog
      hide-overlay
      persistent
      no-click-animation
      v-model="showDialog"
      width="330px"
      height="170px"
    >
      <calculation-data-delete-confirm-dialog
        @ok="deleteMeasureData"
        @cancel="showDialog = false"> 
        <!-- ダイアログタイトル -->
        <template v-slot:title>
          <span>{{$t("CIW0020002006")}} </span>
        </template>
        <!-- ダイアログ本文テキスト -->
        <template v-slot:text>
          <span>{{$t("CIW0020002007")}}<br />{{$t("CQW0010600004")}}</span>
        </template>
      </calculation-data-delete-confirm-dialog>
    </v-dialog>
  </div>
</template>

<script>
import { i18n } from '@/i18n.js';
import * as utils from '@/utils/utils';
import * as cesiumCommon from "@/utils/cesium-common";
import EditProcessingStatus from '@/components/common/EditProcessingStatus.vue';
import MeasureDataName from './MeasureDataName.vue';
import CalculationDataDeleteConfirmDialog from './CalculationDataDeleteConfirmDialog.vue';
import { repositoryFactory } from "@/repositories/repository-factory";
const messageRepository = repositoryFactory.get('message');
const measureRepository = repositoryFactory.get('measure');

export default {
  components: {
    EditProcessingStatus,
    MeasureDataName,
    CalculationDataDeleteConfirmDialog
  },
  props: {
    measureData: Object,
    // MeasureData を受け取ってラベル表示用のテキストを返す関数
    // labelFormatter が設定されていない場合はラベルは表示されない
    labelFormatter: Function,
    allowedToShowMeasuredResults: Boolean,
  },
  data: () => ({
    showDialog: false,
  }),
  computed: {
    isMeasurementFinished() {
      return !!this.measureData.measureInfo;
    },
    canShowMeasuredResults() {
      return this.isMeasurementFinished && this.allowedToShowMeasuredResults;
    }
  },
  watch: {
    /**
     * 親点群が非表示になったら点数結果も非表示にする
     */
    canShowMeasuredResults(newValue) {
      if (!newValue && this.measureData.isVisible) {
        cesiumCommon.hideMeasuredResults(this.measureData);
        this.measureData.isVisible = false;
      }
    }
  },
  methods: {
    /**
     * 編集処理エラーを解除する
     * 解除に失敗した場合は通知を表示する
     */
    async clearProcessError() {
      let successMessage = i18n.tc("CIW0020300018")
      let errorMessage = i18n.tc("CEW0020300019")
      const body = {
        user_id: this.$store.getters.loginData.user_id
      };
      try {
        this.$store.dispatch("updateIsLoading", true);
        const res = await messageRepository.updateMeasureMessageById(this.measureData.id, body);
        if (res.status === 204) {
          // データリストを最新化
          await this.$store.dispatch("getPointDataList", this.$route.query.siteId),
          utils.showSnackBar('success', successMessage);
        } else {
          // 想定外のステータスコードが返却された場合エラーをスロー
          throw new Error(errorMessage);
        }
      } catch (e) {
        console.error(e.message);
        if (utils.createErrorMessage(e)) {
          errorMessage = utils.createErrorMessage(e);
        }
        utils.showSnackBar('error', errorMessage);
      } finally {
        this.$store.dispatch("updateIsLoading", false);
      }
    },
    changeIsVisible(measureData) {
      if (measureData.isVisible) {
        // 計測結果の非表示に成功したら目を閉じた状態にする
        measureData.isVisible = !cesiumCommon.hideMeasuredResults(measureData);
      }
      else {
        // Viewer で複数の選択範囲表示を扱えないため、既に表示中の選択範囲を予め非表示にしておく
        this.$store.getters.visibleMeasurements.forEach(m => this.changeIsVisible(m));
        // 計測結果の表示に成功したら目を開いた状態にする
        measureData.isVisible = cesiumCommon.showMeasuredResults(measureData, this.labelFormatter);
      }
    },
    async deleteMeasureData() {
      let successMessage = i18n.tc("CIW0020300022")
      let errorMessage = i18n.tc("CEW0020300023")
      const body = {
        user_id: this.$store.getters.loginData.user_id
      };
      try {
        // ローディング開始
        this.$store.dispatch("updateIsLoading", true);
        const res = await measureRepository.deleteMeasureByMeasureId(this.measureData.id, body);
        if (res.status === 204) {
          // データリストを最新化
          await this.$store.dispatch("getPointDataList", this.$route.query.siteId)
          utils.showSnackBar('success', successMessage);
          this.showDialog = false;
        } else {
          // 想定外のステータスコードが返却された場合エラーをスロー
          throw new Error(errorMessage);
        }
      } catch (e) {
        console.error(e.message);
        if (utils.createErrorMessage(e)) {
          errorMessage = utils.createErrorMessage(e);
        }
        utils.showSnackBar('error', errorMessage);
      } finally {
        // ローディング終了
        this.$store.dispatch("updateIsLoading", false);
      }
    }
  },
}
</script>

<style lang="sass" scoped>
.data-list-title
  font-size: 14px
  margin-top: 4px
  white-space: normal
  word-break: break-all

.data-list-title ::v-deep .v-text-field .v-input__control .v-input__slot
  min-height: auto

::v-deep .v-input__slot .v-input--selection-controls__input
  margin-right: 0
  height: auto

.data-list-title-name .on-hover
  cursor: pointer
  background-color: rgba(245,245,245,0.2)

// チェックボックスホバー時の背景色の変化を無効化
::v-deep div.v-input--selection-controls__ripple
  display: none

</style>