<!-- 点群データアップロード（ローカル） -->
<template>
  <v-card class="point-upload-dialog px-4 py-2">
    <v-card-title class="pa-0 justify-space-between">
      {{ item.text }}
      <close-button @click="$emit('close')" size="20px"></close-button>
    </v-card-title>
    <v-card-text class="px-0 py-1">
      <v-container class="pa-0">
        <v-row class="ma-0">
          <validation-observer class="d-block" style="width: 100%" ref="observer" v-slot="{ invalid }">
            <v-col v-if="item.value == 1" cols="12" class="my-3 pa-0">
              <label class="mb-1 d-block">{{$t("CQW0020104002")}}</label>
              <div
                class="pa-2 drop-area"
                :class="{ enter: isEnter }"
                @dragenter="dragEnter"
                @dragleave="dragLeave"
                @dragover.prevent
                @drop.prevent="dropFile"
              >
                <div class="d-inline-block">
                  <v-btn class="rounded-pill" color="rgba(127, 127, 127, 0.8)" width="140px" height="35px" depressed
                    :loading="isSelecting" @click="onButtonClick">
                    {{$t("CQW0020104003")}}
                  </v-btn>
                  <input
                    ref="uploader"
                    class="d-none"
                    type="file"
                    :accept="acceptedExtensions"
                    @change="onFileChanged" 
                  />
                </div>
                <div class="d-inline-block ml-2">
                  <v-icon class="d-inline-block mr-2" color="rgba(151,143,143,0.8)">mdi-download</v-icon>
                  <span class="d-inline-block" style="color: rgba(151, 143, 143, 0.8)">{{$t("CQW0020104004")}}</span>
                </div>
              </div>
              <div v-if="selectedFile" class="pt-2 d-flex justify-space-between" style="width: 95%">
                <span>{{ selectedFile.name }}</span>
                <close-button @click="resetForm" size="20px"></close-button>
              </div>
            </v-col>
            <v-col v-if="isTextFile" cols="12" class="my-3 pa-0">
              <label class="mb-1 d-block">{{$t("CQW0020104005")}}</label>
              <v-select v-model="selectedTextFormat" class="text-black" height="32px" background-color="#BFBFBF"
                :items="textFormatList" hide-details solo>
              </v-select>
            </v-col>
            <v-col cols="12" class="my-3 pa-0">
              <validation-provider :rules="{
                required: true,
                'file-enable-characters': true,
                'file-required': selectedFile,
                'data-name-length': 100
              }" v-slot="{ errors }">
                <div class="mb-1">
                  <label class="d-inline-block mr-2">{{$t("CQW0020104006")}}</label>
                  <span class="d-inline-block" style="font-size: 12px; color: #ff5252">{{ errors[0] }}</span>
                </div>
                <v-text-field @reset.prevent="resetError()" v-model.trim="dataName" :error-messages="errors"
                  class="text-black ma-0" height="32px" background-color="#BFBFBF" outlined solo></v-text-field>
              </validation-provider>
            </v-col>
            <!-- アップロード先 -->
            <v-col cols="12" class="my-3 pa-0">
              <label class="mb-1 d-block">{{$t("CQW0020104022")}}</label>
              <v-select
                v-model="selectedUploadDirectory"
                class="text-black"
                height="32px"
                background-color="#BFBFBF"
                :items="uploadDirectoryList"
                item-text="text"
                :item-value="item => ({value: item.value, text: item.text})"
                hide-details
                solo
              >
              </v-select>
            </v-col>
            <!-- チェックボックス -->
            <span>
              <v-row>
                <v-col cols="3" class="ml-2 mb-1 pa-0">
                  <v-checkbox
                    class="checkbox-label-size"
                    v-model="isCoordinateSystemSelected"
                    :label="$t('CQW0020900012')"
                    hide-details
                  >
                  </v-checkbox>
                </v-col>
                <v-col cols="3" class="mb-1 pa-0">
                  <v-checkbox
                    class="checkbox-label-size"
                    v-model="isVerticalShiftSelected"
                    :label="$t('CQW0020900014')"
                    hide-details
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
            </span>
            <!-- 座標系変換 -->
            <span v-if="isCoordinateSystemSelected">
              <v-col cols="12" class="my-3 pa-0 justify-center">
                <v-autocomplete
                  outlined
                  :items="ESPG_code_items"
                  v-model="ESPG_code"
                  :loading="isLoading"
                  class="text-black"
                  height="32px"
                  background-color="#BFBFBF"
                  item-text="label"
                  item-value="coordinate_system"
                  cache-items
                  hide-no-data
                  hide-selected
                  required
                  solo
                ></v-autocomplete>
              </v-col>
            </span>
            <!-- 垂直基準変換 -->
            <span v-if="isVerticalShiftSelected">
              <v-col cols="12" class="my-3 pa-0 justify-center">
                <v-autocomplete
                  outlined
                  :items="vertical_shift_items"
                  v-model="vertical_shift"
                  :loading="isLoading"
                  class="text-black"
                  height="32px"
                  background-color="#BFBFBF"
                  item-text="label"
                  item-value="vertical_shift"
                  cache-items
                  hide-no-data
                  hide-selected
                  required
                  solo
                ></v-autocomplete>
              </v-col>
            </span>
            <!-- アップロードボタン -->
            <v-col cols="4" class="mt-4 pa-0">
              <v-btn
                class="rounded-pill"
                color="primary"
                width="135px"
                height="35px"
                depressed
                :disabled="invalid"
                @click="fileUpload"
              >
                {{$t("CIW0020000007")}}
              </v-btn>
            </v-col>
          </validation-observer>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import CloseButton from "@/components/common/CloseButton.vue";
import * as constants from '@/constants/constants';
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { regex } from "vee-validate/dist/rules";
import * as utils from '@/utils/utils';
import { repositoryFactory } from "@/repositories/repository-factory";
import * as espg from '@/constants/espg';
import { i18n } from '@/i18n.js'
const pointRepository = repositoryFactory.get('point');

const enableExtensionPattern 
  = new RegExp(`.(${constants.pointEnableExtensions.join('|')})$`, 'i');

export default {
  components: {
    CloseButton,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    item: Object,
  },
  data() {
    return {
      systemDate: null,
      selectedFile: null,
      dataName: "",
      selectedUploadDirectory: "",
      selectedTextFormat: "NEZ-RGB",
      uploadDirectoryList: [],
      enableExtensions: constants.pointEnableExtensions,
      ESPG_code: "",
      ESPG_code_items:[
        {coordinate_system:"", label:i18n.tc("CQW0020900011")},
        ...espg.COORDINATE_SYSTEM_SETTING_LIST
      ],
      vertical_shift: "",
      vertical_shift_items:[
        {vertical_shift:"", label:i18n.tc("CQW0020900011")},
        ...espg.VERTICAL_SHIFT_SETTING_LIST
      ],
      textFormatList: [
        "NEZ-RGB",
        "ENZ-RGB",
        "NEZ-IRGB",
        "ENZ-IRGB",
        "NEZ-RGBI",
        "ENZ-RGBI",
        "NEZ",
        "ENZ",
      ],
      ddCounter: 0,
      isSelecting: false,
      isCoordinateSystemSelected: false,
      isVerticalShiftSelected: false
    }
  },
  computed: {
    isLoading: {
      get() {
        return this.$store.getters.isLoading;
      },
      set(value) {
        this.$store.dispatch("updateIsLoading", value);
      }
    },
    isEnter() {
      return this.ddCounter > 0;
    },
    isTextFile() {
      if (this.selectedFile) {
        return this.selectedFile.type === "text/plain" || this.selectedFile.type === "text/csv";
      }
      return false;
    },
    acceptedExtensions() {
      return this.enableExtensions.map(ext => `.${ext}`).join(',');
    }
  },
  watch: {
    isCoordinateSystemSelected() {
      this.ESPG_code = "";
    },
    isVerticalShiftSelected() {
      this.vertical_shift = "";
    }
  },
  methods: {
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.uploader.click();
    },
    async onFileChanged(event) {
      if (this.checkFileExtension(event.target.files[0].name)) {
        this.selectedFile = event.target.files[0];
        this.dataName = this.selectedFile.name;
      }
      event.target.value = "";
    },
    dragEnter() {
      this.ddCounter++;
    },
    dragLeave() {
      this.ddCounter--;
    },
    dropFile(event) {
      this.ddCounter = 0;
      if (
        event.dataTransfer.files.length === 1 &&
        this.checkFileExtension(event.dataTransfer.files[0].name)
      ) {
        this.selectedFile = event.dataTransfer.files[0];
        this.dataName = this.selectedFile.name;
      }
    },
    checkFileExtension(fileName) {
      return regex.validate(fileName, { regex: enableExtensionPattern });
    },
    async resetForm() {
      this.selectedFile = null;
      this.dataName = "";
      this.selectedTextFormat = "NEZ-RGB";
      this.selectedUploadDirectory = this.uploadDirectoryList.find(
          project => project.text === this.systemDate
        )
      await this.resetError();
    },
    async resetError() {
      await this.$refs.observer.validate();
      await this.$refs.observer.reset();
    },
    async fileUpload() {
      this.isLoading = true
      const form = new FormData()
      const body = {
        point_name: this.dataName,
        project_name: this.selectedUploadDirectory.text,
        project_id: this.selectedUploadDirectory.value,
        site_id: this.$route.query.siteId,
        is_dashboard: 0,
        format_type: this.isTextFile ? this.selectedTextFormat : "",
        created_by: this.$store.getters.loginData.user_id,
        point_file: this.selectedFile,
        coordinate_system: this.ESPG_code,
        vertical_shift: this.vertical_shift
      };
      for (const field in body) {
        form.append(field, body[field])
      }
      await this.request(form);
      await this.resetForm();
      this.isLoading = false;
      this.$emit('close');
    },
    setUploadDirectoryList() {
      let uploadDirectoryList = [];
      for (const project of this.$store.state.pointDataList) {
        uploadDirectoryList.push({value: project.id, text: project.name});
      }

      const sysDateProject = 
        this.$store.state.pointDataList.find(
          project => project.name === this.systemDate
        )

      if (!sysDateProject) {
        this.selectedUploadDirectory = {value: "", text: this.systemDate};
        uploadDirectoryList = [
          ...uploadDirectoryList,
          this.selectedUploadDirectory,
        ];
      } else {
        this.selectedUploadDirectory = {value: sysDateProject.id, text: sysDateProject.name}
      }
      this.uploadDirectoryList = uploadDirectoryList
    },
    async request(form) {
      let successMessage = i18n.tc("CIW0020104005");
      let errorMessage = i18n.tc("CEW0020104005");
      try {
        const res = await pointRepository.create(form);
        if (res.status === 201) {
          await this.$store.dispatch("getPointDataList",
            this.$route.query.siteId
          )
          this.setUploadDirectoryList()
          utils.showSnackBar('success', successMessage);
        } else {
          utils.showSnackBar('error', errorMessage);
        }
      } catch (e) {
        console.error(e);
        if (utils.createErrorMessage(e)) {
          errorMessage = utils.createErrorMessage(e);
        }
        utils.showSnackBar('error', errorMessage);
      }
    }
  },
  mounted() {
    const today = new Date();

    this.systemDate =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      today.getDate().toString().padStart(2, "0");
    this.setUploadDirectoryList();
  },
};
</script>

<style lang="sass" scoped>
.point-upload-dialog
  .v-card__title
    font-size: 16px

.point-upload-dialog
  p,span
    color: white

::v-deep .v-input__append-outer
  margin: 0

::v-deep .v-input__slot
  margin: 0
  padding: 0

.text-black ::v-deep input,
.text-black ::v-deep label,
.text-black ::v-deep .v-select__selection,
.text-black ::v-deep .v-input__append-inner i
  color: black

::v-deep .v-text-field input,
::v-deep .v-text-field.v-input .v-select__selections input
  padding: 0

.drop-area
  border: 2px dashed white

.enter
  border: 2px dashed #2196F3

.text-field-error
  border: solid 2px #ff5252

::v-deep .v-text-field__details
  display: none
::v-deep .checkbox-label-size .v-label
 font-size: 11.5px
</style>