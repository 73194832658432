import Repository from "./repository";

const resource = "/adjustment";
export default {
  coloringByPointId(pointId, payload) {
    return Repository.put(`${resource}/coloring/${pointId}`, payload);
  },
  offSetByPointId(pointId, payload) {
    return Repository.put(`${resource}/offset/${pointId}`, payload);
  },
};
