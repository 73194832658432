<template>
  <v-dialog
    v-model="isShow"
    v-bind="dialog.attributes"
    v-on="dialog.handlers"
  >
    <component
      :is="name"
      v-bind="component.attributes"
      v-on="component.handlers"
    >
    </component>
  </v-dialog>
</template>

<script>
import LocalPointUploadDialog from "./Upload/Point/LocalPointUploadDialog.vue";
import DashboardPointUploadDialog from "./Upload/Point/DashboardPointUploadDialog.vue";
import MapPointUploadDialog from "./Upload/Point/MapPointUploadDialog.vue";
import LocalDesignUploadDialog from "./Upload/Design/LocalDesignUploadDialog.vue";
import ThinningDesignDialog from "./ThinningDesignDialog.vue";
import ThinningLatticeDialog from "./ThinningLatticeDialog.vue";
import ThinningGroundSurfaceDialog from "./ThinningGroundSurfaceDialog.vue";
import GridInterpolationDialog from "./Interpolation/GridInterpolationDialog.vue";
import LinearDrawingDialog from "./LinearDrawingDialog.vue";
import LinearEditDialog from "./LinearEditDialog.vue";
import PointCloudColoringDialog from "./Adjustments/PointCloudColoringDialog.vue";
import LinearUploadDialog from "./LinearUploadDialog.vue";
import UnlockConfirmDialog from "./Lock/UnlockConfirmDialog.vue";
import ForceUnlockConfirmDialog from "./Lock/ForceUnlockConfirmDialog.vue";
import LandXMLCreateDialog from "./LandXMLCreateDialog.vue"
import PointCreateDialog from "./PointCreateDialog.vue";
import PointSyntheticDialog from "./PointSyntheticDialog.vue";
import HoleInterpolationDialog from "./Interpolation/HoleInterpolationDialog.vue";
import DashboardExportDialog from "./Download/DashboardExportDialog";
import BrowserDownloadDialog from "./Download/BrowserDownloadDialog.vue";
import OffSetDialog from "./Adjustments/OffSetDialog.vue";
import CancelEditingPointCloudDialog from "./General/CancelEditingPointCloudDialog.vue";
import FlatnessCheckDialog from "./MeasurementAndDisplay/Measurement/FlatnessCheck.vue";
import CrossSectionCreateDialog from "./CrossSectionCreateDialog.vue";
import UnsavedConfirmDialog from "./UnsavedConfirmDialog.vue";
import PerimeterLineCreateDialog from "./PerimeterLineCreateDialog.vue";
import FlatnessConditionCreateDialog from "./FlatnessConditionCreateDialog.vue";

export default {
  components: {
    LocalPointUploadDialog,
    DashboardPointUploadDialog,
    MapPointUploadDialog,
    LocalDesignUploadDialog,
    ThinningDesignDialog,
    ThinningLatticeDialog,
    ThinningGroundSurfaceDialog,
    GridInterpolationDialog,
    LinearDrawingDialog,
    LinearEditDialog,
    PointCloudColoringDialog, 
    LinearUploadDialog,
    UnlockConfirmDialog,
    ForceUnlockConfirmDialog,
    LandXMLCreateDialog,
    PointCreateDialog,
    PointSyntheticDialog,
    HoleInterpolationDialog,
    DashboardExportDialog,
    BrowserDownloadDialog,
    OffSetDialog,
    CancelEditingPointCloudDialog,
    FlatnessCheckDialog,
    CrossSectionCreateDialog,
    UnsavedConfirmDialog,
    PerimeterLineCreateDialog,
    FlatnessConditionCreateDialog
  },
  props: {
    // ダイアログの状態
    isShow: {
      type: Boolean,
      require: true,
      default: false,
    },
    // 表示するダイアログ名
    name: {
      type: String,
      require: true,
    },
    // ダイアログ内で扱うデータ
    item: {
      type: Object,
      require: false,
    },
    // ブラウザで発生するイベント
    event: {
      type: Object,
      require: false,
    }
  },
  data() {
    return {
      'dialog': {
        attributes: {
          'hide-overlay': false,
          'persistent': true,
          'no-click-animation': true
        },
        handlers: {
          //'click:outside': this.closeDialog
        },
      },
      'component': {
        attributes: {},
        handlers: {
          'close': () => { this.$emit('close') },
          'hide': () => { this.$emit('hide') }, 
          'reshow': () => { this.$emit('reshow') } 
        },
      },
    }
  },
  beforeUpdate() {
    switch (this.name) {
      case 'local-point-upload-dialog':
      case 'dashboard-point-upload-dialog':
      case 'map-point-upload-dialog':
      case 'local-design-upload-dialog':
      case 'linear-upload-dialog':
        this.dialog.attributes = {
          ...this.dialog.attributes,
          width: '500px'
        }
        this.component.attributes = { 
          item: this.item
        };
        break;
      case 'thinning-lattice-dialog':
      case 'thinning-ground-surface-dialog':
      case 'thinning-design-dialog':
      case 'grid-interpolation-dialog':
      case 'linear-drawing-dialog':
      case 'off-set-dialog':
      case 'point-cloud-coloring-dialog':
      case 'landXML-create-dialog':
      case 'point-create-dialog':
      case 'point-synthetic-dialog':
      case 'hole-interpolation-dialog':
      case 'dashboard-export-dialog':
      case 'browser-download-dialog':
      case 'cancel-editing-point-cloud-dialog':
      case 'flatness-check-dialog':
      case 'flatness-condition-create-dialog':
      case 'cross-section-create-dialog':
      case 'perimeter-line-create-dialog':
        this.dialog.attributes = {
          ...this.dialog.attributes,
          width: '460px'
        }
        break;
      case 'unlock-confirm-dialog':
        this.dialog.attributes = {
          ...this.dialog.attributes,
          width: '350px'
        }
        this.component.attributes = {
            project: this.item
        }
        break;
      case 'force-unlock-confirm-dialog':
        this.dialog.attributes = {
          ...this.dialog.attributes,
          width: '400px'
        }
        this.component.attributes = {
            project: this.item
        }
        break;
      case 'linear-edit-dialog':
        this.dialog.attributes = {
          ...this.dialog.attributes,
          width: '680px'
        }
        break;
      case 'unsaved-confirm-dialog':
        this.dialog.attributes = {
          ...this.dialog.attributes,
          width: '350px'
        },
        this.component.attributes = {
            ...this.item
        }
        break;
      default:
        break;
    }
   }
}
</script>