
<template>
  <v-card class="project-dialog">
    <v-card-title class="pt-2 justify-space-between">
      <span>{{ title }}</span>
      <close-button @click="$emit('close')" size="18px"></close-button>
    </v-card-title>
    <v-card-text class="py-0 pb-0" style="color: white">
      <slot name="contents"></slot>
    </v-card-text>
    <v-card-actions class="pa-0 pb-5 justify-center">
      <v-btn v-if="useCancelButton" class="pa-3 mr-2 btn" color="#ff5050" width="125px" height="45px" @click="cancelHandler">
        {{ cancelButtonLabel }}
      </v-btn>
      <v-btn v-if="useOKButton" class="pa-3 mr-1 btn" color="#4472c4" width="125px" height="45px" @click="okHandler">
        {{ okButtonLabel }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import CloseButton from "@/components/common/CloseButton.vue";
import { i18n } from '@/i18n.js'

export default {
  components: {
    CloseButton
  },
  props: {
    title: String,
    useOKButton: {
      required: false,
      type: Boolean,
      default: true
    },
    okButtonLabel: {
      type: String,
      default: i18n.tc("CQW0010600006")
    },
    okHandler: Function,
    useCancelButton: {
      required: false,
      type: Boolean,
      default: true
    },
    cancelButtonLabel: {
      type: String,
      default: i18n.tc("CQW0010600005")
    },
    cancelHandler: Function,
  }
};
</script>
<style lang="sass" scoped>
.project-dialog
  .v-card__title
    font-size: 16px

.project-dialog .btn
    font-size: 13px

.project-dialog
  p,span
    color: white
</style>