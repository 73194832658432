// 処理結果通知のstore
const state = {
  isShow: false,
  message: {
    text: "",
    type: "",
    icon: ""
  },
  timeout: 5000
};

const getters = {
  isShow: state => state.isShow,
  message: state => state.message,
  timeout: state => state.timeout
};

const mutations = {
  setIsShow(state, isShow) {
    state.isShow = isShow;
  },
  setMessage(state, message) {
    state.message = message;
  },
  setTimeout(state, timeout) {
    state.timeout = timeout;
  }
};

const actions = {
  setIsShow({ commit }, isShow) {
    commit("setIsShow", isShow);
  },
  setMessage({ commit }, message) {
    commit("setMessage", message);
  },
  setTimeout({ commit }, timeout) {
    commit("setTimeout", timeout);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}