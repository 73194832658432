<!-- 線形描画ダイアログ -->
<template>
  <v-card class="project-dialog px-4 pt-2 pb-4">
    <v-card-title class="pa-0 justify-space-between">
      <label>
        {{$t("CQW0020122001")}}
      </label>
      <close-button @click="$emit('close')" size="20px"></close-button>
    </v-card-title>
    <v-card-text class="px-0 pt-1 pb-0 white--text">
      <v-container class="pa-0">
        <v-row class="ma-0">
          <validation-observer class="d-block" style="width: 100%" ref="observer" v-slot="{ invalid, pristine }">
            <v-col cols="12" class="my-3 pa-0">
            </v-col>
            <!-- 測点間隔 -->
            <v-col cols="12" class="mt-3 pa-0">
              <validation-provider :rules="{
                required: true,
                'number-format': true,
                'decimal-size-limit': 3,
                'min-value': 1,
                'max-value': 100.999,
              }" v-slot="{ errors }">
                <div class="mb-1">
                  <label class="d-inline-block mr-2">{{$t("CQW0020122003")}}</label>
                  <span class="d-inline-block" style="font-size: 12px; color: #ff5252">{{ errors[0] }}</span>
                </div>
                <v-text-field
                  @reset.prevent="resetError()"
                  v-model="stationInterval"
                  :error-messages="errors"
                  class="text-black ma-0"
                  height="32px"
                  background-color="#BFBFBF"
                  outlined
                  solo
                  suffix="m"
                >
                </v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" class="mt-4 pa-0 d-flex justify-end">
              <v-btn
                class="rounded-lg mr-5"
                color="#ff5050"
                width="115px"
                height="45px"
                depressed
                @click="$emit('close')">
                {{$t("CQW0010600005")}}
              </v-btn>
              <v-btn
                class="rounded-lg"
                color="#4472c4"
                width="115px"
                height="45px"
                depressed
                :disabled="pristine || invalid"
                @click="draw">
                {{$t("CQW0020122005")}}
              </v-btn>
            </v-col>
          </validation-observer>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { i18n } from '@/i18n.js'
import * as utils from '@/utils/utils';
import * as cesiumCommon from "@/utils/cesium-common";
import CloseButton from "@/components/common/CloseButton.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { repositoryFactory } from "@/repositories/repository-factory";
import Linear from "../../../models/Linear";
const linearFileRepository = repositoryFactory.get('linearFile');

export default {
  components: {
    CloseButton,
    ValidationProvider,
    ValidationObserver,
  },
  computed: {
    stationInterval: {
      get() {
        return this.$store.getters["edit/linearDrawing"].stationInterval;
      },
      set(value) {
        const properties = {
          stationInterval: value
        }
        this.$store.dispatch("edit/setLinearDrawing", properties);
      }
    },
    linearData: {
      get() {
        return this.$store.getters["edit/linearDrawing"].linearData;
      },
      set(value) {
        const property = {
          linearData: value
        }
        this.$store.dispatch("edit/setLinearDrawing", property);
      }
    },
    //↑と同じく非活性
    /* isDisabledDrawingBtn() {
      // 0が入力されている場合非活性
      if (!Number(this.stationInterval)) return true
      else return false
    } */
  },
  methods: {
    resetForm() {
      this.stationInterval = "" 
      this.linearData = {}
      this.resetError();
    },
    async resetError() {
      await this.$refs.observer.validate();
      await this.$refs.observer.reset();
    },
    async draw() {
      // ダイアログを閉じる
      this.$emit('close')
      try {
        const routeEpsg4978 = await cesiumCommon.drawRoute();
        const linearFile = await this.saveRoute(routeEpsg4978);
        this.$store.commit("linearFile/addLinearFile", linearFile);
        cesiumCommon.changeLinearVisible(linearFile);

        // エラーがなければ正常メッセージを出力する
        utils.showSnackBar("success", i18n.tc("CIW0020122001"));
      } catch (e) {
        // エラーが発生したら処理終了
        console.error(e);
        const errorMessage = utils.createErrorMessage(e) ?? i18n.tc("CEW0020122002");
        utils.showSnackBar("error", errorMessage);
      }
    },
    /**
     * 線形データを保存する
     * @param {*} event 
     */
    async saveRoute(routeEpsg4978) {
      // APIリクエスト

      // ローディング開始
      this.$store.dispatch("updateIsLoading", true);
      try {
        const res = await linearFileRepository.createFromDrawing(this.$route.query.siteId, routeEpsg4978, this.stationInterval);
        if (res.status !== 201) {
          // 想定外のステータスコードが返却された場合エラーをスロー
          throw new Error(res.data);
        }

        return new Linear(
          res.data.linear_id,
          res.data.linear_name,
          res.data.linear_info,
          res.data.route,
          res.data.route_epsg4978,
          res.data.station_interval
        );
      } finally {
        // ローディング解除
        this.$store.dispatch("updateIsLoading", false);
      }
    },
  },
  mounted() {
    // 初期化
    this.resetForm();
    // viewer上の線形ファイルを全て非表示にする
    const visibleLinearList = this.$store.getters['linearFile/visibleLinearFileList'];
    for (const linear of visibleLinearList) {
      cesiumCommon.changeLinearVisible(linear);
    }
  }
};
</script>

<style lang="sass" scoped>
.project-dialog
  .v-card__title
    font-size: 16px

::v-deep .v-input__append-outer
  margin: 0

::v-deep .v-input__slot
  margin: 0
  padding: 0

.text-black ::v-deep input,
.text-black ::v-deep label,
.text-black ::v-deep .v-input__append-inner i,
::v-deep .v-text-field__suffix
  color: black

::v-deep .v-text-field input,
::v-deep .v-text-field.v-input .v-select__selections input
  padding: 0

.select-file-area
  border: 1px dashed white

.text-field-error
  border: solid 2px #ff5252

::v-deep .v-text-field__details
  display: none

::v-deep div.v-input.v-text-field div.v-input__prepend-outer
  margin: auto 8px auto 1px
  color: white

::v-deep button.v-btn span.v-btn__content
  font-size: 13px
</style>