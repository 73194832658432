<!-- クリッピング -->
<template>
  <v-card class="confirm" data-testid="clipping-confirm-dialog">
    <loading :parentLoading="isLoading" />
    <v-card-title class="pt-2 justify-space-between" style="font-size: 14px; color: white">
      <span>{{$t("CIW0020000014")}} </span>
      <close-button role="close" @click="$emit('close')" size="18px"></close-button>
    </v-card-title>
    <v-card-text class="py-0 pb-5" style="color: white">
      <span>{{$t("CQW0020109002")}}<br />{{$t("CQW0020109003")}}</span>
    </v-card-text>
    <v-card-actions class="pa-0 pb-5 justify-center">
      <v-btn class="pa-3 mr-2 btn" role="cancel" color="#ff5050" width="110px" height="40px" @click="$emit('cancel')">
        {{$t("CQW0010600005")}}
      </v-btn>
      <v-btn
        class="pa-3 btn"
        role="ok"
        color="#4472c4"
        width="110px"
        height="40px"
        @click="clipping"
      >
      {{$t("CQW0020109005")}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import CloseButton from "@/components/common/CloseButton.vue";
import { ApplicationError } from "@/utils/application-error";
import * as utils from '@/utils/utils';
import Loading from '../../../common/LoadingOverLay.vue';
import { repositoryFactory } from "@/repositories/repository-factory";
const clippingRepository = repositoryFactory.get('clipping');

export default {
  components: {
    CloseButton,
    Loading
  },
  data() {
    return {
      isLoading: false
    };
  },
  methods: {
    clipping() {
      this.$emit('ok', this.save);
    },
    // Cesium.vueでクリッピング実行後に呼ばれる
    async save(clippingInfo) {
      this.isLoading = true
      const body = {
        clipping_info: clippingInfo,
        user_id: this.$store.getters.loginData.user_id
       };
      await this.request(body);
      await this.$store.dispatch("getPointDataList", this.$route.query.siteId)
      this.isLoading = false
    },
    async request(body) {
      try {
        for (const point of this.$store.getters.visiblePointClouds) {
          const res = await clippingRepository.createByPointId(point.pointId, body);
          if (res.status !== 201) {
            // 想定外のステータスコードが返却された場合エラーをスロー
            throw ApplicationError.fromMessageCode("CEW0020109002", res.statusText);
          }
        }
      } catch (e) {
        // エラーが発生したら処理終了
        const errorMessage = utils.createErrorMessage(e);
        if (errorMessage) {
          throw ApplicationError.fromFormattedMessage(errorMessage, e);
        }
        throw e;
      }
    },
  }
};
</script>
<style lang="sass" scoped>
.confirm .btn
    font-size: 13px
</style>