<!-- 平坦度計算条件名のコンポーネント -->
<template>
  <div id="flatness-condition-title-name">
    <!-- ファイル名表示 -->
    <div
      v-if="!isEditable"
      @mousedown.prevent=""
      @click.stop="onClickFileName"
    >
      <v-hover v-slot="{ hover }">
        <div class="d-flex align-center" :class="{ 'on-hover': hover }">
          {{ flatnessConditionName }}
        </div>
      </v-hover>
    </div>
    <!-- ファイル名編集 -->
    <div v-else>
      <validation-observer
        class="d-block"
        style="width: 100%"
        ref="observer"
        v-slot="{ invalid }"
      >
        <v-tooltip
          :value="invalid"
          bottom
          color="error"
          nudge-top=8
          max-width="200"
          min-width="200"
        >
          <template v-slot:activator="{}">
            <validation-provider :rules="{
                required: true,
                'data-name-length': 100
              }" ref="provider">
              <v-text-field
                class="ma-0 pa-0"
                hide-details=""
                height="24px"
                v-model="flatnessConditionName"
                outlined
                autofocus
                style="font-size: 14px"
                :loading="isLoading"
                @blur="blur"
                @keyup.enter="enter"
                @keyup.escape="cancelUpdateFileName"
              >
              </v-text-field>
            </validation-provider>
           </template>
           <!-- エラーメッセージ -->
           <p class="ma-0 pa-0">{{$refs.provider?.errors[0]}}</p>
        </v-tooltip>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import * as utils from '@/utils/utils';
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { repositoryFactory } from "@/repositories/repository-factory";
import { i18n } from '../../../i18n.js'
const flatRepository = repositoryFactory.get('flat');

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    flatnessCondition: Object
  },
  data: () => ({
    flatnessConditionName: "",
    isEditable: false,
    clickCounter: 0
  }),
  computed: {
    isLoading: {
      get() {
        return this.$store.getters.isLoading;
      },
      set(value) {
        this.$store.dispatch("updateIsLoading", value);
      }
    }
  },
  methods: {
    getLockState() {
      return this.$store.getters['lock/getLockBySiteId'](this.$route.query.siteId);
    },
    onClickFileName() {
      this.clickCounter++;
      if (this.clickCounter === 1) {
        setTimeout(()=>{
          if (this.clickCounter === 2) {
            const lockState = this.getLockState();
            let userId = "";
            if(lockState != undefined) {
              userId = lockState.userId;
            }
            if (userId == this.$store.getters.loginData.user_id) {
              this.startNameEditing();
            }
          }
          this.clickCounter = 0;
        }, 400)
      }
    },
    async blur() {
      // 前回のリクエストが終わっていない場合はリクエストを送らない
      if (this.isLoading) return;

      // バリデーションエラーがある場合編集キャンセル
      if (this.$refs.observer.flags.invalid) {
        this.cancelUpdateFileName();
        return
      }
      // ファイル名に変更がなければリクエストを送らない
      if ( this.flatnessConditionName === this.flatnessCondition.flatnessConditionName) {
          this.endNameEditing();
          return
      }
      // リクエスト成功の場合は編集モード終了、失敗の場合は変更を戻す
      const status = await this.updateFileName();
      if (status) {
        this.endNameEditing();
      } else {
        this.cancelUpdateFileName();
      }
    },
    async enter() {
      // 前回のリクエストが終わっていない場合はリクエストを送らない（連打防止）
      if (this.isLoading) return;

      // バリデーションエラーがある場合Enterキーを押しても確定させない
      if (this.$refs.observer.flags.invalid) {
        return
      }
      // ファイル名に変更がなければリクエストを送らない
      if ( this.flatnessConditionName === this.flatnessCondition.flatnessConditionName) {
          this.endNameEditing();
          return
      }
      // リクエスト成功の場合は編集モード終了
      const status = await this.updateFileName();
      if (status) this.endNameEditing();
    },
    // ファイル名変更
    async updateFileName() {
      this.isLoading = true
      const body = {
        flat_condition_name: this.flatnessConditionName,
        user_id: this.$store.getters.loginData.user_id
      };
      const status = await this.request(body);
      this.isLoading = false
      return status;
    },
    // ファイル名変更キャンセル
    cancelUpdateFileName() {
      this.flatnessConditionName = this.flatnessCondition.flatnessConditionName;
      this.endNameEditing();
    },
    // ファイル名編集開始と終了
    startNameEditing() {
      this.isEditable = true
    },
    endNameEditing() {
      this.isEditable = false
    },
    async request(body) {
      let successMessage = i18n.tc("CIW0020126005");
      let errorMessage = i18n.tc("CEW0020126006");
      try {
        const res = await flatRepository.updateFlatConditionByConditionId(this.flatnessCondition.flatnessConditionId, body);
        if (res.status === 204) {
          await this.$store.dispatch("flatness/fetchFlatnessConditionList", this.$route.query.siteId)
          utils.showSnackBar('success', successMessage);
          return res.status;
        } else {
          // 想定外のステータスコードが返却された場合エラーをスロー
          throw new Error(errorMessage);
        }
      } catch (e) {
        console.error(e.message);
        if (utils.createErrorMessage(e)) {
          errorMessage = utils.createErrorMessage(e);
        }
        utils.showSnackBar('error', errorMessage);
        return "";
      }
    },
  },
  created() {
    this.flatnessConditionName = this.flatnessCondition.flatnessConditionName;
  },
}
</script>

<style lang="sass" scoped>
#flatness-condition-title-name .on-hover
  cursor: pointer
  background-color: rgba(245,245,245,0.2)

#flatness-condition-title-name ::v-deep div.v-input__slot
  padding: 0 2px

</style>