var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"project-dialog px-4 pt-2 pb-4"},[_c('v-card-title',{staticClass:"pa-0 justify-space-between"},[_c('label',[_vm._v(" "+_vm._s(_vm.$t("外周線作成"))+" ")]),_c('close-button',{attrs:{"size":"20px"},on:{"click":function($event){return _vm.$emit('close')}}})],1),_c('v-card-text',{staticClass:"px-0 pt-1 pb-0 white--text"},[_c('v-container',{staticClass:"pa-0"},[_c('v-row',{staticClass:"ma-0"},[_c('validation-observer',{ref:"observer",staticClass:"d-block",staticStyle:{"width":"100%"},scopedSlots:_vm._u([{key:"default",fn:function({ invalid, pristine }){return [_c('v-col',{staticClass:"my-3 pa-0",attrs:{"cols":"12"}},[_c('editing-point-clouds',{attrs:{"title":_vm.$t('CIW0020000008')}})],1),_c('v-col',{staticClass:"mt-3 pa-0",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":{
              required: true,
              'number-format': true,
              'decimal-size-limit': 2,
              'min-value': 0.01,
              'max-value': 1000.99,
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"d-inline-block mr-2"},[_vm._v(_vm._s(_vm.$t("CQW0020137001")))]),_c('span',{staticClass:"d-inline-block",staticStyle:{"font-size":"12px","color":"#ff5252"}},[_vm._v(_vm._s(errors[0]))])]),_c('v-text-field',{staticClass:"text-black ma-0",attrs:{"error-messages":errors,"height":"32px","background-color":"#BFBFBF","outlined":"","solo":"","suffix":"m"},on:{"reset":function($event){$event.preventDefault();return _vm.resetError()}},model:{value:(_vm.maxDistance),callback:function ($$v) {_vm.maxDistance=$$v},expression:"maxDistance"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-4 pa-0 d-flex justify-end",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mr-10 rounded-lg",attrs:{"color":"#ff5050","width":"115px","height":"45px","depressed":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t("CQW0010600005"))+" ")]),_c('v-btn',{staticClass:"rounded-lg",attrs:{"color":"#4472c4","width":"115px","height":"45px","depressed":"","disabled":pristine || invalid || !_vm.isExecutable},on:{"click":_vm.execute}},[_vm._v(" "+_vm._s(_vm.$t("CQW0020109005"))+" ")])],1)]}}])})],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","width":"350px"},model:{value:(_vm.showConfirmDialog),callback:function ($$v) {_vm.showConfirmDialog=$$v},expression:"showConfirmDialog"}},[_c('confirm-dialog',{attrs:{"title":_vm.$tc('CQW0020115008'),"okHandler":_vm.toggleConfirmDialog,"useCancelButton":false},on:{"close":_vm.toggleConfirmDialog},scopedSlots:_vm._u([{key:"contents",fn:function(){return [_c('p',[_vm._v(_vm._s(_vm.$t("CQW0020115009", 1, {name: _vm.$t("CIW0020000072")})))])]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }