import Repository from "./repository";

const resource = "/section-file";
export default {
  // 断面ファイルリスト返却
  findBySiteId(siteId, payload) {
    return Repository.post(`${resource}/${siteId}`, payload);
  },
  // 断面データ更新
  updateSectionFileBySectionId(sectionId, payload) {
    return Repository.put(`${resource}/${sectionId}`, payload);
  },
  // 断面データ削除
  deleteSectionFileBySectionId(sectionId, payload) {
    return Repository.delete(`${resource}/${sectionId}`, { data:payload });
  },
  // 断面作成
  createSectionFile(payload) {
    return Repository.put(`${resource}`, payload);
  },
  // 断面編集
  editSectionFileBySectionId(sectionId, lineIndex, payload) {
    return Repository.put(`${resource}/edit/${sectionId}/${lineIndex}`, payload);
  },
  // 断面データ編集処理監視
  monitoringBySectionFile(payload) {
    return Repository.get(`${resource}/section/watch`, { params: payload });
  }
};
