<template>
  <v-card class="confirm">
    <loading :parentLoading="isLoading" />
    <v-card-title class="pt-2 justify-space-between" style="font-size: 14px; color: white">
      <span>{{$t("CIW0020000017")}}</span>
      <close-button @click="$emit('close')" size="18px"></close-button>
    </v-card-title>
    <v-card-text class="py-0 pb-5" style="color: white">
      <span>{{$t("CQW0020110002")}}<br />{{$t("CQW0020110003")}}</span>
    </v-card-text>
    <v-card-actions class="pa-0 pb-5 justify-center">
      <v-btn class="pa-3 mr-2 btn" color="#ff5050" width="110px" height="40px" @click="$emit('cancel')">
        {{$t("CQW0010600005")}}
      </v-btn>
      <v-btn class="pa-3 mr-1 btn" color="#4472c4" width="110px" height="40px" @click="execute(1)">
        {{$t("CQW0020109005")}}<br>{{$t("CQW0020110006")}}
      </v-btn>
      <v-btn class="pa-3 btn" color="#4472c4" width="110px" height="40px" @click="execute(2)">
        {{$t("CQW0020110007")}}<br>{{$t("CIW0020000005")}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import CloseButton from "@/components/common/CloseButton.vue";
import * as utils from '@/utils/utils';
import Loading from '../../../common/LoadingOverLay.vue';
import { repositoryFactory } from "@/repositories/repository-factory";
import { i18n } from '../../../../i18n.js'
const unnecessaryRemovalRepository = repositoryFactory.get('unnecessaryRemoval');

export default {
  components: {
    CloseButton,
    Loading
  },
  data() {
    return {
      isLoading: false
    };
  },
  methods: {
    execute(method) {
      this.$emit('remove', this.deleteCallback.bind(null, method));
    },
    // Cesium.vueの不要物除去実行後に呼ばれる
    deleteCallback(method, selectInfo) {
      return this.save(method, selectInfo)    
    },
    async save(method, selectInfo) {
      this.isLoading = true
      const body = {
        execution_method: method,
        user_id: this.$store.getters.loginData.user_id,
        select_info: selectInfo,
       };

      await this.putRequest(body);
      this.isLoading = false
    },
    async putRequest(body) {
      let successMessage = i18n.tc("CIW0020110005")
      let errorMessage = i18n.tc("CEW0020110001")
      let status
      
      try {
        // リクエストマップの件数分リクエストを送信
        for (const pointFile of this.$store.getters.visiblePointClouds) {
          const res = await unnecessaryRemovalRepository.updateSelectPointByPointId(pointFile.pointId, body);
          if (res.status === 201 || res.status === 204) {
            status = res.status;
          } else {
            // 想定外のステータスコードが返却された場合エラーをスロー
            throw new Error(errorMessage);
          }
        }
        // エラーがなければ正常メッセージを出力する
        utils.showSnackBar('success', successMessage);

        return status;
      } catch (e) {
        // エラーが発生したら処理終了
        console.error(e.message);
        if (utils.createErrorMessage(e)) {
          errorMessage = utils.createErrorMessage(e);
        }
        utils.showSnackBar('error', errorMessage);
        return "";
      } finally {
        // 上書きに成功した点群を不可視にする
        if (status === 201 || status === 204) {
          utils.allClearTileset();
          // データリストを更新
          utils.refreshDataList(this.$route.query.siteId);
        }
      }
    },
  }
};
</script>
<style lang="sass" scoped>
.confirm .btn
    font-size: 13px
</style>