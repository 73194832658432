<!-- 平坦度チェック -->
<template>
  <v-card class="px-4 pt-2 pb-4">
    <v-card-title class="pa-0 justify-space-between">
      <label>
        {{ $t("CQW0020126015") }}
      </label>
      <close-button @click="close" size="20px"></close-button>
    </v-card-title>
    <v-card-text class="px-0 pt-1 pb-0 white--text">
      <v-container class="pa-0">
        <v-row class="ma-0">
          <v-col cols="12" class="my-3 pa-0">
            <editing-point-clouds :title="$t('CIW0020000008')">
            </editing-point-clouds>
          </v-col>
          <v-col cols="12" class="my-3 pa-0">
            <label class="mb-1 d-block">{{ $t("CQW0020126016") }}</label>
            <v-select
              v-model="selectedCondition"
              class="text-black"
              height="32px"
              background-color="#BFBFBF"
              :items="conditionList"
              item-text="text"
              item-value="value"
              hide-details
              solo
            >
            </v-select>
          </v-col>
          <v-col cols="12" class="mt-4 pa-0 d-flex justify-end">
            <v-btn
              class="mr-10 rounded-lg"
              color="#ff5050"
              width="115px"
              height="45px"
              depressed
              @click="close">
              {{ $t("CQW0010600005") }}
            </v-btn>
            <v-btn
              class="rounded-lg"
              color="#4472c4"
              width="115px"
              height="45px"
              depressed
              :disabled="!isExecutable"
              @click="execute">
              {{ $t("CQW0020109005") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import CloseButton from "@/components/common/CloseButton.vue";
import Dialog from "@/components/modules/dialog";
import EditingPointClouds from "@/components/Project/Dialogs/EditingPointClouds.vue";
import * as utils from '@/utils/utils';
import { repositoryFactory } from "@/repositories/repository-factory";
import { i18n } from '@/i18n.js'
const measureRepository = repositoryFactory.get('measure');

export default {
  components: {
    CloseButton,
    EditingPointClouds,
  },
  data() {
    return {
      selectedCondition: ""
    }
  },
  computed: {
    isExecutable() {
      return this.editingPointClouds.length && this.selectedCondition !== ""
    },
    editingPointClouds() {
      return this.$store.getters.editingPointClouds;
    },
    conditionList() {
      return this.$store.getters["flatness/flatnessConditionList"]
            .map(flatnessCondition => ({ text: flatnessCondition.flatnessConditionName, value: flatnessCondition}))
    }
  },
  methods: {
    /**
     * ダイアログを閉じる
     */
    close() {
      const dialog = new Dialog("project");
      dialog.close();
    },
    /**
     * 平坦度チェック処理を実行指示する
     */
    execute() {
      // 座標変換
      const flatnessConditions = this.convertCoordsToPointCloudLocal();
      // 変換処理でエラーが発生した場合は処理終了
      if (!flatnessConditions.length) return;

      // API送信
      const body = {
        user_id: this.$store.getters.loginData.user_id,
        flat_conditions: flatnessConditions
      };
      this.request(body);
    },
    /**
      * 選択した平坦度条件データと点群を元に点群ローカル座標を取得する
      * @returns 点群IDと点群ローカル座標が対応したオブジェクトリスト
      */
    convertCoordsToPointCloudLocal() {
      const flatnessConditions = [];
      try {
        // 選択した点群の数だけ点群ローカル座標を取得
        for (const point of this.editingPointClouds) {
          const flatnessCondition = {
            point_id: point.pointId,
            asset_id: point.assetId,
            data: window['viewer'].getLocalizedFlatnessData(
              point.assetId,
              this.selectedCondition.selectInfo,
              this.selectedCondition.gridSize
            )
          }
          flatnessConditions.push(flatnessCondition);
        }
        return flatnessConditions;
      } catch (e) {
        utils.showSnackBar("error", i18n.tc("CEW0020124009"));
        console.error(e);
        return [];
      }
    },
    async request(body) {
      let successMessage = i18n.tc("CIW0020126020");
      let errorMessage = i18n.tc("CEW0020126021");

      this.$store.dispatch("updateIsLoading", true);
      try {
        const res = await measureRepository.flatExecutionByConditionId(this.selectedCondition.flatnessConditionId, body);
        if (res.status === 201) {
          utils.showSnackBar("success", successMessage);
          // データリストのリフレッシュ
          this.$store.dispatch("getPointDataList", this.$route.query.siteId)
          this.close();
        } else {
          // 想定外のステータスコードが返却された場合エラーをスロー
          throw new Error(errorMessage);
        }
      } catch (e) {
        console.error(e.message);
        if (utils.createErrorMessage(e)) {
          errorMessage = utils.createErrorMessage(e);
        }
        utils.showSnackBar("error", errorMessage);
      } finally {
        this.$store.dispatch("updateIsLoading", false);
      }
    }
  },
};
</script>

<style lang="sass" scoped>
.v-card__title
    font-size: 16px

::v-deep .v-input__append-outer
  margin: 0

::v-deep .v-input__slot
  margin: 0
  padding: 0

.text-black ::v-deep input,
.text-black ::v-deep label,
.text-black ::v-deep .v-select__selection,
.text-black ::v-deep .v-input__append-inner i
  color: black

::v-deep .v-text-field input,
::v-deep .v-text-field.v-input .v-select__selections input
  padding: 0

.text-field-error
  border: solid 2px #ff5252

::v-deep .v-text-field__details
  display: none

::v-deep .v-text-field__suffix
  color: black

::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

::v-deep div.v-radio
  div.v-input--selection-controls__input i.v-icon
    font-size: 14px
    color: #f0f0f0
  label.v-label
    font-size: 14px
    color: white

::v-deep div.v-input.v-text-field div.v-input__prepend-outer
  margin: auto 8px auto 1px
  color: white

::v-deep button.v-btn span.v-btn__content
  font-size: 13px
</style>