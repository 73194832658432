import Repository from "./repository";

const resource = "/lock";
export default {
  getLockBySiteId(siteId) {
    return Repository.get(`${resource}/${siteId}`);
  },
  updateLockBySiteId(siteId, payload) {
    return Repository.put(`${resource}/lock/${siteId}`, payload);
  },
  updateUnLockBySiteId(siteId, payload) {
    return Repository.put(`${resource}/unlock/${siteId}`, payload);
  }
}