<!-- 点群編集処理中止 -->
<template>
  <v-card class="px-4 pt-2 pb-4">
    <loading :parentLoading="isLoading" />
    <v-card-title class="pa-0 justify-space-between">
      <label>
        {{$t("CQW0020135001")}}
      </label>
      <close-button @click="$emit('close')" size="20px"></close-button>
    </v-card-title>
    <v-card-text class="px-0 pt-1 pb-0 white--text">
      <v-container class="pa-0">
        <v-row class="ma-0">
          <v-col cols="12" class="my-3 pa-0">
            <editing-point-clouds
              :title="$t('CIW0020000008')">
            </editing-point-clouds>
          </v-col>
          <v-col cols="12" class="mt-4 pa-0 d-flex justify-end">
            <v-btn
              class="mr-10 rounded-lg"
              color="#ff5050"
              width="115px"
              height="45px"
              depressed
              @click="$emit('close')">
              {{$t("CQW0010600005")}}
            </v-btn>
            <v-btn
              class="rounded-lg"
              color="#4472c4"
              width="115px"
              height="45px"
              depressed
              :disabled="!isExecutable"
              @click="execute">
              {{$t("CQW0020109005")}}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import CloseButton from "@/components/common/CloseButton.vue";
import EditingPointClouds from "../EditingPointClouds.vue";
import Loading from '@/components/common/LoadingOverLay.vue';
import * as utils from '@/utils/utils';
import { repositoryFactory } from "@/repositories/repository-factory";
import { i18n } from '@/i18n.js'
const pointRepository = repositoryFactory.get('point');

export default {
  components: {
    CloseButton,
    EditingPointClouds,
    Loading
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    isExecutable() {
      return this.editingPointClouds.length
    },
    editingPointClouds() {
      return this.$store.getters.editingPointClouds;
    },
  },
  methods: {
    async execute() {
      const successList = [];
      const errorList = [];

      this.isLoading = true;
      const body = { 
        user_id: this.$store.getters.loginData.user_id
      };
      for (const point of this.editingPointClouds) {
        const result = await this.request(point, body);
        if (result.status === 204) {
          // 同一の処理メッセージごとに集約する
          const message = successList
            .find(success => success.text === result.text);
          if (!message) {
            // メッセージが未追加の場合、新規メッセージとして追加
            const newMessage = {
              text: result.text,
              fileList: [result.point.pointName]
            }
            successList.push(newMessage);
          } else {
            // メッセージ追加済みの場合、同一メッセージのファイルリストに追加
            message.fileList.push(result.point.pointName);
          }
        } else {
          // 同一の処理メッセージごとに集約する
          const message = errorList
            .find(error => error.text === result.text);
          if (!message) {
            // メッセージが未追加の場合、新規メッセージとして追加
            const newMessage = {
              text: result.text,
              fileList: [result.point.pointName]
            }
            errorList.push(newMessage);
          } else {
            // メッセージ追加済みの場合、同一メッセージのファイルリストに追加
            message.fileList.push(result.point.pointName);
          }
        }
      }

      // 通知に表示するメッセージを組み立てる
      if (successList.length && errorList.length) {
      // 成功と失敗が混在する場合warning通知
        const message = [];
        for (const success of successList) {
          message.push(success.text);
          message.push(
            ...success.fileList.map(pointName => `・${pointName}`)
          );
          // メッセージ間に空白行を入れる
          message.push("");
        }

        for (const error of errorList) {
          message.push(error.text);
          message.push(
            ...error.fileList.map(pointName => `・${pointName}`)
          );
          // メッセージ間に空白行を入れる
          message.push("");
        }
        // メッセージ配列末尾の空文字を削除
        message.pop();
        utils.showSnackBar('warning', message, 0);
      } else if (successList.length) {
      // 成功のみの場合success通知
        const message = [];
        for (const success of successList) {
          message.push(success.text);
          message.push(
            ...success.fileList.map(pointName => `・${pointName}`)
          );
          // メッセージ間に空白行を入れる
          message.push("");
        // メッセージ配列末尾の空文字を削除
        message.pop();
        }
        utils.showSnackBar('success', message);
      } else if (errorList.length) {
      // 失敗のみの場合error通知
        const message = [];
        for (const error of errorList) {
          message.push(error.text);
          message.push(
            ...error.fileList.map(pointName => `・${pointName}`)
          );
          // メッセージ間に空白行を入れる
          message.push("");
        }
        // メッセージ配列末尾の空文字を削除
        message.pop();
        utils.showSnackBar('error', message, 0);
      }
      this.isLoading = false;
      // データリストのリフレッシュ
      this.$store.dispatch("getPointDataList", this.$route.query.siteId)
      this.$emit('close');
    },
    async request(point, body) {
      let successMessage = i18n.tc("CIW0020135001");
      let errorMessage = i18n.tc("CEW0020135002");
      const result = {
        point,
        status: "",
        text: ""
      }
      try {
        const res = await pointRepository.cancelByPointId(point.pointId, body);
        result.status = res.status;
        if (res.status === 204) {
          result.text = successMessage;
          return result;
        } else {
          // 想定外のステータスコードが返却された場合エラーをスロー
          throw new Error(errorMessage);
        }
      } catch (e) {
        console.error(e.message);
        if (utils.createErrorMessage(e)) {
          errorMessage = utils.createErrorMessage(e);
        }
        result.text = errorMessage;
        return result;
      }
    }
  },
};
</script>

<style lang="sass" scoped>
.thinning-lattice-dialog
  .v-card__title
    font-size: 16px

::v-deep .v-input__append-outer
  margin: 0

::v-deep .v-input__slot
  margin: 0
  padding: 0

.text-black ::v-deep input,
.text-black ::v-deep label,
.text-black ::v-deep .v-select__selection,
.text-black ::v-deep .v-input__append-inner i
  color: black

::v-deep .v-text-field input,
::v-deep .v-text-field.v-input .v-select__selections input
  padding: 0

.text-field-error
  border: solid 2px #ff5252

::v-deep .v-text-field__details
  display: none

::v-deep .v-text-field__suffix
  color: black

::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

::v-deep div.v-radio
  div.v-input--selection-controls__input i.v-icon
    font-size: 14px
    color: #f0f0f0
  label.v-label
    font-size: 14px
    color: white

::v-deep div.v-input.v-text-field div.v-input__prepend-outer
  margin: auto 8px auto 1px
  color: white

::v-deep button.v-btn span.v-btn__content
  font-size: 13px
</style>