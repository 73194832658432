// 平坦度データ
export default class FlatnessData{
  /** 
   * フィールド 
   */
  _flatnessId;
  _flatnessName;
  _flatnessInfo;
  _selectInfo;
  _gridSize;
  _status;
  _message;
  _pointId;
  _assetId;
  _viewerFlatnessId;
  _isVisible;
  _isLoaded;
  _heights;
  _colors;

  /**
   * コンストラクタ
   */
  constructor(flatnessId, flatnessName, flatnessInfo, selectInfo, gridSize, status, message, pointId, assetId, heights, colors) {
    this.flatnessId = flatnessId;
    this.flatnessName = flatnessName;
    this.flatnessInfo = flatnessInfo;
    this.selectInfo = selectInfo;
    this.gridSize = gridSize;
    this.status = status;
    this.message = message;
    // 紐づく点群の点群id
    this.pointId = pointId; 
    // 紐づく点群のアセットid
    this.assetId = assetId; 
    // Viewer上のid
    this.viewerFlatnessId = "";
    // Viewer上に表示中か
    this.isVisible = false;
    // Viewer上に読み込み済みか
    this.isLoaded = false;
    this.heights = heights;
    this.colors = colors;
  }

  /**
   * getters
   */
  get flatnessId() {
    return this._flatnessId;
  }

  get flatnessName() {
    return this._flatnessName;
  }

  get flatnessInfo() {
    return this._flatnessInfo;
  }

  get selectInfo() {
    return this._selectInfo;
  }

  get gridSize() {
    return this._gridSize;
  }

  get pointId() {
    return this._pointId;
  }

  get assetId() {
    return this._assetId;
  }

  get status() {
    return this._status;
  }

  get message() {
    return this._message
  }

  get viewerFlatnessId() {
    return this._viewerFlatnessId;
  }

  get isVisible() {
    return this._isVisible;
  }

  get isLoaded() {
    return this._isLoaded;
  }

  get heights() {
    return this._heights;
  }

  get colors() {
    return this._colors;
  }

  /**
   * setters
   */
  set flatnessId(flatnessId) {
    this._flatnessId = flatnessId;
  }

  set flatnessName(flatnessName) {
    this._flatnessName = flatnessName;
  }

  set flatnessInfo(flatnessInfo) {
    if (flatnessInfo) {
      this._flatnessInfo = JSON.parse(flatnessInfo);
    } else {
      this._flatnessInfo = flatnessInfo;
    }
  }

  set selectInfo(selectInfo) {
    if (selectInfo) {
      this._selectInfo = JSON.parse(selectInfo);
    } else {
      this._selectInfo = selectInfo;
    }
  }

  set gridSize(gridSize) {
    this._gridSize = Number(gridSize);
  }

  set pointId(pointId) {
    this._pointId = pointId;
  }

  set assetId(assetId) {
    this._assetId = assetId;
  }

  set viewerFlatnessId(viewerFlatnessId) {
    this._viewerFlatnessId = viewerFlatnessId;
  }

  set isVisible(isVisible) {
    this._isVisible = isVisible;
  }

  set isLoaded(isLoaded) {
    this._isLoaded = isLoaded;
  }

  set status(status) {
    this._status = Number(status);
  }

  set message(message) {
    this._message = message;
  }

  set heights(heights) {
    this._heights = heights;
  }

  set colors(colors) {
    this._colors = colors;
  }

  /**
   * functions
   */
  update(flatnessName, flatnessInfo, selectInfo, gridSize, status, message, pointId, assetId) {
    this.flatnessName = flatnessName;
    this.flatnessInfo = flatnessInfo;
    this.selectInfo = selectInfo;
    this.gridSize = gridSize;
    this.status = status;
    this.message = message;
    this.pointId = pointId; 
    this.assetId = assetId; 
  }
}