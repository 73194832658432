<template>
  <v-card class="browser-download-dialog px-4 pt-2 pb-4">
    <v-card-title class="pa-0 justify-space-between">
      <label>
        {{$t("CQW0020107001")}}
      </label>
      <close-button @click="$emit('close')" size="20px"></close-button>
    </v-card-title>
    <v-card-text class="px-0 pt-1 pb-0 white--text">
      <v-container class="pa-0">
        <v-row 
          class="d-block ma-0"
          style="width: 100%"
        >
            <!-- チェックボックス -->
            <v-row>
              <v-col cols="3" class="ml-2 mb-1 pa-0">
                <v-checkbox
                  class="checkbox-label-size"
                  v-model="isPointCloudChecked"
                  :label="$t('CIW0020000008')"
                  hide-details
                >
                </v-checkbox>
              </v-col>
              <v-col cols="3" class="mb-1 pa-0">
                <v-checkbox
                  class="checkbox-label-size"
                  v-model="isCrossSectionChecked"
                  :label="$t('CIW0020300011')"
                  hide-details
                >
                </v-checkbox>
              </v-col>
              <v-col cols="3" class="mb-1 pa-0">
                <v-checkbox
                  class="checkbox-label-size"
                  v-model="isDesignChecked"
                  :label="$t('CIW0020000010')"
                  hide-details
                >
                </v-checkbox>
              </v-col>
            </v-row>
            <!-- フォルダを選択 -->
            <span v-if="isPointCloudChecked">
              <v-col cols="12" class="my-3 pa-0">
                <label class="mb-1 d-block">{{$t("CQW0020107002")}}</label>
                <v-select
                  v-model="selectedFolder"
                  class="text-black"
                  height="32px"
                  background-color="#BFBFBF"
                  :items="folderList"
                  item-text="name"
                  item-value="id"
                  hide-details
                  solo
                >
                </v-select>
              </v-col>
            </span>
            <!-- 点群データを選択 -->
            <v-col cols="12" class="my-3 pa-0">
              <span v-if="isPointCloudChecked">
                <label>{{$t("CQW0020104010")}}</label>
                  <v-select
                    :label="$t('CQW0020117004')"
                    v-model="selectedPoints"
                    class="text-black"
                    background-color="#BFBFBF"
                    :items="dataList"
                    item-text="text"
                    item-value="value"
                    hide-details
                    multiple
                    solo
                  >
                    <template v-slot:selection="{ index }">
                      <span class="black--text" v-if="index === 0">{{
                        $t("CQW0020117004")
                      }}</span>
                    </template>
                  </v-select>
              </span>
              <div v-if="isPointCloudChecked && selectedPoints.length">
                <div v-for="point in selectedPoints" :key="point.pointId" class="pt-2 d-flex justify-space-between" style="width: 95%">
                  <span>{{ point.pointName }}</span>
                  <close-button @click="deselectPoint(point.pointId)" size="20px"></close-button>
                </div>
              </div>
            </v-col>
            <!-- 点群データ出力拡張子を選択 -->
            <span v-if="isPointCloudChecked">
              <v-col cols="12" class="my-3 pa-0">
                <label class="mb-1 d-block">{{$t("CQW0020107008")}}</label>
                <v-select
                  v-model="selectedOutputExtension"
                  class="text-black"
                  height="32px"
                  background-color="#BFBFBF"
                  :items="outputExtensionList"
                  item-text="text"
                  item-value="value"
                  hide-details
                  solo>
                </v-select>
              </v-col>
            </span>
            <!-- 断面データを選択 -->
            <v-col cols="12" class="my-3 pa-0">
              <span v-if="isCrossSectionChecked">
                <label>{{$t("CQW0020125005")}}</label>
                  <v-select
                    v-model="selectedSections"
                    :label="$t('CQW0020117004')"
                    class="text-black"
                    height="32px"
                    background-color="#BFBFBF"
                    :items="sectionList"
                    item-text="text"
                    item-value="value"
                    hide-details
                    multiple
                    solo
                    >
                    <template v-slot:selection="{ index }">
                      <span class="black--text" v-if="index === 0">{{
                        $t("CQW0020117004")
                      }}</span>
                    </template>
                  </v-select>
              </span>
              <div v-if="isCrossSectionChecked && selectedSections.length">
                <div v-for="section in selectedSections" :key="section.sectionId" class="pt-2 d-flex justify-space-between" style="width: 95%">
                  <span>{{ section.sectionName }}</span>
                  <close-button @click="deselectSection(section.sectionId)" size="20px"></close-button>
                </div>
              </div>
            </v-col>
            <!-- 設計データを選択 -->
            <v-col cols="12" class="my-3 pa-0">
              <span v-if="isDesignChecked">
                <label>{{$t("CQW0020114003")}}</label>
                  <v-select
                    v-model="selectedDesigns"
                    :label="$t('CQW0020117004')"
                    class="text-black"
                    height="32px"
                    background-color="#BFBFBF"
                    :items="designList"
                    item-text="text"
                    item-value="value"
                    hide-details
                    multiple
                    solo
                    >
                    <template v-slot:selection="{ index }">
                      <span class="black--text" v-if="index === 0">{{
                        $t("CQW0020117004")
                      }}</span>
                    </template>
                  </v-select>
              </span>
              <div v-if="isDesignChecked && selectedDesigns.length">
                <div v-for="design in selectedDesigns" :key="design.designId" class="pt-2 d-flex justify-space-between" style="width: 95%">
                  <span>{{ design.designName }}</span>
                  <close-button @click="deselectDesign(design.designId)" size="20px"></close-button>
                </div>
              </div>
            </v-col>
            <v-row class="ma-0">
              <!-- ダウンロードボタン -->
              <v-col cols="4" class="mt-2 mb-2 pa-0">
                <v-btn
                  class="rounded-pill"
                  color="primary"
                  width="150px"
                  height="35px"
                  depressed
                  :disabled="isDisabledDownloadBtn"
                  @click="download"
                >
                {{$t("CIW0020000111")}}
                </v-btn>
              </v-col>
            </v-row>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import CloseButton from "@/components/common/CloseButton.vue";
import { repositoryFactory } from "@/repositories/repository-factory";

const browserDownloadRepository = repositoryFactory.get('browserDownload');

export default {
  components: {
    CloseButton
  },
  data() {
    return {
      selectedFolder: "",
      selectedPoints: [],
      selectedDesigns: [],
      selectedSections: [],
      selectedOutputExtension: "las",
      outputExtensionList: [
        {value: "txt", text: "TEXT(.txt)"},
        {value: "csv", text: "CSV(.csv)"},
        {value: "las", text: "LAS(.las)"},
      ],
      isPointCloudChecked: false,
      isCrossSectionChecked: false,
      isDesignChecked: false,
    }
  },
  computed: {
    /**
     * ダウンロードボタンの活性制御
     */
    isDisabledDownloadBtn() {
      return !this.selectedPoints.length 
          && !this.selectedDesigns.length
          && !this.selectedSections.length
    },
    folderList() {
      return [
        { id: "", name: "" },
        ...this.$store.getters.pointDataList,
      ];
    },
    // プロジェクトID、プロジェクト名と点群ファイルの紐付きリストを作成
    projectList() {
      const projectList = [];
      for (const pointData of this.$store.getters.pointDataList) {
        const project = Object.assign({}, pointData)
        project.children
         = project.children
          .map(
            file => (
              {
                projectId: project.id,
                projectName: project.name,
                pointId: file.pointId,
                pointName: file.pointName
              }
            )
          )
        projectList.push(project)
      }
      return projectList
    },
    // データリストに表示するオブジェクトリストを作成
    dataList() {
      const pointFileList = [];
      for (const project of this.projectList) {
        // フォルダが選択されていない場合は全点群を表示
        if (!this.selectedFolder) {
          for (const pointFile of project.children) {
            pointFileList.push(
                { 
                  value: pointFile,
                  text: `${pointFile.pointName}(${pointFile.projectName})`
                }
              );
          }
        } else if (project.id === this.selectedFolder) {
          // フォルダが選択されている場合は選択中のプロジェクトと紐づく点群ファイルを表示
          for (const pointFile of project.children) {
            pointFileList.push(
                {
                  value: pointFile,
                  text: pointFile.pointName
                }
            );
          }
        }
      }
      return pointFileList
    },
    designList() {
      // 設計データの選択項目を作成する
      return this.$store.getters["designFile/designFileList"]
            .map(design => (
              { 
                value: { 
                          designId: design.designId,
                          designName: design.designName,
                       }, 
                text: design.designName
              }
            ));
    },
    sectionList() {
      // 断面データの選択項目を作成する
      return this.$store.getters["sectionFile/sectionFileList"]
            .map(section => (
              { 
                value: { 
                          sectionId: section.sectionId,
                          sectionName: section.sectionName,
                          sectionInfo: section.sectionInfo,
                       }, 
                text: section.sectionName 
              }
            ));
    }
  },
  watch: {
    isPointCloudChecked(newValue) {
      // チェックがオフになったら点群データの選択をクリア
      if (!newValue) this.selectedPoints = [];
    },
    isCrossSectionChecked(newValue) {
      // チェックがオフになったら断面データの選択をクリア
      if (!newValue) this.selectedSections = [];
    },
    isDesignChecked(newValue) {
      // チェックがオフになったら設計データの選択をクリア
      if (!newValue) this.selectedDesigns = [];
    },
  },
  methods: {
    /**
     * 指定した点群データの選択を解除する
     * @param {*} pointId 
     */
    deselectPoint(pointId) {
      this.selectedPoints 
       = this.selectedPoints.filter(item => pointId !== item.pointId);
    },
    /**
     * 指定した設計データの選択を解除する
     * @param {*} designId 
     */
    deselectDesign(designId) {
      this.selectedDesigns
       = this.selectedDesigns.filter(item => designId !== item.designId);
    },
    /**
     * 指定した断面データの選択を解除する
     * @param {*} sectionId 
     */
    deselectSection(sectionId) {
      this.selectedSections
       = this.selectedSections.filter(item => sectionId !== item.sectionId);
    },
    download(){
      browserDownloadRepository.fileDownload({
          point_id: this.selectedPoints.map(item => item.pointId),
          design_id: this.selectedDesigns.map(item => item.designId),
          section_id: this.selectedSections.map(item => item.sectionId),
          file_extension: this.selectedOutputExtension,
          user_id: this.$store.getters.loginData.user_id
      });
      this.$emit("close");
    },
  }
};
</script>

<style lang="sass" scoped>
.browser-download-dialog
  .v-card__title
    font-size: 16px
.text-black ::v-deep input,
.text-black ::v-deep label,
.text-black ::v-deep .v-select__selection,
.text-black ::v-deep .v-input__append-inner i
  color: black
::v-deep .checkbox-label-size .v-label
 font-size: 11.5px

</style>