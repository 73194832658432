import Repository from "./repository";

const resource = "/create";
export default {
  createLandXMLByPointId(pointId, payload) {
    return Repository.post(`${resource}/landXML/${pointId}`, payload);
  },
  pointSynthetic(payload){
    return Repository.post(`${resource}/pointSynthetic`, payload);
  },
  createPointCloudFromLandXml(payload) {
    return Repository.post(`${resource}/pointCloudCreate`,payload);
  }
};
