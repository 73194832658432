<template>
  <v-dialog
    v-model="isShow"
    v-bind="dialog.attributes"
    v-on="dialog.handlers"
  >
    <component
      :is="name"
      v-bind="component.attributes"
      v-on="component.handlers"
    >
    </component>
  </v-dialog>
</template>

<script>
import ClippingConfirmDialog from "./Clipping/ClippingConfirmDialog.vue";
import UnnecessaryRemoveConfirmDialog from "./UnnecessaryRemoval/UnnecessaryRemoveConfirmDialog.vue";
import HoleInterpolationDialog from "./Interpolation/HoleInterpolationDialog.vue";
import FlatnessConditionCreateDialog from "./FlatnessConditionCreateDialog.vue";
import NumberOfPointsConfirmDialog from "./MeasurementAndDisplay/Measurement/NumberOfPointsConfirmDialog.vue";
import CrossSectionEditConfirmDialog from "./CrossSectionEditConfirmDialog.vue";
import PerimeterLineEditingDialog from "./PerimeterLineEditingDialog.vue";

export default {
  components: {
    ClippingConfirmDialog,
    UnnecessaryRemoveConfirmDialog,
    HoleInterpolationDialog,
    FlatnessConditionCreateDialog,
    NumberOfPointsConfirmDialog,
    CrossSectionEditConfirmDialog,
    PerimeterLineEditingDialog,
  },
  props: {
    // ダイアログの状態
    isShow: {
      type: Boolean,
      require: true,
      default: false,
    },
    // 表示するダイアログ名
    name: {
      type: String,
      require: true,
    },
    // ダイアログ内で扱うデータ
    item: {
      type: Object,
      require: false,
    },
    // ブラウザで発生するイベント
    event: {
      type: Object,
      require: false,
    }
  },
  data() {
    return {
      'dialog': {
        attributes: {
            'hide-overlay': true, 
        },
        handlers: {
            'click:outside': this.close
        },
      },
      'component': {
        attributes: {
          isVisible: false,
        },
        handlers: {
            'ok': this.ok, 
            'cancel': this.cancel,
            'close': this.close,
            'addDialogAttributes': this.addDialogAttributes,
        },
      },
    }
  },
  beforeUpdate() {
    this.component.attributes = {
      ...this.component.attributes,
      isVisible: this.$props.isShow,
    };
    switch (this.name) {
      case 'clipping-confirm-dialog':
        this.dialog.attributes = {
            ...this.dialog.attributes,
            height: '170px',
            width: '300px'
        },
        this.component.handlers = {
            ...this.component.handlers,
            'ok': (callback) => { 
                this.$emit('ok', callback) 
            }
        }
        break;
      case 'thinning-ground-surface-dialog':
        this.dialog.attributes = {
            ...this.dialog.attributes,
            height: '170px',
            width: '300px'
        }
        break;
      case 'unnecessary-remove-confirm-dialog':
        this.dialog.attributes = {
            ...this.dialog.attributes,
            height: '200px',
            width: '400px'
        },
        this.component.handlers = {
            ...this.component.handlers,
            'remove': (callback) => { 
                this.$emit('remove', callback) 
            }
        }
        break;
      case 'hole-interpolation-dialog':
        this.dialog.attributes = {
          ...this.dialog.attributes,
          height: '350px',
          width: '460px'
        }
        this.component.handlers = {
            ...this.component.handlers,
            'hole-interpolation': (callback) => { 
                this.$emit('hole-interpolation', callback) 
            }
        }
        break;
        case 'number-of-points-confirm-dialog':
        this.dialog.attributes = {
            ...this.dialog.attributes,
            height: '170px',
            width: '300px'
        },
        this.component.handlers = {
            ...this.component.handlers,
            'count-points': (callback) => { 
                this.$emit('count-points', callback) 
            }
        }
        break;
      case 'flatness-condition-create-dialog':
        this.dialog.attributes = {
          width: '460px',
          'hide-overlay': false,
          'persistent': true,
          'no-click-animation': true
        },
        this.dialog.handlers = {
          ...this.dialog.handlers,
          'click:outside': '' 
        },
        this.component.handlers = {
          ...this.component.handlers,
          'close': this.cancel 
        }
        break;
      case 'unlock-confirm-dialog':
        this.dialog.attributes = {
          ...this.dialog.attributes,
          width: '350px'
        }
        this.component.attributes = {
            project: this.item
        }
        break;
      case 'cross-section-edit-confirm-dialog':
        this.dialog.attributes = {
            ...this.dialog.attributes,
            height: '170px',
            width: '300px'
        },
        this.component.handlers = {
            ...this.component.handlers
        }
        break;
      default:
        break;
    }
  },
  methods: {
    ok() {
      this.$emit('ok');
    },
    cancel() {
      this.$emit('cancel');
    },
    close() {
      this.$store.commit("dialog/setCesiumDialog", { isShow: false });
      this.$emit('close');
    },
    addDialogAttributes(attributes) {
      this.dialog.attributes = {
        ...this.dialog.attributes,
        ...attributes,
      };
    },
  }
}
</script>