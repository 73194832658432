import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import "./assets/style_custom.css";
import './plugins/vee-validate'
import store from './store'
import VueI18n from 'vue-i18n'
import router from './route/router'
import axios from 'axios'
import paper from 'paper'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.config.productionTip = false

if (process.env.NODE_ENV === "development") {
  axios.defaults.baseURL = "http://localhost:3001";
}

const dict = require('./SCPointworksDict.json')

// 言語を設定
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: store.getters.loginData.language || 'ja',
  messages: dict
})

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCKO8c1nFODTwON6N_Rv9YfirwE1hgWHes',
    libraries: 'places',
    region: 'JP',
    language: 'ja'
  }
})

new Vue({
  router,
  vuetify,
  store,
  paper,
  i18n: i18n,
  render: h => h(App),
  methods: {
    handleClick_changeLanguage(lang) {
      this.$i18n.locale = lang
    }
  }
}).$mount('#app')
