import store from '@/store/index';
import * as constants from '@/constants/constants';
import * as cesiumCommon from '@/utils/cesium-common';
import { createDecipheriv } from 'crypto'

/**
 * エラーレスポンスから画面出力用のエラーメッセージを作成する
 * @param {*} e エラーレスポンス
 * @returns {*} message 出力用メッセージ
 */
export const createErrorMessage = (e) => {
  let message = '';
  if (
    e.response &&
    e.response.data &&
    e.response.data.err_message
  ) {
    if (e.response.data.err_code) {
      message = `${e.response.data.err_message}（${e.response.data.err_code}）`;
    } else {
      message = e.response.data.err_message;
    }
  }
  return message;
}

/**
 * BLOBエラーレスポンスから画面出力用のエラーメッセージを作成する
 * @param {*} e エラーレスポンス
 * @returns {*} message 出力用メッセージ
 */
export const createErrorMessageForBlobResponse = async(e) => {
  let resData = {};
  let message = '';

  if (e.response && e.response.data) {
    resData = JSON.parse(await e.response.data.text())
    if (resData.err_message) {
      if (resData.err_code) {
        message = `${resData.err_message}（${resData.err_code}）`;
      } else {
        message = resData.err_message;
      }
    }
  }
  return message;
}

/**
 * サーバー側のEXEを実行する処理のステータスが
 * 編集中もしくはエラーの状態かをチェックする
 * @param {*} status ステータス
 * @returns {*} result チェック結果
 */
export const isEditingOrErrorExeProcess = (status) => {
  if (!status) return false;
  else if (status === constants.exeProcessStatus.succeeded) return false;
  else return true;
}

/**
 * snackBarを表示する
 * @param {*} type 'success' or 'error' or 'warning'
 *                 snackbarのレイアウトタイプを設定する
 * @param {*} text Array or text 表示するテキストを設定する
 * @param {*} timeout Number snackbarが閉じるまでの時間を設定する
 */
export const showSnackBar = (type, text, timeout) => {
  let message = {
    text: "",
    type: "",
    icon: "",
  };

  // 表示内容を設定
  if (Array.isArray(text)) {
    // textが配列の場合改行コードで結合後、文字列に変換する
    message.text = text.join("\n");
  } else {
    message.text = text;
  }

  if (type === 'success') {
    message.type = "success";
    message.icon = "mdi-check-circle";
  } else if (type === 'error') {
    message.type = 'error';
    message.icon = 'mdi-alert-circle'
  } else if (type === 'warning') {
    message.type = 'warning';
    message.icon = 'mdi-alert'
  }

  if (timeout !== undefined) {
    store.dispatch('processingResultNotification/setTimeout', timeout);
  }

  if (store.getters['processingResultNotification/isShow']) {
    store.dispatch('processingResultNotification/setIsShow', false);
  }

  // snackBar呼び出し
  setTimeout(() => {
    store.dispatch('processingResultNotification/setMessage', message);
    store.dispatch('processingResultNotification/setIsShow', true);
  }, 500);
} 

/**
 * Cesiumに読み込まれているTilesetを全て削除
 */
export const allClearTileset = () => {
  for (const point of store.getters.tilingPointClouds) {
    cesiumCommon.removeTileset(point);
    point.isVisible = false;
    point.isEditing = false;
  }

  for (const design of store.getters['designFile/tilingDesigns']) {
    cesiumCommon.removeTileset(design);
    design.isVisible = false;
  }
}

/**
 * Cesium上に表示されている線形データを全て非表示にする
 */
export const allClearLinearData = () => {
  for (const linear of store.getters["linearFile/visibleLinearFileList"]) {
    cesiumCommon.changeLinearVisible(linear);
  }
}

// データリストを更新
export const refreshDataList = (siteId) => {
  // 点群データ、線形データ、断面データを再取得
  store.dispatch("getPointDataList", siteId)
  store.dispatch('linearFile/fetchLinearFileList', siteId)
  store.dispatch('sectionFile/fetchSectionFileList', siteId)
  store.dispatch('flatness/fetchFlatnessConditionList', siteId)
}

// 点群IDを指定してファイルを取得する
export const getPointFileByPointId = (pointId) => {
  const pointFile = store.getters.pointDataList.flatMap(
    project => project.getPoints()
  ).find(
    pointFile => pointFile.pointId === pointId
  );
  return pointFile;
};

// 設計IDを指定してファイルを取得する
export const getDesignFileByDesignId = (designId) => {
  const designFile = store.getters["designFile/designFileList"].find(
    designFile => designFile.designId === designId
  );
  return designFile;
};

// 指定した小数点以下桁数で四捨五入する
export const round = (n, decimalPlaces) => Math.round(n * 10 ** decimalPlaces) / (10 ** decimalPlaces);

// 文字列の復号化
const algorithm = 'aes-256-gcm';
const hexEncoding = 'hex';
const utf8Encoding = 'utf8';
export const decrypt = (text) => {
  const [encrypted, keyStr, ivStr, authTag] = text.split('|');
  const key = Buffer.from(keyStr, hexEncoding);
  const iv = Buffer.from(ivStr, hexEncoding);
  const decipher = createDecipheriv(algorithm, key, iv);
  decipher.setAuthTag(Buffer.from(authTag, hexEncoding));
  let decrypted = `${decipher.update(
    encrypted,
    hexEncoding,
    utf8Encoding,
  )}${decipher.final(utf8Encoding)}`;
  return decrypted;
}
