import Repository from "./repository";

const resource = "/project-return";
export default {
  getProjectList(payload, params) {
    let url = `${resource}?offset=${params.offset}&limit=${params.limit}`;
    if(params.siteName != undefined) {
      url = url + `&siteName=${params.siteName}`;
    }
    return Repository.post(url, payload);
  },
}