import Dialog from "./dialog";
import store from "@/store/index";

export default class ContextmenuHandler{
  constructor(self) {
    // 呼び出し元インスタンスへの参照を設定
    this.self = self ;
  }

  /**
    * 平坦度条件データを保存する
    * @param {*} event 
    */
 async showFlatnessConditionCreateDialog(event) {
    let selectInfo = "";
    event.preventDefault();
    try {
      selectInfo = window['viewer'].getSelectedAreaCoords();
    } catch (e) {
      console.error(e);
    } 
    this.self.$store.dispatch("edit/setConditionCreate", { selectInfo });
    this.self.$store.dispatch('updateEditType', "");
    // ダイアログを再表示する
    const dialog = new Dialog("cesium");
    dialog.reShow();
  }

  /**
   * 断面編集ダイアログを表示する
   * @param {*} event 
   */
  async showCrossSectionEditConfirmDialog(event) {
    event.preventDefault();
    // 編集モードを解除 
    store.dispatch("updateEditType", "");
    // ダイアログを表示
    const dialog = new Dialog();
    dialog.type = 'cesium'
    dialog.name = 'cross-section-edit-confirm-dialog';
    dialog.show();
  }

  /**
   * 外周線編集ダイアログを表示する
   * @param {*} event 
   */
  showPerimeterLineEditingDialog(event) {

    const dialog = new Dialog("cesium", "perimeter-line-editing-dialog");
    if (dialog.isVisible) {
      return;
    }

    const perimeterLineElement = window['viewer'].pickPerimeterLineElement(event);
    this.self.$store.commit("edit/setPerimeterLineValues", { selectedElement: perimeterLineElement });

    dialog.show();
  }
}
