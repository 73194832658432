
<template>
  <v-card class="confirm">
    <v-card-title class="pt-2 justify-space-between" style="font-size: 14px; color: white">
      <span>{{$t("CQW0010600001")}}</span>
      <close-button @click="$emit('close')" size="18px"></close-button>
    </v-card-title>
    <v-card-text class="py-0 pb-5" style="color: white">
      <p>{{`'${project.current_lock_user}'`}}{{$t("CQW0010600002")}}{{$t("CQW0010600003")}}<br>{{$t("CQW0010600004")}}</p>
    </v-card-text>
    <v-card-actions class="pa-0 pb-5 justify-center">
      <v-btn class="pa-3 mr-2 btn" color="#ff5050" width="110px" height="40px" @click="$emit('cancel')">
        {{$t("CQW0010600005")}}
      </v-btn>
      <v-btn class="pa-3 mr-1 btn" color="#4472c4" width="110px" height="40px" @click="$emit('ok', project)">
        {{$t("CQW0010600006")}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import CloseButton from "@/components/common/CloseButton.vue";

export default {
  components: {
    CloseButton
  },
  props: {
    project: Object
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="sass" scoped>
.confirm .btn
    font-size: 13px
</style>