import { render, staticRenderFns } from "./PointFile.vue?vue&type=template&id=1fa37910&scoped=true"
import script from "./PointFile.vue?vue&type=script&lang=js"
export * from "./PointFile.vue?vue&type=script&lang=js"
import style0 from "./PointFile.vue?vue&type=style&index=0&id=1fa37910&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fa37910",
  null
  
)

export default component.exports