<!-- 計測データリスト -->
<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header
        style="font-size: 15px; background-color: #1e1e1e"
        class="pa-2"
      >
        <div class="d-flex align-center">
          <!-- メニューボタン -->
          <v-btn
            width="24px"
            plain
            min-width="24px"
            class="pa-0 mr-1"
            @click.stop="changeShawEditDialog"
          >
            <v-icon style="background-color: transparent">mdi-menu</v-icon>
          </v-btn>
          <div>{{$t("CQW0020300016")}}</div>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="pt-1">
        <!-- 計測データリスト -->
        <v-list class="pa-0" style="font-size: 14px">
          <!-- 平坦度計算条件 -->
          <v-subheader class="mb-4 white--text align-start justify-space-between">
            {{$t("CIW0020000043")}}
            <!-- <v-menu 
              light
              offset-y 
              transition="slide-y-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  small
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item @click="showFlatnessConditionDialog">
                  <v-list-item-icon class="mr-4">
                    <v-icon>mdi-pencil</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ $t("CQW0020300018") }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu> -->
          </v-subheader>
          <v-list-item
            v-for="flatnessCondition in flatnessConditionList"
            :key="flatnessCondition.flatnessConditionId"
            class="data-list mb-3 ml-3"
          >
            <v-list-item-title>
              <flatness-condition
                :selected="selected"
                @add-checked="addChecked"
                @delete-checked="deleteChecked"
                :deleteMode="deleteMode"
                :flatnessCondition="flatnessCondition"
              ></flatness-condition>
            </v-list-item-title>
          </v-list-item>
        </v-list>
        <div v-if="deleteMode" class="d-flex justify-end mb-2">
          <v-btn
            class="mr-3"
            color="red lighten-1"
            width="100px"
            :disabled="this.selected.length === 0"
            @click="deleteMeasure"
          >
          {{$t("CIW0020301001")}}
          </v-btn>
        </div>
      </v-expansion-panel-content>
      <v-dialog
        hide-overlay
        persistent
        no-click-animation
        v-model="showEditDialog"
        width="360px"
      >
        <calculation-condition-edit-dialog
          @close="changeShawEditDialog"
          @change-delete-mode="changeDeleteMode"
          @all-delete="allDelete"
        />
      </v-dialog>
    </v-expansion-panel>
  </v-expansion-panels>

</template>

<script>
import FlatnessCondition from "./FlatnessCondition.vue";
import CalculationConditionEditDialog from "../CalculationConditionEditDialog.vue";
import * as utils from '@/utils/utils';
import { repositoryFactory } from "@/repositories/repository-factory";
import { i18n } from '../../../i18n.js'
import * as constants from '@/constants/constants'
const flatRepository = repositoryFactory.get('flat');

export default {
  components: {
    FlatnessCondition,
    CalculationConditionEditDialog
  },
  data() {
    return {
      showEditDialog: false,
      selected: [],
      deleteMode: false
    }
  },
  computed: {
    flatnessConditionList() {
      return this.$store.getters['flatness/flatnessConditionList']
    },
    cesiumDialog: {
      get() {
        return this.$store.getters['dialog/cesiumDialog']
      },
      set(value) {
        this.$store.dispatch('dialog/setCesiumDialog', value);
      }
    }
  },
  watch: {
    flatnessConditionList(newValue) {
      if (newValue.length === 0) {
        this.deleteMode = false;
        this.showEditDialog = false;
      }
    }
  },
  methods: {
    showFlatnessConditionDialog(event) {
      event.preventDefault();
      const dialogHandlers = {
        'close': this.closeDialog,
        'cancel':this.cancelDialog
      }
      this.showDialog('flatness-condition-create-dialog', {}, dialogHandlers)
    },
    showDialog(name, attributes, handlers, item, event) {
      setTimeout(() => {
        this.cesiumDialog = {
          isShow: true,
          name,
          attributes,
          handlers,
          item,
          event
        }
      }, 50)
    },
    closeDialog() {
      this.cesiumDialog = {
        attributes: {},
        handlers: {},
        isShow: false,
        name: '',
        item: null,
        event: null
      }
    },
    cancelDialog() {
      try {
        window["viewer"].removeSelection(constants.editType.numberOfPoints)
      } catch(e) {
        console.error(e);
      } finally {
        if (this.$store.getters.backgroundMap.visibility) {
         // 背景地図をONに戻す
         window["viewer"].setGlobeVisibility(true);
        }
        this.$store.dispatch("updateMode", "");
        this.closeDialog()
      }
    },
    // エディットダイアログの開閉を行う
    changeShawEditDialog() {
      if (this.deleteMode) {
        this.changeDeleteMode();
      }
      this.showEditDialog = !this.showEditDialog;
    },
    // チェックボックスがチェックされたとき
    addChecked(flatConditionId) {
      this.selected.push(flatConditionId);
    },
    // チェックボックスが外れたとき
    deleteChecked(flatConditionId) {
      this.selected = this.selected.filter(id => id !== flatConditionId);
    },
    // エディットダイアログで削除ボタン押された際の動作
    changeDeleteMode() {
      this.deleteMode = !this.deleteMode;
    },
    // 選択された計測ファイルを削除
    deleteMeasure() {
      if (this.selected.length === 0) return;
      this.request(this.selected);
    },
    // 計測ファイルを全削除
    allDelete() {
      if (this.flatnessConditionList.length === 0) return;
      const deleteIdList = this.flatnessConditionList.map(flatnessCondition => flatnessCondition.flatnessConditionId);
      this.request(deleteIdList);
    },
    async request(deleteIdList) {
      let successMessage = i18n.tc("CIW0020126019");
      let errorMessage = i18n.tc("CEW0020126020");

      const body = {
        user_id: this.$store.getters.loginData.user_id
      }
      try {
        for(let id of deleteIdList){
          const res = await flatRepository.deleteFlatConditionByConditionId(id, body);
          if (res.status !== 204) {
            // 想定外のステータスコードが返却された場合エラーをスロー
            throw new Error(errorMessage);
          }
        }
        // エラーがなければ正常メッセージを出力する
        utils.showSnackBar('success', successMessage);
        this.selected.splice(0);
      } catch(e) {
        // エラーが発生したら処理終了
        console.error(e.message);
        if (utils.createErrorMessage(e)) {
          errorMessage = utils.createErrorMessage(e);
        }
        utils.showSnackBar('error', errorMessage);
      } finally {
        this.fetchMeasureConditionList();
      }
    },
    // 計測ファイルを取得
    fetchMeasureConditionList() {
      this.$store.dispatch('flatness/fetchFlatnessConditionList', this.$route.query.siteId)
    },
  },
  created() {
    this.fetchMeasureConditionList();
  }
};
</script>

<style lang="sass" scoped>
.data-list
  height: auto
  min-height: auto
  display: block

::v-deep .v-expansion-panel-content__wrap
  padding: 0 0 8px 0

::v-deep div.v-expansion-panel-content__wrap
  padding-bottom: 0px

.v-subheader
  height: 28px
</style>