<!-- 外周線作成 -->
<template>
  <v-card class="project-dialog px-4 pt-2 pb-4">
    <v-card-title class="pa-0 justify-space-between">
      <label>
        {{$t("外周線作成")}}
      </label>
      <close-button @click="$emit('close')" size="20px"></close-button>
    </v-card-title>
    <v-card-text class="px-0 pt-1 pb-0 white--text">
      <v-container class="pa-0">
        <v-row class="ma-0">
          <validation-observer class="d-block" style="width: 100%" ref="observer" v-slot="{ invalid, pristine }">
            <v-col cols="12" class="my-3 pa-0">
              <editing-point-clouds
                :title="$t('CIW0020000008')">
              </editing-point-clouds>
            </v-col>
            <!-- 点間の最大長 -->
            <v-col cols="12" class="mt-3 pa-0">
              <validation-provider :rules="{
                required: true,
                'number-format': true,
                'decimal-size-limit': 2,
                'min-value': 0.01,
                'max-value': 1000.99,
              }" v-slot="{ errors }">
                <div class="mb-1">
                  <label class="d-inline-block mr-2">{{$t("CQW0020137001")}}</label>
                  <span class="d-inline-block" style="font-size: 12px; color: #ff5252">{{ errors[0] }}</span>
                </div>
                <v-text-field
                  @reset.prevent="resetError()"
                  v-model="maxDistance"
                  :error-messages="errors"
                  class="text-black ma-0"
                  height="32px"
                  background-color="#BFBFBF"
                  outlined
                  solo
                  suffix="m"
                  >
                </v-text-field>
              </validation-provider>
            </v-col>
            <!-- ボタン -->
            <v-col cols="12" class="mt-4 pa-0 d-flex justify-end">
              <v-btn
                class="mr-10 rounded-lg"
                color="#ff5050"
                width="115px"
                height="45px"
                depressed
                @click="$emit('close')">
                {{$t("CQW0010600005")}}
              </v-btn>
              <v-btn
                class="rounded-lg"
                color="#4472c4"
                width="115px"
                height="45px"
                depressed
                :disabled="pristine || invalid || !isExecutable"
                @click="execute">
                {{$t("CQW0020109005")}}
              </v-btn>
            </v-col>
          </validation-observer>
        </v-row>
      </v-container>
    </v-card-text>
    <v-dialog
      v-model="showConfirmDialog"
      persistent
      width="350px"
    >
      <confirm-dialog
        :title="$tc('CQW0020115008')"
        :okHandler="toggleConfirmDialog"
        :useCancelButton="false"
        @close="toggleConfirmDialog"
      >
        <template v-slot:contents>
          <p>{{$t("CQW0020115009", 1, {name: $t("CIW0020000072")})}}</p>
        </template>
      </confirm-dialog>
    </v-dialog>
  </v-card>
</template>

<script>
import CloseButton from "@/components/common/CloseButton.vue";
import EditingPointClouds from "./EditingPointClouds.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ConfirmDialog from '@/components/common/ConfirmDialog.vue';
import * as utils from '@/utils/utils';
import { repositoryFactory } from "@/repositories/repository-factory";
import { i18n } from "@/i18n.js";
const perimeterLineRepository = repositoryFactory.get('perimeterLine');

export default {
  components: {
    CloseButton,
    EditingPointClouds,
    ValidationProvider,
    ValidationObserver,
    ConfirmDialog
  },
  data() {
    return {
      maxDistance: "",
      showConfirmDialog: false,
    }
  },
  computed: {
    isLoading: {
      get() {
        return this.$store.getters.isLoading;
      },
      set(value) {
        this.$store.dispatch("updateIsLoading", value);
      }
    },
    isExecutable() {
      return this.editingPointClouds.length
    },
    editingPointClouds() {
      return this.$store.getters.editingPointClouds;
    },
  },
  methods: {
    toggleConfirmDialog() {
      this.showConfirmDialog = !this.showConfirmDialog
    },
    resetForm() {
      this.maxDistance = "";
    },
    async execute() {
      if (this.editingPointClouds.length > 1) {
        this.toggleConfirmDialog();
        return
      }
      const body = { 
        max_distance: Number(this.maxDistance),
        point_id: this.editingPointClouds[0].pointId,
        user_id: this.$store.getters.loginData.user_id
      };
      await this.request(body);

      this.$emit('close');
    },
    async request(body) {
      let actionName = i18n.tc("CIW0020000072");
      let successMessage = i18n.tc("CIW0020000073", 1, { actionName });
      let errorMessage = i18n.tc("CEW0020000074", 1, { actionName });
      let status;
      try {
        this.isLoading = true;
        const res = await perimeterLineRepository.createPerimeterLine(body);
        if (res.status === 201) {
          status = res.status;
        } else {
          // 想定外のステータスコードが返却された場合エラーをスロー
          throw new Error(errorMessage);
        }
        // データリストを最新化
        await this.$store.dispatch("getPointDataList", this.$route.query.siteId)
        // エラーがなければ正常メッセージを出力する
        utils.showSnackBar('success', successMessage);
        this.resetForm();
        return status;
      } catch (e) {
        console.error(e.message);
        if (utils.createErrorMessage(e)) {
          errorMessage = utils.createErrorMessage(e);
        }
        utils.showSnackBar('error', errorMessage);
        return "";
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.project-dialog
  .v-card__title
    font-size: 16px

::v-deep .v-input__append-outer
  margin: 0

::v-deep .v-input__slot
  margin: 0
  padding: 0

.text-black ::v-deep input,
.text-black ::v-deep label,
.text-black ::v-deep .v-input__append-inner i
  color: black

::v-deep .v-text-field input,
::v-deep .v-text-field.v-input .v-select__selections input
  padding: 0

.text-field-error
  border: solid 2px #ff5252

::v-deep .v-text-field__details
  display: none

::v-deep .v-text-field__suffix
  color: black

::v-deep div.v-input.v-text-field div.v-input__prepend-outer
  margin: auto 8px auto 1px
  color: white

::v-deep button.v-btn span.v-btn__content
  font-size: 13px
</style>