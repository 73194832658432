import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@fortawesome/fontawesome-free/css/all.css';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            dark: {
                primary: "#0064BA",
                header: "#000000",
                panel_main: "#29292f",
                panel_sub: "#31323a",
                highlight_primary: "#057F00"
            }
        },
        dark: true
    },
    icons: {
     iconfont: 'fa',
    },
});
