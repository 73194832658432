// 平坦度チェック機能用のstore
import FlatnessCondition from "@/models/FlatnessCondition";
import { repositoryFactory } from "@/repositories/repository-factory";
const flatRepository = repositoryFactory.get('flat');

const state = {
  flatnessConditionList: []
};

const getters = {
  flatnessConditionList: state => state.flatnessConditionList
};

const mutations = {
  setFlatnessConditionList(state, flatnessConditionList) {
    state.flatnessConditionList = flatnessConditionList;
  }
};

const actions = {
  async fetchFlatnessConditionList({ getters, commit, rootGetters }, siteId) {
    const flatnessConditionList = [];
    const body = {
      user_id: rootGetters.loginData.user_id
    }
    try {
      const res = await flatRepository.findFlatConditionBySiteId(siteId, body);
      if (res.status === 201) {
        for (const flatnessCondition of res.data.flat_conditions) {
          const target = getters.flatnessConditionList.find(flatness =>
            flatnessCondition.flat_condition_id === flatness.flatnessConditionId
          );
          // リストに存在する場合は更新、しない場合は追加
          if (target) {
            target.update(
              flatnessCondition.flat_condition_name,
              flatnessCondition.select_info,
              flatnessCondition.grid_size,
            )
            flatnessConditionList.push(target);
          } else {
            const newFlatnessCondition
            = new FlatnessCondition(
              flatnessCondition.flat_condition_id,
              flatnessCondition.flat_condition_name,
              flatnessCondition.select_info,
              flatnessCondition.grid_size,
            )
            flatnessConditionList.push(newFlatnessCondition);
          }
        }
        commit("setFlatnessConditionList", flatnessConditionList);
      }
    } catch (error) {
      console.error(error.message);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};