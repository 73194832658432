<!-- プロジェクトメニューダイアログ -->
<template>
  <v-card>
    <v-card-title class="justify-space-between">
      {{$t("CQW0020300001")}} {{ pointData.name }}
      <close-button @click="$emit('close')" size="18px"></close-button>
    </v-card-title>
    <v-card-text class="py-1" style="font-size: 18px">
      <v-container class="pa-0 pt-2">
        <v-row>
          <v-col cols="4">{{$t("CQW0020300002")}}</v-col>
        </v-row>
        <!-- 名前順にソート -->
        <v-row class="my-0" align="center">
          <v-col cols="4">{{$t("CQW0020300003")}}</v-col>
          <v-col cols="8">
            <v-btn-toggle v-model="nameSortValue">
              <v-btn
                value="asc"
                height="36px"
                class="rounded-l-xl rounded-r-0"
                :class="{
                  sortButton__normal: nameSortValue !== 'asc',
                  sortButton__selected: nameSortValue === 'asc',
                }"
                :color="nameSortValue === 'asc' ? 'blue accent-4' : 'grey'"
                width="110px"
              >
                <v-icon 
                  class="mr-1"
                  :color="nameSortValue === 'asc' ? 'white' : 'black'"
                >
                  mdi-arrow-up
                </v-icon>
                {{$t("CQW0020300004")}}
              </v-btn>

              <v-btn
                value="desc"
                height="36px"
                class="rounded-r-xl rounded-l-0"
                :class="{
                  sortButton__normal: nameSortValue !== 'desc',
                  sortButton__selected: nameSortValue === 'desc',
                }"
                :color="nameSortValue === 'desc' ? 'blue accent-4' : 'grey'"
                width="110px"
              >
                {{$t("CQW0020300005")}}
                <v-icon 
                  class="ml-1"
                  :color="nameSortValue === 'desc' ? 'white' : 'black'"
                >
                  mdi-arrow-down
                </v-icon>
              </v-btn>

            </v-btn-toggle>
          </v-col>
        </v-row>
        <!-- 登録順にソート -->
        <v-row class="my-0" align="center">
          <v-col cols="4">{{$t("CQW0020300006")}}</v-col>
          <v-col cols="8">
            <v-btn-toggle v-model="createdDateSortValue">
              <v-btn
                value="asc"
                height="36px"
                class="rounded-l-xl rounded-r-0"
                :class="{
                  sortButton__normal: createdDateSortValue !== 'asc',
                  sortButton__selected: createdDateSortValue === 'asc',
                }"
                :color="createdDateSortValue === 'asc' ? 'blue accent-4' : 'grey'"
                width="110px"
              >
                <v-icon 
                  class="mr-1"
                  :color="createdDateSortValue === 'asc' ? 'white' : 'black'"
                >
                  mdi-arrow-up
                </v-icon>
                {{$t("CQW0020300004")}}
              </v-btn>
              <v-btn
                value="desc"
                height="36px"
                class="rounded-r-xl rounded-l-0"
                :class="{
                  sortButton__normal: createdDateSortValue !== 'desc',
                  sortButton__selected: createdDateSortValue === 'desc',
                }"
                :color="createdDateSortValue === 'desc' ? 'blue accent-4' : 'grey'"
                width="110px"
              >
                {{$t("CQW0020300005")}}
                <v-icon 
                  class="ml-1"
                  :color="createdDateSortValue === 'desc' ? 'white' : 'black'"
                >
                  mdi-arrow-down
                </v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <!-- 削除 -->
        <v-row class="my-0" align="center">
          <v-col cols="4">{{$t("SIW0020301003")}}</v-col>
          <v-col cols="8">
            <v-btn
              class="mr-1"
              color="red lighten-1"
              width="110px"
              @click="changeDeleteMode"
            >
              {{$t("CIW0020301001")}}
            </v-btn>
            <v-btn
              :disabled="isDisabledAllDeleteBtn"
              color="red accent-4"
              width="110px"
              @click="allDelete">
              {{$t("CIW0020301002")}}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import CloseButton from "@/components/common/CloseButton.vue";

export default {
  components: {
    CloseButton,
  },
  props: {
    id: String,
    pointData: Object,
    sortKey: Object
  },
  computed: {
    nameSortValue: {
      get: function() {
        return this.sortKey.name;
      },
      set: function(sortDirection) {
        if (sortDirection) this.$emit("sort-by-name", sortDirection);
        else this.$emit("sort-by-name", "");
      }
    },
    createdDateSortValue: {
      get: function() {
        return this.sortKey.createdAt;
      },
      set: function(sortDirection) {
        if (sortDirection) this.$emit("sort-by-created-at", sortDirection);
        else this.$emit("sort-by-created-at", "");
      }
    },
    /**
     * 全削除チェックボックスの活性制御を行う
     * ・全ファイルが断面と紐づいている場合非活性
     */
    isDisabledAllDeleteBtn() {
      const sectionFileList = this.$store.getters['sectionFile/sectionFileList'];
      return this.pointData.getPoints().every(point => {
              return !!sectionFileList.find(section => section.pointId === point.pointId)
            });
    }
  },
  methods: {
    changeDeleteMode() {
      this.$emit("change-delete-mode");
    },
    allDelete() {
      this.$emit("all-delete");
    },
  },
};
</script>

<style lang="sass" scoped>
::v-deep .v-btn__content
  font-size: 18px
  line-height: 100%

.sortButton__normal
  color: black

.sortButton__selected
  color: white
</style>
