import { render, staticRenderFns } from "./PointCreateDialog.vue?vue&type=template&id=c80593be&scoped=true"
import script from "./PointCreateDialog.vue?vue&type=script&lang=js"
export * from "./PointCreateDialog.vue?vue&type=script&lang=js"
import style0 from "./PointCreateDialog.vue?vue&type=style&index=0&id=c80593be&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c80593be",
  null
  
)

export default component.exports