import { render, staticRenderFns } from "./CrossSectionEditConfirmDialog.vue?vue&type=template&id=013deac7&scoped=true"
import script from "./CrossSectionEditConfirmDialog.vue?vue&type=script&lang=js"
export * from "./CrossSectionEditConfirmDialog.vue?vue&type=script&lang=js"
import style0 from "./CrossSectionEditConfirmDialog.vue?vue&type=style&index=0&id=013deac7&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "013deac7",
  null
  
)

export default component.exports