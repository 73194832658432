// 断面
export default class Section{
  /** 
   * フィールド 
   */
  _sectionId;
  _sectionName;
  _sectionInfo;
  _sectionInfoEpsg4978;
  _surroundingPoints;
  _linearId;
  _leftSideWidth;
  _rightSideWidth;
  _pointId;
  _status;
  _message;
  _viewerSectionId;
  _isVisible;

  /**
   * コンストラクタ
   */
  constructor(sectionId, sectionName, sectionInfo, sectionInfoEpsg4978, surroundingPoints, pointId, linearId, leftSideWidth, rightSideWidth, status, message) {
    this.sectionId = sectionId;
    this.sectionName = sectionName;
    this.sectionInfo = sectionInfo;
    this.sectionInfoEpsg4978 = sectionInfoEpsg4978;
    this.surroundingPoints = surroundingPoints;
    this.pointId = pointId;
    this.linearId = linearId;
    this.leftSideWidth = leftSideWidth;
    this.rightSideWidth = rightSideWidth;
    this.status = status;
    this.message = message;
    this.viewerSectionId = "";
    this.isVisible = false;
  }

  /**
   * getters
   */
  get sectionId() {
    return this._sectionId;
  }

  get sectionName() {
    return this._sectionName;
  }

  get sectionInfo() {
    return this._sectionInfo;
  }

  get sectionInfoEpsg4978() {
    return this._sectionInfoEpsg4978;
  }

  get surroundingPoints() {
    return this._surroundingPoints;
  }

  get pointId() {
    return this._pointId;
  }

  get linearId() {
    return this._linearId;
  }

  get leftSideWidth() {
    return this._leftSideWidth;
  }

  get rightSideWidth() {
    return this._rightSideWidth;
  }

  get status() {
    return this._status;
  }

  get message() {
    return this._message
  }

  get viewerSectionId() {
    return this._viewerSectionId;
  }

  get isVisible() {
    return this._isVisible;
  }

  /**
   * setters
   */
  set sectionId(sectionId) {
    this._sectionId = sectionId;
  }

  set sectionName(sectionName) {
    this._sectionName = sectionName;
  }

  set sectionInfo(sectionInfo) {
    this._sectionInfo = sectionInfo;
  }

  set sectionInfoEpsg4978(sectionInfoEpsg4978) {
    this._sectionInfoEpsg4978 = sectionInfoEpsg4978;
  }

  set surroundingPoints(surroundingPoints) {
    this._surroundingPoints = surroundingPoints;
  }

  set pointId(pointId) {
    this._pointId = pointId;
  }

  set linearId(linearId) {
    this._linearId = linearId;
  }

  set leftSideWidth(leftSideWidth) {
    this._leftSideWidth = Number(leftSideWidth);
  }

  set rightSideWidth(rightSideWidth) {
    this._rightSideWidth = Number(rightSideWidth);
  }

  set status(status) {
    this._status = Number(status);
  }

  set message(message) {
    this._message = message;
  }

  set viewerSectionId(viewerSectionId) {
    this._viewerSectionId = viewerSectionId;
  }

  set isVisible(isVisible) {
    this._isVisible = isVisible;
  }

  /**
   * functions
   */
  update(sectionName, sectionInfo, sectionInfoEpsg4978, surroundingPoints, pointId, linearId, leftSiteWidth, rightSiteWidth, status, message) {
    this.sectionName = sectionName;
    this.sectionInfo = sectionInfo;
    this.sectionInfoEpsg4978 = sectionInfoEpsg4978;
    this.surroundingPoints = surroundingPoints;
    this.pointId = pointId;
    this.linearId = linearId;
    this.leftSideWidth = leftSiteWidth;
    this.rightSideWidth = rightSiteWidth;
    this.status = status;
    this.message = message;
  }
}