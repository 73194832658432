import * as utils from '@/utils/utils';
import { repositoryFactory } from "@/repositories/repository-factory";
const siteRepository = repositoryFactory.get('site');

export const isAllowedToOpenSite = async (siteId, userId) => {
  let result = false;
  let errorMessage = '現場閲覧権限の取得に失敗しました';

  if (!siteId || !userId) return false;

  const body = {
    user_id: userId
  };

  try {
    const res = await siteRepository.existsBySiteIdAndUserId(siteId, body);
    if (res.status === 201) {
      result = res.data.result;
    } else {
      utils.showSnackBar("error", errorMessage);
    }
  } catch (e) {
    console.error(e.message);
    if (utils.createErrorMessage(e)) {
      errorMessage = utils.createErrorMessage(e);
    }
    utils.showSnackBar('error', errorMessage);
  } 
  return result
}
