<!-- 点群データアップロード（地図） -->
<template>
  <v-card class="point-upload-dialog px-4 py-2">
    <v-card-title class="pa-0 justify-space-between">
      {{ item.text }}
      <close-button @click="$emit('close')" size="20px"></close-button>
    </v-card-title>
    <v-card-text class="px-0 py-1">
      <v-container class="pa-0">
        <v-row class="ma-0">
          <validation-observer class="d-block" style="width: 100%" ref="observer" v-slot="{ invalid }">
            <v-col cols="12" class="my-3 pa-0">
              <div class="mb-1">
                <label class="d-inline-block mr-2">{{$t("CQW0020104019")}}</label>
              </div>
              <GmapMap
                :center="centerPosition"
                :zoom="7"
                map-type-id="terrain"
                style="width: 100%; height: 300px"
              >
                <GmapMarker
                  :key="index"
                  v-for="(m, index) in markers"
                  :position="m.position"
                  :clickable="true"
                  :draggable="true"
                  @click="center=m.position"
                />
              </GmapMap>
            </v-col>
            <v-col cols="12" class="my-3 pa-0">
              <validation-provider :rules="{
                required: true,
                numeric: true,
              }" v-slot="{ errors }">
                <div class="mb-1">
                  <label class="d-inline-block mr-2">{{$t("CQW0020104020")}}</label>
                  <span class="d-inline-block" style="font-size: 12px; color: #ff5252">{{ errors[0] }}</span>
                </div>
                <v-text-field
                    @reset.prevent="resetError()"
                    v-model="interpolationPitch"
                    :error-messages="errors"
                    class="text-black ma-0"
                    height="32px"
                    background-color="#BFBFBF"
                    outlined
                    solo
                    type="number"
                    suffix="m"
                  >
                  </v-text-field>
                </validation-provider>
            </v-col>
            <v-col cols="12" class="my-3 pa-0">
              <validation-provider :rules="{
                required: true,
              }" v-slot="{ errors }">
                <div class="mb-1">
                  <label class="d-inline-block mr-2">{{$t("CQW0020104006")}}</label>
                  <span class="d-inline-block" style="font-size: 12px; color: #ff5252">{{ errors[0] }}</span>
                </div>
                <v-text-field @reset.prevent="resetError()" v-model.trim="uploadFileName" :error-messages="errors"
                  class="text-black ma-0" height="32px" background-color="#BFBFBF" outlined solo></v-text-field>
              </validation-provider>
            </v-col>
            <!-- アップロード先 -->
            <v-col cols="12" class="my-3 pa-0">
              <label class="mb-1 d-block">{{$t("CQW0020104022")}}</label>
              <v-select
                v-model="selectedUploadDirectory"
                class="text-black"
                height="32px"
                background-color="#BFBFBF"
                :items="uploadDirectoryList"
                item-text="text"
                :item-value="item => ({value: item.value, text: item.text})"
                hide-details
                solo
              >
              </v-select>
            </v-col>
            <!-- アップロードボタン -->
            <v-col cols="4" class="mt-4 pa-0">
              <v-btn
                class="rounded-pill"
                color="primary"
                width="135px"
                height="35px"
                depressed
                :disabled="invalid"
                @click="fileUpload"
              >
                {{$t("CIW0020000007")}}
              </v-btn>
            </v-col>
          </validation-observer>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import CloseButton from "@/components/common/CloseButton.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import * as utils from '@/utils/utils';
import { repositoryFactory } from "@/repositories/repository-factory";
import { i18n } from '@/i18n.js'
const pointRepository = repositoryFactory.get('point');

export default {
  components: {
    CloseButton,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    item: Object,
  },
  data() {
    return {
      systemDate: null,
      interpolationPitch: "",
      siteLatitude: "",
      siteLongitude: "",
      centerPosition: {},
      uploadFileName: "",
      selectedUploadDirectory: "",
      uploadDirectoryList: [],
      markers: []
    }
  },
  computed: {
    isLoading: {
      get() {
        return this.$store.getters.isLoading;
      },
      set(value) {
        this.$store.dispatch("updateIsLoading", value);
      }
    },
  },
  methods: {
    async resetForm() {
      this.uploadFileName = "";
      this.interpolationPitch = "";
      this.selectedUploadDirectory = this.uploadDirectoryList.find(
          project => project.text === this.systemDate
        )
      await this.resetError();
    },
    async resetError() {
      await this.$refs.observer.validate();
      await this.$refs.observer.reset();
    },
    async fileUpload() {
      this.isLoading = true
      const form = new FormData()
      const body = {
        point_name: this.uploadFileName,
        project_name: this.selectedUploadDirectory.text,
        project_id: this.selectedUploadDirectory.value,
        site_id: this.$route.query.siteId,
        is_dashboard: 0,
        format_type: "",
        created_by: this.$store.getters.loginData.user_id,
        point_file: "",
        coordinate_system: "",
        vertical_shift: "" 
      };
      for (const field in body) {
        if (field === "point_file") {
          form.append(field, body[field], this.uploadFileName)
        } else {
          form.append(field, body[field])
        }
      }
      await this.request(form);
      await this.resetForm();
      this.isLoading = false;
      this.$emit('close');
    },
    setUploadDirectoryList() {
      let uploadDirectoryList = [];
      for (const project of this.$store.state.pointDataList) {
        uploadDirectoryList.push({value: project.id, text: project.name});
      }

      const sysDateProject = 
        this.$store.state.pointDataList.find(
          project => project.name === this.systemDate
        )

      if (!sysDateProject) {
        this.selectedUploadDirectory = {value: "", text: this.systemDate};
        uploadDirectoryList = [
          ...uploadDirectoryList,
          this.selectedUploadDirectory,
        ];
      } else {
        this.selectedUploadDirectory = {value: sysDateProject.id, text: sysDateProject.name}
      }
      this.uploadDirectoryList = uploadDirectoryList
    },
    async request(form) {
      let successMessage = i18n.tc("CIW0020104005");
      let errorMessage = i18n.tc("CEW0020104005");
      try {
        const res = await pointRepository.create(form);
        if (res.status === 201) {
          await this.$store.dispatch("getPointDataList",
            this.$route.query.siteId
          )
          this.setUploadDirectoryList()
          utils.showSnackBar('success', successMessage);
        } else {
          utils.showSnackBar('error', errorMessage);
        }
      } catch (e) {
        console.error(e);
        if (utils.createErrorMessage(e)) {
          errorMessage = utils.createErrorMessage(e);
        }
        utils.showSnackBar('error', errorMessage);
      }
    },
    // 現場の緯度経度を取得
    async getSiteCoordinate(siteId){
      const siteRepository = repositoryFactory.get('site');
      const res = await siteRepository.getSiteBySiteName(siteId);

      this.siteLatitude = Number(res.data.origin_latitude);
      this.siteLongitude = Number(res.data.origin_longitude);
      //マーカー(モック用)
      // this.siteLatitude = 10;
      // this.siteLongitude = 10;
      // 地図中心座標の設定
      this.centerPosition = {...this.centerPosition, lat: Number(this.siteLatitude), lng: Number(this.siteLongitude)}

      this.markers.push({
        position : {
          lat: this.siteLatitude,
          lng: this.siteLongitude 
        }
      })
    },
  },
  mounted() {
    const today = new Date();
    const siteId = this.$route.query.siteId;

    this.systemDate =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      today.getDate().toString().padStart(2, "0");
    this.setUploadDirectoryList();
    this.getSiteCoordinate(siteId);
  },
};
</script>

<style lang="sass" scoped>
.point-upload-dialog
  .v-card__title
    font-size: 16px

.point-upload-dialog
  p,span
    color: white

::v-deep .v-input__append-outer
  margin: 0

::v-deep .v-input__slot
  margin: 0
  padding: 0

.text-black ::v-deep input,
.text-black ::v-deep label,
.text-black ::v-deep .v-select__selection,
.text-black ::v-deep .v-input__append-inner i
  color: black

::v-deep .v-text-field input,
::v-deep .v-text-field.v-input .v-select__selections input
  padding: 0

.drop-area
  border: 2px dashed white

.enter
  border: 2px dashed #2196F3

.text-field-error
  border: solid 2px #ff5252

::v-deep .v-text-field__details
  display: none

::v-deep .checkbox-label-size .v-label
 font-size: 11.5px
</style>