<template>
  <v-time-picker
    v-model="pickerTime"
    format="24hr"
    use-seconds
    color="primary"
  >
    <template slot="default">
      <div class="ml-auto">
        <v-btn
          color="primary mr-4"
          @click="setSystemTime()"
        >
        現在時刻
        </v-btn>
        <v-btn
          color="primary"
          @click="confirm()"
        >
        確定
        </v-btn>
      </div>
    </template> 
  </v-time-picker>
</template>

<script>
export default {
  props: {
    time: String
  },
  computed: {
    pickerTime: {
      get: function() {
        return this.time;
      }, 
      set: function(newTime) {
        this.$emit("set-time", newTime);
      }, 
    }
  },
  methods: {
    confirm() {
      this.$emit("confirm")
    },
    setSystemTime() {
      const today = new Date();
      this.pickerTime = 
        today.getHours().toString().padStart(2, "0") +
        ":" +
        today.getMinutes().toString().padStart(2, "0") +
        ":" +
        today.getSeconds().toString().padStart(2, "0");
    },
  }
}
</script>

<style lang="sass" scoped>
::v-deep div.v-picker__actions.v-card__actions
  background-color: #424242
</style>