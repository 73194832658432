<!-- 点数 -->
<template>
  <v-card class="number-of-points-confirm-dialog px-4 py-2">
    <loading :parentLoading="isLoading" />
    <v-card-title class="pa-0 justify-space-between">
      <label>
        {{$t("CIW0020000045")}}
      </label>
      <close-button @click="$emit('close')" size="20px"></close-button>
    </v-card-title>
    <v-card-text class="px-0 pt-1 pb-0 white--text">
      <v-container class="pa-0">
        <v-row class="ma-0">
            <v-col cols="12" class="my-3 pa-0">
              <editing-point-clouds
                :title="$t('CIW0020000008')">
              </editing-point-clouds>
            </v-col>
        </v-row>
        <v-row class="ma-0">
            <v-col cols="12" class="my-3 pa-0">
              {{$t("CQW0020125007")}}<br />
              {{$t("CQW0010600004")}}
            </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="6" class="mt-2 mb-4 pa-0" align="left">
            <v-btn
              class=".rounded-lg"
              color="#ff5050"
              width="125px"
              height="45px"
              depressed
              @click="$emit('cancel')"
              >{{$t("CQW0010600005")}}</v-btn
            >
          </v-col>
          <v-col cols="6" class="mt-2 mb-4 pa-0" align="right">
            <v-btn
              class=".rounded-lg"
              color="#4472c4"
              width="125px"
              height="45px"
              depressed
              :disabled="!editingPointClouds.length"
              @click="execute()"
              >{{$t("CQW0020109005")}}</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import CloseButton from "@/components/common/CloseButton.vue";
import Loading from "@/components/common/LoadingOverLay.vue";
import EditingPointClouds from "@/components/Project/Dialogs/EditingPointClouds.vue";
import { i18n } from '@/i18n.js'
import { repositoryFactory } from "@/repositories/repository-factory";
import * as utils from "@/utils/utils";
const measurePointsRepository = repositoryFactory.get('measurePoints');

export default {
  name: "HoleInterpolationDialog",
  components: {
    CloseButton,
    EditingPointClouds,
    Loading
  },
  computed: {
    editingPointClouds() {
      return this.$store.getters.editingPointClouds;
    }
  },
  methods: {
    execute() {
      this.$emit('count-points', this.sendCallback.bind());
    },
    // Cesium.vueの範囲選択後（右クリック後）に呼ばれる
    sendCallback(selectionMapForMeasurement, selectionDataForRepresentation) {
      return this.send(selectionMapForMeasurement, selectionDataForRepresentation);
    },
    // 実行(保存)押下時処理
    async send(selectionMapForMeasurement, selectionDataForRepresentation) {
      this.isLoading = true;
      const body = {
        user_id: this.$store.getters.loginData.user_id
      };

      await this.putRequest(selectionMapForMeasurement, selectionDataForRepresentation, body);
      this.isLoading = false
    },
    async putRequest(selectionMapForMeasurement, selectionDataForRepresentation, body) {
      let successMessage = i18n.tc("CIW0020121001");  
      let errorMessage = i18n.tc("CEW0020121002");   
      let status
      
      try {
        // 点群ファイルの件数分リクエストを送信
        for (const pointFile of this.editingPointClouds) {
          const assetId = pointFile.assetId;
          const reqBody = {
            asset_id: assetId,
            select_info: {
              forMeasurement: selectionMapForMeasurement.get(assetId),
              forRepresentation: selectionDataForRepresentation,
            },
            ...body
          };
          const pointId = pointFile.pointId;
          const res = await measurePointsRepository.countPointsByPointId(pointId, reqBody);
          if (res.status === 201 || res.status === 204) {
            status = res.status;
            // データリストのリフレッシュ
            this.$store.dispatch("getPointDataList", this.$route.query.siteId);
          } else {
            // 想定外のステータスコードが返却された場合エラーをスロー
            throw new Error(errorMessage);
          }
        }
        // エラーがなければ正常メッセージを出力する
        utils.showSnackBar('success', successMessage);

        return status;
      } catch (e) {
        // エラーが発生したら処理終了
        console.error(e.message);
        if (utils.createErrorMessage(e)) {
          errorMessage = utils.createErrorMessage(e);
        }
        utils.showSnackBar('error', errorMessage);
        return "";
      }
    },
  },
};
</script>
