<!-- 平坦度条件データ -->
<template>
  <div class="d-flex align-center data-list-title">
    <v-checkbox
      v-if="deleteMode"
      :value="isChecked"
      @click="changeCheckBox"
      off-icon="mdi-checkbox-blank"
      color="white"
      class="my-0 mr-1 pa-0"
      hide-details
    >
    </v-checkbox>
    <!-- 条件名 -->
    <flatness-condition-name :flatnessCondition="flatnessCondition"></flatness-condition-name>
  </div>
</template>

<script>
import FlatnessConditionName from './FlatnessConditionName.vue';

export default {
  components: {
    FlatnessConditionName
  },
  props: {
    selected: Array,
    flatnessCondition: Object,
    deleteMode: Boolean
  },
  data: () => ({}),
  computed: {
    isChecked() {
      return this.selected.includes(this.flatnessCondition.flatnessConditionId);
    }
  },
  methods: {
    changeCheckBox() {
      if (this.isChecked) this.$emit('delete-checked', this.flatnessCondition.flatnessConditionId)
      else this.$emit('add-checked', this.flatnessCondition.flatnessConditionId);
    }
  }
}
</script>

<style lang="sass" scoped>
.data-list-title
  font-size: 14px
  margin-bottom: 4px
  white-space: normal
  word-break: break-all

.data-list-title ::v-deep .v-text-field .v-input__control .v-input__slot
  min-height: auto

::v-deep .v-input__slot .v-input--selection-controls__input
  margin-right: 0
  height: auto

.data-list-title-name .on-hover
  cursor: pointer
  background-color: rgba(245,245,245,0.2)

// チェックボックスホバー時の背景色の変化を無効化
::v-deep div.v-input--selection-controls__ripple
  display: none

</style>