import Repository from "./repository";

const resource = "/flat";
export default {
  // 平坦度条件リスト返却
  findFlatConditionBySiteId(siteId, payload) {
    return Repository.post(`${resource}/condition/${siteId}`, payload);
  },
  // 平坦度条件削除
  deleteFlatConditionByConditionId(conditionId, payload) {
    return Repository.delete(`${resource}/condition/${conditionId}`, { data: payload });
  },
  // 平坦度条件更新
  updateFlatConditionByConditionId(conditionId, payload) {
    return Repository.put(`${resource}/condition/${conditionId}`, payload);
  },
  // 平坦度データ更新
  updateFlatDataByFlatId(flatId, payload) {
    return Repository.put(`${resource}/data/${flatId}`, payload);
  },
  // 平坦度データ削除
  deleteFlatDataByFlatId(flatId, payload) {
    return Repository.delete(`${resource}/data/${flatId}`, { data: payload });
  },
};
