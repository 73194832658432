<!-- 断面編集 -->
<template>
  <v-card class="project-dialog">
    <v-card-title class="pt-2 justify-space-between" style="font-size: 14px; color: white">
      <span>{{ $t("CIW0020000040") }} </span>
      <close-button role="close" @click="close" size="18px"></close-button>
    </v-card-title>
    <v-card-text class="py-0 pb-5" style="color: white">
      <span>{{ $t("CQW0020125014") }}<br />{{ $t("CQW0020109003") }}</span>
    </v-card-text>
    <v-card-actions class="pa-0 pb-5 justify-center">
      <v-btn class="pa-3 mr-2 btn" role="cancel" color="#ff5050" width="110px" height="40px" @click="cancel">
        {{ $t("CQW0010600005") }}
      </v-btn>
      <v-btn class="pa-3 btn" :disabled="isDisabledSaveBtn" role="ok" color="#4472c4" width="110px" height="40px" @click="save">
        {{ $t("CIW0020000005") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import * as cesiumCommon from '@/utils/cesium-common';
import CloseButton from "@/components/common/CloseButton.vue";
import Dialog from "@/components/modules/dialog";
import * as utils from '@/utils/utils';
import { i18n } from '@/i18n.js'
import { repositoryFactory } from "@/repositories/repository-factory";
const sectionFileRepository = repositoryFactory.get('sectionFile');

export default {
  components: {
    CloseButton
  },
  computed: {
    visibleSectionFile() {
      return this.$store.getters['sectionFile/visibleSectionFile'];
    },
    sectionInfo() {
      return this.$store.getters["edit/sectionEdit"].sectionInfo;
    },
    /**
     * 保存ボタンの活性制御
     * 以下の場合非活性
     * ・可視状態の断面ファイルがない
     */
    isDisabledSaveBtn() {
      return !this.visibleSectionFile
    }
  },
  methods: {
    close() {
      this.$store.dispatch('updateEditType', '');
      const dialog = new Dialog();
      dialog.type = 'cesium';
      dialog.close();
    },
    cancel() {
      cesiumCommon.cancelCrossSectionEdit();
      this.close();
    },
    /**
     * 断面データの編集を確定し、断面情報を取得する
     */
    async save() {
      try {
        const section = this.$store.getters['sectionFile/visibleSectionFile'];
        const selectedLineIndex = this.$store.getters["sectionFile/selectedLineIndex"];
        const lineData = cesiumCommon.getLocalizedCrossSectionLine();
        const lineDataEpsg4978 = await this.sendRequest(selectedLineIndex, lineData);
        section.sectionInfo.lines[selectedLineIndex] = lineData;
        section.sectionInfoEpsg4978.lines[selectedLineIndex] = lineDataEpsg4978;
        cesiumCommon.show3DCrossSectionLines(section, selectedLineIndex);
        window['viewer'].setSurroundingPointsVisibility(false);
        this.close();
      } catch (e) {
        console.error(e);
        // エラーが発生した場合変更をキャンセルしてダイアログを閉じる
        utils.showSnackBar("error", i18n.tc("CEW0020125015"));
        this.cancel();
      }
    },
    async sendRequest(lineIndex, lineData) {
      let successMessage = i18n.tc("CIW0020122001")
      let errorMessage = i18n.tc("CEW0020122002")
      const body = {
        line_data: lineData,
        user_id: this.$store.getters.loginData.user_id
      };
      this.$store.dispatch("updateIsLoading", true);
      try {
        const editSectionId = this.visibleSectionFile.sectionId;
        const res = await sectionFileRepository.editSectionFileBySectionId(editSectionId, lineIndex, body);
        if (res.status !== 200) {
          // 想定外のステータスコードが返却された場合エラーをスロー
          throw new Error(errorMessage);
        }
        // エラーがなければ正常メッセージを出力する
        utils.showSnackBar("success", successMessage);
        return res.data;
      } catch (e) {
        // エラーが発生したら処理終了
        console.error(e.message);
        if (utils.createErrorMessage(e)) {
          errorMessage = utils.createErrorMessage(e);
        }
        utils.showSnackBar("error", errorMessage);
      } finally {
        this.$store.dispatch("updateIsLoading", false);
      }
    }
  }
};
</script>
<style lang="sass" scoped>
.project-dialog .btn
    font-size: 13px
</style>