<!-- 点群オフセット -->
<template>
	<v-card class="off-set-dialog px-4 pt-2 pb-4">
		<loading :parentLoading="isLoading" />
		<v-card-title class="pa-0 justify-space-between">
      <label>{{$t("CQW0020120001")}}</label>
      <close-button @click="$emit('close')" size="20px"></close-button>
		</v-card-title>
		<v-card-text class="px-0 pt-1 pb-0 white--text">
			<v-container class="pa-0">
				<v-row class="ma-0">
					<validation-observer 
							class="d-block"
							style="width: 100%"
							ref="observer"
              v-slot="{ pristine, invalid }"
							>
						<v-col cols="12" class="my-3 pa-0">
              <editing-point-clouds 
                :title="$t('CIW0020000008')">
              </editing-point-clouds>
						</v-col>
						<!-- X -->
						<v-col cols="12" class="mt-3 pa-0">
							<validation-provider 
								:rules="{
                  required: true,
                  'number-format': true,
                  'decimal-size-limit': 3,
                  'min-value': -1000.999,
                  'max-value': 1000.999,
									}"
								v-slot="{ errors }">
									<div class="mb-1">
                    <label class="d-inline-block mr-2">{{$t("CQW0020120003")}}</label>
									</div>
                  <div>
                    <span style="font-size: 12px; color: #ff5252;">{{ errors[0] }}</span>
									</div>
									<div style="float: left; height: 32px; padding-top: 5px;">
                    <label style="font-size: 1rem; margin-right: 10px;">{{$t("CQW0020114010")}}</label>
									</div>
									<v-text-field
                    @reset.prevent="resetError()"
                    @keydown.69.prevent
                    v-model="offSetX"
                    :error-messages="errors"
                    class="text-black ma-0"
                    height="32px"
                    background-color="#BFBFBF"
                    outlined
                    solo
                    suffix="m"
									>
									</v-text-field>
							</validation-provider>
						</v-col>
						<!-- Y -->
						<v-col cols="12" class="mt-3 pa-0">
							<validation-provider 
								:rules="{
                  required: true,
                  'number-format': true,
                  'decimal-size-limit': 3,
                  'min-value': -1000.999,
                  'max-value': 1000.999,
									}"
								v-slot="{ errors }">
                  <div>
                    <span style="font-size: 12px; color: #ff5252;">{{ errors[0] }}</span>
									</div>
									<div style="float: left; height: 32px; padding-top: 5px;">
                    <label style="font-size: 1rem; margin-right: 10px;">{{$t("CQW0020114011")}}</label>
									</div>
									<v-text-field
                    @reset.prevent="resetError()"
                    @keydown.69.prevent
                    v-model="offSetY"
                    :error-messages="errors"
                    class="text-black ma-0"
                    height="32px"
                    background-color="#BFBFBF"
                    outlined
                    solo
                    suffix="m"
									>
									</v-text-field>
							</validation-provider>
						</v-col>
						<!-- Z -->
						<v-col cols="12" class="mt-3 pa-0">
							<validation-provider 
                :rules="{
                  required: true,
                  'number-format': true,
                  'decimal-size-limit': 3,
                  'min-value': -1000.999,
                  'max-value': 1000.999,
									}"
                v-slot="{ errors }">
                  <div>
                    <span style="font-size: 12px; color: #ff5252;">{{ errors[0] }}</span>
									</div>
									<div style="float: left; height: 32px; padding-top: 5px;">
											<label style="font-size: 1rem; margin-right: 10px;">{{$t("CQW0020120006")}}</label>
									</div>
									<v-text-field
											@reset.prevent="resetError()"
                      @keydown.69.prevent
											v-model="offSetZ"
											:error-messages="errors"
											class="text-black ma-0"
											height="32px"
											background-color="#BFBFBF"
											outlined
											solo
											suffix="m"
									>
									</v-text-field>
							</validation-provider>
						</v-col>
						<!-- キャンセル -->
						<v-col cols="12" class="mt-4 pa-0 d-flex justify-space-between">
							<v-btn
                class="rounded-lg"
                color="#ff5050"
                width="115px"
                height="45px"
                depressed
                @click="$emit('close')">
                {{$t("CQW0010600005")}}
              </v-btn>
							<!-- 実行（上書き）-->
							<v-btn
                class="rounded-lg"
                color="#4472c4"
                width="115px"
                height="45px"
                depressed
                :disabled="pristine || invalid || !editingPointClouds.length"
								@click="pointDataEditing(1)"
                >
                {{$t("CQW0020109005")}}<br>{{$t("CQW0020110006")}}
              </v-btn>
							<!-- 別点群として保存 -->
              <v-btn
                class="rounded-lg"
                color="#4472c4"
                width="115px"
                height="45px"
                depressed
                :disabled="pristine || invalid || !editingPointClouds.length"
								@click="pointDataEditing(2)"
                >
                {{$t("CQW0020110007")}}<br>{{$t("CIW0020000005")}}
              </v-btn>
						</v-col>
					</validation-observer>
				</v-row>
			</v-container>
		</v-card-text>
	</v-card>
</template>

<!-- SCRIPT -->
<script>
import eventBus from "../../../modules/eventBus";
import CloseButton from "@/components/common/CloseButton.vue";
import Loading from '../../../common/LoadingOverLay.vue';
import EditingPointClouds from "../EditingPointClouds.vue";
import * as utils from "@/utils/utils";
import { repositoryFactory } from "@/repositories/repository-factory";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { i18n } from '../../../../i18n.js'
const offSetRepository = repositoryFactory.get('adjustment');

export default {
	components: {
		Loading,
		CloseButton,
		EditingPointClouds,
		ValidationProvider,
		ValidationObserver
	},
	data: () => ({
		isLoading: false,
		offSetX: '',
		offSetY: '',
		offSetZ: '',
	}),
	computed: {
		editingPointClouds() {
      return this.$store.getters.editingPointClouds;
    }
	},
	methods: {
		resetForm() {
      this.offSetX = ""
      this.offSetY = ""
			this.offSetZ = ""
      this.resetError();
    },
    async resetError() {
      await this.$refs.observer.validate();
      await this.$refs.observer.reset();
    },
		//実行(上書き)/別点群として保存を実行する。
		async pointDataEditing(runValue) {
			this.isLoading = true;
			const body = {
				user_id: this.$store.getters.loginData.user_id,
        off_set_x: this.offSetY,
        off_set_y: this.offSetX,
				off_set_z: this.offSetZ,
        execution_method: runValue
      };
			const status = await this.putRequest(body);

			// 戻り値204は表示中の点群の上書き成功のため再タイリングする
      if (status === 204) {
        // 再タイリング
        await eventBus.$emit('cesium-event', 're-tiling', { pointFile: this.$store.getters.editingPointClouds[0] });
      }
			this.isLoading = false;
			this.$emit('close');
		},
		async putRequest(body) {
			let successMessage = i18n.tc("CIW0020120001");
      let errorMessage = i18n.tc("CEW0020120003");
      let status;
			try {
        for (const pointFile of this.editingPointClouds) {
          const res = await offSetRepository.offSetByPointId(pointFile.pointId, body);
          if (res.status === 201 || res.status === 204) {
            status = res.status;
          } else {
            utils.showSnackBar('error', errorMessage);
            return "";
          }
        }
        utils.showSnackBar('success', successMessage);
        await this.$store.dispatch("getPointDataList", this.$route.query.siteId)
        this.resetForm();
        return status;
      } catch (e) {
        console.error(e.message);
        if (utils.createErrorMessage(e)) {
          errorMessage = utils.createErrorMessage(e);
        }
        utils.showSnackBar('error', errorMessage);
        return "";
      } finally {
        // 実行に成功した場合に全点群を不可視にする
        if (status === 204 || status === 201) {
          utils.allClearTileset();
          // データリストを更新
          utils.refreshDataList(this.$route.query.siteId);
        }
      }
		}
	}
}
</script>

<!-- STYLE -->
<style lang="sass" scoped>
.off-set-dialog
  .v-card__title
    font-size: 16px

::v-deep .v-input__append-outer
  margin: 0

::v-deep .v-input__slot
  margin: 0
  padding: 0

.text-black ::v-deep input,
.text-black ::v-deep label,
.text-black ::v-deep .v-select__selection,
.text-black ::v-deep .v-input__append-inner i
  color: black

::v-deep .v-text-field input,
::v-deep .v-text-field.v-input .v-select__selections input
  padding: 0

.text-field-error
  border: solid 2px #ff5252

::v-deep .v-text-field__details
  display: none

::v-deep .v-text-field__suffix
  color: black

::v-deep div.v-radio
  div.v-input--selection-controls__input i.v-icon
    font-size: 14px
    color: #f0f0f0
  label.v-label
    font-size: 14px
    color: white

::v-deep div.v-input.v-text-field div.v-input__prepend-outer
  margin: auto 8px auto 1px
  color: white

::v-deep button.v-btn span.v-btn__content
  font-size: 13px
</style>