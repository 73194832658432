<!-- 設計ファイル -->
<template>
  <div class="d-flex align-center data-list-title">
    <v-btn
      class="pa-0 mr-1"
      min-width="auto"
      plain
      height="auto"
      :disabled="isDisabledVisible"
      @click="changeVisibleState(designFile)"
    >
      <v-icon v-if="designFile.isVisible">mdi-eye-outline</v-icon>
      <v-icon v-else>mdi-eye-off-outline</v-icon>
    </v-btn>
    <v-checkbox 
      v-if="deleteMode"
      :value="isChecked"
      @click="changeDeleteState"
      off-icon="mdi-checkbox-blank" color="white" class="my-0 mr-1 pa-0"
      :disabled="isDisabledVisible"
      hide-details
    >
    </v-checkbox>
    <design-file-name :designFile="designFile"></design-file-name>
    <!-- アイコン表示エリア -->
    <div class="pl-3 ml-auto d-flex align-center">
      <v-btn
        class="pa-0"
        min-width="auto"
        plain
        height="auto"
        :disabled="isDisabledVisible"
        @click="onMagnifyDesign(designFile)"
      >
        <v-icon v-if="designFile.isVisible">mdi-magnify</v-icon>
      </v-btn>
      <!-- アップロードステータス -->
      <div v-if="designFile.assetId && (showUploadProgress || isUploadError)">
        <progress-circle
          class="ml-1"
          :showUploadProgress="showUploadProgress"
          :isUploadError="isUploadError"
          @update-upload-progress="(value) => { showUploadProgress = value }"
          @update-upload-error-status="(value) => { isUploadError = value }"
          @error-clear="deleteDesignFile"
          :file="designFile"
        >
        </progress-circle>
      </div>
    </div>
  </div>
</template>

<script>
import * as utils from '@/utils/utils';
import * as constants from '@/constants/constants';
import { i18n } from '@/i18n.js'
import DesignFileName from "./DesignFileName.vue";
import * as cesiumCommon from '@/utils/cesium-common';
import ProgressCircle from "./ProgressCircle.vue";
import { repositoryFactory } from "@/repositories/repository-factory";
const designDataRepository = repositoryFactory.get('designData');

export default {
  components: {
    DesignFileName,
    ProgressCircle
  },
  props: {
    selected: Array,
    designFile: Object,
    deleteMode: Boolean
  },
  data() {
    return {
      showUploadProgress: true,
      isUploadError: false
    }
  },
  computed: {
    isAssetUploaded() {
      return this.designFile.isAssetUploaded;
    },
    isChecked() {
      return this.selected.includes(this.designFile.designId);
    },
    isDisabledVisible() {
      return this.designFile.tilingProgress 
          || this.designFile.isAssetUploaded ===constants.assetUploadStatus.PENDING 
          || this.designFile.isAssetUploaded ===constants.assetUploadStatus.ERROR
    }
  },
  watch: {
    // アップロードステータスがPENDINGになれば再度ポーリング開始
    isAssetUploaded(newValue) {
      if (newValue === constants.assetUploadStatus.PENDING) {
        this.showUploadProgress = true;
        this.isUploadError = false;
      }
    },
  },
  methods: {
    // 可視状態の切り替え
    changeVisibleState(designFile) {
      if(this.designFile.isVisible) {
        cesiumCommon.designVisibilityOffNormal(designFile);
      } else {
        cesiumCommon.designVisibilityOnNormal(designFile);
      }
    },
    changeDeleteState(){
      if (this.isChecked) this.$emit('delete-checked', this.designFile.designId)
      else this.$emit('add-checked', this.designFile.designId);
    },
    //設計の虫眼鏡を作成
    onMagnifyDesign(designFile) {
      cesiumCommon.designVisibilityOn(designFile);
    },
    /**
     * 設計ファイルを削除する
     */
    async deleteDesignFile() {
      let successMessage = i18n.tc("SIW0020302001");
      let errorMessage = i18n.tc("SEW0020302001");

      const body = {
        deleted_by: this.$store.getters.loginData.user_id
      }
      try {
        this.$store.dispatch("updateIsLoading", true);
        const res = await designDataRepository.deleteByDesignId(this.designFile.designId, body);
        if (res.status !== 204) {
          // 想定外のステータスコードが返却された場合エラーをスロー
          throw new Error(errorMessage);
        }
        await this.$store.dispatch('designFile/fetchDesignFileList', this.$route.query.siteId)
        // エラーがなければ正常メッセージを出力する
        utils.showSnackBar('success', successMessage);
      } catch(e) {
        // エラーが発生したら処理終了
        console.error(e.message);
        if (utils.createErrorMessage(e)) {
          errorMessage = utils.createErrorMessage(e);
        }
        utils.showSnackBar('error', errorMessage);
      } finally {
        this.$store.dispatch("updateIsLoading", false);
      }
    },
  },
  beforeDestroy() {
    // ファイルが削除されたらCesium上から削除
    cesiumCommon.removeTileset(this.designFile);
  }
}
</script>

<style lang="sass" scoped>
.data-list-title
  font-size: 14px
  margin-bottom: 4px
  white-space: normal
  word-break: break-all

.data-list-title ::v-deep .v-text-field .v-input__control .v-input__slot
  min-height: auto

::v-deep .v-input__slot .v-input--selection-controls__input
  margin-right: 0
  height: auto

// チェックボックスホバー時の背景色の変化を無効化
::v-deep div.v-input--selection-controls__ripple
  display: none
</style>