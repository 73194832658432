<template>
  <v-app v-if="isAuth">
  <loading :parentLoading="$store.getters.isLoading" />
    <template v-if="$route.meta.useFrame !== false">
      <app-header />
      <v-main>
        <cesium />
        <chart-board></chart-board>
      </v-main>
    </template>
    <router-view />
    <processing-result-notification></processing-result-notification>
  </v-app>
</template>

<script>
import AppHeader from "./components/AppHeader.vue";
import store from "@/store/index";
import Cesium from "@/components/Cesium.vue";
import { PointworksViewer } from "pointworks-viewer";
import Loading from '@/components/common/LoadingOverLay.vue';
import processingResultNotification from "@/components/common/ProcessingResultNotification.vue";
import ChartBoard from "@/components/Project/ChartBoard.vue";
import { getCookie } from "@/components/modules/getCookie";
import { repositoryFactory } from "@/repositories/repository-factory";
const userRepository = repositoryFactory.get('user');
import * as utils from '@/utils/utils';

export default {
  name: "App",
  data: () => ({
    isAuth: false,
  }),
  components: {
    Cesium,
    AppHeader,
    processingResultNotification,
    Loading,
    ChartBoard
  },
  created() {
    if (process.env.NODE_ENV === 'production') {
      const loginData = getCookie('LOGIN_DATA');
      if(loginData.user_id) {
        const userId = Number(utils.decrypt(loginData.user_id));
        userRepository.getUserByUserId(userId).then((res) => {
        this.$store.commit("setLoginData", { ...res.data, user_id: userId});
        this.$i18n.locale = res.data.language;
        })
      }else {
        window.location.replace("/login");
      }
    }
  },
  mounted() {
    window.onbeforeunload = function () {
      // clear cookie
      document.cookie = "LOGIN_DATA=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    };
    if (process.env.NODE_ENV === "production") {
      if (Object.keys(store.state.loginData).length) {
        this.isAuth = true;
      } else {
        this.isAuth = false;
      }
    } else {
      this.isAuth = true;
    }
    if (this.$route.meta.useFrame !== false) {
      this.$nextTick(() => {
        const container = "Cesium";
        const token = process.env.VUE_APP_CESIUM_ACCESS_TOKEN;
        const viewer = new PointworksViewer({cesiumView:{container, token}, crossSectionViewContainer: "crossSectionContainer"});
        window["viewer"] = viewer;
      });
    }
  },
};
</script>