<template>
  <div
    class="flatness-label"
    v-if="showLabel"
  >
    <div class="bar" :style="barStyle">
      <div class="unit-label">(m)</div>
      <div class="high section">
        <div class="height-label">{{ truncateDecimals(zoomedPointCloudFlatness.heights[0], 3) }}</div>
      </div>
      <div class="middle section">
        <div class="height-label">{{ truncateDecimals(zoomedPointCloudFlatness.heights[1], 3) }}</div>
      </div>
      <div class="low section">
        <div class="height-label">{{ truncateDecimals(zoomedPointCloudFlatness.heights[2], 3) }}</div>
      </div>
    </div>
</div>
</template>

<script>
export default {
  props: {
    size: String,
  },
  computed: {
    /**
     * 平坦度データから高さと色を取得し、CSSの変数に設定する
     */
    barStyle() {
      if(this.showLabel) {
        const maxHeight = this.zoomedPointCloudFlatness.heights[0]
        const middleHeight = this.zoomedPointCloudFlatness.heights[1]
        const lowHeight = this.zoomedPointCloudFlatness.heights[2]
        const style = {
          // カラーコードを設定
          "--high-color": this.zoomedPointCloudFlatness.colors[0],
          "--middle-color": this.zoomedPointCloudFlatness.colors[1],
          "--low-color": this.zoomedPointCloudFlatness.colors[2],
          // グラデーションする位置を設定
          "--high-color-stops": 100 + "%",
          "--middle-color-stops": middleHeight / maxHeight * 100 + "%",
          "--low-color-stops": lowHeight / maxHeight * 100 + "%",
          // 0とlowの間、lowとmiddleの間、middleとhightの間の高さを設定
          "--high-section-height": (maxHeight - middleHeight) / maxHeight * 100 + "%",
          "--middle-section-height": (middleHeight - lowHeight) / maxHeight * 100 + "%",
          "--low-section-height": lowHeight / maxHeight * 100 + "%"
        }
        return style;
      } else {
        return {}
      }
    },
    /**
     * ズームされている点群と紐づく可視状態の平坦度データを返す
     */
    zoomedPointCloudFlatness() {
      const zoomedPointCloud = this.$store.getters.zoomedPointCloud;
      if (!zoomedPointCloud) {
        return {};
      }
      else {
        const zoomedPointCloudFlatness
         = this.$store.getters.visibleFlats
           .find(item => item.pointId === zoomedPointCloud.pointId);
        return zoomedPointCloudFlatness;
      }
    },
    /**
     * 可視状態の平坦度の中にズームされている点群と紐づくものがあれば表示
     */
    showLabel() {
      if (!this.zoomedPointCloudFlatness) {
        return false;
      }
      else {
        return !!Object.keys(this.zoomedPointCloudFlatness).length; 
      }
    },
   },
   methods: {
    /**
     * 小数点以下の指定した桁数nを超える部分を切り捨てる
     * @param {*} num フォーマット対象の数字
     * @param {*} n 小数点以下の桁数
     */
    truncateDecimals(num, n) {
      const numStr = num.toString();
      const regex = /^[+-]?\d+(\.\d+)?$/;
      // フォーマット対象が数値形式かチェック
      if (regex.test(numStr)) 
      {
        // 小数点以下の桁数がnより多い場合は余分な桁数を切り捨てる
        if (numStr.indexOf('.') !== -1 && numStr.split('.')[1].length > n) {
          return parseFloat(numStr).toFixed(n);
        } 
        // 小数点なし or 小数点以下の桁数がn以下の数値は形式だけ変換する
        return parseFloat(numStr);
      }
      // 数値でない場合はそのまま返す
      return num;
    },
   }
};
</script>

<style lang="sass" scoped>
  // コンポーネント全体のスタイル
  .flatness-label
    position: relative
    width: 30px
    height: 500px
    display: flex
    flex-direction: row
    align-items: flex-end
    gap: 2px
  
  // バー全体のスタイル
  .bar
    display: flex
    flex-direction: column
    height: 100%
    flex-grow: 1
    background-image: linear-gradient(to top, var(--low-color) var(--low-color-stops), var(--middle-color) var(--middle-color-stops), var(--high-color) var(--high-color-stops))
   
  // 単位を表示するラベル
  .unit-label
    position: absolute
    text-align: start
    top: -25px
    right: -20px
  
  // 高さの数値を表示するラベル
  .height-label
    font-size: 14px
    width: 55px
    box-sizing: border-box
    position: absolute
    top: -9px
    right: -80px
    text-align: start
    word-wrap: break-word

  // 各セクション（low-middle-high）共通のスタイル
  .section
    position: relative
  
  .section::after
    content: ""
    width: 20px
    position: absolute
    right: -20px
    opacity: 0.9

  // 各セクション（low-middle-high）固有のスタイル
  .high
    flex-basis: var(--high-section-height)
  
  .middle
    flex-basis: var(--middle-section-height)
  
  .low 
    flex-basis: var(--low-section-height)
  
  .high::after
    border-top: solid 2px var(--high-color)
  
  .middle::after
    border-top: solid 2px var(--middle-color)
  
  .low::after
    border-top: solid 2px var(--low-color)
  
</style>