import Repository from "./repository";

const resource = "/point";
export default {
  // 点群アップロード
  create(payload) {
    return Repository.post(`${resource}`, payload);
  },
  // 点群リスト返却
  findBySiteId(siteId, payload) {
    return Repository.post(`${resource}/${siteId}`, payload);
  },
  // 点群更新
  updateByPointId(pointId, payload) {
    return Repository.put(`${resource}/${pointId}`, payload);
  },
  // 点群削除
  deletePointByPointId(pointId, payload) {
    return Repository.delete(`${resource}/${pointId}`, { data: payload });
  },
  // 点群複数削除
  deletePointsByPointIds(payload) {
    return Repository.post(`${resource}/delete_all`, payload);
  },
  // 点群編集処理中止
  cancelByPointId(pointId, payload) {
    return Repository.put(`${resource}/cancel/${pointId}`, payload);
  },
  // 点群編集処理監視
  monitoringByPointId(payload) {
    return Repository.get(`${resource}/watch`, { params: payload });
  }
}