<!-- 間引き格子面 -->
<template>
  <v-card class="thinning-lattice-dialog px-4 pt-2 pb-4">
    <loading :parentLoading="isLoading" />
    <v-card-title class="pa-0 justify-space-between">
      <label>
        {{$t("CQW0020112001")}}
      </label>
      <close-button @click="$emit('close')" size="20px"></close-button>
    </v-card-title>
    <v-card-text class="px-0 pt-1 pb-0 white--text">
      <v-container class="pa-0">
        <v-row class="ma-0">
          <validation-observer class="d-block" style="width: 100%" ref="observer" v-slot="{ invalid, pristine }">
            <v-col cols="12" class="my-3 pa-0">
              <editing-point-clouds
                :title="$t('CIW0020000008')">
              </editing-point-clouds>
            </v-col>
            <!-- 格子サイズ -->
            <v-col cols="12" class="mt-3 pa-0">
              <validation-provider :rules="{
                required: true,
                'number-format': true,
                'decimal-size-limit': 2,
                'min-value': 0.01,
                'max-value': 100.99,
              }" v-slot="{ errors }">
                <div class="mb-1">
                  <label class="d-inline-block mr-2">{{$t("CQW0020112003")}}</label>
                  <span class="d-inline-block" style="font-size: 12px; color: #ff5252">{{ errors[0] }}</span>
                </div>
                <v-text-field
                  @reset.prevent="resetError()"
                  v-model="latticeSize"
                  :error-messages="errors"
                  class="text-black ma-0"
                  height="32px"
                  background-color="#BFBFBF"
                  outlined
                  solo
                  suffix="m"
                  >
                </v-text-field>
              </validation-provider>
            </v-col>
            <!-- 格子内残点数 -->
            <v-col cols="12" class="mt-3 pa-0">
              <validation-provider :rules="{
                required: true,
                'number-format': true,
                'min-value': 1,
                'max-value': 100,
              }" v-slot="{ errors }">
                <div class="mb-1">
                  <label class="d-inline-block mr-2">{{$t("CQW0020112004")}}</label>
                  <span class="d-inline-block" style="font-size: 12px; color: #ff5252">{{ errors[0] }}</span>
                </div>
                <v-text-field
                  @reset.prevent="resetError()"
                  @keydown.190.prevent
                  @keydown.110.prevent
                  v-model="remainingPointsInLattice"
                  :error-messages="errors"
                  class="text-black ma-0"
                  height="32px"
                  background-color="#BFBFBF"
                  outlined
                  solo
                  >
                </v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" class="my-3 pa-0">
              <label class="mb-1 d-block">{{$t("CQW0020112005")}}</label>
              <v-select
                v-model="selectedType"
                class="text-black"
                height="32px"
                background-color="#BFBFBF"
                :items="typeList"
                item-text="text"
                item-value="value"
                label="selectedType"
                hide-details
                solo>
              </v-select>
            </v-col>
            <!-- 2D/3D -->
            <v-col cols="12" class="mt-1 mb-3 pa-0">
              <v-radio-group
                v-model="dimensionRadioGroup"
                class="justify-space-between ma-0 mb-1 pa-0"
                row
                hide-details=""
               >
                <v-radio
                  class="ma-0 radio-button"
                  color="#f0f0f0"
                  label="2D"
                  value="1"
                  on-icon="fa-solid fa-circle-dot"
                  off-icon="fa-solid fa-circle"
                  :ripple="false"
                ></v-radio>
                <v-radio
                  class="ma-0 radio-button"
                  color="#f0f0f0"
                  label="3D"
                  value="2"
                  on-icon="fa-solid fa-circle-dot"
                  off-icon="fa-solid fa-circle"
                  :ripple="false"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" class="mt-4 pa-0 d-flex justify-space-between">
              <v-btn
                class="rounded-lg"
                color="#ff5050"
                width="115px"
                height="45px"
                depressed
                @click="$emit('close')">
                {{$t("CQW0010600005")}}
              </v-btn>
              <v-btn
                class="rounded-lg"
                color="#4472c4"
                width="115px"
                height="45px"
                depressed
                :disabled="pristine || invalid || !isExecutable"
                @click="execute(1)">
                {{$t("CQW0020109005")}}<br>{{$t("CQW0020110006")}}
              </v-btn>
              <v-btn
                class="rounded-lg"
                color="#4472c4"
                width="115px"
                height="45px"
                depressed
                :disabled="pristine || invalid || !isExecutable"
                @click="execute(2)">
                {{$t("CQW0020110007")}}<br>{{$t("CIW0020000005")}}
              </v-btn>
            </v-col>
          </validation-observer>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import CloseButton from "@/components/common/CloseButton.vue";
import EditingPointClouds from "./EditingPointClouds.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Loading from '../../common/LoadingOverLay.vue';
import * as utils from '@/utils/utils';
import { repositoryFactory } from "@/repositories/repository-factory";
import { i18n } from "@/i18n.js";
const thinningRepository = repositoryFactory.get('thinning');

export default {
  components: {
    CloseButton,
    EditingPointClouds,
    ValidationProvider,
    ValidationObserver,
    Loading
  },
  data() {
    return {
      isLoading: false,
      uploadFileName: "",
      latticeSize: "",
      remainingPointsInLattice: "",
      selectedType: 1,
      dimensionRadioGroup: "1",
      typeList: [
        {text: this.$t('CQW0020112006'), value: 1},
        {text: this.$t('CQW0020112007'), value: 3},
        {text: this.$t('CQW0020112008'), value: 5},
      ],
    }
  },
  computed: {
    isExecutable() {
      return this.editingPointClouds.length
    },
    editingPointClouds() {
      return this.$store.getters.editingPointClouds;
    },
  },
  methods: {
    resetForm() {
      this.latticeSize = "" 
      this.remainingPointsInLattice = ""; 
      this.selectedType = 1;
      this.dimensionRadioGroup = "1";
      this.resetError();
    },
    async resetError() {
      await this.$refs.observer.validate();
      await this.$refs.observer.reset();
    },
    async execute(executionMethod) {
      this.isLoading = true;
      const body = { 
        lattice_size: this.latticeSize,
        lattice_remaining_points: this.remainingPointsInLattice,
        type: this.selectedType,
        execution_method: executionMethod,
        dimension: this.dimensionRadioGroup,
        user_id: this.$store.getters.loginData.user_id
      };
      await this.putRequest(body);

      this.isLoading = false;
      this.$emit('close');
    },
    async putRequest(body) {
      let successMessage = i18n.tc("CIW0020112001");
      let errorMessage = i18n.tc("CEW0020112002");
      let status;
      try {
        for (const pointFile of this.editingPointClouds) {
          const res = await thinningRepository.createLatticeByPointId(pointFile.pointId, body);
          if (res.status === 201 || res.status === 204) {
            status = res.status;
          } else {
            // 想定外のステータスコードが返却された場合エラーをスロー
            throw new Error(errorMessage);
          }
        }
        // エラーがなければ正常メッセージを出力する
        utils.showSnackBar('success', successMessage);
        this.resetForm();
        return status;
      } catch (e) {
        console.error(e.message);
        if (utils.createErrorMessage(e)) {
          errorMessage = utils.createErrorMessage(e);
        }
        utils.showSnackBar('error', errorMessage);
        return "";
      } finally {
        // 上書きに成功した点群を不可視にする
        if (status === 204 || status === 201) {
          utils.allClearTileset();
          // データリストを更新
          utils.refreshDataList(this.$route.query.siteId);
        }
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.thinning-lattice-dialog
  .v-card__title
    font-size: 16px

::v-deep .v-input__append-outer
  margin: 0

::v-deep .v-input__slot
  margin: 0
  padding: 0

.text-black ::v-deep input,
.text-black ::v-deep label,
.text-black ::v-deep .v-select__selection,
.text-black ::v-deep .v-input__append-inner i
  color: black

::v-deep .v-text-field input,
::v-deep .v-text-field.v-input .v-select__selections input
  padding: 0

.text-field-error
  border: solid 2px #ff5252

::v-deep .v-text-field__details
  display: none

::v-deep .v-text-field__suffix
  color: black

::v-deep div.v-input.v-text-field div.v-input__prepend-outer
  margin: auto 8px auto 1px
  color: white

::v-deep button.v-btn span.v-btn__content
  font-size: 13px

.radio-button
  width: calc(100% / 2)
  
::v-deep div.v-radio
  div.v-input--selection-controls__input i.v-icon
    font-size: 14px
    color: #f0f0f0
  label.v-label
    font-size: 14px
    color: white
</style>