<!-- 平坦度計算結果 -->
<template>
  <div class="d-flex align-center data-list-title">
    <v-btn
      class="pa-0 mr-1"
      min-width="auto"
      plain
      height="auto"
      :disabled="isDisabledVisibleBtn"
      @click="changeIsVisible()"
    >
      <v-icon v-if="flatnessData.isVisible">mdi-eye-outline</v-icon>
      <v-icon v-else>mdi-eye-off-outline</v-icon>
    </v-btn>
    <!-- データ名 -->
    <flatness-data-name :flatnessData="flatnessData"></flatness-data-name>
    <!-- アイコン表示エリア -->
    <div class="pl-3 ml-auto d-flex align-center">
      <edit-processing-status
        class="ml-auto"
        @error-clear="clearProcessError"
        :message="flatnessData.message"
        :status="flatnessData.status">
      </edit-processing-status>
    </div>
    <v-menu 
      light
      offset-y 
      transition="slide-y-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-auto"
          icon
          v-bind="attrs"
          v-on="on"
          small
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item @click="showDialog = true">
          <v-list-item-icon class="mr-4">
            <v-icon>mdi-delete</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("CQW0020300019", 1, {name:$t("CQW0020126023")}) }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <!-- 削除確認ダイアログ -->
    <v-dialog
      hide-overlay
      persistent
      no-click-animation
      v-model="showDialog"
      width="330px"
      height="170px"
    >
      <calculation-data-delete-confirm-dialog
        @ok="deleteFlatnessData"
        @cancel="showDialog = false">
        <!-- ダイアログタイトル -->
        <template v-slot:title>
          <span>{{$t("CQW0020126017", 1, {name: $t('CQW0020126021')})}} </span>
        </template>
        <!-- ダイアログ本文テキスト -->
        <template v-slot:text>
          <span>{{$t("CQW0020126018", 1, {name: $t('CQW0020126022')})}}<br />{{$t("CQW0010600004")}}</span>
        </template>
      </calculation-data-delete-confirm-dialog>
    </v-dialog>
  </div>
</template>

<script>
import * as utils from '@/utils/utils';
import * as cesiumCommon from "@/utils/cesium-common";
import EditProcessingStatus from '@/components/common/EditProcessingStatus.vue';
import FlatnessDataName from './FlatnessDataName.vue';
import CalculationDataDeleteConfirmDialog from './CalculationDataDeleteConfirmDialog.vue';
import { i18n } from '../../../i18n.js';
import { repositoryFactory } from "@/repositories/repository-factory";
const messageRepository = repositoryFactory.get('message');
const flatRepository = repositoryFactory.get('flat');

export default {
  components: {
    EditProcessingStatus,
    FlatnessDataName,
    CalculationDataDeleteConfirmDialog
  },
  props: {
    flatnessData: Object,
    allowedToShowFlatnessResults: Boolean
  },
  data: () => ({
    showDialog: false,
  }),
  computed: {
    /**
     * 可視不可視アイコンの活性制御を行う
     * 以下の場合非活性
     *  非表示かつ
     *    平坦度データor選択範囲データorアセットIDが空の場合
     *    紐づく点群が不可視状態の場合
     */
    isDisabledVisibleBtn() {
      return !this.flatnessData.isVisible
          && (( !this.flatnessData.flatnessInfo 
            || !Object.keys(this.flatnessData.flatnessInfo).length)
            || !this.flatnessData.selectInfo
            || !this.flatnessData.assetId
            || !this.allowedToShowFlatnessResults);
    },
    visibleFlats() {
      return this.$store.getters.visibleFlats;
    }
  },
  watch: {
    /**
     * 親点群が非表示になったら平坦度も非表示にする
     */
    allowedToShowFlatnessResults(newValue) {
      if (!newValue && this.flatnessData.isVisible) {
        cesiumCommon.changeFlatnessDataVisible(this.flatnessData);
      }
    }
  },
  methods: {
    /**
     * 編集処理エラーを解除する
     * 解除に失敗した場合は通知を表示する
     */
    async clearProcessError() {
      let successMessage = i18n.tc("CIW0020300018")
      let errorMessage = i18n.tc("CEW0020300019")
      const body = {
        user_id: this.$store.getters.loginData.user_id
      };
      try {
        this.$store.dispatch("updateIsLoading", true);
        const res = await messageRepository.updateFlatMessageById(this.flatnessData.flatnessId, body);
        if (res.status === 204) {
          // データリストを最新化
          await this.$store.dispatch("getPointDataList", this.$route.query.siteId),
          utils.showSnackBar('success', successMessage);
        } else {
          // 想定外のステータスコードが返却された場合エラーをスロー
          throw new Error(errorMessage);
        }
      } catch (e) {
        console.error(e.message);
        if (utils.createErrorMessage(e)) {
          errorMessage = utils.createErrorMessage(e);
        }
        utils.showSnackBar('error', errorMessage);
      } finally {
        this.$store.dispatch("updateIsLoading", false);
      }
    },
    changeIsVisible() {
      try {
        if (!this.flatnessData.isVisible) {
          // 同じ点群と紐づく平坦度データが表示中であれば非表示にする
          const sameParentVisibleFlats
           = this.visibleFlats.filter( 
              flat => flat.pointId === this.flatnessData.pointId
            );
          for (const flat of sameParentVisibleFlats) {
            cesiumCommon.changeFlatnessDataVisible(flat);
          }
        }
        cesiumCommon.changeFlatnessDataVisible(this.flatnessData);
      } catch (e) {
        console.error(e);
      }
    },
    async deleteFlatnessData() {
      let successMessage = i18n.tc("CIW0020300022")
      let errorMessage = i18n.tc("CEW0020300023")
      const body = {
        flat_id: this.flatnessData.flatnessId,
        user_id: this.$store.getters.loginData.user_id
      };
      try {
        // ローディング開始
        this.$store.dispatch("updateIsLoading", true);
        const res = await flatRepository.deleteFlatDataByFlatId(this.flatnessData.flatnessId, body);
        if (res.status === 204) {
          // データリストを最新化
          await this.$store.dispatch("getPointDataList", this.$route.query.siteId)
          utils.showSnackBar('success', successMessage);
          this.showDialog = false;
        } else {
          // 想定外のステータスコードが返却された場合エラーをスロー
          throw new Error(errorMessage);
        }
      } catch (e) {
        console.error(e.message);
        if (utils.createErrorMessage(e)) {
          errorMessage = utils.createErrorMessage(e);
        }
        utils.showSnackBar('error', errorMessage);
      } finally {
        // ローディング終了
        this.$store.dispatch("updateIsLoading", false);
      }
    }
  },
  beforeDestroy() {
    if (this.flatnessData.isVisible) {
      // データが削除されたらCesium上から削除
      this.changeIsVisible();
    }
  }
}
</script>

<style lang="sass" scoped>
.data-list-title
  font-size: 14px
  margin-top: 4px
  white-space: normal
  word-break: break-all

.data-list-title ::v-deep .v-text-field .v-input__control .v-input__slot
  min-height: auto

::v-deep .v-input__slot .v-input--selection-controls__input
  margin-right: 0
  height: auto

.data-list-title-name .on-hover
  cursor: pointer
  background-color: rgba(245,245,245,0.2)

// チェックボックスホバー時の背景色の変化を無効化
::v-deep div.v-input--selection-controls__ripple
  display: none

</style>