import Repository from "./repository";

const resource = "/design-data";
export default {
  findBySiteId(siteId, payload) {
    return Repository.post(`${resource}/${siteId}`, payload);
  },
  create(payload) {
    return Repository.post(`${resource}`, payload);
  },
  updateByDesignId(pointId, payload) {
    return Repository.put(`${resource}/${pointId}`, payload);
  },
  deleteByDesignId(pointId, payload) {
    return Repository.delete(`${resource}/${pointId}`, { data: payload });
  }
};
