<template>
  <v-card class="design-upload-dialog px-4 py-2">
    <loading :parentLoading="isLoading" />
    <v-card-title class="pa-0 justify-space-between">
      {{ item.text }}
      <close-button @click="$emit('close')" size="20px"></close-button>
    </v-card-title>
    <v-card-text class="px-0 py-1">
      <v-container class="pa-0">
        <v-row class="ma-0">
          <validation-observer
            class="d-block"
            style="width: 100%"
            ref="observer"
            v-slot="{ invalid }"
          >
            <!-- 設計データをアップロード -->
            <v-col cols="12" class="my-3 pa-0">
              <label class="mb-1 d-block">{{$t("CQW0020106001")}}</label>
              <div
                class="pa-2 drop-area"
                :class="{ enter: isEnter }"
                @dragenter="dragEnter"
                @dragleave="dragLeave"
                @dragover.prevent
                @drop.prevent="dropFile"
              >
                <div class="d-inline-block">
                  <v-btn
                    class="rounded-pill"
                    color="rgba(127, 127, 127, 0.8)"
                    width="140px"
                    height="35px"
                    depressed
                    :loading="isSelecting"
                    @click="onButtonClick"
                  >
                  {{$t("CQW0020104003")}}
                  </v-btn>
                  <input
                    ref="uploader"
                    class="d-none"
                    type="file"
                    :accept="acceptedExtensions"
                    @change="onFileChanged"
                  />
                </div>
                <div class="d-inline-block ml-2">
                  <v-icon
                    class="d-inline-block mr-2"
                    color="rgba(151,143,143,0.8)"
                    >mdi-download</v-icon
                  >
                  <span
                    class="d-inline-block"
                    style="color: rgba(151, 143, 143, 0.8)"
                    >{{$t("CQW0020104004")}}</span
                  >
                </div>
              </div>
              <div
                v-if="selectedFile"
                class="pt-2 d-flex justify-space-between"
                style="width: 95%"
              >
                <span>{{ selectedFile.name }}</span>
                <close-button @click="resetForm" size="20px"></close-button>
              </div>
            </v-col>
            <!-- データ名 -->
            <v-col cols="12" class="my-3 pa-0">
              <validation-provider
                :rules="{
                  required: true,
                  'file-enable-characters': true,
                  'file-required': selectedFile,
                  'data-name-length': 100
                }"
                v-slot="{ errors }"
              >
                <div class="mb-1">
                  <label class="d-inline-block mr-2">{{$t("CQW0020104006")}}</label>
                  <span
                    class="d-inline-block"
                    style="font-size: 12px; color: #ff5252"
                    >{{ errors[0] }}</span
                  >
                </div>
                <v-text-field
                  @reset.prevent="resetError()"
                  v-model.trim="designName"
                  :error-messages="errors"
                  class="text-black ma-0"
                  height="32px"
                  background-color="#BFBFBF"
                  outlined
                  solo
                ></v-text-field>
              </validation-provider>
            </v-col>

            <!-- アップロードボタン -->
            <v-col cols="4" class="mt-4 pa-0">
              <v-btn
                class="rounded-pill"
                color="primary"
                width="135px"
                height="35px"
                depressed
                :disabled="invalid"
                @click="fileUpload"
              >
              {{$t("CIW0020000007")}}
              </v-btn>
            </v-col>
          </validation-observer>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import CloseButton from "@/components/common/CloseButton.vue";
import * as constants from '@/constants/constants';
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { regex } from "vee-validate/dist/rules";
import * as utils from '@/utils/utils';
import Loading from '@/components/common/LoadingOverLay.vue';
import { repositoryFactory } from "@/repositories/repository-factory";
import { i18n } from '@/i18n.js'
const designDataRepository = repositoryFactory.get('designData');

const enableExtensionPattern 
  = new RegExp(`.(${constants.designEnableExtensions.join('|')})$`, 'i');

export default {
  name: "DesignUploadDialog",
  components: {
    CloseButton,
    ValidationProvider,
    ValidationObserver,
    Loading
  },
  props: {
    item: Object,
  },
  data() {
    return {
      isLoading: false,
      selectedFile: null,
      designName: "",
      ddCounter: 0,
      isSelecting: false,
      systemDate: null, 
      enableExtensions: constants.designEnableExtensions
    }
  },
  computed: {
    isEnter() {
      return this.ddCounter > 0;
    },
    isUpdatedFromDashboard() {
      return this.item.value === 2;
    },
    acceptedExtensions() {
      return this.enableExtensions.map(ext => `.${ext}`).join(',');
    }
  },
  methods: {
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.uploader.click();
    },
    async onFileChanged(event) {
      if (this.checkFileExtension(event.target.files[0].name)) {
        this.resetForm();
        this.selectedFile = event.target.files[0];
        this.designName = this.selectedFile.name;
      }
      event.target.value = "";
    },
    dragEnter() {
      this.ddCounter++;
    },
    dragLeave() {
      this.ddCounter--;
    },
    dropFile(event) {
      this.ddCounter = 0;
      if (
        event.dataTransfer.files.length === 1 &&
        this.checkFileExtension(event.dataTransfer.files[0].name)
      ) {
        this.resetForm();
        this.selectedFile = event.dataTransfer.files[0];
        this.designName = this.selectedFile.name;
      }
    },
    checkFileExtension(fileName) {
      return regex.validate(fileName, { regex: enableExtensionPattern });
    },
    resetForm() {
      this.selectedFile = null;
      this.designName = "";
      this.resetError();
    },
    async resetError() {
      await this.$refs.observer.validate();
      await this.$refs.observer.reset();
    },
    async fileUpload() {
      this.isLoading = true
      const form = new FormData()
      const body = {
        design_name: this.designName,
        created_by: this.$store.getters.loginData.user_id,
        design_file: this.selectedFile,
        site_id: this.$route.query.siteId,
      };
      for (const field in body) {
        form.append(field, body[field])
      }
      await this.postRequest(form);
      this.isLoading = false;
      this.$emit('close');
    },
    async postRequest(form) {
      let successMessage = i18n.tc("CIW0020106005")
      let errorMessage = i18n.tc("CEW0020106005")
      try {
        const res = await designDataRepository.create(form);
        if (res.status === 201) {
          await this.$store.dispatch("designFile/fetchDesignFileList",
            this.$route.query.siteId
          )
          utils.showSnackBar('success', successMessage);
          this.resetForm();
        } else {
          utils.showSnackBar('error', errorMessage);
        }
      } catch (e) {
        console.error(e.message);
        if (utils.createErrorMessage(e)) {
          errorMessage = utils.createErrorMessage(e);
        }
        utils.showSnackBar('error', errorMessage);
      }
    }
  }
};
</script>

<style lang="sass" scoped>
.design-upload-dialog
  .v-card__title
    font-size: 16px

.point-upload-dialog
  p,span
    color: white

::v-deep .v-input__append-outer
  margin: 0

::v-deep .v-input__slot
  margin: 0
  padding: 0

.text-black ::v-deep input,
.text-black ::v-deep label,
.text-black ::v-deep .v-select__selection,
.text-black ::v-deep .v-input__append-inner i
  color: black

::v-deep .v-text-field input,
::v-deep .v-text-field.v-input .v-select__selections input
  padding: 0

.drop-area
  border: 2px dashed white

.enter
  border: 2px dashed #2196F3

.text-field-error
  border: solid 2px #ff5252

::v-deep .v-text-field__details
  display: none
</style>