function trim(value) {
    return value.replace(/^\s*|\s*$/g, '');
}

export function getCookie(key) {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
        const keyValue = cookie.split('=');

        if (trim(keyValue[0]) == key) {
          return JSON.parse(
            decodeURIComponent(trim(keyValue[1])).replace("j:", "")
          );
        }
    }
    return {};
}