<template>
  <v-card class="point-upload-dialog px-4 py-2">
    <v-card-title class="pa-0 justify-space-between">
      {{$t("CIW0020104002")}}
      <close-button @click="close" size="20px"></close-button>
    </v-card-title>
    <v-card-text class="px-0 py-1">
      <v-container class="pa-0">
        <v-row class="ma-0">
          <validation-observer class="d-block" style="width: 100%" ref="observer" v-slot="{ invalid }">
            <v-col cols="12" class="my-3 pa-0">
              <label class="mb-1 d-block">{{$t("CQW0020105002")}}</label>
              <div class="pa-2 drop-area" :class="{ enter: isEnter }" @dragenter="dragEnter" @dragleave="dragLeave"
                @dragover.prevent @drop.prevent="dropFile">
                <div class="d-inline-block">
                  <v-btn class="rounded-pill" color="rgba(127, 127, 127, 0.8)" width="140px" height="35px" depressed
                    :loading="isSelecting" @click="onButtonClick">
                    {{$t("CQW0020104003")}}
                  </v-btn>
                    <input
                    ref="uploader"
                    class="d-none"
                    type="file"
                    :accept="acceptedExtensions"
                    @change="onFileChanged" />
                </div>
                <div class="d-inline-block ml-2">
                  <v-icon class="d-inline-block mr-2" color="rgba(151,143,143,0.8)">mdi-download</v-icon>
                  <span class="d-inline-block" style="color: rgba(151, 143, 143, 0.8)">{{$t("CQW0020104004")}}</span>
                </div>
              </div>
              <div v-if="selectedFile" class="pt-2 d-flex justify-space-between" style="width: 95%">
                <span>{{ selectedFile.name }}</span>
                <close-button @click="resetForm" size="20px"></close-button>
              </div>
            </v-col>
            <v-col cols="12" class="my-3 pa-0">
              <validation-provider :rules="{
                required: true,
                'file-enable-characters': true,
                'file-required': selectedFile,
                'data-name-length': 100
              }" v-slot="{ errors }">
                <div class="mb-1">
                  <label class="d-inline-block mr-2">{{$t("CQW0020104006")}}</label>
                  <span class="d-inline-block" style="font-size: 12px; color: #ff5252">{{ errors[0] }}</span>
                </div>
                <v-text-field @reset.prevent="resetError()" v-model.trim="uploadFileName" :error-messages="errors"
                  class="text-black ma-0" height="32px" background-color="#BFBFBF" outlined solo></v-text-field>
              </validation-provider>
            </v-col>
            <!-- 測点間隔 -->
            <v-col cols="12" class="mt-3 pa-0">
              <validation-provider :rules="{
                'number-format': true,
                'decimal-size-limit': 3,
                'min-value': 1,
                'max-value': 100.999,
              }" v-slot="{ errors }">
                <div class="mb-1">
                  <label class="d-inline-block mr-2">{{$t("CQW0020122003")}}</label>
                  <span class="d-inline-block" style="font-size: 12px; color: #ff5252">{{ errors[0] }}</span>
                </div>
                <v-text-field
                  @reset.prevent="resetError()"
                  v-model="stationInterval"
                  :error-messages="errors"
                  class="text-black ma-0"
                  height="32px"
                  background-color="#BFBFBF"
                  outlined
                  solo
                  suffix="m"
                >
                </v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="4" class="mt-4 pa-0">
              <v-btn
                class="rounded-pill"
                color="primary"
                width="135px"
                height="35px"
                depressed
                :disabled="invalid"
                @click="fileUpload"
              >
              {{$t("CIW0020000007")}}
              </v-btn>
            </v-col>
          </validation-observer>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import CloseButton from "@/components/common/CloseButton.vue";
import * as constants from '@/constants/constants';
import Dialog from "@/components/modules/dialog";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { regex } from "vee-validate/dist/rules";
import * as utils from '@/utils/utils';
import { i18n } from '../../../i18n.js';
import { repositoryFactory } from "@/repositories/repository-factory";
const linearFileRepository = repositoryFactory.get('linearFile');

const enableExtensionPattern 
  = new RegExp(`.(${constants.linearEnableExtensions.join('|')})$`, 'i');

export default {
  name: "LinearUploadDialog",
  components: {
    CloseButton,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    item: Object,
  },
  data: () => ({
    selectedFile: null,
    uploadFileName: "",
    ddCounter: 0,
    isSelecting: false,
    stationInterval: "",
    enableExtensions: constants.linearEnableExtensions
  }),
  computed: {
    isEnter: function () {
      return this.ddCounter > 0;
    },
    acceptedExtensions() {
      return this.enableExtensions.map(ext => `.${ext}`).join(',');
    }
  },
  methods: {
    close() {
      // ダイアログを再表示する
      const dialog = new Dialog("project");
      dialog.close();
    },
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.uploader.click();
    },
    async onFileChanged(event) {
      if (this.checkFileExtension(event.target.files[0].name)) {
        this.resetForm();
        this.selectedFile = event.target.files[0];
        this.uploadFileName = this.selectedFile.name;
      }
      event.target.value = "";
    },
    dragEnter() {
      this.ddCounter++;
    },
    dragLeave() {
      this.ddCounter--;
    },
    dropFile(event) {
      this.ddCounter = 0;
      if (
        event.dataTransfer.files.length === 1 &&
        this.checkFileExtension(event.dataTransfer.files[0].name)
      ) {
        this.resetForm();
        this.selectedFile = event.dataTransfer.files[0];
        this.uploadFileName = this.selectedFile.name;
      }
    },
    checkFileExtension(fileName) {
      return regex.validate(fileName, { regex: enableExtensionPattern });
    },
    resetForm() {
      this.selectedFile = null;
      this.uploadFileName = "";
      this.resetError();
    },
    async resetError() {
      await this.$refs.observer.validate();
      if (this.$refs.observer) {
        await this.$refs.observer.reset();
      }
    },
    /**
     * アップロードを実行指示する
     */
    async fileUpload() {
      const form = new FormData()
      const body = {
        linear_name: this.uploadFileName,
        site_id: this.$route.query.siteId,
        created_by: this.$store.getters.loginData.user_id,
        linear_file: this.selectedFile,
        station_interval: this.stationInterval ?? 0,
      };
      for (const field in body) {
        if (field === "linear_file") {
          form.append(field, body[field], this.uploadFileName)
        } else {
          form.append(field, body[field])
        }
      }
      this.request(form);
    },
    async request(form) {
      let successMessage = i18n.tc("CIW0020106005")
      let errorMessage = i18n.tc("CEW0020106005")

      this.$store.dispatch("updateIsLoading", true);
      try {
        const res = await linearFileRepository.createFromSima(form);
        if (res.status !== 201) {
          // 想定外のステータスコードが返却された場合エラーをスロー
          throw new Error(errorMessage);
        }
        // エラーがなければ正常メッセージを出力する
        utils.showSnackBar("success", successMessage);
        this.$store.dispatch('linearFile/fetchLinearFileList', this.$route.query.siteId)
        this.close();
        this.resetForm();
      } catch (error) {
        console.error(error.message);
        if(utils.createErrorMessage(error)){
          errorMessage = utils.createErrorMessage(error);
        }
        utils.showSnackBar('error', errorMessage);
      } finally {
        this.$store.dispatch("updateIsLoading", false);
      }
    },
  },
  mounted() {},
};
</script>

<style lang="sass" scoped>
.point-upload-dialog
  .v-card__title
    font-size: 16px

.point-upload-dialog
  p,span
    color: white

::v-deep .v-input__append-outer
  margin: 0

::v-deep .v-input__slot
  margin: 0
  padding: 0

.text-black ::v-deep input,
.text-black ::v-deep label,
.text-black ::v-deep .v-select__selection,
.text-black ::v-deep .v-input__append-inner i,
::v-deep .v-text-field__suffix
  color: black

::v-deep .v-text-field input,
::v-deep .v-text-field.v-input .v-select__selections input
  padding: 0

.drop-area
  border: 2px dashed white

.enter
  border: 2px dashed #2196F3

.text-field-error
  border: solid 2px #ff5252

::v-deep .v-text-field__details
  display: none
</style>