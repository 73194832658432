<!-- 点群着色 -->
<template>
  <v-card class="point-cloud-coloring-dialog px-4 pt-2 pb-4">
    <v-card-title class="pa-0 justify-space-between">
      <label>
        {{$t("CIW0020000033")}}
      </label>
      <close-button @click="$emit('close')" size="20px"></close-button>
    </v-card-title>
    <v-card-text class="px-0 pt-1 pb-0 white--text">
      <v-container class="pa-0">
        <v-row class="ma-0">
          <validation-observer
            class="d-block"
            style="width: 100%"
            ref="observer"
            v-slot="{ invalid, validated }">
            <v-col cols="12" class="my-3 pa-0">
            <editing-point-clouds
              :title="$t('CIW0020000008')">
            </editing-point-clouds>
            </v-col>
            <v-col cols="12" class="mt-3 pa-0">
              <validation-provider 
                :rules="{
                  required: true,
                  'file-required': selectedFile,
                 }" 
                ref="provider" v-slot="{ errors }">
                <div class="mb-1">
                  <label class="d-inline-block mr-2">{{$t("CQW0020121003")}}</label>
                  <span
                    class="d-inline-block"
                    style="font-size: 12px; color: #ff5252">
                    {{ errors[0] }}
                  </span>
                </div>
                <div
                  class="pa-2 drop-area"
                  :class="{ enter: isEnter }"
                  @dragenter="dragEnter"
                  @dragleave="dragLeave"
                  @dragover.prevent
                  @drop.prevent="dropFile">
                  <div class="d-inline-block">
                    <v-btn
                      class="rounded-pill"
                      color="rgba(127, 127, 127, 0.8)"
                      width="140px"
                      height="35px"
                      depressed
                      :loading="isSelecting"
                      @click="onButtonClick">
                      {{$t("CQW0020104003")}}
                    </v-btn>
                      <input
                        ref="uploader"
                        value="selectedFile"
                        class="d-none"
                        type="file"
                        accept=".tiff,.tif"
                        @change="onFileChanged" />
                  </div>
                  <div class="d-inline-block ml-2">
                    <v-icon
                      class="d-inline-block mr-2"
                      color="rgba(151,143,143,0.8)">
                      mdi-download
                    </v-icon>
                    <span
                      class="d-inline-block"
                      style="color: rgba(151, 143, 143, 0.8)">
                      {{$t("CQW0020104004")}}
                    </span>
                  </div>
                </div>
                <div
                  v-if="selectedFile"
                  class="pt-2 d-flex justify-space-between"
                  style="width: 95%">
                  <span>{{ selectedFile.name }}</span>
                  <close-button @click="remove" size="20px"></close-button>
                </div>
              </validation-provider>
            </v-col>
            <v-col cols="12" class="mt-4 pa-0 d-flex justify-space-between">
              <v-btn
                class="rounded-lg"
                color="#ff5050"
                width="115px"
                height="45px"
                depressed
                @click="$emit('close')">
                {{$t("CQW0010600005")}}
              </v-btn>
              <v-btn
                class="rounded-lg"
                color="#4472c4"
                width="115px"
                height="45px"
                depressed
                :disabled="!validated || invalid || !editingPointClouds.length"
                @click="execute(1)">
                {{$t("CQW0020109005")}}<br>{{$t("CQW0020110006")}}
              </v-btn>
              <v-btn
                class="rounded-lg"
                color="#4472c4"
                width="115px"
                height="45px"
                depressed
                :disabled="!validated || invalid || !editingPointClouds.length"
                @click="execute(2)">
                {{$t("CQW0020110007")}}<br>{{$t("CIW0020000005")}}
              </v-btn>
            </v-col>
          </validation-observer>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import CloseButton from "@/components/common/CloseButton.vue";
import * as utils from '@/utils/utils';
import EditingPointClouds from "../EditingPointClouds.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { regex } from "vee-validate/dist/rules";
import { repositoryFactory } from "@/repositories/repository-factory";
import { i18n } from '../../../../i18n.js'
const adjustmentRepository = repositoryFactory.get('adjustment');

const enableExtensionPattern = /(\.tiff|\.tif)$/i;

export default {
  components: {
    CloseButton,
    EditingPointClouds,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      selectedFile: null,
      uploadFileName: "",
      ddCounter: 0,
      isSelecting: false,
    }
  },
  computed: {
    isEnter() {
      return this.ddCounter > 0;
    },
    editingPointClouds() {
      return this.$store.getters.editingPointClouds;
    }
  },
  methods: {
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.uploader.click();
    },
    onFileChanged(event) {
      if ( this.checkFileExtension(event.target.files[0].name)
        && this.checkFileType(event.target.files[0].type) ) {
        this.resetForm();
        this.selectedFile = event.target.files[0];
        this.uploadFileName = this.selectedFile.name;
        this.fileValidate();
      }
      event.target.value = "";
    },
    dragEnter() {
      this.ddCounter++;
    },
    dragLeave() {
      this.ddCounter--;
    },
    async fileValidate(){
      await this.$refs.provider.validate(this.selectedFile);
      await this.$refs.provider.validate();
      await this.$refs.observer.validate();
    },
    dropFile(event) {
      this.ddCounter = 0;
      if (
        event.dataTransfer.files.length === 1 &&
        this.checkFileExtension(event.dataTransfer.files[0].name) &&
        this.checkFileType(event.dataTransfer.files[0].type)
      ) {
        this.resetForm();
        this.selectedFile = event.dataTransfer.files[0];
        this.uploadFileName = this.selectedFile.name;
        this.fileValidate();
      }
    },
    checkFileExtension(fileName) {
      return regex.validate(fileName, { regex: enableExtensionPattern });
    },
    checkFileType(fileType) {
      return fileType === 'image/tiff'
    },
    async remove() {
      this.selectedFile = null;
      this.uploadFileName = "";
      await this.$refs.provider.syncValue(this.selectedFile);
      await this.$refs.provider.validate();
      await this.$refs.observer.validate();
    },
    async resetForm() {
      this.selectedFile = null;
      this.uploadFileName = "";
    },
    async resetError() {
      await this.$refs.observer.validate();
      await this.$refs.observer.reset();
    },
    async execute(executionMethod) {
      const form = new FormData();
      form.append('execution_method', executionMethod);
      form.append('user_id', this.$store.getters.loginData.user_id);
      form.append('ortho_file', this.selectedFile, this.uploadFileName);

      await this.request(form);
      this.$emit('close')
    },
    async request(form) {
      let successMessage = i18n.tc("CIW0020121001");
      let errorMessage = i18n.tc("CEW0020121002");
      let status;

      try {
        // ローディングを表示
        this.$store.dispatch("updateIsLoading", true);
        // チェックされている点群の件数分リクエストを送信
        for (const pointFile of this.editingPointClouds.slice().reverse()) {
          const pointId = pointFile.pointId;
          const res = await adjustmentRepository.coloringByPointId(pointId, form);
          if (res.status === 201 || res.status === 204) {
            status = res.status;
          } else {
            // 想定外のステータスコードが返却された場合エラーをスロー
            throw new Error(errorMessage);
          }
        }
        // エラーがなければ正常メッセージを出力する
        utils.showSnackBar('success', successMessage);
      } catch (e) {
        // エラーが発生したら処理終了
        console.error(e.message);
        if (utils.createErrorMessage(e)) {
          errorMessage = utils.createErrorMessage(e);
        }
        utils.showSnackBar('error', errorMessage);
        return "";
      } finally {
        // 実行に成功した場合に全点群を不可視にする
        if (status === 204 || status === 201) {
          utils.allClearTileset();
          // データリストを更新
          utils.refreshDataList(this.$route.query.siteId);
        }
        // ローディングを非表示
        this.$store.dispatch("updateIsLoading", false);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.point-cloud-coloring-dialog
  .v-card__title
    font-size: 16px

.select-file-area
  border: 1px dashed white

::v-deep button.v-btn span.v-btn__content
  font-size: 13px

.drop-area
  border: 1px dashed white

.enter
  border: 1px dashed #2196F3
</style>