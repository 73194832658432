<!-- 計測データリスト -->
<template>
  <div>
    <!-- 計測データリスト -->
    <v-list class="ml-4 pa-0" style="font-size: 14px">
      <!-- 計算結果 -->
      <v-subheader class="pr-0 white--text align-center justify-space-between">
      {{$t("CQW0020300021")}}
      </v-subheader>
      <!-- 平坦度 -->
      <v-list-item
        v-for="flatnessData in flatnessDataList"
        :key="flatnessData.flatnessId"
        class="data-list ml-3 pr-0"
      >
        <v-list-item-title>
          <flatness-data
            :flatnessData="flatnessData"
            :allowedToShowFlatnessResults="allowedToShowFlatnessResults"
          ></flatness-data>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import FlatnessData from "./FlatnessData.vue";

export default {
  components: {
    FlatnessData
  },
  props: {
    flatnessDataList: Array,
    allowedToShowFlatnessResults: Boolean
  }
};
</script>

<style lang="sass" scoped>
.data-list
  height: auto
  min-height: auto
  display: block

.v-subheader
  height: 28px
</style>