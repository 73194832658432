<template>
  <v-app-bar app clipped-left class="project-tabbar" width="auto" height="144px" style="top: 48px">
    <div style="width: 100%">
      <v-tabs background-color="#31323A" v-model="tab" height="20px" hide-slider>
        <v-tab
          v-for="item in items"
          :key="item.tab"
          :disabled="tabItemDisabled(item.controlName)"
        >
          {{ item.tab }}
        </v-tab>
      </v-tabs>
      <v-divider color="white"></v-divider>
      <div class="ma-1">
        <v-tabs-items style="background-color: #31323a" v-model="tab">
          <v-tab-item v-for="item in items" :key="item.tab" transition="false">
            <div style="overflow: auto" class="d-flex align-center">
              <!-- ボタングループ -->
              <div class="button-group mr-1" v-for="group in item.groups" :key="group.groupName">
                <div>
                  <div style="background-color: transparent" class="d-flex align-center">
                    <!-- ボタン -->
                    <div class="mr-1" v-for="button in group.buttons" :key="button.title">
                      <div
                        v-if="button.type === 'button'"
                        class="d-flex align-center">
                        <!-- 編集モードかビューモードかに応じてボタンの活性状態を切り替える。ボタンに個別のdisabled条件が設定されている場合はその条件も考慮する -->
                        <v-btn
                          :disabled="!isEditable(button) || 
                            (isKeyExists(button, 'disabled') && button.disabled()) || 
                            buttonItemDisabled(button.controlName)"
                          @click="button.click()"
                          text
                          height="100%">
                          <div>
                            <div class="d-flex align-center justify-center" style="height: 56px">
                              <v-img v-if="button.iconPath" :src="button.iconPath" max-width="36"></v-img>
                              <v-icon v-else-if="button.icon" large style="background-color: transparent">{{ button.icon
                              }}</v-icon>
                            </div>
                            <div class="button-title">
                              {{ button.title }}
                            </div>
                          </div>
                        </v-btn>
                      </div>

                      <!-- メニューボタン -->
                      <div v-else-if="button.type === 'menu'" class="d-flex align-center">
                        <v-menu
                          transition="fade-transition"
                          offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              :disabled="!isEditable(button) || buttonItemDisabled(button.controlName)"
                              v-bind="{...attrs, ...button.attributes}"
                              v-on="on"
                              text
                              height="100%">
                              <div>
                                <div class="d-flex align-center justify-center" style="height: 56px">
                                  <v-img v-if="button.iconPath" :src="button.iconPath" max-width="36px"></v-img>
                                  <v-icon v-else-if="button.icon" large style="background-color: transparent">{{
                                      button.icon
                                  }}</v-icon>
                                </div>
                                <div class="button-title">
                                  {{ button.title }}
                                </div>
                              </div>
                            </v-btn>
                          </template>

                          <!-- メニューのリスト -->
                          <v-list 
                            v-bind="{...button.options.attributes}"
                            light class="pb-1"
                          >
                            <v-subheader class="font-weight-bold px-4 mb-1" style="
                                height: 20px;
                                font-size: 14px;
                                color: black;
                              ">
                              {{ button.options.itemsTitle }}
                            </v-subheader>
                            <v-list-item class="px-4 menu-list-item" v-for="(item, i) in filterItems(button.options.items)"
                              @click="button.click(button.options.items[i])" :key="i">
                              <v-list-item-icon v-if="item.icon" class="ma-0 my-2">
                                <v-icon color="black" left>{{
                                    item.icon
                                }}</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content class="py-2">
                                <v-list-item-title style="font-size: 14px; color: black">
                                {{ item.text }}
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>
                      <div v-else-if="button.type === 'select'">
                        <div class="pt-1 mr-1 d-flex align-center justify-center" style="height: 56px">
                          <v-select
                            :disabled="buttonItemDisabled(button.controlName)"
                            :class="button.class"
                            :items="button.options.items"
                            v-model="button.options.selectedValue"
                            hide-details
                            :label="button.options.currentItem"
                            solo
                            dense
                            background-color="black"
                            color="grey lighten-3"
                            style="font-size: 14px"
                            @change="fetchSelect(button)"
                          ></v-select>
                        </div>
                        <div class="button-title">
                          {{ button.title }}
                        </div>
                      </div>
                      <div v-else-if="button.type === 'switch'" class="switch-button">
                        <div class="pt-3 d-flex align-center" style="height: 56px; width: 60px">
                          <v-switch
                            class="mb-1"
                            v-model="button.options.switch"
                            hide-details
                            dense></v-switch>
                        </div>
                        <div class="button-title" >
                          {{ button.title }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- ボタングループラベル -->
                  <div class="button-group-label">
                    <label>
                      {{ group.groupName }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </v-tab-item>
          <project-dialog
            :isShow="dialog.isShow"
            :name="dialog.name"
            :item="dialog.item"
            :event="dialog.event"
            @close="closeDialog"
            @hide="hideDialog"
            @reshow="reShowDialog"
            >
          </project-dialog>
        </v-tabs-items>
      </div>
      <v-divider color="white"></v-divider>
    </div>
  </v-app-bar>
</template>

<script>
import ProjectDialog from './Dialogs/ProjectDialog'
import * as utils from '@/utils/utils';
import Dialog from '@/components/modules/dialog';
import * as cesiumCommon from '@/utils/cesium-common';
import * as config from '../../../config/config';
import * as constants from '@/constants/constants';
import { repositoryFactory } from "@/repositories/repository-factory";
import { i18n } from '../../i18n.js'
const pointEditRepository = repositoryFactory.get('pointEdit');
const clippingRepository = repositoryFactory.get('clipping');

const mode = constants.lockState;

export default {
  components: {
    ProjectDialog
  },
  data() {
    return {
      tab: null,
      isLoadingNoOverlay: false,
      items: [
        {
          tab: this.$t("CIW0020000001"),
          controlName: "file",
          groups: [
            {
              groupName: this.$t("CIW0020000002"),
              controlName: "operation",
              buttons: [
                {
                  type: "button",
                  active: [mode.edit],
                  title: this.$t("CIW0020000003"),
                  controlName: "go-back",
                  icon: "mdi-reply",
                  disabled: () =>  {
                    // 点群データリストのチェック数が1つでない場合、戻る操作実行中、の場合、タイリング中の場合は無効
                    if ( this.$store.getters.editingPointClouds.length !== 1 
                      || this.isLoadingNoOverlay
                      || this.$store.getters.editingPointClouds[0].tilingProgress
                    ) {
                      return true;
                    } else {
                      return false;
                    }
                  },
                  click: () => { 
                    this.goBack()
                  }
                },
                {
                  type: "button",
                  active: [mode.edit],
                  title: this.$t("CIW0020000004"),
                  controlName: "go-forward",
                  icon: "mdi-share",
                  disabled: () =>  {
                    // 点群データリストのチェック数が1つでない場合、進む操作実行中、の場合、タイリング中の場合は無効
                    if ( this.$store.getters.editingPointClouds.length !== 1 
                      || this.isLoadingNoOverlay
                      || this.$store.getters.editingPointClouds[0].tilingProgress
                    ) {
                      return true;
                    } else {
                      return false;
                    }
                  },
                  click: () => { 
                    this.goForward()
                  }
                },
                {
                  type: "button",
                  active: [mode.edit],
                  title: this.$t("CIW0020000005"),
                  controlName: "save",
                  icon: "mdi-content-save",
                  click: () => {
                    this.save();
                  },
                },
                {
                  type: "button",
                  controlName: "lock",
                  active: [mode.edit, mode.noEdit, mode.view],
                  title: "lock",
                  icon: "mdi-lock",
                },
              ],
            },
            {
              groupName: this.$t("CIW0020000007"),
              buttons: [
                {
                  type: "menu",
                  active: [mode.edit],
                  title: this.$t("CIW0020000008"),
                  controlName: "point-upload",
                  icon: "mdi-dots-grid",
                  click: (selectedItem) => {
                    if (selectedItem.value === 1) this.showDialog('local-point-upload-dialog', selectedItem)
                    else if (selectedItem.value === 2) this.showDialog('dashboard-point-upload-dialog', selectedItem)
                    else if (selectedItem.value === 3) this.showDialog('map-point-upload-dialog', selectedItem)
                  },
                  options: {
                    itemsTitle: this.$t('CIW0020104001'),
                    items: [
                      { 
                        text: this.$t("CIW0020104002"),
                        value: 1
                      },
                      // { 
                      //   text: this.$t("CIW0020106003"), 
                      //   value: 2
                      // },
                      // { 
                      //   text: this.$t("CIW0020104004"),
                      //   value: 3
                      // },
                    ],
                  },
                },
                {
                  type: "button",
                  active: [mode.edit],
                  title: this.$t("CIW0020000009"),
                  controlName: "linear-upload",
                  icon: "mdi-vector-line",
                  click: () => {
                    this.showDialog('linear-upload-dialog')
                  },
                },
                {
                  type: "menu",
                  active: [mode.edit],
                  title: this.$t("CIW0020000010"),
                  controlName: "design-upload",
                  icon: "mdi-layers-outline",
                  click: (selectedItem) => {
                    if (selectedItem.value === 1) this.showDialog('local-design-upload-dialog', selectedItem)
                  },
                  options: {
                    itemsTitle: this.$t("CIW0020106001"),
                    items: [
                      { 
                        text: this.$t("CIW0020104002"),
                        value: 1
                      },
                      //{ state: this.$t("CIW0020106003"), value: 2 }, スコープ外なので、外しています
                    ],
                  },
                },
              ],
            },
            {
              groupName: this.$t("CIW0020000011"),
              buttons: [
                {
                  type: "button",
                  active: [mode.edit, mode.noEdit, mode.view],
                  title: this.$t("CIW0020000012"),
                  controlName: "browser-download",
                  icon: "mdi-view-gallery-outline",
                  click:() => {
                    this.existsUnSavedData(
                      // 未保存データなしの場合、ブラウザダウンロードを開く
                      this.showDialog.bind(null, 'browser-download-dialog'),
                      // 未保存データありの場合、確認ダイアログを開く
                      this.showDialog.bind(null,'unsaved-confirm-dialog', 
                        { 
                          OKHandler: () => { // 確認ダイアログでOK押下時の処理
                            this.showDialog('browser-download-dialog') 
                          }
                        }
                      )
                    );
                  }
                },
                {
                  type: "button",
                  active: [mode.edit, mode.noEdit, mode.view],
                  title: this.$t("CIW0010600003"),
                  controlName: "dashboard-download",
                  iconPath: require("../../assets/SM-Dashboard.svg"),
                  click:() => {
                    this.existsUnSavedData(
                      // 未保存データなしの場合、ブラウザダウンロードを開く
                      this.showDialog.bind(null, 'dashboard-export-dialog'),
                      // 未保存データありの場合、確認ダイアログを開く
                      this.showDialog.bind(null,'unsaved-confirm-dialog', 
                        { 
                          OKHandler: () => { // 確認ダイアログでOK押下時の処理
                            this.showDialog('dashboard-export-dialog') 
                          }
                        }
                      )
                    );
                  }
                },
              ],
            },
          ],
        },
        {
          tab: this.$t("CIW0020000055"),
          controlName: "edit",
          groups: [
            {
              groupName: this.$t("CIW0020000014"),
              buttons: [
                {
                  // クリッピング（図形選択）
                  type: "menu",
                  active: [mode.edit],
                  title: this.$t("CIW0020000015"),
                  controlName: "clipping-shape-selection",
                  icon: "mdi-shape",
                  attributes:{ "data-testid": "clipping-shape-selection" },
                  click: (selectedItem) => {
                    this.selectClippingType(selectedItem);
                  },
                  options: {
                    itemsTitle: this.$t("CIW0020109001"),
                    attributes:{ "class": "out-of-cancellation-range" },
                    items: [
                      { 
                        icon: "mdi-pentagon-outline", 
                        text: this.$t("CIW0020109002"), 
                        value: constants.cesiumSelectingType.polygon
                      },
                      { 
                        icon: "mdi-square-outline", 
                        text: this.$t("CIW0020109003"), 
                        value: constants.cesiumSelectingType.box
                      },
                      { 
                        icon: "mdi-circle-outline", 
                        text: this.$t("CIW0020109004"), 
                        value: constants.cesiumSelectingType.circle
                      },
                    ],
                  },
                },
                {
                  type: "button",
                  active: [mode.edit],
                  title: this.$t("CIW0020000016"),
                  controlName: "clipping-clear",
                  icon: "mdi-close-thick",
                  disabled: () =>  {
                    // クリッピングされた点群ファイルが存在しない場合は非活性
                    const existsClippingFile = this.$store.getters.pointDataList.flatMap(
                      project => project.getPoints()
                    ).filter(
                      pointFile => pointFile.mode === 'clipping'
                    ).length;
                    return !existsClippingFile || this.$store.getters.beginSelecting;
                  },
                  click: () => {
                    this.deleteClipping()
                  },
                },
              ],
            },
            {
              groupName: this.$t("CIW0020000017"),
              buttons: [
                {
                  // 不要物除去（図形選択）
                  type: "menu",
                  active: [mode.edit],
                  title: this.$t("CIW0020000015"),
                  controlName: "unnecessary-removal-shape-selection",
                  icon: "mdi-shape",
                  click: (selectedItem) => {
                    this.selectUnnecessaryRemoveType(selectedItem);
                  },
                  options: {
                    itemsTitle: this.$t("CIW0020110001"),
                    attributes:{ "class": "out-of-cancellation-range" },
                    items: [
                      { 
                        icon: "mdi-pentagon-outline", 
                        text: this.$t("CIW0020109002"), 
                        value: constants.cesiumSelectingType.polygon
                      },
                      { 
                        icon: "mdi-square-outline", 
                        text: this.$t("CIW0020109003"), 
                        value: constants.cesiumSelectingType.box
                      },
                      { 
                        icon: "mdi-circle-outline", 
                        text: this.$t("CIW0020109004"), 
                        value: constants.cesiumSelectingType.circle
                      },
                    ],
                  },
                },
                {
                  type: "button",
                  active: [mode.edit],
                  title: this.$t("CIW0020000019"),
                  controlName: "unnecessary-removal-ai",
                  icon: "mdi-head-flash-outline",
                },
              ],
            },
            {
              groupName: this.$t("CIW0020000020"),
              buttons: [
                {
                  // 間引き地表面
                  type: "button",
                  active: [mode.edit],
                  title: this.$t("CIW0020000021"),
                  controlName: "thinning-lattice",
                  icon: "mdi-cube-outline",
                  click: () => {
                    this.showDialog('thinning-lattice-dialog')
                  },
                },
                {
                  type: "button",
                  active: [mode.edit],
                  title: this.$t("CIW0020000022"),
                  controlName: "thinning-ground-surface",
                  icon: "mdi-application-edit-outline",
                  click: () => {
                    this.showDialog('thinning-ground-surface-dialog')
                  },
                },
                {
                  type: "button",
                  active: [mode.edit],
                  title: this.$t("CIW0020000023"),
                  controlName: "thinning-design",
                  icon: "mdi-archive-edit-outline",
                  click: () => {
                    this.showDialog('thinning-design-dialog')
                  },
                },
              ],
            },
            {
              groupName: this.$t("CIW0020000024"),
              buttons: [
                {
                  // 穴補間
                  type: "menu",
                  active: [mode.edit],
                  title: this.$t("CIW0020000025"),
                  controlName: "hole-interpolation",
                  icon: "mdi-circle-box",
                  click: (selectedItem) => {
                    this.selectHoleInterpolationType(selectedItem);
                  },
                  options: {
                    itemsTitle: this.$t("CIW0020115001"),
                    attributes:{ "class": "out-of-cancellation-range" },
                    items: [
                      { 
                        icon: "mdi-pentagon-outline", 
                        text: this.$t("CIW0020109002"), 
                        value: constants.cesiumSelectingType.polygon,
                        isEnabled: config.isHoleInterpolationEnabled.polygon
                      },
                      { 
                        icon: "mdi-square-outline", 
                        text: this.$t("CIW0020109003"), 
                        value: constants.cesiumSelectingType.box,
                        isEnabled: config.isHoleInterpolationEnabled.box
                      },
                      { 
                        icon: "mdi-circle-outline", 
                        text: this.$t("CIW0020109004"), 
                        value: constants.cesiumSelectingType.circle,
                        isEnabled: config.isHoleInterpolationEnabled.circle
                      },
                    ],
                  },
                },
                {
                  type: "button",
                  active: [mode.edit],
                  title: this.$t("CIW0020000026"),
                  controlName: "grid-interpolation",
                  icon: "mdi-grid",
                  click: () => {
                    this.showDialog('grid-interpolation-dialog')
                  },
                },
              ],
            },
            {
              groupName: this.$t("CIW0020000027"),
              buttons: [
                {
                  type: "button",
                  active: [mode.edit],
                  title: this.$t("CIW0020000028"),
                  controlName: "point-synthetic",
                  icon: "mdi-dots-grid",
                  click: () => {
                    this.showDialog('point-synthetic-dialog')
                  },
                },
                {
                  type: "button",
                  active: [mode.edit],
                  title: this.$t("CIW0020000029"),
                  controlName: "landXML-create",
                  icon: "mdi-file-replace",
                  click: () => {
                    this.showDialog('landXML-create-dialog')
                  },
                },
                {
                  type: "button",
                  active: [mode.edit],
                  title: this.$t("CIW0020000030"),
                  controlName: "point-create",
                  icon: "mdi-file-move",
                  click: () => {
                    this.showDialog('point-create-dialog')
                  },
                },
                {
                  type: "button",
                  active: [mode.edit],
                  title: this.$t("CIW0020000072"),
                  controlName: "perimeter-line-create",
                  icon: "mdi-shape-square-rounded-plus",
                  click: () => {
                    this.showDialog('perimeter-line-create-dialog')
                  },
                }
              ],
            },
            {
              groupName: this.$t("CIW0020000031"),
              buttons: [
                {
                  type: "button",
                  active: [mode.edit],
                  title: this.$t("CIW0020000032"),
                  controlName: "off-set",
                  icon: "mdi-dots-grid",
                  click: () => {
                    this.showDialog('off-set-dialog')
                  },
                },
                {
                  type: "button",
                  active: [mode.edit],
                  title: this.$t("CIW0020000033"),
                  controlName: "point-cloud-coloring",
                  icon: "mdi-dots-grid",
                  click: () => {
                    this.showDialog('point-cloud-coloring-dialog')
                  },
                },
              ],
            },
            {
              groupName: this.$t("CIW0020000056"),
              buttons: [
                {
                  type: "button",
                  active: [mode.edit],
                  title: this.$t("CIW0020000057"),
                  controlName: "cancel-editing-point-cloud",
                  icon: "mdi-cancel",
                  click: () => {
                    this.showDialog('cancel-editing-point-cloud-dialog')
                  },
                }
              ],
            }
          ],
        },
        {
          tab: this.$t("CIW0020000034"),
          controlName: "cross-section",
          groups: [
            {
              groupName: this.$t("CIW0020000035"),
              buttons: [
                {
                  type: "button",
                  active: [mode.edit],
                  title: this.$t("CIW0020000036"),
                  controlName: "linear-drawing",
                  icon: "mdi-draw",
                  click: () => {
                    this.showDialog('linear-drawing-dialog')
                  },
                },
                {
                  type: "button",
                  active: [mode.edit],
                  title: this.$t("CIW0020000037"),
                  controlName: "linear-edit",
                  icon: "mdi-pencil",
                  click: () => {
                    this.showDialog('linear-edit-dialog')
                  },
                },
              ],
            },
            {
              //断面
              groupName: this.$t("CIW0020000034"),
              buttons: [
                {
                  //断面作成
                  type: "button",
                  active: [mode.edit],
                  title: this.$t("CIW0020000039"),
                  controlName: "cross-section-create",
                  icon: "mdi-waves",
                  click: () => {
                    this.showDialog('cross-section-create-dialog')
                  },
                },
                {
                  //断面編集
                  type: "menu",
                  active: [mode.edit],
                  title: this.$t("CIW0020000040"),
                  controlName: "cross-section-edit",
                  icon: "mdi-waves",
                  click: (selectedItem) => {
                    this.selectCrossSectionEdit(selectedItem);
                  },
                  options: {
                    itemsTitle: this.$t("CIW0020000040"),
                    attributes:{ "class": "out-of-cancellation-range" },
                    items: [
                      { 
                        text: this.$t("CIW0020125002"), 
                        value: constants.crossSectionViewerMode.move
                      },
                      { 
                        text: this.$t("CIW0020125003"), 
                        value: constants.crossSectionViewerMode.add
                      },
                      { 
                        text: this.$t("SIW0020301003"), 
                        value: constants.crossSectionViewerMode.delete
                      },
                    ],
                  },
                },
              ],
            },
          ],
        },
        {
          tab: this.$t("CIW0020000041"),
          controlName: "measurement-and-display",
          groups: [
            {
              //計測
              groupName: this.$t("CIW0020000042"),
              buttons: [
                {
                  //平坦度
                  type: "menu",
                  active: [mode.edit, mode.view, mode.noEdit],
                  title: this.$t("CIW0020000043"),
                  controlName: "measurement-flatness",
                  icon: "mdi-application-edit-outline",
                  click: (selectedItem) => {
                    if (selectedItem.value === 1) {
                      this.showDialog('flatness-check-dialog')
                    } else if (selectedItem.value === 2) {
                      const dialogHandlers = {
                        'close': this.closeCesiumDialog,
                        'cancel':this.cancelCesiumDialog
                      }
                      this.showCesiumDialog('flatness-condition-create-dialog', {}, dialogHandlers)
                    }
                  },
                  options: {
                    itemsTitle: this.$t('CIW0020000043') + ' ' + this.$t('CQW0020300001'),
                    items: [
                      { 
                        text: this.$t("CIW0020000043"),
                        value: 1
                      },
                      { 
                        text: this.$t("CQW0020126001"),
                        value: 2
                      },
                    ],
                  },
                },
                {
                  //距離
                  type: "button",
                  active: [mode.edit, mode.noEdit, mode.view],
                  title: this.$t("CIW0020000044"),
                  controlName: "measurement-distance",
                  icon: "mdi-ruler",
                  click: () => {
                    this.measurementDistance()
                  },
                },
                {
                  //点数
                  type: "menu",
                  active: [mode.edit, mode.noEdit, mode.view],
                  title: this.$t("CIW0020000045"),
                  controlName: "measurement-area-and-volume",
                  icon: "mdi-cube-outline",
                  click: (selectedItem) => {
                    this.selectNumberOfPointsType(selectedItem);
                  },
                  options: {
                    itemsTitle: this.$t("CIW0020002003"),
                    attributes:{ "class": "out-of-cancellation-range" },
                    items: [
                      { 
                        icon: "mdi-pentagon-outline", 
                        text: this.$t("CIW0020109002"), 
                        value: constants.cesiumSelectingType.polygon
                      },
                      { 
                        icon: "mdi-square-outline", 
                        text: this.$t("CIW0020109003"), 
                        value: constants.cesiumSelectingType.box
                      },
                      { 
                        icon: "mdi-circle-outline", 
                        text: this.$t("CIW0020109004"), 
                        value: constants.cesiumSelectingType.circle
                      },
                    ],
                  },
                },
              ],
            },
            {
              //表示
              groupName: this.$t("CIW0020000046"),
              buttons: [
                {
                  //投影方法
                  type: "select",
                  active: [mode.edit, mode.noEdit, mode.view],
                  title: this.$t("CIW0020000047"),
                  controlName: "projection-method",
                  icon: "mdi-arrow-u-right-top",
                  class: "select-button__projection-type",
                  options: {
                    selectedValue: 0,
                    items: [
                      { text: this.$t("CIW0020129001"), value: 0 },
                      { text: this.$t("CIW0020129002"), value: 1 },
                    ],
                  },
                },
                {
                  //点サイズ
                  type: "select",
                  active: [mode.edit, mode.noEdit, mode.view],
                  title: this.$t("CIW0020000048"),
                  controlName: "point-size",
                  icon: "mdi-arrow-u-left-top",
                  class: "select-button__point-size",
                  options: {
                    selectedValue: this.$store.getters.pointCloudPointSize,
                    items: [
                      { text: 1, value: 1 },
                      { text: 2, value: 2 },
                      { text: 3, value: 3 },
                      { text: 4, value: 4 },
                      { text: 5, value: 5 },
                    ],
                  },
                },
                {
                  //表示色
                  type: "select",
                  active: [mode.edit, mode.noEdit, mode.view],
                  title: this.$t("CIW0020000049"),
                  controlName: "display-color",
                  icon: "mdi-arrow-u-left-top",
                  class: "select-button__display-color",
                  options: {
                    selectedValue: this.$store.getters.pointCloudColoringType,
                    items: [
                      { text: this.$t("CIW0020130001"),
                        value: "Normal" 
                      },
                      { text: this.$t("CIW0020130002"),
                        value: "Elevation"
                      },
                    ],
                  },
                },
                {
                  //陰影
                  type: "select",
                  active: [mode.edit, mode.noEdit, mode.view],
                  title: this.$t("CIW0020000050"),
                  controlName: "shadow",
                  icon: "mdi-arrow-u-left-top",
                  class: "select-button__shadow",
                  options: {
                    selectedValue: this.$store.getters.pointCloudShadingType,
                    items: [
                      { text: this.$t("CQW0020125011"), value: 0 },
                      { text: this.$t("CQW0020125012"), value: 1 }, 
                      { text: this.$t("CQW0020125013"), value: 2 },
                    ],
                  },
                },
              ],
            },
          ],
        },
      ],
      lockButton: {
        lock: { 
          type: "button",
          active: [mode.edit, mode.noEdit, mode.view],
          icon:"mdi-lock", 
          title:this.$t("CIW0020133001"),
          click: () => {
            this.existsUnSavedData(
              // 未保存データなしの場合アンロック
              () => {
                this.$store.dispatch(
                  'lock/unLockState', 
                  {
                    siteId: this.$route.query.siteId,
                    loginData: this.$store.getters.loginData
                  }
                );
              },
              // 未保存データありの場合、確認ダイアログを開く
              this.showDialog.bind(null, 'unlock-confirm-dialog', {siteId: this.$route.query.siteId})
            );
          }
        },
        unLock:{
          type: "button",
          active: [mode.edit, mode.noEdit, mode.view],
          icon:"mdi-lock-open-variant", 
          controlName: "lock",
          title:this.$t("CIW0020133002"),
          click: () => {
            // ロックを取得
            this.$store.dispatch(
              'lock/lock', 
              { 
                siteId: this.$route.query.siteId, 
                loginData: this.$store.getters.loginData
              }
            );
          }
        },
        otherUserLock:{
          type: "button",
          active: [mode.edit, mode.noEdit, mode.view],
          icon:"mdi-account-lock", 
          title:this.$t("CIW0020133003"),
          controlName: "lock",
          click: () => {
            // 強制ロック解除ダイアログを表示
            this.showDialog('force-unlock-confirm-dialog', {siteId: this.$route.query.siteId})
          }
        },
      },
    };
  },
  computed: {
    currentMode() {
      return this.$store.getters['lock/currentMode'](this.$route.query.siteId);
    },
    getLockState() {
      return this.$store.getters['lock/getLockBySiteId'](this.$route.query.siteId);
    },
    dialog: {
      get() {
        return this.$store.getters['dialog/projectPageDialog']
      },
      set(value) {
        this.$store.dispatch('dialog/setProjectDialog', value);
      }
    },
    cesiumDialog: {
      get() {
        return this.$store.getters['dialog/cesiumDialog']
      },
      set(value) {
        this.$store.dispatch('dialog/setCesiumDialog', value);
      }
    }
  },
  watch: {
    currentMode() {
      this.setLockButton();
    }
  },
  methods: {
    fetchSelect(event) {
      const name = event.controlName;
      const selectedValue = event.options.selectedValue;
      if (name == "display-color") {
        this.$store.commit("setPointCloudColoringType", selectedValue);
        window['viewer'].setTilesetColor(selectedValue);
      } else if(name == "projection-method") {
        window['viewer'].switchProjectionMode(selectedValue);
      } else if(name == "point-size") {
        this.$store.commit("setPointCloudPointSize", selectedValue);
        window['viewer'].setTilesetPointSize(selectedValue);       
      } else if(name == "shadow") {
        this.$store.commit("setPointCloudShadingType", selectedValue);
        window['viewer'].setTilesetShading(selectedValue);
      }
    },
    /**
     * 有効な項目のみにフィルタリングして返却する
     * ・isEnabledキーが無い場合は有効とみなす
     * ・isEnabledがTrueの場合は有効
     * @param {*} items フィルタリング前の項目リスト
     */
    filterItems(items) {
      return items.filter(item => !("isEnabled" in item) || item.isEnabled);
    },
    /**
     * 未保存データの有無をチェックし、引数のコールバック関数を実行する
     * @param {*} next 未保存データが無い場合に実行される処理
     * @param {*} confirm 未保存データが有る場合に実行される処理
     */
    async existsUnSavedData(next, confirm) {
      try {
        await this.$store.dispatch("fetchUnSavedData", this.$route.query.siteId);
        // 未保存データの有無を確認
        if (!this.$store.getters.unSavedDataList.length) {
          next();
        } else {
          confirm();
        }
      } catch(e) {
        console.error(e);
        utils.showSnackBar("error", this.$t("CEW0020133006"));
      }
    },
    isEditable(button) {
      // 他ユーザーがロックを取得していない場合は編集可能
      return button.active.includes(this.currentMode);
    },
    // タブ共通の活性制御処理（リリース対象外の機能を非活性にするための関数）
    tabItemDisabled(tabName) {
      // disabledTabNameに指定されているタブは非活性にする
      if (config.inactiveFunctionSettings.enable) {
        if (config.inactiveFunctionSettings.disableTabName.includes(tabName)) {
          return true
        }
      }
      return false;
    },
    // ボタン共通の活性制御処理（リリース対象外の機能を非活性にするための関数）
    buttonItemDisabled(buttonName) {
      // disableButtonNameに指定されているボタンは非活性にする
      if (config.inactiveFunctionSettings.enable) {
        if (config.inactiveFunctionSettings.disableButtonName.includes(buttonName)) {
          return true
        }
      }
      return false;
    },
    // ロックの状態に応じてボタンを変更する
    setLockButton() {
      const lockButton = this.items
      .find(item => item.controlName === 'file')?.groups
      .find(group => group.controlName === 'operation')?.buttons;

      switch (this.currentMode) {
        case mode.edit:
          this.$set(lockButton, 3, this.lockButton.lock);
          break;
        case mode.noEdit:
          this.$set(lockButton, 3, this.lockButton.unLock);
          break;
        case mode.view:
          this.$set(lockButton, 3, this.lockButton.otherUserLock);
          break;
      }
    },
    // キーの存在チェック
    isKeyExists(obj, key) {
      if(obj[key] === undefined) return false;
      else return true;
    },
    showCesiumDialog(name, attributes, handlers, item, event) {
      setTimeout(() => {
        this.cesiumDialog = {
          isShow: true,
          name,
          attributes,
          handlers,
          item,
          event
        }
      }, 50)
    },
    closeCesiumDialog() {
      this.cesiumDialog = {
        attributes: {},
        handlers: {},
        isShow: false,
        name: '',
        item: null,
        event: null
      }
    },
    cancelCesiumDialog() {
      try {
        window["viewer"].removeSelection(constants.editType.numberOfPoints)
      } catch {
        console.error('Cesium Dialog display error');
      } finally {
        if (this.$store.getters.backgroundMap.visibility) {
         // 背景地図をONに戻す
         window["viewer"].setGlobeVisibility(true);
        }
        this.$store.dispatch("updateMode", "");
        this.closeCesiumDialog()
      }
    },
    showDialog(name, props, event) {
      const dialog = new Dialog();
      dialog.type = 'project';
      dialog.name = name;
      dialog.item = props;
      dialog.event = event;
      dialog.show();
    },
    closeDialog() {
      const dialog = new Dialog();
      dialog.close();
    },
    reShowDialog() {
      const dialog = new Dialog();
      dialog.reShow();
    },
    hideDialog() {
      const dialog = new Dialog();
      dialog.hide();
    },
    async selectClippingType(selectType) {
      const visiblePointClouds = this.$store.getters.visiblePointClouds;
      const errorMessage = i18n.tc("CEW0020104004")
      if(visiblePointClouds.length == 0) {
        utils.showSnackBar("error", errorMessage);
        return false;
      }

      // 設計データを非表示にする
      const designFileList = this.$store.getters["designFile/tilingDesigns"];
      for (const design of designFileList) {
        cesiumCommon.removeTileset(design);
        design.isVisible = false;
      }

      cesiumCommon.beginSelecting(
        constants.cesiumSelectingCameraType.canvas, 
        selectType.value,
        constants.editType.clipping);
      this.$store.dispatch("updateEditType", constants.editType.clipping);
    },
    selectUnnecessaryRemoveType(selectType) {
      cesiumCommon.beginSelecting(
        constants.cesiumSelectingCameraType.canvas, 
        selectType.value,
        constants.editType.unnecessaryRemoval);
      this.$store.dispatch("updateEditType", constants.editType.unnecessaryRemoval);
    },
    selectHoleInterpolationType(selectType) {
      cesiumCommon.beginSelecting(
        constants.cesiumSelectingCameraType.cesium, 
        selectType.value,
        constants.editType.holeInterpolation);
      this.$store.dispatch("updateEditType", constants.editType.holeInterpolation);
    },
    measurementDistance() {
      cesiumCommon.measurementDistance();
    },
    selectCrossSectionEdit(selectType) {
      cesiumCommon.crossSectionEdit(selectType.value);
      this.$store.dispatch('updateEditType', constants.editType.crossSectionEdit);
    },
    selectNumberOfPointsType(selectType) {
      cesiumCommon.beginSelecting(
        constants.cesiumSelectingCameraType.cesium, 
        selectType.value,
        constants.editType.numberOfPoints);
      this.$store.dispatch('updateEditType', constants.editType.numberOfPoints);
    },
    // 編集中のデータがあるか確認する
    async getEditData() {
      let editDataList = [];
      const body = {
        user_id:  this.$store.getters.loginData.user_id
      }
      const res = await pointEditRepository.findBySiteId(this.$route.query.siteId, body);
      if (res.status === 201) {
        editDataList = res.data.points;
      }
      return editDataList;
    },
    async goForward() {
      let successMessage = i18n.tc("CIW0020000051")
      let errorMessage = i18n.tc("CEW0020000001")
      this.isLoadingNoOverlay = true;

      const body = {
        user_id: this.$store.getters.loginData.user_id
      };
      try {
        const res = await pointEditRepository.goByPointId(this.$store.getters.editingPointClouds[0].pointId, body);
        if (res.status === 201) {
          utils.showSnackBar("success", successMessage);
          this.$store.dispatch("getPointDataList", this.$route.query.siteId);
        } else {
          // 想定外のステータスコードが返却された場合エラーをスロー
          throw new Error(errorMessage);
        }
      } catch (e) {
        console.error(e.message);
        if (utils.createErrorMessage(e)) {
          errorMessage = utils.createErrorMessage(e);
        }
        utils.showSnackBar('error', errorMessage);
      } finally {
        this.isLoadingNoOverlay = false;
      }
    },
    async goBack() {
      let successMessage = i18n.tc("CIW0020000052")
      let errorMessage = i18n.tc("CEW0020000002")
      this.isLoadingNoOverlay = true;

      const body = {
        user_id: this.$store.getters.loginData.user_id
      };
      try {
        const res = await pointEditRepository.backByPointId(this.$store.getters.editingPointClouds[0].pointId, body);
        if (res.status === 201) {
          utils.showSnackBar("success", successMessage); 
          this.$store.dispatch("getPointDataList", this.$route.query.siteId);
        } else {
          // 想定外のステータスコードが返却された場合エラーをスロー
          throw new Error(errorMessage);
        }
      } catch (e) {
        console.error(e.message);
        if (utils.createErrorMessage(e)) {
          errorMessage = utils.createErrorMessage(e);
        }
        utils.showSnackBar("error", errorMessage);
      } finally {
        this.isLoadingNoOverlay = false;
      }
    },
    async save() {
      let successMessage = i18n.tc("CIW0020000053")
      let errorMessage = i18n.tc("CEW0020000003")
      this.$store.dispatch("updateIsLoading", true);

      const body = {
        user_id: this.$store.getters.loginData.user_id
      };
      try {
        const res = await pointEditRepository.saveBySiteId(this.$route.query.siteId, body);
        if (res.status === 204) {
          utils.showSnackBar("success", successMessage);
          await this.$store.dispatch("getPointDataList", this.$route.query.siteId);
          //保存押下でクリッピング解除(更新)
          window['viewer'].clearClippingPlanes();
        } else {
          // 想定外のステータスコードが返却された場合エラーをスロー
          throw new Error(errorMessage);
        }
      } catch (e) {
        console.error(e.message);
        if (utils.createErrorMessage(e.message)) {
          errorMessage = utils.createErrorMessage(e);
        }
        utils.showSnackBar("error", errorMessage);
      } finally {
        this.$store.dispatch("updateIsLoading", false);
      }
    },
    async deleteClipping() {
      let successMessage = i18n.tc("CIW0020000054")
      let errorMessage = i18n.tc("CEW0020000004")
      this.$store.dispatch("updateIsLoading", true);

      const body = {
        user_id: this.$store.getters.loginData.user_id
      };
      try {
        const res = await clippingRepository.deleteBySiteId(this.$route.query.siteId, body);
        if (res.status === 204) {
          utils.showSnackBar("success", successMessage);
          await this.$store.dispatch("getPointDataList", this.$route.query.siteId);
          window['viewer'].clearClippingPlanes();
        } else {
          // 想定外のステータスコードが返却された場合エラーをスロー
          throw new Error(errorMessage);
        }
      } catch (e) {
        console.error(e.message);
        if (utils.createErrorMessage(e.message)) {
          errorMessage = utils.createErrorMessage(e);
        }
        utils.showSnackBar("error", errorMessage);
      } finally {
        this.$store.dispatch("updateIsLoading", false);
      }
    },
  },
  created() {
    this.$store.dispatch('lock/setLockStateBySiteId', this.$route.query.siteId);
    this.setLockButton();
  }
};
</script>

<style lang="sass" scoped>
.project-tabbar.v-sheet.v-toolbar.v-app-bar
  background-color: #31323A

.project-tabbar
  font-size: 12px
  width: 100%
  height: auto

.button-title
  text-align: center

.button-group:not(:last-of-type)
  border-right: solid 1px

.button-group-label
  text-align: center

.select-button__projection-type
  width: 150px

.select-button__point-size
  width: 100px

.select-button__display-color
  width: 150px

.select-button__shadow
  width: 150px

.switch-button ::v-deep .v-input.v-input--switch
  .v-input--switch__track
    width: 60px
    height: 20px
    border-radius: 0%
    background-color: black
    top: 0px
    left: 0px

.switch-button ::v-deep .v-input.v-input--switch
  .v-input--switch__thumb
    width: 23px
    height: 16px
    border-radius: 0%
    left: 1px

.switch-button ::v-deep .v-input--switch.v-input--is-dirty
  .v-input--selection-controls__ripple,
  .v-input--switch__thumb
    transform: translate(35px, 0)

.project-tabbar ::v-deep .v-toolbar__content
  padding-right: 0

::v-deep .v-list-item
  min-height: 0px

.menu-list-item:not(:last-child)
  border-bottom: solid 1px #E2E2E2
</style>