// 計測データ
export default class MeasureData{
  /**
   * フィールド
   */
  _measureId;
  _measureName;
  _selectInfo;
  _measureInfo;
  _status;
  _message;
  _isVisible;
  // viewerに表示するラベルのID
  _labelId; // Id<ILabel>

  /**
   * コンストラクタ
   */
  constructor(measureId, measureName, selectInfo, measureInfo, status, message) {
    this._measureId = measureId;
    this._measureName = measureName;
    this._selectInfo = selectInfo;
    this._measureInfo = measureInfo;
    this._status = status;
    this._message = message;
    this._isVisible = false;
  }

  /**
   * getters
   */
  get id() {
    return this._measureId;
  }

  get name() {
    return this._measureName;
  }

  get selectInfo() {
    return this._selectInfo;
  }

  get measureInfo() {
    return this._measureInfo;
  }

  get status() {
    return this._status;
  }

  get message() {
    return this._message;
  }

  get isVisible() {
    return this._isVisible;
  }

  get labelId() {
    return this._labelId;
  }

  /**
   * setters
   */
  set name(measureName) {
    this._measureName = measureName;
  }

  set selectInfo(selectInfo) {
    this._selectInfo = selectInfo;
  }

  set measureInfo(measureInfo) {
    this._measureInfo = measureInfo;
  }

  set status(status) {
    this._status = Number(status);
  }

  set message(message) {
    this._message = message;
  }

  set isVisible(isVisible) {
    this._isVisible = isVisible;
  }

  set labelId(labelId) {
    this._labelId = labelId;
  }
}
