<template>
  <v-card class="cesium-dialog px-4 py-2">
    <loading :parentLoading="isLoading" />
    <v-card-title class="pa-0 justify-space-between">
      <label>
        {{$t("CIW0020000025")}}
      </label>
      <close-button @click="$emit('close')" size="20px"></close-button>
    </v-card-title>
    <v-card-text class="px-0 pt-1 pb-0 white--text">
      <v-container class="pa-0">
        <validation-observer class="d-block" style="width: 100%" ref="observer" v-slot="{ pristine, invalid }">
          <v-row class="ma-0">
            <v-col cols="12" class="my-3 pa-0">
              <editing-point-clouds
                :title="$t('CIW0020000008')">
              </editing-point-clouds>
            </v-col>
            <v-col cols="12" class="mt-3 pa-0">
              <validation-provider :rules="{
                required: true,
                'number-format': true,
                'decimal-size-limit': 2,
                'min-value': 0.01,
                'max-value': 100.99,
              }" v-slot="{ errors }">
                <div class="mb-1">
                  <label class="d-inline-block mr-2">{{$t("CQW0020112003")}}</label>
                  <span class="d-inline-block" style="font-size: 12px; color: #ff5252">{{ errors[0] }}</span>
                </div>
                <v-text-field
                  @reset.prevent="resetError()"
                  v-model="latticeSize"
                  :error-messages="errors"
                  class="text-black ma-0"
                  height="32px"
                  background-color="#BFBFBF"
                  outlined
                  solo
                  suffix="m"
                  >
                </v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row class="ma-0 mt-3">
            <v-col cols="4" class="mt-2 mb-4 pa-0" align="left">
              <v-btn
                class=".rounded-lg"
                color="#ff5050"
                width="125px"
                height="45px"
                depressed
                @click="$emit('cancel')"
                >{{$t("CQW0010600005")}}</v-btn
              >
            </v-col>
            <v-col cols="4" class="mt-2 mb-4 pa-0" align="center">
              <v-btn
                class=".rounded-lg"
                color="#4472c4"
                width="125px"
                height="45px"
                depressed
                :disabled="pristine || invalid || !editingPointClouds.length"
                @click="execute(1)"
                >{{$t("CQW0020109005")}}<br />{{$t("CQW0020110006")}}</v-btn
              >
            </v-col>
            <v-col cols="4" class="mt-2 mb-4 pa-0" align="right">
              <v-btn
                class=".rounded-lg"
                color="#4472c4"
                width="125px"
                height="45px"
                depressed
                :disabled="pristine || invalid || !editingPointClouds.length"
                @click="execute(2)"
                >{{$t("CQW0020110007")}}<br />{{$t("CIW0020000005")}}</v-btn
              >
            </v-col>
          </v-row>
        </validation-observer>
      </v-container>
    </v-card-text>
    <v-dialog
      v-model="showConfirmDialog"
      persistent
      width="350px"
    >
      <confirm-dialog
        :title="$tc('CQW0020115008')"
        :okHandler="toggleConfirmDialog"
        :useCancelButton="false"
        @close="toggleConfirmDialog"
      >
        <template v-slot:contents>
          <p>{{$t("CQW0020115009", 1, {name: $t("CIW0020000025")})}}</p>
        </template>
      </confirm-dialog>
    </v-dialog>
  </v-card>
</template>

<script>
import CloseButton from "@/components/common/CloseButton.vue";
import EditingPointClouds from "../EditingPointClouds.vue";
import Loading from '../../../common/LoadingOverLay.vue';
import ConfirmDialog from '@/components/common/ConfirmDialog.vue';
import * as utils from "@/utils/utils";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { repositoryFactory } from "@/repositories/repository-factory";
import { i18n } from '@/i18n.js'
const holeInterpolationRepository = repositoryFactory.get('holeInterpolation');

export default {
  name: "HoleInterpolationDialog",
  components: {
    CloseButton,
    EditingPointClouds,
    ConfirmDialog,
    ValidationProvider,
    ValidationObserver,
    Loading
  },
  props: {
    showDialog: Boolean,
  },
  data() {
    return {
      isLoading: false,
      latticeSize: '',
      showConfirmDialog: false
   }
},
  computed: {
    editingPointClouds() {
      return this.$store.getters.editingPointClouds;
    }
  },
  methods: {
    toggleConfirmDialog() {
      this.showConfirmDialog = !this.showConfirmDialog
    },
    resetForm() {
      this.latticeSize = "" 
      this.resetError();
    },
    async resetError() {
      await this.$refs.observer.validate();
      await this.$refs.observer.reset();
    },
    execute(method) {
      if (this.editingPointClouds.length > 1) {
        this.toggleConfirmDialog();
        return
      }
      this.$emit('hole-interpolation', this.saveCallback.bind(null, method));
    },
    // Cesium.vueの穴補間実行後に呼ばれる
    saveCallback(method, holeInterpolationData) {
      return this.fileUpload(method, holeInterpolationData)
    },
    // 実行(保存)押下時処理
    async fileUpload(executionMethod, holeInterpolationDataMap) {
      this.isLoading = true;

      const body = {
        lattice_size: parseFloat(this.latticeSize),
        create_method: 1,
        execution_method: executionMethod,
        user_id: this.$store.getters.loginData.user_id
      };
      await this.putRequest(holeInterpolationDataMap, body);

      this.isLoading = false;
      this.$emit('close');
    },
    async putRequest(holeInterpolationDataMap, body) {
      let successMessage = i18n.tc("CIW0020115005");
      let errorMessage = i18n.tc("CEW0020115001");
      let status;

      // pointIdとholeInterpolationDataのmapを生成
      const pointIdHoleInterpolationDataMap = new Map();
      for (const pointFile of this.editingPointClouds.slice().reverse()) {
        pointIdHoleInterpolationDataMap.set(
          pointFile.pointId, 
          holeInterpolationDataMap.get(pointFile.assetId)
        );
      }

      try {
        // リクエストマップの件数分リクエストを送信
        for (const [pointId, holeInterpolationData] of pointIdHoleInterpolationDataMap.entries()) {
          const reqBody = {
            hole_interpolation_data: holeInterpolationData,
            ...body
          };
          const res = await holeInterpolationRepository.createByPointId(pointId, reqBody);
          if (res.status === 201 || res.status === 204) {
            status = res.status;
          } else {
            // 想定外のステータスコードが返却された場合エラーをスロー
            throw new Error(errorMessage);
          }
        }
        // エラーがなければ正常メッセージを出力する
        utils.showSnackBar('success', successMessage);
        return status;
      } catch (e) {
        console.error(e.message);
        if (utils.createErrorMessage(e)) {
          errorMessage = utils.createErrorMessage(e);
        }
        utils.showSnackBar('error', errorMessage);
        return "";
      } finally {
        // 実行に成功した場合点群を不可視にする
        if (status === 204 || status === 201) {
          utils.allClearTileset();
          // データリストを更新
          utils.refreshDataList(this.$route.query.siteId);
        }
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.cesium-dialog
  .v-card__title
    font-size: 16px

.cesium-dialog
  p,span
    color: white

::v-deep .v-input__append-outer
  margin: 0

::v-deep .v-input__slot
  margin: 0
  padding: 0

.text-black ::v-deep input,
.text-black ::v-deep label,
.text-black ::v-deep .v-select__selection,
.text-black ::v-deep .v-input__append-inner i
  color: black

::v-deep .v-text-field input,
::v-deep .v-text-field.v-input .v-select__selections input
  padding: 0

.drop-area
  border: 2px dashed white

.enter
  border: 2px dashed #2196F3

.text-field-error
  border: solid 2px #ff5252

::v-deep .v-text-field__details
  display: none

.select-file-area
  border: 1px dashed white

::v-deep .v-text-field__suffix
  color: black

</style>