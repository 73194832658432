// 外周線
export default class PerimeterLine{
  /**
   * フィールド
   */
  _perimeterLineId;
  _perimeterLineInfo;
  _status;
  _message;
  _isVisible;
  _viewerPerimeterLineId;

  /**
   * コンストラクタ
   */
  constructor(perimeterLineId, perimeterLineInfo, status, message) {
    this.perimeterLineId = perimeterLineId;
    this.perimeterLineInfo = perimeterLineInfo;
    this.status = status;
    this.message = message;
    // Viewer上のid
    this.viewerPerimeterLineId = "";
    // Viewer上に表示中か
    this.isVisible = false;
  }

  /**
   * getters
   */
  get perimeterLineId() {
    return this._perimeterLineId;
  }

  get perimeterLineInfo() {
    return this._perimeterLineInfo;
  }

  get status() {
    return this._status;
  }

  get message() {
    return this._message;
  }

  get viewerPerimeterLineId() {
    return this._viewerPerimeterLineId;
  }

  get isVisible() {
    return this._isVisible;
  }

  /**
   * setters
   */
  set perimeterLineId(perimeterLineId) {
    this._perimeterLineId = perimeterLineId;
  }

  set perimeterLineInfo(perimeterLineInfo) {
    this._perimeterLineInfo = perimeterLineInfo;
  }

  set status(status) {
    this._status = status;
  }

  set message(message) {
    this._message = message;
  }

  set viewerPerimeterLineId(viewerPerimeterLineId) {
    this._viewerPerimeterLineId = viewerPerimeterLineId;
  }

  set isVisible(isVisible) {
    this._isVisible = isVisible;
  }

  /**
   * functions
   */
  update(perimeterLine) {
    Object.assign(this, perimeterLine);
  }
}
