<template>
  <v-btn
    class="map-selection-item pa-0"
    style="color: inherit"
    height="100%"
    width="90px"
  >
    <v-card style="color: inherit" height="100%" width="100%" class="py-2">
      <v-img
        width="50px"
        height="50px"
        class="mx-auto rounded-circle"
        :src="src"
      ></v-img>
      <v-card-text style="color: inherit" class="pa-2">
        <slot name="title"></slot
      ></v-card-text>
    </v-card>
  </v-btn>
</template>

<script>
export default {
  props: {
    src: String,
  },
};
</script>

<style lang="sass" scoped>
.map-selection-item ::v-deep .v-btn__content
    height: 100%
    width: 100%
</style>