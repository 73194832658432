<template>
  <v-list width="100%">
    <v-list-item-group
      mandatory
      class="d-flex"
      v-model="backgroundMapLayerType"
    >
      <v-list-item 
        :value="backgroundMapType.satellite"
        class="pa-0"
      >
        <!-- 衛星写真 -->
        <v-list-item-content class="map-selection-item pa-0">
          <map-selection-item :src="require('../../assets/images/background-map/satellite.png')">
            <template v-slot:title> {{$t("CIW0020300004")}} </template>
          </map-selection-item>
        </v-list-item-content>
      </v-list-item>
      <!-- 衛星写真 + 地名表示 -->
      <v-list-item 
        :value="backgroundMapType.satelliteAndPlace"
        class="pa-0"
      >
        <v-list-item-content class="map-selection-item pa-0">
          <map-selection-item :src="require('../../assets/images/background-map/satellite-place.png')">
            <template v-slot:title>
              {{$t("CIW0020300005")}}<br />
              {{$t("CIW0020300006")}}</template>
          </map-selection-item>
        </v-list-item-content>
      </v-list-item>
      <!-- 地図 + 道路表示 -->
      <v-list-item 
        :value="backgroundMapType.mapAndRoad"
        class="pa-0"
      >
        <v-list-item-content class="map-selection-item pa-0">
          <map-selection-item :src="require('../../assets/images/background-map/satellite-traffic.png')">
            <template v-slot:title>
              {{$t("CIW0020300007")}} <br />
              {{$t("CIW0020300008")}}</template>
          </map-selection-item>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import MapSelectionItem from "./MapSelectionItem.vue";
import * as cesiumCommon from '@/utils/cesium-common';
import * as constants from '@/constants/constants';

export default {
  components: {
    MapSelectionItem
  },
  data() {
    return {
      backgroundMapType: constants.backgroundMapType
    }
  },
  computed: {
    backgroundMapLayerType: {
      get() {
        return this.$store.getters.backgroundMap.layerType;
      },
      set(layerType) {
        this.$store.dispatch("updateBackgroundMap", { layerType });
      }
    }
  },
  watch: {
    backgroundMapLayerType(val) {
      cesiumCommon.changeImageryLayer(val);
    }
  },
}
</script>

<style lang="sass" scoped>
.map-selection-item
  height: 100%
</style>