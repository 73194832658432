<template>
  <div class="vld-parent">
    <loading 
      :active="parentLoading"
      :is-full-page="true"
    />
  </div>
</template>
<script>
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  export default {
    props:['parentLoading'],
    data() {
      return {
        isLoading: false,
      }
    },
    components: {
      Loading
    },
  }
</script>