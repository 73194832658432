import * as constants from '@/constants/constants';
import * as cesiumCommon from '@/utils/cesium-common';
export default class Design {
  constructor(designId, designName, assetId, createdAt) {
    this.designId = designId;
    this.designName = designName;
    this.assetId = assetId;
    this.createdAt = createdAt;
    this.isAssetUploaded = constants.assetUploadStatus.PENDING;
    this.tilingProgress = false;
    this.isTiling = false;
    this.isVisible = false;
  }

  // getter
  get id() {
    return this._designId;
  }

  get designId() {
    return this._designId;
  }

  get name() {
    return this._designName;
  }

  get designName() {
    return this._designName;
  }

  get assetId() {
    return this._assetId;
  }

  get createdAt() {
    return this._createdAt;
  }

  get isAssetUploaded() {
    return this._isAssetUploaded;
  }

  get tilingProgress() {
    return this._tilingProgress;
  }

  get isTiling() {
    return this._isTiling;
  }

  get isVisible() {
    return this._isVisible;
  }

  // setter
  set designId(designId) {
    this._designId = designId;
  }

  set name(designName) {
    this._designName = designName;
  }

  set designName(designName) {
    this._designName = designName;
  }

  set assetId(assetId) {
    this._assetId = assetId;
  }

  set createdAt(createdAt) {
    this._createdAt = new Date(createdAt);
  }

  set isAssetUploaded(isAssetUploaded) {
    this._isAssetUploaded = isAssetUploaded;
  }

  set tilingProgress(tilingProgress) {
    this._tilingProgress = tilingProgress;
  }

  set isTiling(isTiling) {
    this._isTiling = isTiling;
  }

  set isVisible(isVisible) {
    this._isVisible = isVisible;
  }

  // functions
  update(designName, assetId, createdAt) {
    this.designName = designName;
    if (this.assetId !== assetId) { 
      // アセットidを変更する場合ステータスをPENDINGに戻す
      this.isAssetUploaded = constants.assetUploadStatus.PENDING;
      if (this.isTiling) {
        // アセットを読み込み済みであれば非表示にする
        cesiumCommon.removeTileset(this);
        this.isVisible = false;
      }
    }
    this.assetId = assetId;
    this.createdAt = createdAt;
  }

  waitAssetUploading() {
    const checkAssetUploaded = (resolve, reject, timeId) => {
      let isResolveOrReject = false;
      if (this.isAssetUploaded === constants.assetUploadStatus.DONE) {
        resolve(this.isAssetUploaded);
        if (timeId) clearInterval(timeId);
        isResolveOrReject = true;
      } else if (this.isAssetUploaded === constants.assetUploadStatus.ERROR) {
        reject(this.isAssetUploaded);
        if (timeId) clearInterval(timeId);
        isResolveOrReject = true;
      }
      return isResolveOrReject;
    }

    return new Promise((resolve, reject) => {
      // 1回目のチェック
      if (checkAssetUploaded(resolve, reject)) return;

      // 2回目以降のチェック
      const timeId = setInterval(()=>{
        if (checkAssetUploaded(resolve, reject, timeId)) return;
      }, 1000)
    })
  }
}