<template>
  <div class="errPage-container">
    <div class="content-body">
      <section>
        <div class="content-body__inner">
          <h1>{{$t("ERR0010100001")}} </h1>
          <p>{{$t("ERR0010100002")}}</p>
        </div>
      </section>
    </div>
    <div class="content-footer">
      <small>{{$t("ERR0010100003")}}</small>
    </div>
  </div>
</template>

<script>

export default {
  name: "Error404",

  components: {},
  data: () => ({}),
  mounted() {},
  destoroyed() {},
  methods: {},
};
</script>

<style scoped>
.errPage-container {
  width: 100%;
  height: 100%;
  background-color: #fff;
  color: #666;
}
.content-body {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateY(-30%) translateX(-50%);
  margin: 0 auto;
  padding: 0;
  width: 80%;
  height: auto;
  min-width: 320px;
  max-width: 600px;
  box-sizing: border-box;
  background-color: initial;
}
.content-body__inner {
  padding: 48px 16px 8px 16px;
  text-align: center;
}
.errPage-container .content-body .content-body__inner h1 {
  font-weight: 400;
  font-size: 1.375rem;
  margin-bottom: 24px;
}
.content-footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 8px 20px 0;
  text-align: center;
  font-size: 62.5%;
}
</style>
