<!-- 計算結果削除確認ダイアログ -->
<template>
  <v-card class="confirm">
    <v-card-title class="pt-2 justify-space-between" style="font-size: 14px; color: white">
      <slot name="title"></slot>
      <close-button role="close" @click="$emit('cancel')" size="18px"></close-button>
    </v-card-title>
    <v-card-text class="py-0 pb-5" style="color: white">
      <slot name="text"></slot>
    </v-card-text>
    <v-card-actions class="pa-0 pb-5 justify-center">
      <v-btn
        class="pa-3 mr-2 btn"
        role="cancel"
        color="#ff5050"
        width="110px"
        height="40px"
        @click="$emit('cancel')">
        {{$t("CQW0010600005")}}
      </v-btn>
      <v-btn
        class="pa-3 btn"
        role="ok"
        color="#4472c4"
        width="110px"
        height="40px"
        @click="$emit('ok')"
      >
      {{$t("CQW0010600006")}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import CloseButton from "@/components/common/CloseButton.vue";

export default {
  components: {
    CloseButton
  }
};
</script>
<style lang="sass" scoped>
.confirm .btn
    font-size: 13px
</style>