<!-- 断面データ -->
<template>
  <div class="d-flex align-center data-list-title">
    <v-btn
      class="pa-0 mr-1"
      min-width="auto"
      plain
      height="auto"
    >
      <v-icon v-if="sectionFile.isVisible" @click="changeSectionVisible">mdi-eye-outline</v-icon>
      <v-icon v-else @click="changeSectionVisible">mdi-eye-off-outline</v-icon>
    </v-btn>
      <v-checkbox
        v-if="deleteMode"
        :value="isChecked"
        @click="changeDeleteState"
        off-icon="mdi-checkbox-blank"
        color="white"
        class="my-0 mr-1 pa-0"
        hide-details
      >
      </v-checkbox>

    <section-file-name :sectionFile="sectionFile"></section-file-name>
    <!-- アイコン表示エリア -->
    <div class="pl-3 ml-auto d-flex align-center">
      <!-- 処理ステータス -->
      <edit-processing-status
        class="ml-auto"
        @error-clear="clearProcessError"
        :status="sectionFile.status"
        :message="sectionFile.message">
      </edit-processing-status>
    </div>
  </div>
</template>

<script>
import * as utils from '@/utils/utils';
import EditProcessingStatus from '@/components/common/EditProcessingStatus.vue';
import * as cesiumCommon from "@/utils/cesium-common";
import SectionFileName from './SectionFileName.vue';
import { i18n } from '@/i18n.js';
import { repositoryFactory } from "@/repositories/repository-factory";
const messageRepository = repositoryFactory.get('message');

export default {
  components: {
    EditProcessingStatus,
    SectionFileName 
  },
  props: {
    selected: Array,
    sectionFile: Object,
    deleteMode: Boolean
  },
  data: () => ({
    counter: 0,
    clickCounter: 0,
    isNameEdit: false
  }),
  computed: {
    isChecked() {
      return this.selected.find(section => section.sectionId === this.sectionFile.sectionId);
    },
    /**
     * 以下の場合は非活性
     * ・他の断面を表示中
     * ・サーバーでEXE処理中か処理エラー発生中
     */
    isDisabledVisibleBtn() {
      const visibleSectionFile = this.$store.getters['sectionFile/visibleSectionFile'];

      return ( visibleSectionFile && visibleSectionFile.sectionId !== this.sectionFile.sectionId )
          || utils.isEditingOrErrorExeProcess(this.sectionFile.status);
    },
    /**
     * 以下の場合は非活性
     * ・他の断面を表示中
     * ・サーバーでEXE処理中か処理エラー発生中
     */
    isDisabledVisibleBtnAndCheckBox() {
      const visibleSectionFile = this.$store.getters['sectionFile/visibleSectionFile'];

      return ( visibleSectionFile && visibleSectionFile.sectionId !== this.sectionFile.sectionId )
          || utils.isEditingOrErrorExeProcess(this.sectionFile.status);
    }
  },
  methods: {
    /**
     * 編集処理エラーを解除する
     * 解除に失敗した場合は通知を表示する
     */
    async clearProcessError() {
      let successMessage = i18n.tc("CIW0020300018")
      let errorMessage = i18n.tc("CEW0020300019")
      const body = {
        user_id: this.$store.getters.loginData.user_id
      };
      try {
        this.$store.dispatch("updateIsLoading", true);
        const res = await messageRepository.updateSectionFileMessageById(this.sectionFile.sectionId, body);
        if (res.status === 204) {
          // データリストを最新化
          await this.$store.dispatch('sectionFile/fetchSectionFileList', this.$route.query.siteId);
          utils.showSnackBar('success', successMessage);
        } else {
          // 想定外のステータスコードが返却された場合エラーをスロー
          throw new Error(errorMessage);
        }
      } catch (e) {
        console.error(e.message);
        if (utils.createErrorMessage(e)) {
          errorMessage = utils.createErrorMessage(e);
        }
        utils.showSnackBar('error', errorMessage);
      } finally {
        this.$store.dispatch("updateIsLoading", false);
      }
    },
    changeSectionVisible() {
      cesiumCommon.changeSectionVisible(this.sectionFile);
    }, 
    changeDeleteState() {
      if (this.isChecked) this.$emit('delete-checked', this.sectionFile)
      else this.$emit('add-checked', this.sectionFile);
    },
    editDataName(event) {
      event.target.dispatchEvent(new Event("blur"));
    },
  },
  beforeDestroy() {
    if (this.sectionFile.isVisible) {
      // データが削除されたらCesium上から削除
      this.changeSectionVisible();
    }
  }
}
</script>

<style lang="sass" scoped>
.data-list-title
  font-size: 14px
  margin-bottom: 4px
  white-space: normal
  word-break: break-all

.data-list-title ::v-deep .v-text-field .v-input__control .v-input__slot
  min-height: auto

::v-deep .v-input__slot .v-input--selection-controls__input
  margin-right: 0
  height: auto

.data-list-title-name .on-hover
  cursor: pointer
  background-color: rgba(245,245,245,0.2)

// チェックボックスホバー時の背景色の変化を無効化
::v-deep div.v-input--selection-controls__ripple
  display: none

</style>