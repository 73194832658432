<!-- 点群作成ダイアログ -->
<template>
  <v-card class="point-create-dialogFile px-4 pt-2 pb-4">
    <loading :parentLoading="isLoading" />
    <v-card-title class="pa-0 justify-space-between">
      <label>
        {{$t("CQW0020119001")}}
      </label>
      <close-button @click="$emit('close')" size="20px"></close-button>
    </v-card-title>
    <v-card-text class="px-0 pt-1 pb-0 white--text">
      <v-container class="pa-0">
        <v-row class="ma-0">
          <validation-observer class="d-block" style="width: 100%" ref="observer" v-slot="{ invalid }">
            <v-col cols="12" class="my-3 pa-0">
              <label class="mb-1 d-block">{{$t("CQW0020119002")}}</label>
              <v-radio-group
                v-model="uploadMethodRadioGroup"
                class="justify-space-between ma-0 mb-1 pa-0"
                column
                hide-details=""
               >
                <v-radio
                  class="ma-0 radio-button"
                  color="#f0f0f0"
                  :label="$t('CQW0020119003')"
                  value="0"
                  on-icon="fa-solid fa-circle-dot"
                  off-icon="fa-solid fa-circle"
                  :ripple="false"
                  @change="resetForm"
                ></v-radio>
                <validation-provider :rules="{
                  required: true,
                }">
                  <v-select
                    v-model="selectedFile"
                    class="text-black ml-8"
                    style="width: 80%"
                    height="32px"
                    background-color="#BFBFBF"
                    :items="dataList"
                    :item-text="item => item.designName"
                    :item-value="item => item.designId"
                    hide-details
                    solo
                    @change="onFileSelect"
                    :disabled="uploadMethodRadioGroup == 1"
                  >
                  </v-select>
                </validation-provider>
                <v-radio
                  class="ma-0 radio-button"
                  color="#f0f0f0"
                  :label="$t('CQW0020119006')"
                  value="1"
                  on-icon="fa-solid fa-circle-dot"
                  off-icon="fa-solid fa-circle"
                  :ripple="false"
                  @change="resetForm"
                ></v-radio>
                <div class="pa-2 drop-area ml-8" :class="{ enter: isEnter && uploadMethodRadioGroup == 1}" @dragenter="dragEnter" @dragleave="dragLeave" @dragover.prevent @drop.prevent="dropFile" :disabled="uploadMethodRadioGroup == 0">
                  <div class="d-inline-block">
                    <v-btn class="rounded-pill ml-8" color="rgba(127, 127, 127, 0.8)" width="140px" height="35px" depressed
                        :loading="isSelecting" @click="onButtonClick" :disabled="uploadMethodRadioGroup == 0">
                        {{$t("CQW0020104003")}}
                    </v-btn>
                    <input
                      ref="uploader"
                      class="d-none"
                      type="file"
                      :accept="acceptedExtensions"
                      @change="onFileChanged"
                      :disabled="uploadMethodRadioGroup == 0" />
                  </div>
                  <div class="d-inline-block ml-2">
                    <v-icon class="d-inline-block mr-2" color="rgba(151,143,143,0.8)">mdi-download</v-icon>
                    <span class="d-inline-block" style="color: rgba(151, 143, 143, 0.8)">{{$t("CQW0020104004")}}</span>
                  </div>
                </div>
              </v-radio-group>
              <div v-if="uploadFileName" class="pt-2 d-flex justify-space-between" style="width: 95%">
                <span>{{uploadFileName}}</span>
                <close-button @click="resetForm" size="20px"></close-button>
              </div>
            </v-col>
            <v-col cols="12" class="my-3 pa-0">
              <validation-provider
                :rules="{
                  required: true
                }"
                v-slot="{ errors }"
              >
                <div class="mb-1">
                  <label class="d-inline-block mr-2">{{$t("CQW0020104006")}}</label>
                  <span
                    class="d-inline-block"
                    style="font-size: 12px; color: #ff5252"
                    >{{ errors[0] }}</span
                  >
                </div>
                <v-text-field
                  @reset.prevent="resetError()"
                  v-model.trim="pointName"
                  :error-messages="errors"
                  class="text-black ma-0"
                  height="32px"
                  background-color="#BFBFBF"
                  outlined
                  solo
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" class="my-3 pa-0">
              <label class="mb-1 d-block">{{$t("CQW0020104022")}}</label>
              <v-select
                v-model="selectedUploadDirectory"
                class="text-black"
                height="32px"
                background-color="#BFBFBF"
                :items="uploadDirectoryList"
                item-text="text"
                :item-value="item => ({value: item.value, text: item.text})"
                hide-details
                solo
              >
              </v-select>
            </v-col>
            <v-col cols="12" class="mt-4 pa-0 d-flex justify-end">
              <v-btn
                class="rounded-lg mr-10"
                color="#ff5050"
                width="115px"
                height="45px"
                depressed
                @click="$emit('close')">
                {{$t("CQW0010600005")}}
              </v-btn>
              <v-btn
                class="rounded-lg"
                color="#4472c4"
                width="115px"
                height="45px"
                depressed
                :disabled="invalid"
                @click="execute">
                {{$t("CQW0020109005")}}
              </v-btn>
            </v-col>
          </validation-observer>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import CloseButton from "@/components/common/CloseButton.vue";
import * as constants from '@/constants/constants';
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Loading from "@/components/common/LoadingOverLay.vue";
import { i18n } from '../../../i18n.js';
import { regex } from "vee-validate/dist/rules";
import * as utils from "@/utils/utils";
import { repositoryFactory } from "@/repositories/repository-factory";

const createRepository = repositoryFactory.get('create');
const siteRepository = repositoryFactory.get('site');
const enableExtensionPattern = /(\.xml)$/i;

export default {
  components: {
    CloseButton,
    ValidationProvider,
    ValidationObserver,
    Loading,
  },
  data() {
    return {
      projectList: [],
      folderList: [],
      uploadDirectoryList: [],
      enableExtensions: constants.pointCreateEnableExtensions,
      selectedFile: "",
      selectedUploadDirectory: "",
      isSelecting: false,
      isLoading: false,
      pointName: "",
      design_id: "",
      uploadMethodRadioGroup: "0",
      selectedTextFormat: "LandXML",
      textFormatList: [
        "LandXML",
      ],
      ddCounter: 0,
      uploadFileName: "",
    }
  },
  computed: {
    isEnter: function () {
      return this.ddCounter > 0;
    },
    dataList() {
      // 設計データの選択項目を作成する
      return this.$store.getters["designFile/designFileList"];
    },
    acceptedExtensions() {
      return this.enableExtensions.map(ext => `.${ext}`).join(',');
    }
  },
  methods: {
    checkFileExtension(fileName) {
      return regex.validate(fileName, { regex: enableExtensionPattern });
    },
    resetForm() {
      this.uploadMethodRadioGroup = "0";
      this.uploadFileName = "";
      this.pointName = "";
    },
    async resetError() {
      await this.$refs.observer.validate();
      await this.$refs.observer.reset();
    },
    setFolderList() {
      for (const pointData of this.$store.state.pointDataList) {
        this.folderList.push(pointData.name);
      }
      if (!this.folderList.includes(this.systemDate)) {
        this.folderList = [
          "",
          ...this.folderList,
        ];
      }
    },
    getDataFile() {
      const projectList = [];
      for (const pointData of this.$store.state.pointDataList) {
        const project = Object.assign({}, pointData)
        const linearFiles
         = project.children
          .map(file => { return {'project_name': project.name, ...file}})
        project.children = linearFiles;
        projectList.push(project)
      }
      this.projectList = projectList
    },
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.uploader.click();
    },
    // アップロード済みの設計データから選択
    onFileSelect(event) {
      const target = this.dataList.find(ele => ele.designId == event)
      const fileName = target.designName.trim();
      this.uploadFileName =  fileName;
      this.design_id = target.designId;
      this.pointName = this.convertToPointFilename(target.designName.trim());
    },
    // ローカルから選択
    async onFileChanged(event) {
      if(this.uploadMethodRadioGroup == 0) {
        return;
      }
      if (this.checkFileExtension(event.target.files[0].name)) {
        // ファイルの実体
        this.selectedFile = event.target.files[0];
        // ファイル名
        this.uploadFileName = this.selectedFile.name;
        this.pointName = this.convertToPointFilename(this.selectedFile.name);
      }
      event.target.value = "";
    },
    dragEnter() {
      this.ddCounter++;
    },
    dragLeave() {
      this.ddCounter--;
    },
    dropFile(event) {
      if(this.uploadMethodRadioGroup == 0) {
        return;
      }
      this.ddCounter = 0;
      if (
        event.dataTransfer.files.length === 1 &&
        this.checkFileExtension(event.dataTransfer.files[0].name)
      ) {
       // this.resetForm();
        this.selectedFile = event.dataTransfer.files[0];
        this.uploadFileName = event.dataTransfer.files[0].name;
        this.pointName = this.convertToPointFilename(event.dataTransfer.files[0].name);
      }
    },
    async execute() {
      this.isLoading = true;
      const form = new FormData();
      const site_id = this.$route.query.siteId;
      const site_name = await siteRepository.getSiteBySiteName(site_id);
      const body = {
        site_id: site_id,
        user_id: this.$store.getters.loginData.user_id,
        project_name: this.selectedUploadDirectory.text,
        project_id: this.selectedUploadDirectory.value,
        // アップロード済みの時値が入る
        design_id: this.design_id != "" ? this.design_id : "",
        // ローカルからの時値が入る
        design_file: this.selectedFile,
        design_name: this.uploadFileName,
        point_name: this.pointName,
        site_name: site_name,
      }
      for(const field in body) {
        if (field == "design_file" && !body.design_id) {
          form.append(field, body[field], this.uploadFileName);
        } else {
          form.append(field, body[field]);
        }
      }
      await this.postRequest(form);
      this.isLoading = false;
      this.$emit('close');
      // api取得処理
    },
    async postRequest(form) {
      let successMessage = i18n.tc("CIW0020119001");
      let errorMessage = i18n.tc("CEW0020119001");
      let status;
      try {
        const res = await createRepository.createPointCloudFromLandXml(form);
        if (res.status === 201) {
          status = res.status;
          await this.$store.dispatch("designFile/fetchDesignFileList",
            this.$route.query.siteId
          )
          this.setUploadDirectoryList();
          utils.showSnackBar("success", successMessage);
          this.resetForm();
        } else {
          this.showRequestResult(this.error);
        }
      } catch (err) {
        if (utils.createErrorMessage(err)) {
          errorMessage = utils.createErrorMessage(err);
        }
        utils.showSnackBar("error", errorMessage);
      } finally {
        //実行に成功した場合に全点群不可視
        if (status === 201){
          utils.allClearTileset();
          // データリストを更新
          utils.refreshDataList(this.$route.query.siteId);
        }
      }
    },
    showRequestResult(message) {
      let timeout = 0;
      if (this.showSnackbar) {
        this.$store.dispatch('processingResultNotification/setIsShow', false);
        timeout = 500;
      }
      setTimeout(() => {
        // this.isLoading = false
        this.$store.dispatch('processingResultNotification/setMessage', message);
        this.$store.dispatch('processingResultNotification/setIsShow', true);
      }, timeout);
    },
    setUploadDirectoryList() {
      let uploadDirectoryList = [];
      for (const project of this.$store.state.pointDataList) {
        uploadDirectoryList.push({value: project.id, text: project.name});
      }
      
      const sysDateProject = 
        this.$store.state.pointDataList.find(
          project => project.name === this.systemDate
        )
      if (!sysDateProject) {
        this.selectedUploadDirectory = {value: "", text: this.systemDate};
        uploadDirectoryList = [
          ...uploadDirectoryList,
          this.selectedUploadDirectory,
        ];
      } else {
        this.selectedUploadDirectory = {value: sysDateProject.id, text: sysDateProject.name}
      }
      this.uploadDirectoryList = uploadDirectoryList
    },
    convertToPointFilename(filename) {
      const suffix = this.$t("CIW0020000027");
      //タイムスタンプがUTCではなくJSTで出てしまう
      /* const dt = new Date();
      const yy = String(dt.getFullYear()).slice(-2);
      const mm = ('0' + (dt.getMonth() + 1)).slice(-2);
      const dd = ('0' + dt.getDate()).slice(-2);
      const hh = ('0' + dt.getHours()).slice(-2);
      const mi = ('0' + dt.getMinutes()).slice(-2);
      const ss = ('0' + dt.getSeconds()).slice(-2); */
      return filename + "_" + suffix;
    },
  },
    mounted() {
    const today = new Date();
    this.systemDate =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      today.getDate().toString().padStart(2, "0");

    this.getDataFile();
    this.setFolderList();
    this.setUploadDirectoryList();
  },
};
</script>

<style lang="sass" scoped>
.point-create-dialogFile
  .v-card__title
    font-size: 16px

::v-deep .v-input__append-outer
  margin: 0

::v-deep .v-input__slot
  margin: 0
  padding: 0

.text-black ::v-deep input,
.text-black ::v-deep label,
.text-black ::v-deep .v-select__selection,
.text-black ::v-deep .v-input__append-inner i
  color: black

::v-deep .v-text-field input,
::v-deep .v-text-field.v-input .v-select__selections input
  padding: 0

.select-file-area
  border: 1px dashed white

.text-field-error
  border: solid 2px #ff5252

::v-deep .v-text-field__details
  display: none

::v-deep .v-text-field__suffix
  color: black

::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

::v-deep div.v-input.v-text-field div.v-input__prepend-outer
  margin: auto 8px auto 1px
  color: white

::v-deep button.v-btn span.v-btn__content
  font-size: 13px

.radio-button
  width: calc(100% / 1.5)
  
::v-deep div.v-radio
  div.v-input--selection-controls__input i.v-icon
    font-size: 14px
    color: #f0f0f0
  label.v-label
    font-size: 14px
    color: white

v-radio.label
  font-size: 14px

.drop-area
  border: 2px dashed white
  width: 90%
.enter
  border: 2px dashed #2196F3
</style>