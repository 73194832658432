<!-- LandXML作成 -->
<template>
  <v-card class="landXML-create-dialog px-4 pt-2 pb-4">
    <loading :parentLoading="isLoading" />
    <v-card-title class="pa-0 justify-space-between">
      <label>
        {{$t("CQW0020118001")}}
      </label>
      <close-button @click="$emit('close')" size="20px"></close-button>
    </v-card-title>
    <v-card-text class="px-0 pt-1 pb-0 white--text">
      <v-container class="pa-0">
        <v-row class="ma-0">
          <validation-observer class="d-block" style="width: 100%" ref="observer" v-slot="{ invalid, pristine }">
            <v-col cols="12" class="my-3 pa-0">
              <label class="mb-1 d-block">{{$t("CQW0020107002")}}</label>
              <v-select
              @reset.prevent="resetError()"
                v-model="selectedFolder"
                class="text-black"
                height="32px"
                background-color="#BFBFBF"
                :items="folderList"
                item-text="name"
                item-value="id"
                hide-details
                solo
              >
              </v-select>
            </v-col>
            <v-col cols="12" class="my-3 pa-0">
              <validation-provider :rules="{
                required: true,
              }">
                <label class="mb-1 d-block">{{$t("CQW0020107003")}}</label>
                <v-select
                @reset.prevent="resetError()"
                  v-model="selectedFile"
                  class="text-black"
                  height="32px"
                  background-color="#BFBFBF"
                  :items="dataList"
                  item-text="text"
                  item-value="value"
                  hide-details
                  solo
                >
                </v-select>
              </validation-provider>
            </v-col>
            <!-- 最小 TIN 間隔 -->
            <v-col cols="12" class="mt-3 pa-0">
              <validation-provider :rules="{
                required: true,
                'positive-number': true,
                'decimal-size-limit': 2,
                'min-value': 0.01,
                'max-value': 100.99,
              }" v-slot="{ errors }">
                <div class="mb-1">
                  <label class="d-inline-block mr-2">{{$t("最小 TIN 間隔")}}</label><!-- TODO: 多言語化 -->
                  <span class="d-inline-block" style="font-size: 12px; color: #ff5252">{{ errors[0] }}</span>
                </div>
                <v-text-field
                  @reset.prevent="resetError()"
                  v-model="minDistanceBetweenPoints"
                  :error-messages="errors"
                  class="text-black ma-0"
                  height="32px"
                  background-color="#BFBFBF"
                  outlined
                  solo
                  suffix="m"
                  >
                </v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" class="mt-4 pa-0 d-flex justify-end">
              <v-btn
                class="rounded-lg mr-10"
                color="#ff5050"
                width="115px"
                height="45px"
                depressed
                @click="$emit('close')">
                {{$t("CQW0010600005")}}
              </v-btn>
              <v-btn
                class="rounded-lg"
                color="#4472c4"
                width="115px"
                height="45px"
                depressed
                :disabled="pristine || invalid"
                @click="execute">
                {{$t("CQW0020109005")}}
              </v-btn>
            </v-col>
          </validation-observer>
        </v-row>
      </v-container>
    </v-card-text>
    <v-dialog
      v-model="showConfirmDialog"
      persistent
      width="350px"
    >
      <confirm-dialog
        :title="$tc('CQW0020115008')"
        :okHandler="confirmOK"
        :useCancelButton="true"
        :cancelHandler="confirmCancel"
        @close="confirmCancel"
      >
        <template v-slot:contents>
          <p>{{$t("CQW0020118002", 1, {name: selectedFileDetail.pointName})}}</p>
        </template>
      </confirm-dialog>
    </v-dialog>
  </v-card>
</template>

<script>
import CloseButton from "@/components/common/CloseButton.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import * as utils from '@/utils/utils';
import Loading from '../../common/LoadingOverLay.vue';
import ConfirmDialog from '@/components/common/ConfirmDialog.vue';
import { repositoryFactory } from "@/repositories/repository-factory";
import { i18n } from '../../../i18n.js'
const createRepository = repositoryFactory.get('create');

export default {
  components: {
    Loading,
    CloseButton,
    ConfirmDialog,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      isLoading: false,
      selectedFolder: "",
      selectedFile: "",
      minDistanceBetweenPoints: "",
      showConfirmDialog: false,
      reject: null,
      resolve: null,
    }
  },
  computed: {
    /**
     * 選択中のファイルの点群ファイルの詳細情報
     */
    selectedFileDetail() {
      return utils.getPointFileByPointId(this.selectedFile.pointId);
    },
    /**
     * プロジェクトID、プロジェクト名と点群ファイルの紐付きリストを作成
     */
    projectList() {
      const projectList = [];
      for (const pointData of this.$store.getters.pointDataList) {
        const project = Object.assign({}, pointData)
        project.children
         = project.children
          .map(
            file => (
              {
                projectId: project.id,
                projectName: project.name,
                pointId: file.pointId,
                pointName: file.pointName
              }
            )
          )
        projectList.push(project)
      }
      return projectList
    },
    /**
     * データリストに表示するオブジェクトリストを作成
     */
    dataList() {
      const pointFileList = [];
      for (const project of this.projectList) {
        // フォルダが選択されていない場合は全点群を表示
        if (!this.selectedFolder) {
          for (const pointFile of project.children) {
            pointFileList.push(
                { 
                  value: pointFile,
                  text: `${pointFile.pointName}（${pointFile.projectName}）`
                }
              );
          }
        } else if (project.id === this.selectedFolder) {
          // フォルダが選択されている場合は選択中のプロジェクトと紐づく点群ファイルを表示
          for (const pointFile of project.children) {
            pointFileList.push(
                {
                  value: pointFile,
                  text: pointFile.pointName
                }
            );
          }
        }
      }
      return pointFileList
    },
    folderList() {
      return [
        { id: "", name: "" },
        ...this.$store.getters.pointDataList,
      ];
    },
  },
  methods: {
    openConfirmDialog() {
      this.showConfirmDialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      })
    },
    confirmOK() {
      this.resolve(true);
      this.showConfirmDialog = false;
    },
    confirmCancel() {
      this.resolve(false);
      this.showConfirmDialog = false;
    },
    resetForm() {
      this.selectedFolder = "";
      this.selectedFile = "";
      this.resetError();
    },
    async resetError() {
      await this.$refs.observer.validate();
      await this.$refs.observer.reset();
    },
    //実行ボタン押下
    async execute() {
      // 外周線がない点群を選択した場合、確認ダイアログを表示
      // if (!this.selectedFileDetail.perimeterLine) {
      //   if (!await this.openConfirmDialog()) return;
      // }
      this.isLoading = true;
      const body = {
        user_id: this.$store.getters.loginData.user_id,
        min_distance_between_points: this.minDistanceBetweenPoints,
      };
      await this.request(body);
      this.isLoading = false;
      await this.$store.dispatch("getPointDataList", this.$route.query.siteId)
      this.$emit('close');
    },
    async request(body) {
      let successMessage = i18n.tc("CIW0020118001");
      let errorMessage = i18n.tc("CEW0020118001");
      let status;

      try {
        const res = await createRepository.createLandXMLByPointId(this.selectedFile.pointId, body);
        if (res.status !== 201) {
          // 想定外のステータスコードが返却された場合エラーをスロー
          throw new Error(errorMessage);
        }
        status = res.status;
        utils.showSnackBar('success', successMessage);
        this.resetForm();
      } catch (e) {
        console.error(e.message);
        if(utils.createErrorMessage(e)){
          errorMessage = utils.createErrorMessage(e);
        }
        utils.showSnackBar('error', errorMessage);
      } finally {
        //実行に成功した場合に点群を不可視にする
        if (status === 201) {
          utils.allClearTileset();
          // データリストを更新
          utils.refreshDataList(this.$route.query.siteId);
        }
      }
    },
  }
};
</script>

<style lang="sass" scoped>
.landXML-create-dialog
  .v-card__title
    font-size: 16px

::v-deep .v-input__append-outer
  margin: 0

::v-deep .v-input__slot
  margin: 0
  padding: 0

.text-black ::v-deep input,
.text-black ::v-deep label,
.text-black ::v-deep .v-select__selection,
.text-black ::v-deep .v-input__append-inner i
  color: black

::v-deep .v-text-field input,
::v-deep .v-text-field.v-input .v-select__selections input
  padding: 0

.select-file-area
  border: 1px dashed white

.text-field-error
  border: solid 2px #ff5252

::v-deep .v-text-field__details
  display: none

::v-deep .v-text-field__suffix
  color: black

::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

::v-deep div.v-input.v-text-field div.v-input__prepend-outer
  margin: auto 8px auto 1px
  color: white

::v-deep button.v-btn span.v-btn__content
  font-size: 13px
</style>