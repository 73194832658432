<template>
  <v-container
    id="Cesium"
    data-testid="Cesium"
    fluid
    class="pa-0"
    style="position: relative;" 
    :style="`height:calc(100vh - ${this.$vuetify.application.top}px - ${this.$vuetify.application.insetFooter}px); transition: all 0.2s; border: 1px solid #434348; z-index: 6`"
    @mousemove="fillXYZCoordinates"
  >
    <cesium-dialog
      :isShow="cesiumDialog.isShow"
      :name="cesiumDialog.name"
      :item="cesiumDialog.item"
      :event="cesiumDialog.event"
      v-bind="cesiumDialog.attributes"
      v-on="cesiumDialog.handlers"
    >
    </cesium-dialog>
    <flatness-label class="flatness-label"></flatness-label>
    <coordinate-panel v-if="this.$route.query.siteId" :coordinates="[...coordinates]" :show-progress="fetchCoordinateProgress" />
  </v-container>
</template>

<script>
import "cesium/Build/Cesium/Widgets/widgets.css";
import CesiumDialog from "../components/Project/Dialogs/CesiumDialog.vue";
import FlatnessLabel from "@/components/common/FlatnessLabel.vue";
import CoordinatePanel from "@/components/common/CoordinatePanel.vue";
import Dialog from "@/components/modules/dialog";
import { ApplicationError } from "@/utils/application-error";
import * as utils from '@/utils/utils';
import * as cesiumCommon from "@/utils/cesium-common";
import ContextmenuHandler from '@/components/modules/contextmenu-handler';
import * as constants from '@/constants/constants'
import { i18n } from '../i18n.js'

//As when we enter in mode for flat ground Horizontal drag functionality is true by default.
let cesium = null;

const debounce = (func, wait = 1000) => {
  let timerId;
  return function(...args){
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
};

export default {
  name: "Cesium",
  components: {
    CesiumDialog,
    FlatnessLabel,
    CoordinatePanel 
  },
  data() {
    return {
      coordinates: [],
      fetchCoordinateProgress: false,
    }
  },
  computed: {
    mode: {
      get() {
        return this.$store.getters.mode;
      },
      set(value) {
        this.$store.dispatch('updateMode', value);
      }
    },
    editType: {
      get() {
        return this.$store.getters.editType;
      },
      set(value) {
        this.$store.dispatch('updateEditType', value);
      }
    },
    cesiumDialog: {
      get() {
        return this.$store.getters['dialog/cesiumDialog']
      },
      set(value) {
        this.$store.dispatch('dialog/setCesiumDialog', value);
      }
    }
  },
  methods: {
    async showClippingConfirmDialog(event) {
      event.preventDefault();
      const dialogHandlers = {
        'ok': this.clipping,
        'cancel': () => {
          this.cancelDialog()
        },
      }
      this.showDialog('clipping-confirm-dialog', {}, dialogHandlers)
    },
    async showUnnecessaryRemoveConfirmDialog(event) {
      event.preventDefault();
      const dialogHandlers = {
        'remove': this.unnecessarySelectRemove,
        'cancel': () => {
          this.cancelDialog()
        },
      }
      this.showDialog('unnecessary-remove-confirm-dialog', {}, dialogHandlers)
    },
    //穴補間
    async showHoleInterpolationConfirmDialog(event) {
      event.preventDefault();
      const dialogHandlers = {
        'hole-interpolation': this.onHoleInterpolation,
        'cancel': () => {
          this.cancelDialog();
        },
      }
      this.showDialog('hole-interpolation-dialog', {}, dialogHandlers)
    },
    //点数
    async showNumberOfPointsConfirmDialog(event) {
      event.preventDefault();
      const dialogHandlers = {
        'count-points': this.countPoints,
        'cancel': () => {
          this.cancelDialog();
        },
      }
      this.showDialog('number-of-points-confirm-dialog', {}, dialogHandlers)
    },
    // 間引き地表面
    async showThinningGroundSurfaceDialog(event) {
      let errorMessage = i18n.tc("CEW0020113002");
      let selectInfo = "";
      event.preventDefault();
      try {
        selectInfo = window['viewer'].getSelectionDataForPostProcessing()
      } catch(e) {
        console.error(e.message);
        utils.showSnackBar('error', errorMessage);
      } 
      this.$store.dispatch("edit/setThinningGroundSurface", { selectInfo });
      this.$store.dispatch('updateEditType', "");
      /* this.cancelDialog(); */
      // ダイアログを再表示する
      const dialog = new Dialog("project");
      dialog.reShow();
      
    },
    // クリッピング実行（saveはClippingConfirmDialogのコールバック関数）
    async clipping(save) {
      try {
        const clippingInfo = cesium.getJSONDataOfSelection();
        await save(clippingInfo);
        cesium.createClippingPlanes();
        // エラーがなければ正常メッセージを出力する
        utils.showSnackBar("success", i18n.tc("CIW0020109005"));
      } catch(e) {
        if (e instanceof TypeError) {
          // 範囲選択せずにクリッピングするとviewerでTypeErrorが発生する
          console.warn(e);
          utils.showSnackBar('error', i18n.tc("CEW0020109001"));
        }
        else if (e instanceof ApplicationError) {
          console.warn(e);
          utils.showSnackBar('error', e.message);
        }
        else {
          console.error(e);
          utils.showSnackBar('error', i18n.tc("CEW0020109002"));
        }
      } finally {
        document
          .getElementById("Cesium")
          .removeEventListener("contextmenu", this.showClippingConfirmDialog);
        this.cancelDialog();
        cesium.finishSelecting();
      }
    },
    // 不要物除去実行（saveはUnnecessaryRemoveConfirmDialog.vueのコールバック関数）
    async unnecessarySelectRemove(save) {
      let errorMessage = i18n.tc("CEW0020300029");
      try {
        const selectedPyramidOrPrism = cesium.getSelectedDeletionData();
        await save(selectedPyramidOrPrism);
      } catch(e) {
        // 範囲選択せずにクリッピングするとviewerでTypeErrorが発生する
        if (e instanceof TypeError) {
          errorMessage = i18n.tc("CEW0020109001");
        }
        console.error(e.message);
        utils.showSnackBar('error', errorMessage);
      } finally {
        this.cancelDialog();
        cesium.finishSelecting();
      }
    },
    // 穴補間（saveはHoleInterpolationDialog.vueのコールバック関数）
    async onHoleInterpolation(save) {
      let errorMessage = i18n.tc("CEW0020115001");
      try {
        const holeInterpolationDataMap = cesium.getSelectedPointsInTilesetCS();
        await save(holeInterpolationDataMap);
      } catch(e) {
        // 範囲選択せずにクリッピングするとviewerでTypeErrorが発生する
        if (e instanceof TypeError) {
          errorMessage = i18n.tc("CEW0020109001");
        }
        console.error(e.message);
        utils.showSnackBar('error', errorMessage);
      } finally {
        this.cancelDialog();
        cesium.finishSelecting();
      }
    },
    // 点数計測実行（sendCallback は NumberOfPointsConfirmDialog.vue のコールバック関数）
    async countPoints(sendCallback) {
      let errorMessage = i18n.tc("CEW0020300028");
      try {
        const selectionDataForRepresentation = cesium.getJSONDataOfSelection();
        // HACK: getJSONDataOfSelection よりも先に getSelectionDataForPostProcessing を呼ぶと getJSONDataOfSelection の selection が取得できない
        //       cesium-viewer.ts の getSelectionDataForPostProcessingForAllPointClouds を修正した方が良い
        const selectionMapForMeasurement = cesium.getSelectionDataForPostProcessing();
        await sendCallback(selectionMapForMeasurement, selectionDataForRepresentation);
      } catch(e) {
        // 範囲選択せずにクリッピングするとviewerでTypeErrorが発生する
        if (e instanceof TypeError) {
          errorMessage = i18n.tc("CEW0020109001");
        }
        console.error(e.message);
        utils.showSnackBar('error', errorMessage);
      } finally {
        this.cancelDialog();
        cesium.finishSelecting();
      }
    },
    cancelDialog() {
      try {
        // 範囲選択モードを解除
        cesiumCommon.removeSelection(this.editType)
        this.editType = "";
      } catch(e) {
        console.error(e);
      } finally {
        if (this.$store.getters.backgroundMap.visibility) {
          // 背景地図をONに戻す
          cesium.setGlobeVisibility(true);
        }
        this.closeDialog()
      }
    },
    showDialog(name, attributes, handlers, item, event) {
      const dialog = new Dialog('cesium');
      dialog.name = name;
      dialog.attributes = attributes;
      dialog.handlers = handlers;
      dialog.items = item;
      dialog.event = event;
      dialog.show();
    },
    closeDialog() {
      const dialog = new Dialog('cesium');
      dialog.close();
    },
    /**
     * viewer上で右クリック押下時のアクションを設定
     * @param {*} event 
     */
    contextMenuEventHandler(event) {
      const contextMenuHandler =  new ContextmenuHandler(this);
      switch (this.editType) {
        case constants.editType.clipping:
          this.showClippingConfirmDialog(event);
          break;
        case constants.editType.unnecessaryRemoval:
          this.showUnnecessaryRemoveConfirmDialog(event);
          break;
        case constants.editType.holeInterpolation:
          this.showHoleInterpolationConfirmDialog(event);
          break;
        case constants.editType.flatnessConditionCreate:
          contextMenuHandler.showFlatnessConditionCreateDialog(event);
          break;
        case constants.editType.numberOfPoints:
          this.showNumberOfPointsConfirmDialog(event);
          break;
        case constants.editType.crossSectionEdit:
          contextMenuHandler.showCrossSectionEditConfirmDialog(event);
          break;
        case constants.editType.thinningGroundSurface:
          this.showThinningGroundSurfaceDialog(event);
          break;
        case constants.editType.perimeterLine:
          contextMenuHandler.showPerimeterLineEditingDialog(event);
          break;
        default:
          break;
      }
    },
    keyDownEventHandler(event) {
      switch (event.key) {
        case "Escape":
          this.cancelDialog();
          break;
        default:
          break;
      }
    },
    async getMetres(coordinates){
      if (coordinates.length>0 && this.$route.query.siteId) {
        const convertedCoords = await cesiumCommon.transformPointToSiteCrs({x: coordinates[0],y:coordinates[1],z:coordinates[2]});
        return [convertedCoords.x, convertedCoords.y, convertedCoords.z];
      }
    },
    fillXYZCoordinates: debounce(function() {
      this.fetchCoordinateProgress = true;
      let coordinate = window["viewer"].getXYZCoordinates();
      if (coordinate !== undefined) {
        this.getMetres(coordinate).then((coords)=>{
          this.coordinates.length = 0;
          this.coordinates.push(...coords);
          this.fetchCoordinateProgress = false;
        })
      }
    }, 100),
  },
  mounted() {
    console.log("Cesium Container Initialized");
    this.$nextTick(() => {
      cesium = window["viewer"]
      // 背景地図を初期化
      const backgroundMap = this.$store.getters.backgroundMap;
      cesium.setGlobeVisibility(backgroundMap.visibility);
      cesium.changeImageryLayer(backgroundMap.layerType);
    });

    // 右クリックイベントを登録
    document
      .getElementById("Cesium")
      .addEventListener("contextmenu", this.contextMenuEventHandler);

    // KeyDownイベントを登録
    document.addEventListener("keydown", this.keyDownEventHandler);

    // viewerの外側をクリックした場合のイベントを登録
    document.addEventListener("click", (event) => {
      if ( 
        /** 以下セレクターの子要素は除外 */
        !event.target.closest('#Cesium') 
        && !event.target.closest('#chart-board') 
        && !event.target.closest('.project-dialog')
        && !event.target.closest('.cesium-dialog')
        && !event.target.closest('[role="dialog"]')
        && !event.target.closest('[class*="out-of-cancellation-range"]')
        && !event.target.closest('[class*="range-selection-dialog"]')
      ) 
      { 
        // viewerのモードをキャンセル
        if (this.mode === constants.viewerMode.selecting) {
          this.cancelDialog();
        } else if (this.mode === constants.viewerMode.lineCreation) {
          this.editType = "";
          cesiumCommon.cancelLineCreation();
        } else if (this.mode === constants.viewerMode.SIMAEdit) {
          this.editType = "";
          cesiumCommon.cancelLineEdit();
        } else if (this.mode === constants.viewerMode.crossSectionEdit) {
          this.editType = "";
          cesiumCommon.cancelCrossSectionEdit();
        }
      }
    });

    document.addEventListener("contextmenu", (event) => {
      if (event.target.closest('#chart-board')) {
        if (this.mode === constants.viewerMode.crossSectionEdit) {
          const contextMenuHandler =  new ContextmenuHandler(this);
          contextMenuHandler.showCrossSectionEditConfirmDialog(event);
        }
      }
    })
  }
};
</script>

<style lang="sass" scoped>
.list-ChartContextMenu:hover
  background-color: #0064ba !important

#Cesium ::v-deep canvas
  height: 100%

.flatness-label
  position: absolute
  bottom: 55px
  right: 110px
  z-index: 1
</style>
