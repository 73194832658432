<!-- 線形データリスト -->
<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header
        style="font-size: 15px; background-color: #1e1e1e"
        class="pa-2"
      >
        <div class="d-flex align-center">
          <!-- メニューボタン -->
          <v-btn
            width="24px"
            plain
            min-width="24px"
            class="pa-0 mr-1"
            @click.stop="changeShawEditDialog"
          >
            <v-icon style="background-color: transparent">mdi-menu</v-icon>
          </v-btn>
          <div>{{$t("CIW0020000009")}}</div>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="pt-1">
        <!-- 線形データリスト -->
        <v-list class="pa-0" style="font-size: 14px">
          <v-list-item
            v-for="linearFile in linearFileList"
            :key="linearFile.linearId"
            class="data-list mb-3"
          >
            <v-list-item-title>
              <linear-file
                :selected="selected"
                @add-checked="addChecked"
                @delete-checked="deleteChecked"
                :deleteMode="deleteMode"
                :linearFile="linearFile"
              ></linear-file>
            </v-list-item-title>
          </v-list-item>
        </v-list>
        <div v-if="deleteMode" class="d-flex justify-end mb-2">
          <v-btn
            class="mr-3"
            color="red lighten-1"
            width="100px"
            :disabled="this.selected.length === 0"
            @click="deleteLinearFile"
          >
          {{$t("CIW0020301001")}}
          </v-btn>
        </div>
      </v-expansion-panel-content>
      <v-dialog
        hide-overlay
        persistent
        no-click-animation
        v-model="showEditDialog"
        width="360px"
      >
        <linear-edit-dialog
          @close="changeShawEditDialog"
          @change-delete-mode="changeDeleteMode"
          @all-delete="allDelete"
          :linearFileList="linearFileList"
        />
      </v-dialog>
    </v-expansion-panel>
  </v-expansion-panels>

</template>

<script>
import LinearFile from "./LinearFile.vue"
import LinearEditDialog from "../LinearEditDialog.vue";
import * as utils from '@/utils/utils';
import { repositoryFactory } from "@/repositories/repository-factory";
import { i18n } from '@/i18n.js'
const linearFileRepository = repositoryFactory.get('linearFile');

export default {
  components: {
    LinearFile,
    LinearEditDialog
  },
  data() {
    return {
      showEditDialog: false,
      deleteMode: false,
      selected: [],
    }
  },
  computed: {
    linearFileList() {
      return this.$store.getters['linearFile/linearFileList']
    }
  },
  methods: {
    // エディットダイアログの開閉を行う
    changeShawEditDialog() {
      if (this.deleteMode) {
        this.changeDeleteMode();
      }
      this.showEditDialog = !this.showEditDialog;
    },
    // チェックボックスがチェックされたとき
    addChecked(linear) {
      this.selected.push(linear);
    },
    // チェックボックスが外れたとき
    deleteChecked(linear) {
      this.selected = this.selected.filter(selected => selected.linearId !== linear.linearId);
    },
    // エディットダイアログで削除ボタンが押された際の動作
    changeDeleteMode() {
      this.deleteMode = !this.deleteMode;
    },
    // 選択された線形ファイルを削除
    deleteLinearFile() {
      if (this.selected.length === 0) return;
      this.request(this.selected);
    },
    // 線形ファイルを全削除
    allDelete() {
      // 断面と紐づかない線形データを削除対象とする
      if (this.linearFileList.length === 0) return;
      this.request(this.linearFileList);
    },
    async request(deleteList) {
      let successMessage = i18n.tc("SIW0020303001");
      let errorMessage = i18n.tc("SEW0020303001");

      const body = {
        linear_ids: deleteList.map(linear => linear.linearId),
        deleted_by: this.$store.getters.loginData.user_id
      }
      try {
        this.$store.dispatch("updateIsLoading", true);
        const res = await linearFileRepository.deleteLinearByLinearIds(body);
        if (res.status !== 200) {
          // 想定外のステータスコードが返却された場合エラーをスロー
          throw new Error(errorMessage);
        }

        // メッセージ出力のため、削除に成功した点群と失敗した点群に分ける
        const { deletedData, warningDataWithSections } 
        = res.data.linear.reduce(
          (result, item) => {
            if (item.status === 'SUCCESS') {
              // 削除に成功した点群のリストに追加
              result.deletedData.push(item)
            } else if (item.status === 'WARNING_SECTION_DATA_EXIST') {
              // 断面データを持つため削除に失敗した点群のリストに追加
              result.warningDataWithSections.push(item)
            }
            return result;
          },
          { deletedData: [], warningDataWithSections: [] }
        )

        // 削除に失敗した点群があれば警告用のメッセージを組み立てる
        if (warningDataWithSections.length) {
          const message = [];
          // 成功した一覧を表示
          message.push(successMessage);
          for (const deletedLinear of deletedData) {
            const linear = deleteList.find(linear => linear.linearId === deletedLinear.linear_id);
            if (linear) message.push(`・${linear.linearName}`);
          }
          // メッセージ間に空白行を入れる
          message.push("");

          // 削除に失敗した一覧を表示
          message.push(i18n.tc("CEW0020301004"));
          for (const warningLinear of warningDataWithSections) {
            const linear = deleteList.find(linear => linear.linearId === warningLinear.linear_id);
            if (linear) message.push(`・${linear.linearName}`);
          }
          // 警告終了用のメッセージを表示
          utils.showSnackBar('warning', message, 0);
        } else {
          // 正常終了用のメッセージを表示
          utils.showSnackBar('success', successMessage);
        }
        await this.fetchLinearFileList()
        this.selected.splice(0);
        this.deleteMode = false;
        this.showEditDialog = false;
      } catch(e) {
        // エラーが発生したら処理終了
        console.error(e.message);
        if (utils.createErrorMessage(e)) {
          errorMessage = utils.createErrorMessage(e);
        }
        utils.showSnackBar('error', errorMessage);
      } finally {
        this.$store.dispatch("updateIsLoading", false);
      }
    },
    // 線形ファイルを取得
    async fetchLinearFileList() {
      this.$store.dispatch('linearFile/fetchLinearFileList', this.$route.query.siteId)
    },
  },
  created() {
    this.fetchLinearFileList();
  }
};
</script>

<style lang="sass" scoped>
.data-list
  height: auto
  min-height: auto
  display: block

::v-deep .v-expansion-panel-content__wrap
  padding: 0 0 8px 0

::v-deep div.v-expansion-panel-content__wrap
  padding-bottom: 0px
</style>