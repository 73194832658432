// 線形ファイル系のstore
import Linear from "../../models/Linear";
import { repositoryFactory } from "@/repositories/repository-factory";
const linearFileRepository = repositoryFactory.get('linearFile');

const state = {
  linearFileList: [],
};

const getters = {
  linearFileList: state => state.linearFileList,
  visibleLinearFileList: state => state.linearFileList.filter(linear => linear.isVisible)
};

const mutations = {
  fetchLinearFileList(state, linearFileList) {
    state.linearFileList = linearFileList;
  },
  addLinearFile(state, linearFile) {
    state.linearFileList.push(linearFile);
  },
};

const actions = {
  async fetchLinearFileList({ state, commit, rootGetters }, siteId) {
    const body = {
      user_id: rootGetters.loginData.user_id
    }
    try {
      const res = await linearFileRepository.findBySiteId(siteId, body);
      if (res.status === 200) {
        const linearList = [];
        for (const linear of res.data.linear) {
          const target = state.linearFileList.find(linearFile =>
            linear.linear_id === linearFile.linearId
          );
          // リストに存在する場合は更新、しない場合は追加
          if (target) {
            target.update(linear.linear_name, linear.linear_info, linear.route, linear.route_epsg4978, linear.station_interval);
            linearList.push(target);
          } else {
            const newLinear
            = new Linear(
                linear.linear_id, 
                linear.linear_name, 
                linear.linear_info,
                linear.route,
                linear.route_epsg4978,
                linear.station_interval,
              );
            linearList.push(newLinear);
          }
        }
        commit("fetchLinearFileList", linearList);
      }
    } catch (error) {
      console.error(error.message);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};