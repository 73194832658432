<!-- Dashboard  -->
<template>
  <v-card class="dashboard-export-dialog px-4 pt-2 pb-4">
    <loading :parentLoading="isLoading" />
    <v-card-title class="pa-0 justify-space-between">
      <label>
        {{$t("CQW0020108001")}}
      </label>
      <close-button @click="$emit('close')" size="20px"></close-button>
    </v-card-title>
    <v-card-text class="px-0 pt-1 pb-0 white--text">
      <v-container class="pa-0">
        <v-row class="ma-0">
          <validation-observer
            class="d-block"
            style="width: 100%"
            ref="observer"
            v-slot="{ invalid, pristine }"
          >
            <!-- フォルダを選択 -->
            <v-col cols="12" class="my-3 pa-0">
              <label class="mb-1 d-block">{{$t("CQW0020107002")}}</label>
              <v-select
                v-model="selectedFolder"
                class="text-black"
                height="32px"
                background-color="#BFBFBF"
                :items="folderList"
                item-text="name"
                item-value="id"
                hide-details
                solo
              >
              </v-select>
            </v-col>
            <!-- データを選択 -->
            <v-col cols="12" class="my-3 pa-0">
              <validation-provider :rules="{
                required: true,
              }">
                <label class="mb-1 d-block">{{$t("CQW0020107003")}}</label>
                <v-select
                  v-model="selectedData"
                  class="text-black"
                  height="32px"
                  background-color="#BFBFBF"
                  :items="dataList"
                  item-text="text"
                  item-value="value"
                  hide-details
                  solo
                >
                </v-select>
              </validation-provider>
            </v-col>
            <!-- 点群データ出力拡張子を選択 -->
            <v-col cols="12" class="my-3 pa-0">
              <validation-provider :rules="{
                required: true,
              }">
                <label class="mb-1 d-block">{{$t("CQW0020107008")}}</label>
                <v-select
                  v-model="selectedOutputExtension"
                  class="text-black"
                  height="32px"
                  background-color="#BFBFBF"
                  :items="outputExtensionList"
                  item-text="text"
                  item-value="value"
                  hide-details
                  solo>
                </v-select>
              </validation-provider>
            </v-col>

            <!-- データ日時 -->
            <v-col cols="12" class="my-3 pa-0">
              <label class="mb-1 d-block">{{$t("CQW0020108006")}}</label>
              <v-sheet class="d-flex">
                <validation-provider :rules="{
                  required: true,
                }">
                  <v-menu top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        class="text-black mr-4"
                        height="32px"
                        background-color="#BFBFBF"
                        hide-details
                        solo
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      no-title
                      color="primary"
                    >
                    </v-date-picker>
                  </v-menu>
                </validation-provider>
                <validation-provider :rules="{
                  required: true,
                }">
                  <v-menu
                    v-model="timeMenu"
                    top
                    :close-on-content-click="false"
                  >
                    <template
                      v-slot:activator="{ on, attrs }"
                    >
                      <v-text-field
                        v-model="time"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        class="text-black"
                        height="32px"
                        background-color="#BFBFBF"
                        hide-details
                        solo
                      >
                      </v-text-field>
                    </template>
                    <time-picker
                      :time="time"
                      @set-time="(newTime) => { time = newTime }"
                      @confirm="timeMenu = false"
                    ></time-picker>
                  </v-menu>
                </validation-provider>
              </v-sheet>
            </v-col>

            <!-- 登録ボタン -->
            <v-col cols="4" class="mt-4 pa-0">
              <v-btn
                class="rounded-pill"
                color="primary"
                width="135px"
                height="35px"
                depressed
                :disabled="pristine || invalid"
                @click="register"
              >
              {{$t("CIW0020109006")}}
              </v-btn>
            </v-col>
          </validation-observer>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import CloseButton from "@/components/common/CloseButton.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Loading from '@/components/common/LoadingOverLay.vue';
import TimePicker from '@/components/common/TimePicker.vue';
import * as utils from '@/utils/utils';
import { repositoryFactory } from "@/repositories/repository-factory";
import { i18n } from '../../../../i18n.js'
const dashboardExportRepository = repositoryFactory.get('dashboardExport');

export default {
  components: {
    CloseButton,
    ValidationProvider,
    ValidationObserver,
    Loading,
    TimePicker 
  },
  data() {
    return {
      selectedFolder: "",
      selectedData: "",
      selectedOutputExtension: "",
      date: "",
      time: "",
      timeMenu: false,
      isLoading: false,
      outputExtensionList: [
        /* {value: "txt", text: "TEXT(.txt)"},
        {value: "csv", text: "CSV(.csv)"}, */
        {value: "las", text: "LAS(.las)"},
      ],
    }
  },
  computed: {
    // フォルダリストに表示するオブジェクトリストを作成
    folderList: function() {
      return [
        { id: "", name: "" },
        ...this.$store.getters.pointDataList,
      ];
    },
    // プロジェクトID、プロジェクト名と点群ファイルの紐付きリストを作成
    projectList() {
      const projectList = [];
      for (const pointData of this.$store.getters.pointDataList) {
        const project = Object.assign({}, pointData)
        project.children
         = project.children
          .map(
            file => (
              {
                projectId: project.id,
                projectName: project.name,
                pointId: file.pointId,
                pointName: file.pointName
              }
            )
          )
        projectList.push(project)
      }
      return projectList
    },
    // データリストに表示するオブジェクトリストを作成
    dataList() {
      const pointFileList = [];
      for (const project of this.projectList) {
        // フォルダが選択されていない場合は全点群を表示
        if (!this.selectedFolder) {
          for (const pointFile of project.children) {
            pointFileList.push(
                { 
                  value: pointFile,
                  text: `${pointFile.pointName}（${pointFile.projectName}）`
                }
              );
          }
        } else if (project.id === this.selectedFolder) {
          // フォルダが選択されている場合は選択中のプロジェクトと紐づく点群ファイルを表示
          for (const pointFile of project.children) {
            pointFileList.push(
                {
                  value: pointFile,
                  text: pointFile.pointName
                }
            );
          }
        }
      }
      return pointFileList
    }
  },
  watch: {
    dataList() {
      // 選択可能なデータ項目が変更されたら選択中のデータをクリアする
      this.selectedData = "";
    },
  },
  methods: {
    resetForm() {
      this.selectedFolder = "";
      this.selectedData = "";
      this.selectedOutputExtension = "";
      this.setDateTime();
    },
    async resetError() {
      await this.$refs.observer.validate();
      await this.$refs.observer.reset();
    },
    setDateTime() {
      const today = new Date();
      // 日付を設定
      this.date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        today.getDate().toString().padStart(2, "0");

      // 時間を設定
      this.time = 
        today.getHours().toString().padStart(2, "0") +
        ":" +
        today.getMinutes().toString().padStart(2, "0") +
        ":" +
        today.getSeconds().toString().padStart(2, "0");
    },
    // 登録処理実行
    async register() {
      this.isLoading = true;
      const body = {
        output_extension: this.selectedOutputExtension,
        created_at: `${this.date} ${this.time}`,
        user_id: this.$store.getters.loginData.user_id
       };
      await this.request(body);
      this.isLoading = false;
      this.$emit('close');
    },
    async request(body) {
      let successMessage = i18n.tc("CIW0020108001")
      let errorMessage = i18n.tc("CEW0020108001")
      try {
        const res = await dashboardExportRepository.exportByPointId(this.selectedData.pointId, body);
        if (res.status === 201) {
          utils.showSnackBar("success", successMessage);
          this.resetForm();
          this.$store.dispatch("getPointDataList", this.$route.query.siteId)
        } else {
          utils.showSnackBar("error", errorMessage);
        }
      } catch (e) {
        console.error(e.message)
        if (utils.createErrorMessage(e)) {
          errorMessage = utils.createErrorMessage(e);
        }
        utils.showSnackBar("error", errorMessage);
      }
    },
  },
    mounted() {
      this.setDateTime();
  },
};
</script>

<style lang="sass" scoped>
.dashboard-export-dialog
  .v-card__title
    font-size: 16px

.text-black ::v-deep input,
.text-black ::v-deep .v-select__selection,
.text-black ::v-deep .v-input__append-inner i
  color: black

::v-deep button.v-btn span.v-btn__content
  font-size: 13px
</style>