// 線形
export default class Linear{
  /** 
   * フィールド 
   */
  _linearId;
  _linearName;
  _linearInfo;
  _route;
  _routeEpsg4978;
  _stationInterval;
  _viewerLinearId;
  _isVisible;

  /**
   * コンストラクタ
   */
  constructor(linearId, linearName, linearInfo, route, routeEpsg4978, stationInterval) {
    this.linearId = linearId;
    this.linearName = linearName;
    this.linearInfo = linearInfo;
    this.route = route;
    this.routeEpsg4978 = routeEpsg4978;
    this.stationInterval = stationInterval;
    this.viewerLinearId = "";
    this.isVisible = false;
  }

  /**
   * getters
   */
  get linearId() {
    return this._linearId;
  }

  get linearName() {
    return this._linearName;
  }

  get linearInfo() {
    return this._linearInfo;
  }

  get route() {
    return this._route;
  }

  get routeEpsg4978() {
    return this._routeEpsg4978;
  }

  get stationInterval() {
    return this._stationInterval;
  }

  get viewerLinearId() {
    return this._viewerLinearId;
  }

  get isVisible() {
    return this._isVisible;
  }

  /**
   * setters
   */
  set linearId(linearId) {
    this._linearId = linearId;
  }

  set linearName(linearName) {
    this._linearName = linearName;
  }

  set linearInfo(linearInfo) {
    this._linearInfo = linearInfo;
  }

  set route(route) {
    this._route = route;
  }

  set routeEpsg4978(routeEpsg4978) {
    this._routeEpsg4978 = routeEpsg4978;
  }

  set stationInterval(stationInterval) {
    this._stationInterval = stationInterval;
  }

  set viewerLinearId(_viewerLinearId) {
    this._viewerLinearId = _viewerLinearId;
  }

  set isVisible(isVisible) {
    this._isVisible = isVisible;
  }

  /**
   * functions
   */
  update(linearName, linearInfo, route, routeEpsg4978, stationInterval) {
    this.linearName = linearName;
    this.linearInfo = linearInfo;
    this.route = route;
    this.routeEpsg4978 = routeEpsg4978;
    this.stationInterval = stationInterval;
  }
}
