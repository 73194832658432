<template>
  <v-snackbar
    :timeout="timeout"
    v-model="isShow"
    :color="message.type"
    right
    bottom
    light
    text
  >
    <div class="px-0 d-flex align-start message">
      <div class="d-flex mr-4 align-center">
        <v-icon
          left
          :color="message.type"
        >
          {{ message.icon }}
        </v-icon>
        <ul class="pa-0">
          <li
            v-for="text in message.text"
            :key="text"
            style="list-style: none;"
          >
            <p
              class="mb-0"
              v-if="text !== ''"
              v-text="text"
            ></p>
            <br v-else>
          </li>
        </ul>
      </div>
      <CloseButton 
        class="ml-auto"
        @click="isShow = false"
      />
    </div>
  </v-snackbar>
</template>

<script>
import CloseButton from "@/components/common/CloseButton.vue";

export default {
  components: {
    CloseButton
  },
  computed: {
    isShow: {
      get() {
        return this.$store.getters['processingResultNotification/isShow'];
      },
      set(value) {
        return this.$store.dispatch('processingResultNotification/setIsShow', value);
      }
    },
    message() {
      const displayMessage = this.$store.getters['processingResultNotification/message'];
      let message = {
        text: "text" in displayMessage ? displayMessage.text.split(/\n/): [],
        type: "type" in displayMessage ? displayMessage.type: "",
        icon: "icon" in displayMessage ? displayMessage.icon: ""
      };
      return message;
    },
    timeout() {
      return this.$store.getters['processingResultNotification/timeout'];
    }
  },
  watch: {
    isShow(newValue) {
      // 非表示になったらメッセージを空にする
      if (!newValue) {
        this.$store.dispatch('processingResultNotification/setMessage', {text: "", type: "", icon: ""});
        this.$store.dispatch('processingResultNotification/setTimeout', 5000);
      }
    }
  }
}
</script>

<style lang="sass" scoped>
::v-deep .v-snack__content
  padding-right: 6px
ul
  max-height: 90vh
  overflow: auto
div.message
  margin: 2px 0
.message p
  max-width: 400px
  overflow-wrap: break-word
  word-wrap: break-word
li:not(:last-child)
  margin-bottom: 4px
li:last-child
  margin-bottom: 0px

</style>