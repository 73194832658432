import Repository from "./repository";

const resource = "/perimeter-line";
export default {
  // 外周線作成
  createPerimeterLine(payload) {
    return Repository.post(`${resource}`, payload);
  },
  // 外周線削除
  deletePerimeterLineByPerimeterLineId(perimeterLineId, payload) {
    return Repository.delete(`${resource}/${perimeterLineId}`, { data: payload });
  },
  // 外周線更新
  updatePerimeterLine(perimeterLineId, modificationHistories) {
    return Repository.put(`${resource}/${perimeterLineId}`, modificationHistories);
  },
}
