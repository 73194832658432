<template>
  <v-navigation-drawer
    permanent
    width="800px"
    mobile-breakpoint="0"
    color="#17171a"
    dark
    clipped
    app
    style="border-top: 1px solid #434348"
  >
    <loading :parentLoading="isLoading" />
    <v-card color="#17171a" flat>
      <v-card-title class="pl-4 pt-6">
        <h1>{{$t("CIW0010100001")}}</h1>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              v-model.trim="searchName"
              solo
              dense
              outlined
              :label="$t('CIW0010500002')"
              type="text"
              hide-details
              height="30px"
              class="pt-10 pb-0"
              style="width: 91%"
              @keyup.enter="findProjectEnter($event)"
            >
              <template v-slot:append-outer>
                <v-btn icon @click="findProject()">
                  <v-icon> icon-Search </v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
          <v-col align-self="end">{{$t("CIW0010500003")}}</v-col>
        </v-row>
        <v-simple-table style="background-color: transparent">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left" style="width: 60%">{{$t("CIW0010600001")}}</th>
                <th class="" style="width:25%" nowrap="nowrap">{{$t("CIW0010600002")}}</th>
                <th class="text-right" style="width: 15%" nowrap="nowrap">{{$t("CIW0010600003")}}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                @click="focusPin(item)"
                @mouseenter="highlightPinOn(item)"
                @mouseleave="highlightPinOff(item)"
                v-for="item in projects"
                :key="item.site_name"
              >
                <td>{{ item.site_name }}</td>
                <td class="d-flex align-center" v-if="item.locked_by">
                  <v-icon left size="1.3rem">mdi-lock</v-icon>
                  <p class="ma-0">{{ item.locked_by_name }}</p>
                </td>
                <td v-else></td>
                <td v-if="isSCDashboard">
                  <v-icon>mdi-check-bold</v-icon>
                </td>
                <td v-else></td>
                <td class="text-right pa-0">
                  <v-btn
                    color="primary"
                    height="100%"
                    @click="checkCoordinateSetting(item)"
                    style="
                      border-top-left-radius: 25px;
                      border-bottom-left-radius: 25px;
                    "
                  >
                  {{$t("CIW0010600004")}}
                    <v-icon small> icon-Arrow-Right </v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-card-text v-if="!projects.length" class="font-italic text-center">
          No more projects to load
        </v-card-text>
        <v-pagination
          v-model="page"
          :length="page_count"
          @input = "transition"
          >
        </v-pagination>
      </v-card-text>
    </v-card>
    <list-dialog
      :isShow="dialog.isShow"
      :name="dialog.name"
      :item="dialog.item"
      :event="dialog.event"
      v-bind="dialog.attributes"
      v-on="dialog.handlers"
    ></list-dialog>
  </v-navigation-drawer>
</template>

<script>
import ListDialog from '@/components/List/ListDialog.vue';
import { repositoryFactory } from "@/repositories/repository-factory";
import Loading from '@/components/common/LoadingOverLay.vue';

export default {
  name: "ListPanel",

  components: {
    ListDialog,
    Loading,
  },
  data: () => ({
    dialog: {
      isShow: false,
      name: '',
      attribute: {},
      handlers: {},
      item: null,
      event: null
    },
    isShowDialog: false,
    isSCDashboard: false,
    isLoading: false,
    dialogItem: {},
    projects: [],
    searchName: "",
    page_count: 1,
    page: 1,
    offset: 1,
    limit: 20
  }),
  created() {
    this.findProject()
  },
  mounted() {
    this.$nextTick(function () {
      // ビュー全体がレンダリングされた後にのみ実行されるコード
      this.setLockState();
    });
  },
  watch: {
    searchName: function(newVal, oldVal) {
      if (newVal != undefined && newVal != oldVal) {
        this.offset = 1;
      }
    }
  },
  destoroyed() {},
  methods: {
    showCoordinateUpdateDialog(project) {
      const dialogHandlers = {
        'ok': this.gotoProject,
        'close': this.closeDialog,
        'cancel': this.closeDialog
      }
      this.showDialog('coordinate-update-dialog', {}, dialogHandlers, project)
    },
    showReadOnlyConfirmDialog(project) {
      const dialogHandlers = {
        'ok': this.gotoProject,
        'close': this.closeDialog,
        'cancel': this.closeDialog
      }
      this.showDialog('read-only-confirm-dialog', {}, dialogHandlers, project)
    },
    showDialog(name, attributes, handlers, item, event) {
      setTimeout(()=>{
        this.dialog = {
          isShow: true,
          name,
          attributes,
          handlers,
          item,
          event
        }
      }, 50)
    },
    closeDialog() {
      this.dialog = {
        attributes: {},
        handlers: {},
        isShow: false,
        name: '',
        item: null,
        event: null
      }
    },
    async checkCoordinateSetting(project) {
      try {
        const siteRepository = repositoryFactory.get('site');
        const res = await siteRepository.getSiteBySiteName(project.site_id)
        const status = res.status
        if(status === 200) {
          // Google Analyticsに連携
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'event': 'sc_jobsite_open',
            'sc_site_id': res.data.site_uuid
          });
          //APIをたたいて座標系の値が取得できない場合、座標系設定ダイアログを出す
          if((!res.data.coordinate_system && !res.data.vertical_shift) && !res.data.rotation) {
            this.showCoordinateUpdateDialog(project)
          } else {
            this.checkLockState(project)
          }
        } else {
          //this.showGetResult(this.error);
        }
      } catch (error) {
        console.error(error.message)
      }
    },
    async checkLockState(project) {
      // 開くボタンが押されたタイミングで最新のロック状況を取得
      await this.$store.dispatch('lock/setLockStateBySiteId', project.site_id);
      const lockState = this.$store.getters['lock/getLockBySiteId'](project.site_id);
      // ロックされている かつ ロックユーザーが自分以外の場合は読み取り専用ダイアログを表示
      if (lockState && lockState.userId != this.$store.getters.loginData.user_id) {
        project.current_lock_user = lockState.userName;
        this.showReadOnlyConfirmDialog(project)
      } else {
        this.gotoProject(project);
      }
    },
    setLockState() {
      let lockState = [];
      for (const project of this.projects) {
        if ('locked_by' in project && project.locked_by) {
          lockState.push(
            { 
              siteId: project.site_id, 
              userId: project.locked_by, 
              userName: project.user_name
            }
          );
        }
      }
      this.$store.dispatch('lock/setLockState', lockState);
    },
    gotoProject(project) {
      this.$router.push(`/project?siteId=${project.site_id}`).catch(() => {});
      this.removeAllPin();
    },
    addPin() {
      this.removeAllPin();
      for (let i = 0; i < this.projects.length; i++) {
        const longitude = this.projects[i].origin_longitude;
        const latitude= this.projects[i].origin_latitude;
        if (longitude && latitude)
          this.projects[i].pin_id = window["viewer"].addPin(Number(longitude), Number(latitude), '#ffffff');
      }
    },
    removeAllPin() {
      for (const project of this.projects) {
        if (project.pin_id) {
          window['viewer'].removePin(project.pin_id)
        }
      }
    },
    focusPin(item) {
      window['viewer'].focusPin(item.pin_id);
    },
    highlightPinOn(item) {
      window['viewer'].updatePin(item.pin_id, {
        color: "#eaaa00",
        highlight: true
      })
    },
    highlightPinOff(item) {
      window['viewer'].updatePin(item.pin_id, {
        color: "#ffffff",
        highlight: false
      })
    },
    findProject() {
      // ページネーションのリセット
      if (this.searchName == "" ) {
        this.page = 1;
      }
      this.getRequest();

      // ローカル確認用
      /*
      const apiData = [
        { name: "test", siteId: "1" },
        { name: "test2", siteId: "2" },
        { name: "test3", siteId: "3" },
      ];
      return apiData;
      */
    },
    findProjectEnter(event) {
      // フォーカスアウトする
      event.target.blur();
      // 検索実行
      this.findProject();
    },
    async getRequest() {
      // プロジェクトリストの更新前にピンを削除する
      this.removeAllPin();

      const projectListRepository = repositoryFactory.get('projectList');
      const siteRepository = repositoryFactory.get('site');
      const offset = this.offset;
      const limit = this.limit;

      const params = {
        offset,
        limit
      }

      if (this.searchName) {
        params.siteName = this.searchName;
        params.searchWord = this.searchName;
      }

      const payload = {
        user_id: this.$store.getters.loginData.user_id
      };
      const siteInfo = await siteRepository.getSiteCount(payload, params);
      this.isLoading = true
      // プロジェクトリスト返却API実行
      try {
        const res = await projectListRepository.getProjectList(payload, params);
        if (res.status === 201) {
          this.projects = res.data.sites;
          // Cesiumにpinを追加
          this.addPin();
          this.page_count = (siteInfo.data.siteCount%limit === 0) ? Math.floor(siteInfo.data.siteCount/limit) : Math.floor(siteInfo.data.siteCount/limit)+1;
        } else {
          //this.showGetResult(this.error);
        }
      } catch (error) {
        console.error(error.message);
        //this.showGetResult(this.error);
      }
      this.isLoading = false
    },
    async transition(page){
      this.isLoading = true
      this.offset = (page * this.limit) - this.limit + 1
      await this.getRequest();
      this.isLoading = false
    },
  },
};
</script>

<style scoped></style>
