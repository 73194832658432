<template>
  <span>
    <v-card v-show="firstDialog" class="justify-center" style="width: 100%">
      <loading :parentLoading="isLoading" />
      <v-btn small fab absolute top right class="mt-9" @click="close(false)">
        <v-icon> mdi-close </v-icon>
      </v-btn>
      <v-card-title>
        {{$t("CQW0020900001")}}
      </v-card-title>
      <v-tabs @change="onTabSwitch">
        <v-tab>{{$t("CIW0020700001")}}</v-tab>
        <v-tab>{{$t("CQW0020900002")}}</v-tab>
      </v-tabs>
      <!-- Dashboard連携タブ -->
      <v-container v-show="tab0" class="ma-4 fill-height fluid content">
        <v-row justify="center">
          <span style="white-space:pre-wrap; word-wrap:break-word;" v-if="is_Dashboard">
            <!-- {{$t("CQW0020900003")}}<br /> -->
            {{dashboardInfo.message}}
          </span>
          <span v-else>
            <v-btn
              block
              color="primary"
              @click="cooperateWithDashboard()"
            >
              {{$t("CIW0020700001")}}
            </v-btn>
          </span>
        </v-row>
        <v-row>
          <v-btn
            class="submit"
            :disabled="isbtn1Disabled"
            color="primary"
            rounded
            @click="onSubmit()"
          >
            {{$t("CQW0020123006")}}
          </v-btn>
        </v-row>
      </v-container>
      <!-- 座標系選択タブ -->
      <v-container v-show="tab1" class="mt-0 fill-height fluid content">
        <v-row class="map" style="display:none">
          <cesium-viewer></cesium-viewer>
        </v-row>
        <v-row>{{$t("CQW0020900004")}}</v-row>
        <v-row>
          <v-col>
            <v-autocomplete
              outlined
              :items="ESPG_code_items"
              v-model="ESPG_code"
              :loading="isLoading"
              :search-input.sync="search_coordinate"
              item-text="label"
              item-value="value"
              hide-no-data
              hide-selected
              return-object
              required
            ></v-autocomplete>
          </v-col>
        </v-row>
        <!-- 鉛直変位 -->
        <v-row>{{$t("CQW0020900005")}}</v-row>
        <v-row>
          <v-col>
            <v-autocomplete
              outlined
              :items="GEO_code_items"
              v-model="vShift"
              :loading="isLoading"
              :search-input.sync="search_vertical"
              item-text="label"
              item-value="value"
              hide-no-data
              hide-selected
              return-object
              required
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-right">
            <v-btn
              :disabled="isbtn2Disabled"
              color="primary"
              rounded
              @click="onSubmit()"
            >
              {{$t("CQW0020123006")}}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card v-show="confirmDialog"  class="justify-center" style="width: 100%">
      <v-card-title>{{$t("CQW0020900001")}}</v-card-title>
      <v-card-title class="justify-left text-subtitle-1">
        {{$t("CQW0020800001")}}
      </v-card-title>
      <v-card-title class="justify-left text-subtitle-1">
        {{$t("CIW0020800002")}}<br />{{$t("CIW0020800003")}} <br />
        {{$t("CQW0020800005")}}<br />{{$t("CQW0020800006")}}
      </v-card-title>
      <v-card-actions class="justify-center">
        <v-btn width="120px" min-height="31px" @click="close(false)" color="error">
          {{$t("CQW0010600005")}}
        </v-btn>
        <v-btn width="120px" min-height="31px" @click="close(true)" color="primary">
          {{$t("CQW0010600006")}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </span>
</template>

<script>
import * as constants from '@/constants/constants'
import { repositoryFactory } from "@/repositories/repository-factory";
import * as utils from '@/utils/utils';
import { i18n } from '../../../../i18n.js'
import * as espg from '@/constants/espg';
const coordinateUploadRepository = repositoryFactory.get('coordinateUpload');
const dashboardEspgRepository  = repositoryFactory.get('dashboardEspg');
import Loading from "@/components/common/LoadingOverLay.vue";
import axios from 'axios';
export default {
  components: { Loading },
  name: "LocalFileUpload",
  props: {project: Object},
  data: () => ({
    tab0: false,
    tab1: true,
    localFile: undefined,
    isbtn1Disabled: true,
    isbtn2Disabled: true,
    firstDialog: true,
    confirmDialog: false,
    //モックデータ(ESPG_code,vShift,ESPG_code_items,GEO_code_items)
    ESPG_code: {coordinate_system:0, label:i18n.tc("CQW0020900011")},
    vShift:  {vertical_shift:0, label:i18n.tc("CQW0020900010")},
    ESPG_code_items:[
      {coordinate_system:0, label:i18n.tc("CQW0020900011")},
    ],
    GEO_code_items: [
      {vertical_shift:0, label:i18n.tc("CQW0020900010")},
    ],
    is_Dashboard: false,
    dashboardInfo: {type: "", horizontal: "", vertical: "", obliqueStereographic: {}},
    isLoading: false,
  }),
  mounted() {
    this.getEpsgItems();
    console.log(this.siteId);
  },
  computed: {
    /**
     * 現在開いているページがプロジェクトページか判定
     */
    isProjectPage() {
      return this.$route.name === constants.pageName.project;
    },
    /**
     * 現場IDを取得
     * 開いているページがプロジェクトページの場合はURLから現場IDを取得
     */
    siteId() {
      if (this.isProjectPage) return this.$route.query.siteId;
      else return this.project.site_id;
    }
  },
  watch: {
    // whenever value changes for this variables, this function will run
    is_Dashboard() {
      this.isbtn1Disabled = this.buttonVisibilityCheck(0);
    },
    ESPG_code() {
      this.isbtn2Disabled = this.buttonVisibilityCheck(1);
    },
    vShift() {
      this.isbtn2Disabled = this.buttonVisibilityCheck(1);
    },
  },
  methods: {
    search_coordinate(){
      //読込済みの場合は、何もしない
      if(this.items.length > 0) return
      //読込中の場合は、何もしない
      if(this.isLoading) return
      
      this.isLoading = true

      // APIから、選択肢をfetchする
        axios.get('https://api.publicapis.org/entries').then(res => {
            const {
                count,
                entries
            } = res.data
            this.count = count
            this.entries = entries
        }).catch(err => {
            console.log(err)
        //読み込みが完了で、loadingをfalseに
        }).finally(() => (this.isLoading = false))
    },
    search_vertical(){
      //読込済みの場合は、何もしない
      if(this.items.length > 0) return
      //読込中の場合は、何もしない
      if(this.isLoading) return
      
      this.isLoading = true

      // APIから、選択肢をfetchする
        axios.get('https://api.publicapis.org/entries').then(res => {
            const {
                count,
                entries
            } = res.data
            this.count = count
            this.entries = entries
        }).catch(err => {
            console.log(err)
        //読み込みが完了で、loadingをfalseに
        }).finally(() => (this.isLoading = false))
    },
    async cooperateWithDashboard() {
      this.isLoading = true;
      const user_id = this.$store.getters.loginData.user_id;
      let errorMessage = i18n.tc("CEW0020800001");
      try {
        const body = {
          user_id: user_id,
          site_id: this.siteId 
        }
        const res = await dashboardEspgRepository.getEspgInfoBySiteId(this.siteId, body);
        if(res.status === 201) {
          this.dashboardInfo.type = res.data.type;
          if (res.data.type == "NAMED_SYSTEM") {
            this.dashboardInfo.horizontal = res.data.namedSystem.horizontal;
            this.dashboardInfo.vertical = res.data.namedSystem.vertical;
            this.dashboardInfo.message = `
              ${i18n.tc("CQW0020125006")}

              ${i18n.tc("CQW0020900004")}: ${this.dashboardInfo.horizontal}
              ${i18n.tc("CQW0020900005")}: ${this.dashboardInfo.vertical}
                                         `
          } else {
            this.dashboardInfo.obliqueStereographic = res.data.obliqueStereographic;
            this.dashboardInfo.message = [`
            ${i18n.tc("CQW0020900003")}

            rotation: ${this.dashboardInfo.obliqueStereographic.rotation}
            origin east: ${this.dashboardInfo.obliqueStereographic.originEast}
            origin north: ${this.dashboardInfo.obliqueStereographic.originNorth}
            origin latitude: ${this.dashboardInfo.obliqueStereographic.originLatitude}
            origin longitude: ${this.dashboardInfo.obliqueStereographic.originLongitude}
            scale: ${this.dashboardInfo.obliqueStereographic.scale}
            verticalOffset: ${this.dashboardInfo.obliqueStereographic.verticalOffset}
            inclineX: ${this.dashboardInfo.obliqueStereographic.inclineX}
            inclineY: ${this.dashboardInfo.obliqueStereographic.inclineY}
            toMeters: ${this.dashboardInfo.obliqueStereographic.toMeters}
            `].join('\n');
          }
        } else {
          this.dashboardInfo.message = errorMessage;
        }
      } catch (e) {
        console.error(e);
        if (utils.createErrorMessage(e)) {
          errorMessage = utils.createErrorMessage(e);
        }
        utils.showSnackBar('error', errorMessage);
      } finally {
        this.is_Dashboard = true;
        this.isLoading = false;
      }
    },
    getEpsgItems() {
      this.ESPG_code_items = this.ESPG_code_items.concat(espg.COORDINATE_SYSTEM_SETTING_LIST);
      this.GEO_code_items = this.GEO_code_items.concat(espg.VERTICAL_SHIFT_SETTING_LIST);
    },
    async close(save_data) {
      this.firstDialog = true;
      this.confirmDialog = false;

      let uploadedData = undefined;
      let params = {
        coordinate_system: "",
        vertical_shift: "", 
        updated_by: this.$store.getters.loginData.user_id,
        rotation: "",
        origin_easting: "",
        origin_northing: "",
        origin_latitude: "",
        origin_longitude: "",
        scale_factor: "",
        vertical_offset: "",
        incline_x: "",
        incline_y: "",
        to_meters: "",
        updated_at: ""
      }
      if(save_data){
        uploadedData = {
          epsg: this.ESPG_code, 
          file: this.localFile, 
          v_Shift: this.vShift
        };
      } else {
        this.$emit("close");
        return false;
      }
      if(this.tab0) {
        if (this.dashboardInfo.type == "NAMED_SYSTEM") {
          params.coordinate_system = this.dashboardInfo.horizontal;
          params.vertical_shift = this.dashboardInfo.vertical;
        } else {
          params.rotation = this.dashboardInfo.obliqueStereographic.rotation;
          params.origin_easting = this.dashboardInfo.obliqueStereographic.originEast;
          params.origin_northing = this.dashboardInfo.obliqueStereographic.originNorth;
          params.origin_latitude = this.dashboardInfo.obliqueStereographic.originLatitude;
          params.origin_longitude = this.dashboardInfo.obliqueStereographic.originLongitude;
          params.scale_factor = this.dashboardInfo.obliqueStereographic.scale;
          params.vertical_offset = this.dashboardInfo.obliqueStereographic.verticalOffset;
          params.incline_x = this.dashboardInfo.obliqueStereographic.inclineX;
          params.incline_y = this.dashboardInfo.obliqueStereographic.inclineY;
          params.to_meters = this.dashboardInfo.obliqueStereographic.toMeters;
        }
      // 座標系から選択
      } else {
        params.coordinate_system = uploadedData.epsg.coordinate_system;
        params.vertical_shift = uploadedData.v_Shift.vertical_shift;
      }
      await this.request(params)
      this.dashboardInfo = {};
      this.$emit("close");
    },
    fileInp1Changed(){
      let txtContainer = document.querySelector(".fileInp1 .v-file-input__text");
      if(txtContainer){
        txtContainer.innerHTML = "";
      }
    },
    onTabSwitch(tab) {
      if (tab === 0) {
        this.tab0 = true;
        this.tab1 = false;
      } else {
        this.tab0 = false;
        this.tab1 = true;
      }
      this.is_Dashboard = false;
      this.dashboardInfo = {type: "", horizontal: "", vertical: "", obliqueStereographic: {}}
    },
    onSubmit() {
      this.firstDialog = false;
      this.confirmDialog = true;
    },
    buttonVisibilityCheck(tabNo) {
      if (tabNo == 0) {
        if (this.dashboardInfo.type) {
          return false;
        } else return true;
      } else if (tabNo == 1) {
        if (this.ESPG_code.coordinate_system != 0 && this.vShift.vertical_shift != 0) {
          return false;
        } else return true;
      }
    },
    async request(params) {
      let successMessage = i18n.tc("CIW0020800004");
      let errorMessage = i18n.tc("CEW0020800001");
      try {
        this.isLoading = true;
        // 呼び出し元のページを判定し現場IDを取得
        const res = await coordinateUploadRepository.update(this.siteId, params);
        if(res.status === 200) {
          utils.showSnackBar('success', successMessage);
          if (this.isProjectPage) {
            // プロジェクト画面の場合、現場情報を最新化する
            this.$store.dispatch("fetchSite", this.siteId);
          } else {
            // プロジェクトリスト画面の場合、プロジェクト画面に遷移する
            this.$emit('ok', this.project)
          }
        } else {
          utils.showSnackBar('error', errorMessage);
        }
      } catch (e) {
        console.error(e);
        if (utils.createErrorMessage(e)) {
          errorMessage = utils.createErrorMessage(e);
        }
        utils.showSnackBar('error', errorMessage);
      } finally {
        this.isLoading = false;
      }
    }
  },
};
</script>
<style lang="sass" scoped>

.content
  margin-top: 5%
  width: 90%
  height: 100%

.submit
  margin-left: 86%
  margin-bottom: 3%
  margin-top: -2%

.cnfDialog
  width: 50%
  margin-left: 30%

.map
  border: 1px solid
  border-style: ridge
  border-block-color: #c6ec1f
  margin-bottom: 2%
  margin-left: 4%
  width: 100%

// タブ
div.v-tab
  text-transform: none
</style>