import store from '@/store/index';
import * as constants from '@/constants/constants';
import * as utils from '@/utils/utils';
import { Color } from "pointworks-viewer";
import Repository from "../repositories/repository";

/**
 * viewerを範囲選択モードに変更
 * @param {*} selectOn 
 * @param {*} type 
 * @param {*} selectionType 
 */
export const beginSelecting = (selectOn, type, selectionType) => {
  if (store.getters.backgroundMap.visibility) {
    // 背景地図をOFFにする
    window['viewer'].setGlobeVisibility(false);
  }
  // 範囲選択モードに設定
  store.dispatch("updateMode", constants.viewerMode.selecting);
  switch (type) {
    case constants.cesiumSelectingType.polygon:
      window['viewer'].beginPolygonSelecting(selectOn, selectionType);
      break;
    case constants.cesiumSelectingType.box:
      window['viewer'].beginBoxSelecting(selectOn, selectionType);
      break;
    case constants.cesiumSelectingType.circle:
      window['viewer'].beginCircleSelecting(selectOn, selectionType);
      break;
  }
};

/**
 * viewerの範囲選択モードを解除
 */
export const removeSelection = async (editType) => {
  try {
    window['viewer'].removeSelection(editType);
    store.dispatch("updateMode", "");
  } catch (e) {
    console.error(e);
  }
};

/**
 * viewerを線形作成モードに変更
 */
export const drawRoute = async () => {
  // 背景地図をOFFにする
  let currentBackgroundMapVisibility = store.getters.backgroundMap.visibility;
  if (currentBackgroundMapVisibility) {
    changeGlobeVisibility(!currentBackgroundMapVisibility);
  }

  try {
    // 線形描画開始
    store.dispatch("updateMode", constants.viewerMode.lineCreation);
    return await window['viewer'].drawRoute();
  } catch (e) {
    console.error(e);
  } finally {
    // 背景地図を線形描画前の状態に戻す
    if (currentBackgroundMapVisibility) {
      changeGlobeVisibility(currentBackgroundMapVisibility);
    }
    store.dispatch("updateMode", "");
  }
  return "";
};

/**
 * viewerの線形作成モードを解除
 */
export const cancelLineCreation = () => {
  try {
    // 編集モードを解除する
    window['viewer'].cancelLineCreation();
    store.dispatch("updateMode", "");
  } catch (e) {
    console.error(e);
  }
};

/**
 * viewerを距離計測モードに設定
 */
export const measurementDistance = async () => {
  // 背景地図をOFFにする
  let currentBackgroundMapVisibility = store.getters.backgroundMap.visibility;
  if (currentBackgroundMapVisibility) {
    changeGlobeVisibility(!currentBackgroundMapVisibility);
  }

  try {
    store.dispatch("updateMode", constants.viewerMode.addPointsForDistanceCalculation);
    await window['viewer'].addPointsForDistanceCalculation();
  } catch (e) {
    console.error(e);
  } finally {
    // 背景地図を元に戻す
    if (currentBackgroundMapVisibility) {
      changeGlobeVisibility(currentBackgroundMapVisibility);
    }
    store.dispatch("updateMode", "");
  }
};

/**
 * viewerの距離計測モードを完了する
 */
export const finishSelecting = () => {
  try {
    window['viewer'].finishSelecting();
    store.dispatch("updateMode", "");
  } catch (e) {
    console.error(e);
  }
};

/**
 * viewerの距離計測モードをキャンセルする
 */
export const cancelSelecting = () => {
  try {
    window['viewer'].cancelSelecting();
    store.dispatch("updateMode", "");
  } catch (e) {
    console.error(e);
  }
};
/**
 * viewerに表示されている距離を削除する
 */
export const resetDistanceCalculation = () => {
  window['viewer'].resetDistanceCalculation();
};

let panningStartPosition = null;

const default2DCrossSectionEventHandler = (event, viewPosition) => {
  // 断面ビューアーのパン
  switch (event.type) {
    case "pointerdown": {
      panningStartPosition = viewPosition;
      break;
    }
    case "pointermove": {
      if (panningStartPosition) {
        window['viewer'].pan2DCrossSection({
          x: viewPosition.x - panningStartPosition.x,
          y: viewPosition.y - panningStartPosition.y,
        });
      }
      break;
    }
    case "pointerup": {
      if (panningStartPosition) {
        panningStartPosition = null;
      }
      break;
    }
  }
}

/**
 * viewerを断面編集モードに設定
 */
export const crossSectionEdit = (selectionType) => {
  switch (selectionType) {
    case constants.crossSectionViewerMode.move: {
      window['viewer'].setSurroundingPointsVisibility(true);
      let selectedSectionLinePointIndex = -1;
      let selectedSurroundingPointIndex = -1;
      window['viewer'].set2DCrossSectionEventHandler((event, elementType, elementIndex) => {
        switch (event.type) {
          case "pointerdown": {
            if (elementType === "SectionLinePoint") {
              selectedSectionLinePointIndex = elementIndex;
              selectedSurroundingPointIndex = -1;
              window['viewer'].set2DSectionLinePointColor(selectedSectionLinePointIndex, { red: 0x80 / 0x100, green: 0xD6 / 0x100, blue: 0x24 / 0x100 });
            }
            break;
          }
          case "pointermove": {
            if (elementType === "SurroundingPoint") {
              selectedSurroundingPointIndex = elementIndex;
              window['viewer'].move2DSectionLinePoint(selectedSectionLinePointIndex, selectedSurroundingPointIndex);
            }
            break;
          }
          case "pointerup": {
            if (selectedSectionLinePointIndex >= 0) {
              window['viewer'].set2DSectionLinePointColor(selectedSectionLinePointIndex, { red: 0xFF / 0x100, green: 0x00, blue: 0x00 });
              selectedSectionLinePointIndex = -1;
            }
            break;
          }
        }
      });
      break;
    }
    case constants.crossSectionViewerMode.add: {
      window['viewer'].setSurroundingPointsVisibility(true);
      window['viewer'].set2DCrossSectionEventHandler((event, elementType, elementIndex) => {
        switch (event.type) {
          case "pointerdown": {
            if (elementType === "SurroundingPoint") {
              window['viewer'].add2DSectionLinePoint(elementIndex);
            }
            break;
          }
        }
      });
      break;
    }
    case constants.crossSectionViewerMode.delete: {
      window['viewer'].set2DCrossSectionEventHandler((event, elementType, elementIndex) => {
        switch (event.type) {
          case "pointerdown": {
            if (elementType === "SectionLinePoint") {
              window['viewer'].remove2DSectionLinePoint(elementIndex);
            }
            break;
          }
        }       
      });
      break;
    }
  }
  store.dispatch("updateMode", constants.viewerMode.crossSectionEdit);
};

/**
 * viewerの断面編集モードを解除
 */
export const cancelCrossSectionEdit = () => {
  window['viewer'].setSurroundingPointsVisibility(false);
  const section = store.getters["sectionFile/visibleSectionFile"];
  if (section) {
    const linear = store.getters["linearFile/linearFileList"].find(linear => linear.linearId === section.linearId);
    const sectionLineIndex = store.getters["sectionFile/selectedLineIndex"];
    window['viewer'].set2DCrossSectionLine(linear.route, linear.stationInterval, sectionLineIndex, section.sectionInfo, section.surroundingPoints);
  }
  store.dispatch("updateMode", "");
};

//虫眼鏡(点群を拡大デフォルト表示)
export const visibilityOn = async (file, uncheckFlag) => {
  // 未タイリングの場合はタイリング
  if (!file.isTiling) {
    await tiling(file, uncheckFlag);
  } else {
    window['viewer'].setTilesetVisibility(file.assetId, true);
    zoomToEntity(file);
  }
  file.isVisible = true;
}

//虫眼鏡を機能として追加したので使用してない
export const visibilityOff = (file) => {
  window['viewer'].setTilesetVisibility(file.assetId, false);
  file.isVisible = false;
}

//通常視点バージョン
export const visibilityOnNormal = (file) => {
  // 未タイリングの場合はタイリング
  if (!file.isTiling) {
    tiling(file);
  } else {
    window['viewer'].setTilesetVisibility(file.assetId, true);
  }
  file.isVisible = true;
}
export const visibilityOffNormal = (file) => {
  window['viewer'].setTilesetVisibility(file.assetId, false);
  file.isVisible = false;
}

/**
 * 背景地図の可視不可視を切り替える
 */
export const changeGlobeVisibility = (visibility) => {
  window['viewer'].setGlobeVisibility(visibility);
  store.dispatch('updateBackgroundMap', { visibility })
}

/**
 * 背景地図の種類を変更する
 */
export const changeImageryLayer = (layerType) => {
  window['viewer'].changeImageryLayer(layerType);
  store.dispatch('updateBackgroundMap', { layerType })
}

/**
 * 特定の点群にズームする
 */
export const zoomToEntity = (file) => {
  window['viewer'].zoomToEntity(file.assetId);
  store.dispatch('setZoomedPointCloud', file)
}

export const addTileset = async (file) => {
  try {
    await window['viewer'].addTileset(
      file.assetId,
      store.getters.pointCloudPointSize,
      store.getters.pointCloudColoringType,
      store.getters.pointCloudShadingType,
    );
    file.isTiling = true;
  } catch(e) {
    console.error(e);
  }
}

export const removeTileset = async (file) => {
  window['viewer'].setTilesetVisibility(file.assetId, false)
  window['viewer'].removeTileset(String(file.assetId))
  file.isTiling = false;
}

export const tiling = async (file, uncheckFlag) => {
  // ステータスをタイリング実行中にする
  file.tilingProgress = true;
  let isAssetUploaded;
  if (!uncheckFlag) {
    isAssetUploaded =
    await file.waitAssetUploading().catch(err => err);
  }

  try {
    if (isAssetUploaded === constants.assetUploadStatus.DONE || uncheckFlag) {
      if (file.mode === 'clipping') {
        await clippingTiling(file);
      } else {
        await addTileset(file);
      }
    }
  } catch (e) {
    console.error(e);
  } finally {
    // タイリング実行中のステータスを解除する
    file.tilingProgress = false;
  }
}

export const clippingTiling = async (file) => {
  // タイリング
  await addTileset(file);
  if (file.reqInfo) {
    try {
      const reqInfo = JSON.parse(file.reqInfo);
      // 選択範囲の復元
      window['viewer'].addSelectionFromJSONData(reqInfo, constants.editType.clipping);
      // visibilityがtrueの点群が全てクリッピング対象となってしまうため、クリッピング実行前に一時的に他の点群のvisibilityをfalseにする
      store.getters.visiblePointClouds.forEach(point => {
        if (file.pointId !== point.pointId) {
          window['viewer'].setTilesetVisibility(point.assetId, false)
        }
      });
      // クリッピング実行
      window['viewer'].createClippingPlanes();
      // visibilityを戻す
      store.getters.visiblePointClouds.forEach(point => {
        if (file.pointId !== point.pointId) {
          window['viewer'].setTilesetVisibility(point.assetId, true)
        }
      });
    } catch (e) {
      console.error(e);
    }
  }
}
//虫眼鏡機能用
export const designVisibilityOn = (file) => {
  // 未タイリングの場合はタイリング
  if (!file.isTiling) {
    tilingDesign(file);
  } else {
    window['viewer'].setTilesetVisibility(file.assetId, true);
    zoomToEntity(file);
  }
  file.isVisible = true;
}
//虫眼鏡機能追加で使用していない
export const designVisibilityOff = (file) => {
  window['viewer'].setTilesetVisibility(file.assetId, false);
  file.isVisible = false
}

//通常視点
export const designVisibilityOnNormal = (file) => {
  // 未タイリングの場合はタイリング
  if (!file.isTiling) {
    tilingDesign(file);
  } else {
    window['viewer'].setTilesetVisibility(file.assetId, true);
  }
  file.isVisible = true;
}

export const designVisibilityOffNormal = (file) => {
  window['viewer'].setTilesetVisibility(file.assetId, false);
  file.isVisible = false
}

export const addTilesetDesign = async (file) => {
  try {
    await window['viewer'].addTilesetDesign(file.assetId)
    file.isTiling = true;
  } catch(e) {
    console.error(e);
  }
}

export const tilingDesign = async (file) => {
  // ステータスをタイリング実行中にする
  file.tilingProgress = true;

  const isAssetUploaded = 
    await file.waitAssetUploading().catch(err => err);
  try {
    if (isAssetUploaded === constants.assetUploadStatus.DONE) {
      await addTilesetDesign(file);
    }
  } catch(e) {
    console.error(e);
  } finally {
    // タイリング実行中のステータスを解除する
    file.tilingProgress = false;
  }
}

const Colors = {
  GREEN: { red: 0, green: 0.5, blue: 0 },
  LIGHTBLUE: { red: 0xAD / 0x100, green: 0xD8 / 0x100, blue: 0xE6 / 0x100 },
  ORANGE: { red: 1, green: 0xA5 / 0x100, blue: 0 },
  RED: { red: 1, green: 0, blue: 0 },
  TURQUOISE: { red: 0x40 / 0x100, green: 0xE0 / 0x100, blue: 0xD0 / 0x100 },
  YELLOW: { red: 1, green: 1, blue: 0 },
  WHITE: { red: 1, green: 1, blue: 1 },
};

const defaultLinearEventHandler = (linearId, event, elementType) => {
  if (event.type === "pointerdown") {
    const color = elementType === "None" ? Colors.GREEN : Colors.YELLOW;
    window["viewer"].setRouteLineColor(linearId, color);
  }
};

/**
 * 線形データを表示する
 * @param {Linear} linear 
 */
export const showLinearData = (linear) => {
  window["viewer"].addRoute(linear.linearId, linear.routeEpsg4978, linear.stationInterval);
  window["viewer"].setRouteEventHandler(linear.linearId, defaultLinearEventHandler);
  linear.isVisible = true;
  linear.viewerLinearId = linear.linearId;
}

/**
 * 線形データを非表示にする
 * @param {Linear} linear
 */
export const hideLinearData = (linear) => {
  window['viewer'].removeRoute(linear.linearId);
  window['viewer'].removeRouteEventHandler(linear.linearId);
  linear.isVisible = false;
  linear.viewerLinearId = "";

  const visibleSectionFile = store.getters["sectionFile/visibleSectionFile"];
  if (visibleSectionFile && (visibleSectionFile.linearId === linear.linearId)) {
      changeSectionVisible(visibleSectionFile);
  }
}

/**
 * 線形データの表示非表示を切り替える
 * @param {*} linear 
 */
export const changeLinearVisible = (linear) => {
  try {
    // 断面データも非表示にする
    const visibleSectionFile = store.getters["sectionFile/visibleSectionFile"];
    if (visibleSectionFile && visibleSectionFile.linearId !== linear.linearId)
      return;
    if (!linear.isVisible) {
      // 表示する
      showLinearData(linear);
    } else {
      // 非表示にする
      hideLinearData(linear);
    } 
  } catch(e) {
    console.error(e); 
  }
}

/**
 * 3D 断面線を表示する
 * @returns 
 */
export const show3DCrossSectionLines = (section, selectedLineIndex) => {
  window['viewer'].set3DCrossSectionLines(section.linearId, section.sectionInfoEpsg4978);
  window["viewer"].setCrossSectionLineColor(section.linearId, Colors.RED, selectedLineIndex);
};

/**
 * 断面データの表示非表示を切り替える
 * @param {*} section
 */
export const changeSectionVisible = async (section) => {
  try {
    if (section.status !== constants.exeProcessStatus.succeeded) {
      return;
    }
    if (!section.isVisible) {
      const point = utils.getPointFileByPointId(section.pointId);

      const linear = store.getters["linearFile/linearFileList"].find(
        linear => linear.linearId === section.linearId
      );
      // 紐づく点群と線形データが存在し、点群がタイリング可能な場合のみ表示
      if ( point && linear ) {

        // 表示中の線形データを非表示にする
        store.getters["linearFile/visibleLinearFileList"].forEach(async (linearData) => {
          changeLinearVisible(linearData);
        });

        // 点群データが非表示の場合、表示状態にする
        if (!point.isVisible) {
          await visibilityOn(point, true);
          zoomToEntity(point);
        }

        // 線形データが非表示の場合、表示状態にする
        if (!linear.isVisible) {
          changeLinearVisible(linear);
        }

        // 断面を表示する
        section.isVisible = true;

        show3DCrossSectionLines(section, 0);

        const eventHandler = (linearId, event, elementType, crossSectionIndex) => {
          if (event.type === "pointerdown" && elementType !== "None") {
            window["viewer"].setRoutePointColor(linearId, Colors.TURQUOISE, "IntersectionPoint");
            window["viewer"].setRoutePointColor(linearId, Colors.LIGHTBLUE, "StationPoint");
            window["viewer"].setRoutePointColor(linearId, Colors.RED, crossSectionIndex);
            window["viewer"].setCrossSectionLineColor(linearId, Colors.ORANGE);
            window["viewer"].setCrossSectionLineColor(linearId, Colors.RED, crossSectionIndex);
            window["viewer"].set2DCrossSectionLine(linear.route, linear.stationInterval, crossSectionIndex, section.sectionInfo, section.surroundingPoints);
            store.commit("sectionFile/setSelectedLineIndex", crossSectionIndex);
          }
        };
        window["viewer"].setRouteEventHandler(section.linearId, eventHandler);

        window["viewer"].setRoutePointColor(section.linearId, Colors.RED, 0);
        window["viewer"].set2DCrossSectionLine(linear.route, linear.stationInterval, 0, section.sectionInfo, section.surroundingPoints);
        window["viewer"].set2DCrossSectionEventHandler(default2DCrossSectionEventHandler);
        store.commit("sectionFile/setSelectedLineIndex", 0);
        window.dispatchEvent(new Event("onSetCrossSectionVisible"));
      }
    } else {
      // 非表示にする
      section.isVisible = false;
      section.viewerSectionId = "";
      window["viewer"].setRoutePointColor(section.linearId, Colors.TURQUOISE, "IntersectionPoint");
      window["viewer"].setRoutePointColor(section.linearId, Colors.LIGHTBLUE, "StationPoint");
      window["viewer"].remove3DCrossSectionLines(section.linearId);
      window["viewer"].setRouteEventHandler(section.linearId, defaultLinearEventHandler);
      window["viewer"].set2DCrossSectionEventHandler(null);
      // 表示中の断面ファイルが無い場合断面ビューを閉じる
      if (!store.getters["sectionFile/visibleSectionFile"]) {
        window.dispatchEvent(new CustomEvent("onSetCrossSectionHidden"));
      }
    } 
  } catch(e) {
    console.error(e);
  }
}

/**
 * 断面ビューアーから断面線を取得する
 */
export const getLocalizedCrossSectionLine = () => {
  return window["viewer"].getLocalizedCrossSectionLine();
};

/**
 * 平坦度データの表示非表示を切り替える
 * @param {*} flatnessData
 */
export const changeFlatnessDataVisible = (flatnessData) => {
  if (!flatnessData.isVisible) {
    if (flatnessData.isLoaded) {
      // 計測結果を表示する
      window['viewer'].setFlatnessVisibility(flatnessData.viewerFlatnessId, true)
      window['viewer'].setFlatnessGridVisibility(flatnessData.viewerFlatnessId, false)
      flatnessData.isVisible = true;
    } else {
      window['viewer'].getLocalizedFlatnessData(
        flatnessData.assetId,
        flatnessData.selectInfo,
        flatnessData.gridSize
      );
      // 計測結果をViewerに読み込む
      const json = flatnessData.flatnessInfo;
      const id = window["viewer"].addFlatnessGrid(json);
      flatnessData.isVisible = true;
      flatnessData.isLoaded = true;
      flatnessData.viewerFlatnessId = id;
    }
    window['viewer'].setFlatnessGridVisibility(flatnessData.viewerFlatnessId, false)
    const heightsAndColors = window['viewer'].getFlatnessColorArray(flatnessData.assetId, flatnessData.viewerFlatnessId)
    flatnessData.heights = heightsAndColors.heights;
    flatnessData.colors = heightsAndColors.colors;
  } else {
    // 計測結果を非表示にする
    window['viewer'].setFlatnessVisibility(flatnessData.viewerFlatnessId, false)
    flatnessData.isVisible = false;
  } 
}

/**
 * 外周線を表示する
 * @param {*} perimeterLine 
 */
export const showPerimeterLine = (perimeterLine) => {
  window['viewer'].setPerimeterLines(perimeterLine.perimeterLineInfo);
  perimeterLine.isVisible = true;
}

/**
 * 外周線を非表示にする
 */
export const hidePerimeterLine = (perimeterLine) => {
  window['viewer'].removePerimeterLines();
  perimeterLine.isVisible = false;
}

/**
 * 計測結果を表示する
 * @param {*} measureData 
 * @param {*} labelFormatter 
 * @returns 
 */
export const showMeasuredResults = (measureData, labelFormatter) => {
  try {
    const viewer = window['viewer'];
    const selectInfoForRepresentation = measureData.selectInfo.forRepresentation;

    viewer.addSelectionFromJSONData(selectInfoForRepresentation, constants.editType.numberOfPoints);

    const boundingBoxOfSelection = viewer.calcAABBOfSelection(selectInfoForRepresentation.selection);
    if (labelFormatter) {
      const label = {
        text: labelFormatter(measureData),
        font: "10px",
        fillColor: Color.BLACK,
        showBackground: true,
        backgroundColor: Color.WHITE,
        disableDepthTestDistance: Number.POSITIVE_INFINITY,
      };
      measureData.labelId = viewer.addLabel(boundingBoxOfSelection.center, label);
    }

    return true;
  }
  catch (e) {
    console.error(e);
    return false;
  }
}

/**
 * 計測結果を非表示にする
 * @param {*} measureData 
 * @returns 
 */
export const hideMeasuredResults = (measureData) => {
  try {
    const viewer = window['viewer'];
    viewer.removeSelection(constants.editType.numberOfPoints);
    if (measureData.labelId) {
      viewer.removeLabel(measureData.labelId);
    }
    return true;
  }
  catch (e) {
    console.error(e);
    return false;
  }
}

export const transformPointToCesiumCrs = async ({ x, y, z }) => {
  const result = await transformPointsToCesiumCrs([{x,y,z}]);
  if (result.length !== 1) {
    throw new Error("Cannot transform point to site CRS.");
  }
  return result[0];
};

export const transformPointsToCesiumCrs = async (points) => {
  //epsg
  const epsg = store.state.site.coordinate_system;
  let epsg_v = store.state.site.vertical_shift;
  if (epsg_v) {
    //epsg_v = epsg_v.substring(5);
    const result = epsg_v.match(/([\d])\w+/g);
    if (result.length > 0) {
      epsg_v = result[0];
    }
    else {
      epsg_v = undefined;
    }
  }

  //proj string
  const rotation = store.state.site.rotation;
  const origin_easting = store.state.site.origin_easting;
  const origin_northing = store.state.site.origin_northing;
  const origin_latitude = store.state.site.origin_latitude;
  const origin_longitude = store.state.site.origin_longitude;
  const scale_factor = store.state.site.scale_factor;
  const vertical_offset = store.state.site.vertical_offset;
  const incline_x = store.state.site.incline_x;
  const incline_y = store.state.site.incline_y;

  let data;

  if (!(epsg === null || epsg === undefined || epsg === "")) {
    data = {
      from: `${epsg}` + (epsg_v ? `+${epsg_v}`: ""),
      to: "EPSG:4978",
      points,
    };
  }

  if (!data) {
    if( !(rotation === null || rotation === "" || rotation === undefined)) {
      data = {
        stereoParameters: [
          `rotation=${rotation}`,
          `origin_easting=${origin_easting}`,
          `origin_northing=${origin_northing}`,
          `origin_latitude=${origin_latitude}`,
          `origin_longitude=${origin_longitude}`,
          `scale_factor=${scale_factor}`,
          `vertical_offset=${vertical_offset}`,
          `incline_x=${incline_x}`,
          `incline_y=${incline_y}`,
        ],
        stereoParametersKeyword: "--local-to-wgs84",
        points: points,
        to: "EPSG:4978",
        from: "EPSG:4326+ESRI:115700",
      };
    }
  }

  try {
    if (data) {
      const url = `/pointCloudTransformer`;

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        auth: store.state.authObject,
        withCredentials: false,
      };
      const response = await Repository.post(url, data, config);
      if (response.data && Array.isArray(response.data) && response.data.length > 0) {
        return response.data;
      }
    }
  } catch (e) {
    console.error(e);
  }
  return [];
};

export const transformPointToSiteCrs = async (point) => {
  const result = await transformPointsToSiteCrs([point]);
  if (result.length !== 1) {
    console.error(`(x:${point.x}, y:${point.y}, z:${point.z})`);
    throw new Error("Cannot transform point to site CRS.");
  }
  return result[0];
};

export const transformPointsToSiteCrs = async (points) => { //{x,y,z}[]
  //epsg
  const epsg = store.state.site.coordinate_system;
  let epsg_v = store.state.site.vertical_shift;
  if (epsg_v) {
    //epsg_v = epsg_v.substring(5);
    const result = epsg_v.match(/([\d])\w+/g);
    if (result.length > 0) {
      epsg_v = result[0];
    }
    else {
      epsg_v = undefined;
    }
  }

  //proj string
  const rotation = store.state.site.rotation;
  const origin_easting = store.state.site.origin_easting;
  const origin_northing = store.state.site.origin_northing;
  const origin_latitude = store.state.site.origin_latitude;
  const origin_longitude = store.state.site.origin_longitude;
  const scale_factor = store.state.site.scale_factor;
  const vertical_offset = store.state.site.vertical_offset;
  const incline_x = store.state.site.incline_x;
  const incline_y = store.state.site.incline_y;

  let data;

  if (!(epsg === null || epsg === undefined || epsg === "")) {
    data = {
      from: "EPSG:4978",
      to: `${epsg}` + (epsg_v ? `+${epsg_v}`: ""),
      points,
    };
  }

  if (!data) {
    if (!(rotation === null || rotation === undefined || rotation === "")) {
      data = {
        stereoParameters: [
          `rotation=${rotation}`,
          `origin_easting=${origin_easting}`,
          `origin_northing=${origin_northing}`,
          `origin_latitude=${origin_latitude}`,
          `origin_longitude=${origin_longitude}`,
          `scale_factor=${scale_factor}`,
          `vertical_offset=${vertical_offset}`,
          `incline_x=${incline_x}`,
          `incline_y=${incline_y}`,
        ],
        stereoParametersKeyword: "--wgs84-to-local",
        from: "EPSG:4978",
        to: "EPSG:4326+ESRI:115700",
        points: points,
      };
    }
  }

  try {
    if (data) {
      const url = `/pointCloudTransformer`;

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        auth: store.state.authObject,
        withCredentials: false,
      };
      const response = await Repository.post(url, data, config);
      if (response.data && Array.isArray(response.data) && response.data.length > 0) {
        return response.data;
      }
    }
  } catch (e) {
    console.error(e);
  }
  return [];
};

export const remove3DSectionLines = async(linearId) => {
  window["viewer"].remove3DCrossSectionLines(linearId);
}

/**
 * 線形編集
 */
let lineEditReject = null;
export const editLine = async (linearId) => {
  store.dispatch("updateMode", constants.viewerMode.SIMAEdit);
  return new Promise((resolve, reject) => {
    lineEditReject = reject;
    // 背景地図をOFFにする
    const currentBackgroundMapVisibility = store.getters.backgroundMap.visibility;
    if (currentBackgroundMapVisibility) {
      changeGlobeVisibility(!currentBackgroundMapVisibility);
    }

    // ドラッグによる移動禁止
    window['viewer'].enableRotate = false;

    let selectedIndex = -1;
    let latestPosition = null;
    window['viewer'].setRouteEventHandler(linearId, (linearId, event, elementType, elementIndex) => {
      console.log(`${elementType}: ${event.type}`);
      switch (event.type) {
        case "contextmenu": {
          // 右クリックで線形編集モード解除
          window['viewer'].enableRotate = true;
          window['viewer'].setRouteEventHandler(linearId, defaultLinearEventHandler);

          lineEditReject = null;
          resolve(window['viewer'].getRoute(linearId));
          return;
        }
        case "pointerup": {
          if (latestPosition && selectedIndex >= 0) {
            window['viewer'].fixRoutePoint(linearId, selectedIndex, latestPosition);
            latestPosition = null;
          }
          selectedIndex = -1;
          console.log("selectedIndex: " + selectedIndex);
          return;
        }
        case "pointerdown": {
          if (elementType === "IntersectionPoint") {
            // ドラッグ対象のIPのインデックスを保持
            selectedIndex = elementIndex;
            console.log("selectedIndex: " + selectedIndex);
          }
          return;
        }
        case "pointermove": {
          if (selectedIndex >= 0) {
            const position = window['viewer'].pickPosition(event);
            if (position) {
              window['viewer'].moveRoutePoint(linearId, selectedIndex, position);
              latestPosition = position;
            }
          }
        }
      }
    });
  });
};

/**
 * viewerの線形編集モードを解除
 */
export const cancelLineEdit = () => {
    // 編集モードを解除する
    if (lineEditReject) {
      lineEditReject(new Error("Line edit canceled."));
    }
};
