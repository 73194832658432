<!-- 断面作成 -->
<template>
    <v-card class="project-dialog px-4 pt-2 pb-4">
    <v-card-title class="pa-0 justify-space-between">
      <label>
        {{$t("CIW0020000039")}}
      </label>
      <close-button @click="$emit('close')" size="20px"></close-button>
    </v-card-title>
    <v-card-text class="px-0 pt-1 pb-0 white--text">
      <v-container class="pa-0">
        <v-row class="ma-0">
          <validation-observer
            class="d-block"
            style="width: 100%"
            ref="observer"
            v-slot="{ invalid, pristine }"
          >
            <!-- 点群データ -->
            <v-col cols="12" class="my-3 pa-0">
              <editing-point-clouds :title="$t('CIW0020000008')">
              </editing-point-clouds>
            </v-col>
            <!-- 線形データを選択 -->
            <v-col cols="12" class="my-3 pa-0">
              <label class="mb-1 d-block">{{$t("CQW0020124003")}}</label>
              <v-select
                v-model="selectedLinearData"
                class="text-black"
                height="32px"
                background-color="#BFBFBF"
                :items="linearList"
                item-text="text"
                item-value="value"
                hide-details
                solo
              >
              </v-select>
            </v-col>
            <!-- 横断画幅 -->
            <v-col cols="12" class="mt-3 pa-0">
              <v-row class="ma-0">
                <v-col cols="6">
                  <v-row>
                    <v-col cols="12" class="mt-3 pa-0">
                      <div class="mb-1">
                        <label class="d-inline-block mr-2">{{$t("CQW0020124004")}}</label>
                      </div>
                      <validation-provider :rules="{
                        required: true,
                        'number-format': true,
                        'decimal-size-limit': 2,
                        'min-value': 1,
                        'max-value': 500.99,
                      }" v-slot="{ errors }">
                        <!-- 左 -->
                        <div>
                          <span style="font-size: 12px; color: #ff5252;">{{ errors[0] }}</span>
                        </div>
                        <v-text-field
                          @reset.prevent="resetError()"
                          v-model="left"
                          :error-messages="errors"
                          class="text-black ma-0 mb-3"
                          height="32px"
                          background-color="#BFBFBF"
                          outlined
                          solo
                          suffix="m"
                          >
                          <template v-slot:prepend>
                            {{$t("CQW0020124005")}}
                          </template>
                        </v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" class="mt-3 pa-0">
                      <validation-provider :rules="{
                        required: true,
                        'number-format': true,
                        'decimal-size-limit': 2,
                        'min-value': 1,
                        'max-value': 500.99,
                      }" v-slot="{ errors }">
                        <!-- 右 -->
                        <div>
                          <span style="font-size: 12px; color: #ff5252;">{{ errors[0] }}</span>
                        </div>
                        <v-text-field
                          @reset.prevent="resetError()"
                          v-model="right"
                          :error-messages="errors"
                          class="text-black ma-0"
                          height="32px"
                          background-color="#BFBFBF"
                          outlined
                          solo
                          suffix="m"
                          >
                          <template v-slot:prepend>
                            {{$t("CQW0020124006")}}
                          </template>
                        </v-text-field>
                      </validation-provider>
                    </v-col>
                    <!-- 奥行 -->
                    <v-col cols="12" class="mt-3 pa-0">
                      <validation-provider :rules="{
                        required: true,
                        'number-format': true,
                        'decimal-size-limit': 2,
                        'min-value': 0.01,
                        'max-value': 100.99,
                      }" v-slot="{ errors }">
                        <div class="mb-1">
                          <label class="d-inline-block mr-2">{{$t("CQW0020124007")}}</label>
                          <span class="d-inline-block" style="font-size: 12px; color: #ff5252">{{ errors[0] }}</span>
                        </div>
                        <v-text-field
                          @reset.prevent="resetError()"
                          v-model="depth"
                          :error-messages="errors"
                          class="text-black ma-0"
                          height="32px"
                          background-color="#BFBFBF"
                          outlined
                          solo
                          suffix="m"
                          >
                        </v-text-field>
                      </validation-provider>
                    </v-col>
                    <!-- フィルタ半径 -->
                    <v-col cols="12" class="mt-3 pa-0">
                      <validation-provider :rules="{
                        required: true,
                        'number-format': true,
                        'decimal-size-limit': 2,
                        'min-value': 0.01,
                        'max-value': 100.99,
                      }" v-slot="{ errors }">
                        <div class="mb-1">
                          <label class="d-inline-block mr-2">{{$t("CQW0020124008")}}</label>
                          <span class="d-inline-block" style="font-size: 12px; color: #ff5252">{{ errors[0] }}</span>
                        </div>
                        <v-text-field
                          @reset.prevent="resetError()"
                          v-model="filterRadius"
                          :error-messages="errors"
                          class="text-black ma-0"
                          height="32px"
                          background-color="#BFBFBF"
                          outlined
                          solo
                          suffix="m"
                          >
                        </v-text-field>
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <p>{{ $t("CQW0020113011") }}</p>
                    <v-img
                      class="description-image"
                      @click="showItemDescription = true"
                      width="100%"
                      :src="descriptionImagePath"
                    ></v-img>
                </v-col>
              </v-row>
            </v-col>
            <!-- ボタン -->
            <v-col cols="12" class="mt-4 pa-0 d-flex justify-end">
              <v-btn
                class="rounded-lg mr-10"
                color="#ff5050"
                width="115px"
                height="45px"
                depressed
                @click="$emit('close')">
                {{$t("CQW0010600005")}}
              </v-btn>
              <v-btn
                class="rounded-lg"
                color="#4472c4"
                width="115px"
                height="45px"
                depressed
                :disabled="pristine || invalid || isExecutable"
                @click="execute">
                {{$t("CQW0020109005")}}
              </v-btn>
            </v-col>
          </validation-observer>
        </v-row>
      </v-container>
    </v-card-text>
    <!-- 解説画像 -->
    <v-dialog
      v-model="showItemDescription"
      max-width="600px"
    >
      <v-img
        @click="showItemDescription = false"
        width="100%"
        :src="descriptionImagePath"
      ></v-img>
    </v-dialog>
  </v-card>
</template>

<script>
import CloseButton from "@/components/common/CloseButton.vue";
import EditingPointClouds from "@/components/Project/Dialogs/EditingPointClouds.vue";
import * as utils from "@/utils/utils";
import * as cesiumCommon from "@/utils/cesium-common";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { i18n } from "@/i18n.js";
import { repositoryFactory } from "@/repositories/repository-factory";
import Section from "../../../models/Section";
const sectionFileRepository = repositoryFactory.get('sectionFile');

export default {
    components: {
        EditingPointClouds,
        CloseButton,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return{
            showItemDescription: false,
            descriptionImagePath: require('@/assets/images/cross-section-create.png'),
            selectedLinearData: "",
            left: "",
            right: "",
            depth: "",
            filterRadius: "",
        }
    },
    computed: {
        linearList() {
            //線形データの選択項目を作成
            return this.$store.getters["linearFile/linearFileList"]
                  .map(linear => (
                    { value: linear, text: linear.linearName }
                  ));
        },
        isExecutable() {
          if (!this.$store.getters.editingPointClouds.length) return true
          else return false
        }
    },
    methods: {
        resetForm() {
            this.left = "";
            this.right = "";
            this.depth = "";
            this.filterRadius = "";
            this.resetForm();
        },
        async resetError() {
            await this.$refs.observer.validate();
            await this.$refs.observer.reset();
        },
        /**
         * 断面作成処理を実行指示する
         */
        async execute() {
          // API送信
          const body = {
            point_ids: this.$store.getters.editingPointClouds.map(cloud => cloud.pointId),
            cross_section_source: this.createCrossSectionSource(),
            linear_id: this.selectedLinearData.linearId,
            left_side_width: this.left,
            right_side_width: this.right,
            depth: this.depth,
            radius: this.filterRadius,
            user_id: this.$store.getters.loginData.user_id
          };
          this.request(body);
        },
        /**
         * 選択した線形データに測点を付加して取得
         */
        createCrossSectionSource() {
          // 選択した線形データをviewerにロード
          if (this.selectedLinearData.isVisible) {
            // ロード済みの場合一度削除
            cesiumCommon.changeLinearVisible(this.selectedLinearData);
          }
          cesiumCommon.changeLinearVisible(this.selectedLinearData);
          try {
            return window['viewer'].createCrossSectionSource(
              this.selectedLinearData.route,
              this.selectedLinearData.stationInterval,
              Number(this.left),
              Number(this.right),
            );
          } catch (e) {
            utils.showSnackBar("error", i18n.tc("CEW0020124009")); // TODO: メッセージの文言が実態と合っていないので CIW0020124004 などに修正する
            console.error(e);
            return [];
          }
        },
        async request(body) {
          let successMessage = i18n.tc("CIW0020124003")
          let errorMessage = i18n.tc("CIW0020124004")

          this.$store.dispatch("updateIsLoading", true);
          try {
            const res = await sectionFileRepository.createSectionFile(body);
            if (res.status !== 201) {
              // 想定外のステータスコードが返却された場合エラーをスロー
              throw new Error(errorMessage);
            }
            // エラーがなければ正常メッセージを出力する
            utils.showSnackBar("success", successMessage);
            const sectionFiles = res.data.map(section => new Section(
              section.section_id,
              section.section_name,
              section.section_info,
              section.section_info_epsg4978,
              section.surrounding_points,
              section.point_id,
              section.linear_id,
              section.left_side_width,
              section.right_side_width,
              section.status,
              section.message,
            ));
            this.$store.commit('sectionFile/addSectionFiles', sectionFiles);
            this.$emit('close');
          } catch (e) {
            // エラーが発生したら処理終了
            console.error(e.message);
            if (utils.createErrorMessage(e)) {
              errorMessage = utils.createErrorMessage(e);
            }
            utils.showSnackBar("error", errorMessage);
          } finally {
            this.$store.dispatch("updateIsLoading", false);
          }
        }
    },
};
</script>

<style lang="sass" scoped>
.project-dialog
  .v-card__title
    font-size: 16px

::v-deep .v-input__append-outer
  margin: 0

::v-deep .v-input__slot
  margin: 0
  padding: 0

.text-black ::v-deep input,
.text-black ::v-deep label,
.text-black ::v-deep .v-select__selection,
.text-black ::v-deep .v-input__append-inner i
  color: black

::v-deep .v-text-field input,
::v-deep .v-text-field.v-input .v-select__selections input
  padding: 0

.text-field-error
  border: solid 2px #ff5252

::v-deep .v-text-field__details
  display: none

::v-deep .v-text-field__suffix
  color: black

:v-deep div.v-input.v-text-field div.v-input__prepend-outer
  margin: auto 8px auto 1px
  color: white

::v-deep button.v-btn span.v-btn__content
  font-size: 13px

.description-image
  cursor: pointer
</style>