<template>
  <v-footer
    v-if="isShow"
    id="chart-board"
    app
    inset
    :height="footerHeight"
    color="panel_main"
    class="pa-0"
  >
    <p class="ma-2">断面ビュー</p>
    <div id="crossSectionContainer" class="crossSectionContainer" style="width: 100%; height: 100%;" ref="container"></div>
    <v-btn absolute icon style="right: 10px; top: 6px">
      <v-icon @click="toggleChartBoardMini()">
        {{ this.mini ? "icon-Chevron-Up" : "icon-Chevron-Down" }}
      </v-icon>
    </v-btn>
  </v-footer>
</template>

<script>
import * as constants from '@/constants/constants'
import ContextmenuHandler from '@/components/modules/contextmenu-handler';

export default {
  name: "ChartBoard",
  data: () => ({
    mini: false,
    height: {
      //hidden: "0px",
      notProjectPage: "0px",
      normal: "240px",
      mini: "40px",
    }, 
    isShow: false,
  }),
  computed: {
    /**
     * 現在のページがプロジェクトページが判定する
     */
    isProjectPage() {
      // プロジェクトページ
      if ( this.$route.name === constants.pageName.project )
      {
        return true;
      } else {
        return false;
      }
    },
    footerHeight() {
      // if (!this.isShow) return this.height.hidden;
      if (!this.isProjectPage) return this.height.notProjectPage;
      else if (this.mini) return this.height.mini;
      else return this.height.normal;
    }
  },
  watch: {
    /**
     * プロジェクトページが開かれた際、canvasのサイズを変更する
     * @param {*} _ 
     * @param {*} oldHeight 
     */
    footerHeight( _, oldHeight ) {
      // resizeイベントを発火させてcanvasのサイズを変更する
      if (oldHeight === this.height.notProjectPage) {
        setTimeout(()=>{
          const event = new Event("resize")
          window.dispatchEvent(event)
        }, 1000)
      }
    }
  },
  methods: {
    toggleChartBoardMini() {
      this.mini = !this.mini;
    },
    contextMenuEventHandler(event) {
      const contextMenuHandler =  new ContextmenuHandler(this);
      switch (this.editType) {
        case constants.editType.crossSectionEdit:
          contextMenuHandler.showCrossSectionEditConfirmDialog(event);
          console.log(`show the dialog.`);
          break;
        default:
          break;
      }
    }
  },
  mounted(){
    console.log("onMiounted");
    window.addEventListener("onSetCrossSectionVisible", ()=> {
      this.isShow= true;
      this.$nextTick(()=>{
        this.$refs.container.append(window["viewer"].getCrossSectionCanvas());
        window.dispatchEvent(new Event("resize"));
        window["viewer"].fitInCrossSectionView();
      });
    });
    window.addEventListener("onSetCrossSectionHidden", ()=> {
      this.isShow= false;
    });
    window.addEventListener("contextmenu", this.contextMenuEventHandler);
  }
};
</script>

<style lang="sass" scoped>
.v-footer
  align-items: start !important
  transition: all 0.2s
</style>
