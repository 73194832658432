
<template>
  <v-card class="project-dialog">
    <v-card-title class="pt-2 justify-space-between">
      <span>{{ $t("CQW0020133004") }}</span>
      <close-button @click="$emit('close')" size="18px"></close-button>
    </v-card-title>
    <v-card-text class="py-0 pb-0" style="color: white">
      <p>
        {{ $t("CQW0020133002") }}<br>
        {{ $t("CQW0020133005") }}
      </p>
    </v-card-text>
    <v-card-actions class="pa-0 pb-5 justify-center">
      <v-btn class="pa-3 mr-2 btn" color="#ff5050" width="125px" height="45px" @click="$emit('close')">
        {{ $t("CQW0010600005") }}
      </v-btn>
      <v-btn class="pa-3 mr-1 btn" color="#4472c4" width="125px" height="45px" @click="ok">
        {{ $t("CQW0010600006") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import CloseButton from "@/components/common/CloseButton.vue";

export default {
  components: {
    CloseButton
  },
  props: {
    OKHandler: Function
  },
  methods: {
    ok() {
      this.$emit('close');
      setTimeout(this.OKHandler, 100);
    }
  },
};
</script>
<style lang="sass" scoped>
.project-dialog
  .v-card__title
    font-size: 16px

.project-dialog .btn
    font-size: 13px

.project-dialog
  p,span
    color: white
</style>