import Vue from 'vue';
import * as constants from '@/constants/constants';
import { i18n } from '../i18n.js'

// 使用する機能
import {
    ValidationProvider,
    ValidationObserver,
    extend
} from 'vee-validate';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

// 使用するvalidation rules
import { required, max, max_value, min_value, regex } from 'vee-validate/dist/rules';

// カスタムルールの設定

/**
 * 必須チェック
 */
extend("required", {
  ...required,
  message: i18n.tc("CEW0020105001"),
});

/**
 * 数値フォーマットチェック
 */
extend("number-format", {
  validate: (value) => {
    const _pattern = /^(?!-0+$)-?(?!0\d)\d+(?:\.\d*[1-9])?$/;
    return regex.validate(value, { regex: _pattern });
  },
  message: i18n.tc("CEW0020300026"),
});

/**
 * 小数点の桁数チェック
 */
extend("decimal-size-limit", {
  params: ['decimalDigits'],
  validate: (value, { decimalDigits }) => {
    const _pattern = new RegExp(`^-?[0-9]+([.]\\d{1,${decimalDigits}})?$`)
    return regex.validate(value, { regex: _pattern });
  },
  message: (_, decimalDigits) => (
    i18n.tc( 
      "CIW0020124002",
      1, // 単数形のメッセージ
      decimalDigits
    )
   )
});

/**
 * 下限値のチェック
 */
extend("min-value", {
  params: ['minValue'],
  validate: (value, { minValue }) => {
    return min_value.validate(value, { min:  minValue});
  },
  message: (_, minValue) => (
    i18n.tc( 
      "CEW0020116005", 
      1, // 単数形のメッセージ
      minValue 
    )
   )
});

/**
 * 上限値のチェック
 */
extend("max-value", {
  params: ['maxValue'],
  validate: (value, { maxValue }) => {
    return max_value.validate(value, { max:  maxValue});
  },
  message: (_, maxValue) => (
    i18n.tc( 
      "CEW0020116006", 
      1, // 単数形のメッセージ
      maxValue 
    )
   )
});

/**
 * データの文字数チェック
 */
extend("data-name-length", {
  ...max,
  message: i18n.tc("CEW0020116002")
});

/**
 * 線形ファイル拡張子チェック
 */
extend("linear-enable-extension", {
  validate: (value) => {
    const enableExtensionPattern = /.(sim)$/i;
    return regex.validate(value, { regex: enableExtensionPattern });
  },
  message: i18n.tc("CEW0020105002"),
});

/**
 * ファイル使用可能文字チェック
 */
extend("file-enable-characters", {
  validate: (value) => {
    const enableCharactersPattern = /^(?!.*(\\|\/|:|\*|\?|"|<|>|\|)).+$/;
    return regex.validate(value, {
      regex: enableCharactersPattern,
    });
  },
  message: i18n.tc("CEW0020104003"),
});

/**
 * ファイル必須チェック
 */
extend("file-required", {
  params: ["file"],
  validate: (value, { file }) => {
    if (value && file) {
      return true;
    }
    return false;
  },
  message: i18n.tc("CEW0020105004"),
});

/**
 * 整数チェック
 */
extend("integer", {
  validate: (value) => {
    const integerRegExp = /^(?!-?0[0-9])-?[0-9]+/;
    return regex.validate(value, { regex: integerRegExp});
  },
  message: i18n.tc("CEW0020300027"),
});

extend("grid-size-limit", {
  ...max_value,
  message: i18n.tc("CEW0020116002")
});

/**
 * 正数チェック
 */
extend("positive-number", {
  validate: (value) => {
    return min_value.validate(value, { min: 0 });
  },
  computesRequired: true,
  message: i18n.tc("CEW0020116004"),
});

/**
 * 点群ファイルの拡張子チェック
 */
extend("point-enable-extension", {
  validate: (value) => {
    const enableExtensionPattern 
      = new RegExp(`.(${constants.pointEnableExtensions.join('|')})$`, 'i');
    return regex.validate(value, { regex: enableExtensionPattern });
  },
  message: i18n.tc(
    "CEW0020106002",
    1, // 単数形のメッセージ
    {
      extensions: constants.pointEnableExtensions.join(', ')
    }
  )
});

/**
 * デザインファイルの拡張子チェック
 */
extend("design-enable-extension", {
  validate: (value) => {
    const enableExtensionPattern 
      = new RegExp(`.(${constants.designEnableExtensions.join('|')})$`, 'i');
    return regex.validate(value, { regex: enableExtensionPattern });
  },
  message: i18n.tc(
    "CEW0020106002",
    1, // 単数形のメッセージ
    {
      extensions: constants.designEnableExtensions.join(', ')
    }
  )
});

/**
 * 線形ファイルの拡張子チェック
 */
extend("linear-enable-extension", {
  validate: (value) => {
    const enableExtensionPattern 
      = new RegExp(`.(${constants.linearEnableExtensions.join('|')})$`, 'i');
    return regex.validate(value, { regex: enableExtensionPattern });
  },
  message: i18n.tc(
    "CEW0020106002",
    1, // 単数形のメッセージ
    {
      extensions: constants.linearEnableExtensions.join(', ')
    }
  )
});

/**
 * 点群作成の拡張子チェック
 */
extend("point-create-enable-extension", {
  validate: (value) => {
    const enableExtensionPattern 
      = new RegExp(`.(${constants.pointCreateEnableExtensions.join('|')})$`, 'i');
    return regex.validate(value, { regex: enableExtensionPattern });
  },
  message: i18n.tc(
    "CEW0020106002",
    1, // 単数形のメッセージ
    {
      extensions: constants.pointCreateEnableExtensions.join(', ')
    }
  )
});

/**
 * 点群合成の拡張子チェック
 */
extend("point-synthetic-enable-extension", {
  validate: (value) => {
    const enableExtensionPattern 
      = new RegExp(`.(${constants.pointSyntheticEnableExtensions.join('|')})$`, 'i');
    return regex.validate(value, { regex: enableExtensionPattern });
  },
  message: i18n.tc(
    "CEW0020106002",
    1,
    {
      extensions: constants.pointSyntheticEnableExtensions.join(', ')
    }
  )
});
