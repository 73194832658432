<template>
  <div>
    <!-- プログレスサークル -->
    <v-progress-circular
      v-if="showUploadProgress"
      class="normal-circle flex-shrink-0"
      :rotate="-90"
      :size="20"
      :width="4"
      :value="percentComplete"
      color="primary"
    >
    </v-progress-circular>
    <!-- エラーサークル -->
    <v-menu 
      v-else-if="isUploadError"
      offset-x
    >
      <template v-slot:activator="{ on: menu, attrs }">
        <v-btn icon v-bind="attrs" v-on="{ ...menu }">
          <v-progress-circular
            class="error-circle flex-shrink-0"
            :rotate="-90"
            :size="20"
            :width="4"
            :value="percentComplete"
          >
        </v-progress-circular>
        </v-btn>
      </template>
      <v-list class="py-0" light dense>
        <v-list-item @click="clearProcessError">
          <v-list-item-title>{{ $t("CQW0020300017") }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import * as constants from '@/constants/constants';
import axios from 'axios';

export default {
  props: {
    file: Object,
    showUploadProgress: Boolean,
    isUploadError: Boolean
  },
  data: () => ({
    percentComplete: 0,
  }),
  watch: {
    // アセットIDに変更があれば再度ポーリング開始
    showUploadProgress(newValue) {
      if (newValue) {
        this.resetProgress();
        this.startPolling();
      }
    },
  },
  methods: {
    resetProgress() {
      this.percentComplete = 0;
    },
    updateIsAssetUploaded(isAssetUploaded) {
      this.file.isAssetUploaded = isAssetUploaded;
    },
    // 進捗率が100になるまで10秒ごとにアップロード状況をチェックする
    // エラーが発生した場合は停止する
    async startPolling () { 
      await this.checkUploadStatus(this.file.assetId);
      const timeId = setInterval(()=>{
        if (this.isUploadError || this.percentComplete === 100) {
          clearInterval(timeId);
          return;
        }
        this.checkUploadStatus(this.file.assetId);
      }, 10000)
    },
    // アセットのアップロード状況をチェックし、プログレスサークルの状態を更新する
    async checkUploadStatus(assetId) {
      try {
        const result = await this.polling(assetId);
        // アップロード結果がエラーの場合はエラーサークルを表示する
        if (result.isError) {
          throw new Error("Asset upload result is error.");
        } else {
          this.percentComplete = result.percentComplete;
          // 進捗率が100%であれば2秒後にプログレスサークルを閉じる
          if (result.percentComplete === 100) {
            this.updateIsAssetUploaded(constants.assetUploadStatus.DONE);
            setTimeout(()=>{
              this.updateUploadProgress(false);
            }, 2000)
          }
        }
      } catch (error) {
        console.error(error.message);
        this.updateUploadProgress(false);
        this.updateUploadErrorStatus(true);
        this.updateIsAssetUploaded(constants.assetUploadStatus.ERROR);
      } 
    },
    // 問い合わせ成功時は進捗率（0～100）を返却し、失敗時は0を返却する
    async polling(assetId){
      const token = process.env.VUE_APP_CESIUM_ACCESS_TOKEN;
      let isError = false;
      let percentComplete = 0;
      try {
        // 問い合わせ実施
        const res = await axios.get(`https://api.cesium.com/v1/assets/${assetId}`, {headers:{"Authorization": `Bearer ${token}`}});

        // 問い合わせ結果
        const assetMetadata = res.data;

        // ステータスがエラーもしくは進捗率が設定されていない場合
        if( assetMetadata.status === constants.assetUploadStatus.ERROR || assetMetadata.status === "DATA_ERROR"
        ){
          isError = true;
          console.error("Cesium API failed: " + assetMetadata.status);
          console.error(JSON.stringify(assetMetadata));
        }
        else {
          // 進捗率を更新
          percentComplete = assetMetadata.percentComplete;
        }
      } catch(e) {
        console.error(e.message);
        isError = true;
      }
      return { isError, percentComplete }
    },
    /**
     * エラークリアイベントを発火する
     */
    clearProcessError() {
      this.$emit('error-clear');
    },
    /**
     * アップロード進捗を表示するフラグを更新する
     */
    updateUploadProgress(value) {
      this.$emit('update-upload-progress', value);
    },
    /**
     * アップロードエラーの有無を判定するフラグを更新する
     */
    updateUploadErrorStatus(value) {
      this.$emit('update-upload-error-status', value);
    }
  },
  async created() {
    this.startPolling();
  }
}
</script>

<style lang="sass" scoped>
.normal-circle ::v-deep svg circle.v-progress-circular__underlay
  stroke: black

.error-circle ::v-deep svg circle.v-progress-circular__underlay
  stroke: #F44336

.v-btn--icon.v-size--default
  width: auto
  height: auto
</style>