<!-- 点群ファイルリスト -->
<template>
  <v-expansion-panel>
    <v-card>
      <v-expansion-panel-header
        style="font-size: 15px"
        class="pa-2"
      >
        <div class="d-flex align-center">
          <v-btn
            width="24px"
            plain 
            min-width="24px"
            class="pa-0 mr-1"
            @click.stop="changeShowEditDialog"
          >
            <v-icon style="background-color: transparent">mdi-menu</v-icon>
          </v-btn>
          <div>
            {{ pointData.getName() }}
          </div>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="pt-1">
        <v-list class="pa-0" style="font-size: 14px">
          <v-list-item
            v-for="points in pointFileArr"
            :key="points.pointId"
            class="data-list mb-3"
          >
            <v-list-item-title>
              <point-file
                :selected="selected"
                @add-checked="addChecked"
                @delete-checked="deleteChecked"
                :deleteMode="deleteMode"
                :pointFile="points"
              ></point-file>
            </v-list-item-title>
          </v-list-item>
        </v-list>
        <div v-if="deleteMode" class="d-flex justify-end mb-2">
          <v-btn 
            class="mr-3"
            color="red lighten-1"
            width="100px"
            :disabled="isSelected"
            @click="deletePointData">
            {{$t("CIW0020301001")}}
          </v-btn>
        </div>
      </v-expansion-panel-content>
    </v-card>

    <v-dialog
      hide-overlay
      persistent
      no-click-animation
      v-model="showEditDialog"
      width="400px"
    >
      <point-edit-dialog
        :pointData="pointData"
        :sortKey="sortKey"
        @sort-by-name="sortByName"
        @sort-by-created-at="sortByCreatedAt"
        @close="changeShowEditDialog"
        @change-delete-mode="changeDeleteMode"
        @all-delete="allDelete" 
      />
    </v-dialog>
  </v-expansion-panel>
</template>

<script>
import PointFile from "./PointFile.vue";
import PointEditDialog from "../PointEditDialog.vue";
import * as utils from '@/utils/utils';
import { repositoryFactory } from "@/repositories/repository-factory";
import { i18n } from '../../../i18n.js'
const pointRepository = repositoryFactory.get('point');

export default {
  components: {
    PointFile,
    PointEditDialog,
  },
  props: {
    pointData: Object
  },
  data() {
    return {
      showEditDialog: false,
      deleteMode: false,
      selected: [],
      sortKey: {name: "", createdAt: ""}
    }
  },
  computed: {
    pointFileArr() {
      const sortByMultiKeys = (a, b) => {
        /**
         * 第1ソートキー（名前）でソート
         */
        // 名前
        const nameA = a.pointName;
        const nameB = b.pointName;

        if (this.sortKey.name === 'asc') {
          return nameA.localeCompare(nameB, this.$i18n.locale, {numeric: true, sensitivity: 'base'});
        } else if (this.sortKey.name === 'desc') {
          return nameB.localeCompare(nameA, this.$i18n.locale, {numeric: true, sensitivity: 'base'});
        }

        /**
         * 第2ソートキー（登録日時）でソート
         */
        // 登録日時
        const createdAtA = a.createdAt;
        const createdAtB = b.createdAt;

        if (this.sortKey.createdAt === 'asc') {
          if (createdAtA < createdAtB) return -1; 
          if (createdAtA > createdAtB) return 1; 
        } else if (this.sortKey.createdAt === 'desc'){
          if (createdAtA < createdAtB) return 1; 
          if (createdAtA > createdAtB) return -1; 
        }
      }
      // 点群ファイルをソートして返却する
      return this.pointData.getPoints().slice().sort((a, b) => sortByMultiKeys(a, b));
    },
    isSelected() {
      return !this.selected.length;
    }
  },
  methods: {
    sortByName(sortDirection) {
      this.sortKey.name = sortDirection;
    },
    sortByCreatedAt(sortDirection) {
      this.sortKey.createdAt = sortDirection;
    },
    // エディットダイアログの開閉を行う
    changeShowEditDialog() {
      if (this.deleteMode) 
        this.changeDeleteMode();
      this.showEditDialog = !this.showEditDialog;
    },
    // チェックボックスがチェックされたとき
    addChecked(pointId) {
      this.selected.push(pointId);
    },
    // チェックボックスが外れたとき
    deleteChecked(pointId) {
      this.selected = this.selected.filter(id => id !== pointId);
    },
    // エディットダイアログで削除ボタン押された際の動作
    changeDeleteMode() {
      this.deleteMode = !this.deleteMode;
      PointFile.deleteMode = !this.deleteMode;
    },
    // 選択された点群ファイルを削除
    deletePointData() {
      if (this.isSelected) return;
      this.request(this.selected);
    },
    // 点群ファイルを全削除
    allDelete() {
      // 断面と紐づかない点群データを削除対象とする
      const deleteItemList = this.pointFileArr.map(point => point.pointId);
      if (deleteItemList.length === 0) return;
      this.request(deleteItemList);
    },
    async request(deleteIdList) {
      let successMessage = i18n.tc("SIW0020301001");
      let errorMessage = i18n.tc("SEW0020301001");
      const body = {
        point_ids: deleteIdList,
        user_id: this.$store.getters.loginData.user_id
      };
      
      try {
        this.isLoading = true;
        const res = await pointRepository.deletePointsByPointIds(body);
        if (res.status !== 200) {
          // 想定外のステータスコードが返却された場合エラーをスロー
          throw new Error(errorMessage);
        }

        // メッセージ出力のため、削除に成功した点群と失敗した点群に分ける
        const { deletedData, warningDataWithSections } 
        = res.data.points.reduce(
          (result, point) => {
            if (point.status === 'SUCCESS') {
              // 削除に成功した点群のリストに追加
              result.deletedData.push(point)
            } else if (point.status === 'WARNING_SECTION_DATA_EXIST') {
              // 断面データを持つため削除に失敗した点群のリストに追加
              result.warningDataWithSections.push(point)
            }
            return result;
          },
          { deletedData: [], warningDataWithSections: [] }
        )

        // 削除に失敗した点群があれば警告用のメッセージを組み立てる
        if (warningDataWithSections.length) {
          const message = [];
          // 成功した一覧を表示
          message.push(successMessage);
          for (const deletedPoint of deletedData) {
            const point = utils.getPointFileByPointId(deletedPoint.point_id);
            if (point) message.push(`・${point.pointName}`);
          }
          // メッセージ間に空白行を入れる
          message.push("");

          // 削除に失敗した一覧を表示
          message.push(i18n.tc("CEW0020301004"));
          for (const warningPoint of warningDataWithSections) {
            const point = utils.getPointFileByPointId(warningPoint.point_id);
            if (point) message.push(`・${point.pointName}`);
          }
          // 警告終了用のメッセージを表示
          utils.showSnackBar('warning', message, 0);
        } else {
          // 正常終了用のメッセージを表示
          utils.showSnackBar('success', successMessage);
        }
        await this.$store.dispatch("getPointDataList", this.$route.query.siteId);
        this.selected.splice(0);
        this.deleteMode = false;
        this.showEditDialog = false;
      } catch (err) {
        // エラーが発生したら処理終了
        console.error(err.message);
        if (utils.createErrorMessage(err)) {
          errorMessage = utils.createErrorMessage(err);
        }
        utils.showSnackBar('error', errorMessage);
      } finally {
        this.isLoading = false;
      }
    },
  }
};
</script>

<style lang="sass" scoped>
.data-list
  height: auto
  min-height: auto
  display: block

::v-deep .v-expansion-panel-content__wrap
  padding: 0 0 8px 0

::v-deep .v-data-table
  line-height: 100%

::v-deep div.v-expansion-panel-content__wrap
  padding-bottom: 0px
</style>