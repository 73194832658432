import Repository from "./repository";

const resource = "/linear-file";
export default {
  findBySiteId(siteId, payload) {
    return Repository.post(`${resource}/${siteId}`, payload);
  },
  // 線形ファイル削除
  deleteLinearByLinearId(linearId, payload) {
    return Repository.delete(`${resource}/${linearId}`, { data: payload });
  },
  // 線形ファイル複数削除
  deleteLinearByLinearIds(payload) {
    return Repository.post(`${resource}/delete_all`, payload);
  },
  // SIMAファイル登録
  createFromSima(payload) {
    return Repository.put(`${resource}/sima`, payload);
  },
  // 線形描画結果登録
  createFromDrawing(siteId, routeEpsg4978, stationInterval) {
    return Repository.put(`${resource}/${siteId}/drawing`, {
      route_epsg4978: routeEpsg4978,
      station_interval: stationInterval,
    });
  },
  // 線形ファイル更新
  updateLinearNameByLinearId(linearId, payload) {
    return Repository.patch(`${resource}/${linearId}/name`, payload);
  },
  updateLinearPointsByLinearId(linearId, route) {
    return Repository.patch(`${resource}/${linearId}/points`, route);
  },
}
