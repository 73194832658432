var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"off-set-dialog px-4 pt-2 pb-4"},[_c('loading',{attrs:{"parentLoading":_vm.isLoading}}),_c('v-card-title',{staticClass:"pa-0 justify-space-between"},[_c('label',[_vm._v(_vm._s(_vm.$t("CQW0020120001")))]),_c('close-button',{attrs:{"size":"20px"},on:{"click":function($event){return _vm.$emit('close')}}})],1),_c('v-card-text',{staticClass:"px-0 pt-1 pb-0 white--text"},[_c('v-container',{staticClass:"pa-0"},[_c('v-row',{staticClass:"ma-0"},[_c('validation-observer',{ref:"observer",staticClass:"d-block",staticStyle:{"width":"100%"},scopedSlots:_vm._u([{key:"default",fn:function({ pristine, invalid }){return [_c('v-col',{staticClass:"my-3 pa-0",attrs:{"cols":"12"}},[_c('editing-point-clouds',{attrs:{"title":_vm.$t('CIW0020000008')}})],1),_c('v-col',{staticClass:"mt-3 pa-0",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":{
                  required: true,
                  'number-format': true,
                  'decimal-size-limit': 3,
                  'min-value': -1000.999,
                  'max-value': 1000.999,
									}},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"d-inline-block mr-2"},[_vm._v(_vm._s(_vm.$t("CQW0020120003")))])]),_c('div',[_c('span',{staticStyle:{"font-size":"12px","color":"#ff5252"}},[_vm._v(_vm._s(errors[0]))])]),_c('div',{staticStyle:{"float":"left","height":"32px","padding-top":"5px"}},[_c('label',{staticStyle:{"font-size":"1rem","margin-right":"10px"}},[_vm._v(_vm._s(_vm.$t("CQW0020114010")))])]),_c('v-text-field',{staticClass:"text-black ma-0",attrs:{"error-messages":errors,"height":"32px","background-color":"#BFBFBF","outlined":"","solo":"","suffix":"m"},on:{"reset":function($event){$event.preventDefault();return _vm.resetError()},"keydown":function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==69)return null;$event.preventDefault();}},model:{value:(_vm.offSetX),callback:function ($$v) {_vm.offSetX=$$v},expression:"offSetX"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-3 pa-0",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":{
                  required: true,
                  'number-format': true,
                  'decimal-size-limit': 3,
                  'min-value': -1000.999,
                  'max-value': 1000.999,
									}},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',[_c('span',{staticStyle:{"font-size":"12px","color":"#ff5252"}},[_vm._v(_vm._s(errors[0]))])]),_c('div',{staticStyle:{"float":"left","height":"32px","padding-top":"5px"}},[_c('label',{staticStyle:{"font-size":"1rem","margin-right":"10px"}},[_vm._v(_vm._s(_vm.$t("CQW0020114011")))])]),_c('v-text-field',{staticClass:"text-black ma-0",attrs:{"error-messages":errors,"height":"32px","background-color":"#BFBFBF","outlined":"","solo":"","suffix":"m"},on:{"reset":function($event){$event.preventDefault();return _vm.resetError()},"keydown":function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==69)return null;$event.preventDefault();}},model:{value:(_vm.offSetY),callback:function ($$v) {_vm.offSetY=$$v},expression:"offSetY"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-3 pa-0",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":{
                  required: true,
                  'number-format': true,
                  'decimal-size-limit': 3,
                  'min-value': -1000.999,
                  'max-value': 1000.999,
									}},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',[_c('span',{staticStyle:{"font-size":"12px","color":"#ff5252"}},[_vm._v(_vm._s(errors[0]))])]),_c('div',{staticStyle:{"float":"left","height":"32px","padding-top":"5px"}},[_c('label',{staticStyle:{"font-size":"1rem","margin-right":"10px"}},[_vm._v(_vm._s(_vm.$t("CQW0020120006")))])]),_c('v-text-field',{staticClass:"text-black ma-0",attrs:{"error-messages":errors,"height":"32px","background-color":"#BFBFBF","outlined":"","solo":"","suffix":"m"},on:{"reset":function($event){$event.preventDefault();return _vm.resetError()},"keydown":function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==69)return null;$event.preventDefault();}},model:{value:(_vm.offSetZ),callback:function ($$v) {_vm.offSetZ=$$v},expression:"offSetZ"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-4 pa-0 d-flex justify-space-between",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"rounded-lg",attrs:{"color":"#ff5050","width":"115px","height":"45px","depressed":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t("CQW0010600005"))+" ")]),_c('v-btn',{staticClass:"rounded-lg",attrs:{"color":"#4472c4","width":"115px","height":"45px","depressed":"","disabled":pristine || invalid || !_vm.editingPointClouds.length},on:{"click":function($event){return _vm.pointDataEditing(1)}}},[_vm._v(" "+_vm._s(_vm.$t("CQW0020109005"))),_c('br'),_vm._v(_vm._s(_vm.$t("CQW0020110006"))+" ")]),_c('v-btn',{staticClass:"rounded-lg",attrs:{"color":"#4472c4","width":"115px","height":"45px","depressed":"","disabled":pristine || invalid || !_vm.editingPointClouds.length},on:{"click":function($event){return _vm.pointDataEditing(2)}}},[_vm._v(" "+_vm._s(_vm.$t("CQW0020110007"))),_c('br'),_vm._v(_vm._s(_vm.$t("CIW0020000005"))+" ")])],1)]}}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }