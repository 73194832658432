<!-- 線形編集ダイアログ -->
<template>
  <v-card class="project-dialog px-4 pt-2 pb-4">
    <v-card-title class="pa-0 justify-space-between">
      <label>
        {{$t("CQW0020123001")}}
      </label>
      <close-button @click="cancel" size="20px"></close-button>
    </v-card-title>
    <v-card-text class="px-0 pt-1 pb-0 white--text">
      <v-container class="pa-0">
        <validation-observer
          class="d-block"
          style="width: 100%"
          ref="observer"
          v-slot="{ invalid, pristine }"
        >
        <v-row class="ma-0">
            <!-- データ選択 -->
            <v-col cols="12" class="my-3 pa-0">
              <label class="mb-1 d-block">{{$t("CQW0020107003")}}</label>
              <v-select
                v-model="selectedLinearData"
                class="text-black"
                height="32px"
                background-color="#BFBFBF"
                :items="linearList"
                item-text="text"
                item-value="value"
                hide-details
                solo
              >
              </v-select>
            </v-col>
            <!-- 座標表示 -->
            <div class="position-area">
              <v-col v-for="n in route.points.length" :key="n" cols="12" class="mt-3 pa-0 d-flex align-center">
                <label class="mr-2" style="width: 25px; font-size: 16px">{{ n }}</label>
                <div class="d-flex">
                  <!-- X -->
                  <validation-provider
                    :rules="{
                        required: true,
                        'decimal-size-limit': 6,
                      }"
                    v-slot="{ errors }">
                    <div>
                      <span style="font-size: 12px; color: #ff5252">{{ errors[0] }}</span>
                      <v-text-field
                        v-model.number="route.points[n-1].x"
                        type="number"
                        step="0.000001"
                        :disabled="!isEditable"
                        class="text-black ma-0 mb-0 mr-2"
                        height="25px"
                        background-color="#BFBFBF"
                        outlined
                        solo
                        >
                        <template v-slot:prepend>
                          {{$t("CQW0020114010")}}
                        </template>
                      </v-text-field>
                    </div>
                  </validation-provider>
                  <!-- Y -->
                  <validation-provider
                    :rules="{
                        required: true,
                        'decimal-size-limit': 6,
                      }"
                    v-slot="{ errors }">
                    <div>
                      <span style="font-size: 12px; color: #ff5252">{{ errors[0] }}</span>
                      <v-text-field
                        v-model.number="route.points[n-1].y"
                        type="number"
                        step="0.000001"
                        :disabled="!isEditable"
                        class="text-black ma-0 mb-0 mr-2"
                        height="25px"
                        background-color="#BFBFBF"
                        outlined
                        solo
                        >
                        <template v-slot:prepend>
                          {{$t("CQW0020114011")}}
                        </template>
                      </v-text-field>
                    </div>
                  </validation-provider>
                  <!-- Z -->
                  <validation-provider
                    :rules="{
                        required: true,
                        'decimal-size-limit': 6,
                      }"
                    v-slot="{ errors }">
                    <div>
                      <span style="font-size: 12px; color: #ff5252">{{ errors[0] }}</span>
                      <v-text-field
                        v-model.number="route.points[n-1].z"
                        type="number"
                        step="0.000001"
                        :disabled="!isEditable"
                        class="text-black ma-0"
                        height="25px"
                        background-color="#BFBFBF"
                        outlined
                        solo
                        >
                        <template v-slot:prepend>
                          {{$t("CQW0020120006")}}
                        </template>
                      </v-text-field>
                    </div>
                  </validation-provider>
                </div>
              </v-col>
            </div>
            <!-- 地図で編集 -->
            <v-col cols="12" class="mt-4 pa-0 d-flex justify-end">
              <v-btn
                class="rounded-lg"
                color="#4472c4"
                width="115px"
                height="45px"
                depressed
                :disabled="!isEditable"
                @click="editOnMap">
                {{$t("CQW0020123004")}} 
              </v-btn>
            </v-col>
            <v-col cols="12" class="mt-4 pa-0 d-flex justify-end">
              <!-- キャンセル -->
              <v-btn
                class="rounded-lg mr-5"
                color="#ff5050"
                width="115px"
                height="45px"
                depressed
                @click="cancel">
                {{$t("CQW0010600005")}}
              </v-btn>
              <!-- 確定 -->
              <v-btn
                class="rounded-lg"
                color="#4472c4"
                width="115px"
                height="45px"
                depressed
                :disabled="!isEditable || invalid || (!isEditDone && pristine)"
                @click="confirm">
                {{$t("CQW0020123006")}}
              </v-btn>
            </v-col>
          </v-row>
        </validation-observer>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import * as cesiumCommon from "@/utils/cesium-common";
import * as utils from "@/utils/utils";
import { i18n } from "@/i18n.js";
import CloseButton from "@/components/common/CloseButton.vue";
import { repositoryFactory } from "@/repositories/repository-factory";
import Linear from "../../../models/Linear";
const linearFileRepository = repositoryFactory.get('linearFile');

export default {
  components: {
    CloseButton,
    ValidationProvider,
    ValidationObserver,
  },
  data: () => {
    return {
      isEditDone: false,
      linearData: new Linear(undefined, "", undefined, { name: "", points: [], }, undefined, undefined),
      route: { points: [] },
    };
  },
  computed: {
    editType() {
      return this.$store.getters.editType;
    },
    selectedLinearData: {
      get() {
        return this.linearData;
      },
      set(item) {
        this.linearData = item;
        this.route = {
          name: item.route.name,
          points: item.route.points.map(point => {
            return {
              ...point,
            };
          }),
        };
      }
    },
    linearList() {
        //線形データの選択項目を作成
        return this.$store.getters["linearFile/linearFileList"]
              .map(linear => (
                { value: linear, text: linear.linearName }
              ));
    },
    isEditable() {
      const linearId = this.selectedLinearData.linearId;
      if (!linearId) {
        // 線形が選択されていなければ編集禁止
        return false;
      }
      if (this.$store.getters["sectionFile/sectionFileList"].some(sectionFile => sectionFile.linearId === linearId)) {
        // 断面が作成されていたら編集禁止
        return false;
      }
      return this.selectedLinearData.route.points.length;
    },
    isDisabledConfirmBtn() {
      // 線形データが選択されていない場合、線形座標がない場合、編集が完了していない場合は非活性
      if (!this.selectedLinearData.route.points.length || !this.isEditDone) {
        return true;
      } else {
        return false
      }
    }
  },
  watch: {
    editType(newValue) {
      // 編集完了前に編集モードが解除された場合、フォームをリセットする
      if (!newValue && !this.isEditDone) {
        this.resetForm();
      }
    },
  },
  methods: {
    resetForm() {
      this.linearData = new Linear(undefined, "", undefined, { name: "", points: [], }, undefined, undefined);
      this.route = { points: [] };
      this.resetEditStatus;
    },
    resetEditStatus() {
      this.isEditDone = false;
    },
    cancel() {
      if (this.isEditDone) {
        // 地図で編集後にキャンセルした場合、編集したものをリセット
        cesiumCommon.hideLinearData(this.selectedLinearData);
        cesiumCommon.showLinearData(this.selectedLinearData);
      }
      this.resetForm();
      this.$emit('close');
    },
    async editOnMap() {
      this.isEditDone = false;
      if (!this.selectedLinearData.isVisible) {
        // 編集対象の線形が表示されていない場合は表示
        cesiumCommon.changeLinearVisible(this.selectedLinearData);
      }

      // 編集対象の線形以外は非表示にする
      const visibleLinearList = this.$store.getters['linearFile/visibleLinearFileList'].filter(
        linear => linear.linearId !== this.selectedLinearData.linearId
      )
      for (const linear of visibleLinearList) {
        cesiumCommon.changeLinearVisible(linear);
      }
      this.$emit('hide');

      try {
        const routeEpsg4978 = await cesiumCommon.editLine(this.selectedLinearData.linearId);
        const transformed = await cesiumCommon.transformPointsToSiteCrs(routeEpsg4978.points);
        this.route = {
          name: routeEpsg4978.name,
          points: routeEpsg4978.points.map((point, index) => {
            return {
              name: point.name,
              ...transformed[index],
            };
          }),
        };
      }
      catch (e) {
        console.log(e.message);
      }
      this.$emit('reshow');
      this.isEditDone = true;
    },
    async confirm() {
      let successMessage = i18n.tc("CIW0020122001")
      let errorMessage = i18n.tc("CEW0020122002")

      this.$store.dispatch("updateIsLoading", true);
      try {
        const res = await linearFileRepository.updateLinearPointsByLinearId(this.selectedLinearData.linearId ,this.route);
        if (res.status !== 200) {
          // 想定外のステータスコードが返却された場合エラーをスロー
          throw new Error(errorMessage);
        }
        this.selectedLinearData.route = this.route;
        this.selectedLinearData.routeEpsg4978 = res.data;
        // エラーがなければ正常メッセージを出力する
        utils.showSnackBar("success", successMessage);
        this.resetForm();
        this.$emit('close');
      } catch (e) {
        // エラーが発生したら処理終了
        console.error(e.message);
        if (utils.createErrorMessage(e)) {
          errorMessage = utils.createErrorMessage(e);
        }
        utils.showSnackBar("error", errorMessage);
      } finally {
        this.$store.dispatch("updateIsLoading", false);
      }
    },
  },
  mounted() { 
    if (!this.isEditDone) {
      this.resetForm()
    }
  },
};
</script>

<style lang="sass" scoped>
.project-dialog
  .v-card__title
    font-size: 16px

::v-deep .v-input__append-outer
  margin: 0

::v-deep .v-input__slot
  margin: 0
  padding: 0

.text-black ::v-deep input,
.text-black ::v-deep label,
.text-black ::v-deep .v-select__selection,
.text-black ::v-deep .v-input__append-inner i
  color: black

::v-deep .v-text-field input,
::v-deep .v-text-field.v-input .v-select__selections input
  padding: 0

::v-deep .v-text-field__details
  display: none

::v-deep div.v-input.v-text-field div.v-input__prepend-outer
  margin: auto 8px auto 1px
  color: white

// 座標表示エリア
.position-area
  max-height: 400px
  overflow-y: auto

</style>