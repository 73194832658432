import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from './store'

const dict = require('./SCPointworksDict.json')

Vue.use(VueI18n);
export const i18n = new VueI18n({
  locale: store.getters.loginData.language || 'ja',
  messages: dict
})
