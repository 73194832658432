// 点群データ
import FlatnessData from './FlatnessData';
import MeasureData from './MeasureData';
import PerimeterLine from './PerimeterLine';
import * as constants from '@/constants/constants';
import * as cesiumCommon from '@/utils/cesium-common';
export default class Point {
  constructor(pointId, pointName, mode, reqInfo, assetId, createdAt, processStatus, processStatusAt, processMessage, isProcessCancel, flats, measure, perimeterLine) {
    this.pointId = pointId;
    this.pointName = pointName;
    this.mode = mode;
    this.reqInfo = reqInfo;
    this.assetId = assetId;
    this.createdAt = createdAt;
    this.processStatus = processStatus;
    this.processStatusAt = processStatusAt;
    this.processMessage = processMessage;
    this.isProcessCancel = isProcessCancel;
    this.flats = flats;
    this.measure = measure;
    this.perimeterLine = perimeterLine;
    this.isAssetUploaded = constants.assetUploadStatus.PENDING;
    this.tilingProgress = false;
    this.isTiling = false;
    this.isEditing = false;
    this.isVisible = false;
  }

  // getter
  get pointId() {
    return this._pointId;
  }

  get name() {
    return this._pointName;
  }

  get pointName() {
    return this._pointName;
  }

  get mode() {
    return this._mode;
  }

  get reqInfo() {
    return this._reqInfo;
  }

  get assetId() {
    return this._assetId;
  }

  get createdAt() {
    return this._createdAt;
  }

  get processStatus() {
    return this._processStatus;
  }

  get processStatusAt() {
    return this._processStatusAt;
  }

  get processMessage() {
    return this._processMessage;
  }

  get isProcessCancel() {
    return this._isProcessCancel;
  }

  get flats() {
    return this._flats ? this._flats : [];
  }

  get measure() {
    return this._measure ? this._measure : [];
  }

  get perimeterLine() {
    return this._perimeterLine;
  }

  get isAssetUploaded() {
    return this._isAssetUploaded;
  }

  get tilingProgress() {
    return this._tilingProgress;
  }

  get isTiling() {
    return this._isTiling;
  }

  get isEditing() {
    return this._isEditing;
  }

  get isVisible() {
    return this._isVisible;
  }

  // setter
  set pointId(pointId) {
    this._pointId = pointId;
  }

  set name(pointName) {
    this._pointName = pointName;
  }

  set pointName(pointName) {
    this._pointName = pointName;
  }

  set mode(mode) {
    this._mode = mode;
  }

  set reqInfo(reqInfo) {
    this._reqInfo = reqInfo;
  }

  set assetId(assetId) {
    this._assetId = assetId;
  }

  set createdAt(createdAt) {
    this._createdAt = new Date(createdAt);
  }

  set processStatus(processStatus) {
    this._processStatus = Number(processStatus);
  }

  set processStatusAt(processStatusAt) {
    this._processStatusAt = processStatusAt
  }

  set processMessage(processMessage) {
    this._processMessage = processMessage;
  }

  set isProcessCancel(isProcessCancel) {
    this._isProcessCancel = isProcessCancel;
  }

  set flats(flats) {
    const flatnessDataList = [];
    if (flats instanceof Array) {
      for (const flatnessData of flats) {
        const target = this.flats.find(flatness =>
          flatnessData.flat_id === flatness.flatnessId
        );
        // リストに存在する場合は更新、しない場合は追加
        if (target) {
          target.update(
              flatnessData.flat_name, 
              flatnessData.flat_info,
              flatnessData.select_info,
              flatnessData.grid_size,
              flatnessData.status,
              flatnessData.message,
              this.pointId,
              this.assetId
            );
          flatnessDataList.push(target);
        } else {
          const newFlatnessData
          = new FlatnessData(
              flatnessData.flat_id, 
              flatnessData.flat_name, 
              flatnessData.flat_info,
              flatnessData.select_info,
              flatnessData.grid_size,
              flatnessData.status,
              flatnessData.message,
              this.pointId,
              this.assetId
            );
          flatnessDataList.push(newFlatnessData);
        }
      }
    }
    this._flats = flatnessDataList;
  }

  set measure(measure) {
    const measureDataList = [];
    if (measure instanceof Array) {
      for (const measureData of measure) {
        const target = this.measure.find(item =>
          measureData.measure_id === item.id
        );
        // リストに存在する場合は更新、しない場合は追加
        if (target) {
          target.measureName = measureData.measure_name;
          target.selectInfo = measureData.select_info;
          target.measureInfo = measureData.measure_info;
          target.status = measureData.status;
          target.message = measureData.message;

          measureDataList.push(target);
        } else {
          const newMeasureData
          = new MeasureData(
              measureData.measure_id, 
              measureData.measure_name, 
              measureData.select_info,
              measureData.measure_info,
              measureData.status,
              measureData.message
            );
          measureDataList.push(newMeasureData);
        }
      }
    }
    this._measure = measureDataList;
  }

  set perimeterLine(perimeterLine) {
    // 引数が空の場合は処理終了
    if (!perimeterLine) {
      this._perimeterLine = undefined;
      return
    }

    // 外周線が更新されている場合は更新
    if (this.perimeterLine) {
      if (this.perimeterLine.perimeterLineId === perimeterLine.perimeterLineId) {
        this._perimeterLine?.update(perimeterLine);
      } else {
        this._perimeterLine 
        = new PerimeterLine(
            perimeterLine?.perimeter_line_id, 
            perimeterLine?.perimeter_line_info_epsg4978,
            perimeterLine?.status,
            perimeterLine?.message
        );
      }
    } else {
      this._perimeterLine 
      = new PerimeterLine(
          perimeterLine?.perimeter_line_id, 
          perimeterLine?.perimeter_line_info_epsg4978,
          perimeterLine?.status,
          perimeterLine?.message
      );
    }
  }

  set isAssetUploaded(isAssetUploaded) {
    this._isAssetUploaded = isAssetUploaded;
  }

  set tilingProgress(tilingProgress) {
    this._tilingProgress = tilingProgress;
  }

  set isTiling(isTiling) {
    this._isTiling = isTiling;
  }

  set isEditing(isEditing) {
    this._isEditing = isEditing;
  }

  set isVisible(isVisible) {
    this._isVisible = isVisible;
  }

  // functions
  update(pointName, mode, reqInfo, assetId, createdAt, processStatus, processStatusAt, processMessage, isProcessCancel, flats, measure, perimeterLine) {
    this.pointName = pointName;
    this.mode = mode;
    this.reqInfo = reqInfo ;
    if (this.assetId !== assetId) { 
      // アセットidを変更する場合ステータスをPENDINGに戻す
      this.isAssetUploaded = constants.assetUploadStatus.PENDING;
      if (this.isTiling) {
        // アセットを読み込み済みであれば非表示にする
        cesiumCommon.removeTileset(this);
        this.isVisible = false;
        this.isEditing = false;
      }
    }
    this.assetId = assetId;
    this.createdAt = createdAt;
    this.processStatus = processStatus;
    this.processStatusAt = processStatusAt;
    this.processMessage = processMessage;
    this.isProcessCancel = isProcessCancel;
    this.flats = flats;
    this.measure = measure;
    this.perimeterLine = perimeterLine;
  }

  waitAssetUploading() {
    const checkAssetUploaded = (resolve, reject, timeId) => {
      let isResolveOrReject = false;
      if (this.isAssetUploaded === constants.assetUploadStatus.DONE) {
        resolve(this.isAssetUploaded);
        if (timeId) clearInterval(timeId);
        isResolveOrReject = true;
      } else if (this.isAssetUploaded === constants.assetUploadStatus.ERROR) {
        reject(this.isAssetUploaded);
        if (timeId) clearInterval(timeId);
        isResolveOrReject = true;
      }
      return isResolveOrReject;
    }

    return new Promise((resolve, reject) => {
      // 1回目のチェック
      if (checkAssetUploaded(resolve, reject)) return;

      // 2回目以降のチェック
      const timeId = setInterval(()=>{
        if (checkAssetUploaded(resolve, reject, timeId)) return;
      }, 1000)
    })
  }
}