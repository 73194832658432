// ダイアログ用のstore
const state = {
  projectPageDialog: {
    isShow: false,
    name: "",
    item: null,
    event: null,
  },
  cesiumDialog: {
    isShow: false,
    name: '',
    attribute: {},
    handlers: {},
    item: null,
    event: null
  },
};

const getters = {
  projectPageDialog: state => state.projectPageDialog,
  cesiumDialog: state => state.cesiumDialog
};

const mutations = {
  setProjectDialog(state, property) {
    for (const key of Object.keys(property)) {
      state.projectPageDialog[key] = property[key];
    }
  },
  setCesiumDialog(state, property) {
    for (const key of Object.keys(property)) {
      state.cesiumDialog[key] = property[key];
    }
  }
};

const actions = {
  setProjectDialog({ commit }, property) {
    commit("setProjectDialog", property);
  },
  setCesiumDialog({ commit }, property) {
    commit("setCesiumDialog", property);
  },
  setCesiumDialogIsShow({ commit }, isShow) {
    commit("setCesiumDialog", { isShow });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};