import Repository from "./repository";

const resource = "/site";
export default {
  getSiteBySiteName(siteId) {
    return Repository.get(`${resource}/${siteId}`);
  },
  existsBySiteIdAndUserId(siteId, payload) {
    return Repository.post(`${resource}/referable/${siteId}`, payload);
  },
  getSiteCount(payload, params) {
    let url = `${resource}/count`;
    if(params.searchWord != undefined) {
      url = url + `?searchWord=${params.searchWord}`;
    }
    return Repository.post(url, payload);
  }
}