
<template>
  <v-card class="confirm">
    <v-card-title class="pt-2 justify-space-between">
      <span>{{$t("CQW0020133001")}}</span>
      <close-button @click="$emit('close')" size="18px"></close-button>
    </v-card-title>
    <v-card-text class="py-0 pb-0" style="color: white">
      <p>
        '{{$store.getters['lock/getLockBySiteId'](project.siteId).userName}}'{{$t("CQW0020133008")}}<br>
        {{$t("CQW0020133009")}}<br>
        {{$t("CQW0020133010")}}
      </p>
    </v-card-text>
    <v-card-text class="py-0 pb-4" style="color: white">
      <v-text-field
        v-model="forceUnlockField"
        class="text-black ma-0"
        :label="$t('CQW0020133011')"
        height="32px"
        background-color="#BFBFBF"
        outlined
        solo>
      </v-text-field>
    </v-card-text>
    <v-card-actions class="pa-0 pb-5 pr-5 justify-end">
      <v-btn class="pa-3 mr-2 btn" color="#ff5050" width="125px" height="45px" @click="$emit('close')">
        {{$t("CQW0010600005")}}
      </v-btn>
      <v-btn class="pa-3 mr-1 btn" :disabled="!isUnlock" color="#4472c4" width="125px" height="45px" @click="unlock">
        {{$t("CQW0020133013")}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import CloseButton from "@/components/common/CloseButton.vue";
import { i18n } from '../../../../i18n.js'

export default {
  components: {
    CloseButton
  },
  props: {
    project: Object
  },
  data() {
    return {
      forceUnlockField:'',
      forceUnlockWord: i18n.tc("CIW0020133004")
    };
  },
  computed: {
    isUnlock() {
      return this.forceUnlockField === this.forceUnlockWord;
    }
  },
  methods: {
    unlock() {
      this.$store.dispatch(
        'lock/forceUnLockState', 
        {
          siteId: this.project.siteId,
          loginData: this.$store.getters.loginData
        }
      );
      this.$emit('close');
    }
  },
};
</script>
<style lang="sass" scoped>
.confirm
  .v-card__title
    font-size: 16px

.confirm .btn
    font-size: 13px

.confirm
  p,span
    color: white

::v-deep .v-input__append-outer
  margin: 0

::v-deep .v-input__slot
  margin: 0
  padding: 0

.text-black ::v-deep input,
.text-black ::v-deep label,
.text-black ::v-deep .v-select__selection,
.text-black ::v-deep .v-input__append-inner i
  color: black

::v-deep .v-input__slot
  .v-text-field__slot
    .v-label
      color: gray
      opacity: 0.8

::v-deep .v-text-field input,
::v-deep .v-text-field.v-input .v-select__selections input
  padding: 0

::v-deep .v-text-field__details
  display: none
</style>