const resource = "/browser-download";
export default {
  fileDownload(payload) {

    const form = document.createElement("form");
    form.setAttribute("action", `${resource}`);
    form.setAttribute("method", "post");
    form.style.display = "none";
    
    for (const propertyName in payload) {
      const input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", propertyName);
      input.setAttribute("value", payload[propertyName]);
      form.appendChild(input);
    }

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  },
};
