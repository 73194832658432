<!-- グリッド補間 -->
<template>
  <v-card class="grid-interpolation-dialog px-4 py-2">
    <loading :parentLoading="isLoading" />
    <v-card-title class="pa-0 justify-space-between">
      <label>
        {{$t("CIW0020000026")}}
      </label>
      <close-button @click="$emit('close')" size="20px"></close-button>
    </v-card-title>
    <v-card-text class="px-0 pt-1 pb-0 white--text">
      <v-container class="pa-0">
        <validation-observer
          class="d-block"
          style="width: 100%"
          ref="observer"
          v-slot="{ pristine, invalid }"
        >
          <v-row class="ma-0">
            <v-col cols="12" class="mt-3 pa-0">
                <editing-point-clouds
                  :title="$t('CIW0020000008')">
                </editing-point-clouds>
            </v-col>
            <v-col cols="12" class="mt-3 pa-0">
              <validation-provider :rules="{
                required: true,
                'number-format': true,
                'decimal-size-limit': 2,
                'min-value': 0.01,
                'max-value': 100.99,
              }" v-slot="{ errors }">
                <div class="mb-1">
                  <label class="d-inline-block mr-2">{{$t("CQW0020112003")}}</label>
                  <span class="d-inline-block" style="font-size: 12px; color: #ff5252">{{ errors[0] }}</span>
                </div>
                <v-text-field
                  @reset.prevent="resetError()"
                  v-model="latticeSize"
                  :error-messages="errors"
                  class="text-black ma-0"
                  height="32px"
                  background-color="#BFBFBF"
                  outlined
                  solo
                  suffix="m"
                  >
                </v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row class="ma-0 mt-3">
            <v-col cols="4" class="mt-2 mb-4 pa-0" align="left">
              <v-btn
                class=".rounded-lg"
                color="#ff5050"
                width="125px"
                height="45px"
                depressed
                @click="$emit('close')"
                >{{$t("CQW0010600005")}}</v-btn
              >
            </v-col>
            <v-col cols="4" class="mt-2 mb-4 pa-0" align="center">
              <v-btn
                class=".rounded-lg"
                color="#4472c4"
                width="125px"
                height="45px"
                depressed
                :disabled="pristine || invalid || !editingPointClouds.length"
                @click="fileUpload(1)"
                >{{$t("CQW0020109005")}}<br />{{$t("CQW0020110006")}}</v-btn
              >
            </v-col>
            <v-col cols="4" class="mt-2 mb-4 pa-0" align="right">
              <v-btn
                class=".rounded-lg"
                color="#4472c4"
                width="125px"
                height="45px"
                depressed
                :disabled="pristine || invalid || !editingPointClouds.length"
                @click="fileUpload(2)"
                >{{$t("CQW0020110007")}}<br />{{$t("CIW0020000005")}}</v-btn
              >
            </v-col>
          </v-row>
        </validation-observer>
      </v-container>
    </v-card-text>
    <v-dialog
      v-model="showConfirmDialog"
      persistent
      width="350px"
    >
      <confirm-dialog
        :title="$tc('CQW0020115008')"
        :okHandler="confirmOK"
        :useCancelButton="true"
        :cancelHandler="confirmCancel"
        @close="confirmCancel"
      >
        <template v-slot:contents>
          <p>
            {{$t(
              "CQW0020118002", 
              1, 
              {
                name: editingPointCloudsNoPerimeterLine.map(point => point.pointName).join(', ')
              }
            )}}
          </p>
        </template>
      </confirm-dialog>
    </v-dialog>
  </v-card>
</template>

<script>
import CloseButton from "@/components/common/CloseButton.vue";
import EditingPointClouds from "../EditingPointClouds.vue";
import Loading from '../../../common/LoadingOverLay.vue';
import ConfirmDialog from '@/components/common/ConfirmDialog.vue';
import * as utils from "@/utils/utils";
import { repositoryFactory } from "@/repositories/repository-factory";
import { i18n } from '../../../../i18n.js'
const gridInterpolationRepository = repositoryFactory.get('gridInterpolation');

export default {
  name: "GridInterpolationDialog",
  components: {
    CloseButton,
    EditingPointClouds,
    Loading,
    ConfirmDialog
  },
  props: {
    showDialog: Boolean,
    uploadFrom: Object,
  },
  data() {
  return {
    isLoading: false,
    latticeSize: '',
    latticeSizeMaxLength: 8,
    showConfirmDialog: false,
    reject: null,
    resolve: null,
  }
},
  computed: {
    /**
     * 編集中の点群のリスト
     */
    editingPointClouds() {
      return this.$store.getters.editingPointClouds;
    },
    /**
     * 編集中かつ外周線がない点群のリスト
     */
    editingPointCloudsNoPerimeterLine() {
      return this.editingPointClouds.filter(point => !point.perimeterLine);
    },
  },
  methods: {
    openConfirmDialog() {
      this.showConfirmDialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      })
    },
    confirmOK() {
      this.resolve(true);
      this.showConfirmDialog = false;
    },
    confirmCancel() {
      this.resolve(false);
      this.showConfirmDialog = false;
    },
    resetForm() {
      this.latticeSize = "" 
      this.resetError();
    },
    async resetError() {
      await this.$refs.observer.validate();
      await this.$refs.observer.reset();
    },
    // 実行(保存)押下時処理
    async fileUpload(executionMethod) {
      // 外周線がない点群を選択した場合、確認ダイアログを表示
      // if (this.editingPointCloudsNoPerimeterLine) {
      //   if (!await this.openConfirmDialog()) return;
      // }
      this.isLoading = true;
      const body = {
        lattice_size: this.latticeSize,
        create_method: 1,
        execution_method: executionMethod,
        user_id: this.$store.getters.loginData.user_id
      };
      await this.putRequest(body);

      this.isLoading = false;
      this.$emit('close');
    },
    async putRequest(body) {
      let successMessage = i18n.tc("CIW0020116001");
      let errorMessage = i18n.tc("CEW0020116003");
      let status;
      try {
        for (const pointFile of this.editingPointClouds) {
          const res = await gridInterpolationRepository.createByPointId(pointFile.pointId, body);
          if (res.status === 201 || res.status === 204) {
            status = res.status;
          } else {
            // 想定外のステータスコードが返却された場合エラーをスロー
            throw new Error(errorMessage);
          }
        }
        // エラーがなければ正常メッセージを出力する
        utils.showSnackBar('success', successMessage);
        this.resetForm();
        return status;
      } catch (e) {
        console.error(e.message);
        if (utils.createErrorMessage(e)) {
          errorMessage = utils.createErrorMessage(e);
        }
        utils.showSnackBar('error', errorMessage);
        return "";
      } finally {
        // 実行に成功した場合に点群を不可視にする
        if (status === 204 || status === 201) {
          utils.allClearTileset();
          // データリストを更新
          utils.refreshDataList(this.$route.query.siteId);
        }
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.grid-interpolation-dialog
  .v-card__title
    font-size: 16px

.grid-interpolation-dialog
  p,span
    color: white

::v-deep .v-input__append-outer
  margin: 0

::v-deep .v-input__slot
  margin: 0
  padding: 0

.text-black ::v-deep input,
.text-black ::v-deep label,
.text-black ::v-deep .v-select__selection,
.text-black ::v-deep .v-input__append-inner i
  color: black

::v-deep .v-text-field input,
::v-deep .v-text-field.v-input .v-select__selections input
  padding: 0

.drop-area
  border: 2px dashed white

.enter
  border: 2px dashed #2196F3

.text-field-error
  border: solid 2px #ff5252

::v-deep .v-text-field__details
  display: none

.select-file-area
  border: 1px dashed white
::v-deep .v-text-field__suffix
  color: black

</style>