// ダイアログ
import store from '@/store/index';

export default class Dialog{
  constructor(type = "project", name, attributes, handlers, item, event) {
    this.type = type;
    this.name = name;
    this.attributes = attributes;
    this.handlers = handlers;
    this.item = item;
    this.event = event;
  }

  /**
   * ダイアログを表示する
   */
  show() {
    const property = {
      isShow: true,
      name: this.name,
      attributes: this.attributes,
      handlers: this.handlers,
      item: this.item,
      event: this.event
    }

    setTimeout(() => {
      if (this.type === "cesium") {
        store.dispatch('dialog/setCesiumDialog', property);
      } else if (this.type === "project") {
        store.dispatch('dialog/setProjectDialog', property);
      }
    }, 50)
  }

  /**
   * ダイアログを閉じる
   */
  close() {
    const property = {
      isShow: false,
      name: "",
      attributes: {},
      handlers: {},
      item: null,
      event: null 
    }
    if (this.type === "cesium") {
      store.dispatch('dialog/setCesiumDialog', property);
    } else if (this.type === "project") {
      store.dispatch('dialog/setProjectDialog', property);
    }
  }

  /**
   * ダイアログを一時的に非表示にする
   */
  hide() {
    const property = {
      isShow: false,
    }
    if (this.type === "cesium") {
      store.dispatch('dialog/setCesiumDialog', property);
    } else if (this.type === "project") {
      store.dispatch('dialog/setProjectDialog', property);
    }
  }

  /**
   * 一時的に非表示にされているダイアログを再表示する
   */
  reShow() {
    const property = {
      isShow: true,
    }

    setTimeout(() => {
      if (this.type === "cesium") {
        store.dispatch('dialog/setCesiumDialog', property);
      } else if (this.type === "project") {
        store.dispatch('dialog/setProjectDialog', property);
      }
    }, 50)
  }

  get isVisible() {
    switch (this.type) {
      case "project":
        return store.getters["dialog/projectPageDialog"].isShow;
      case "cesium":
        return store.getters["dialog/cesiumDialog"].isShow;
      default:
        return false;
    }
  }
}
