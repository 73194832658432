<template>
  <v-dialog
    v-model="isShow"
    v-bind="dialog.attributes"
    v-on="dialog.handlers"
  >
    <component
      :is="name"
      v-bind="component.attributes"
      v-on="component.handlers"
    >
    </component>
  </v-dialog>
</template>

<script>
import ReadOnlyConfirmDialog from '@/components/List/Dialogs/Lock/ReadOnlyConfirmDialog.vue';
import CoordinateUpdateDialog from '@/components/List/Dialogs/Coordinate/CoordinateUpdateDialog.vue';

export default {
  components: {
    ReadOnlyConfirmDialog,
    CoordinateUpdateDialog
  },
  props: {
    // ダイアログの状態
    isShow: {
      type: Boolean,
      require: true,
      default: false,
    },
    // 表示するダイアログ名
    name: {
      type: String,
      require: true,
    },
    // ダイアログ内で扱うデータ
    item: {
      type: Object,
      require: false,
    },
    // ブラウザで発生するイベント
    event: {
      type: Object,
      require: false,
    }
  },
  data() {
    return {
      'dialog': {
        attributes: {
            'hide-overlay': true, 
        },
        handlers: {
            'click:outside': this.close
        },
      },
      'component': {
        attributes: {},
        handlers: {
            'ok': (siteId) => this.$emit('ok', siteId), 
            'cancel': this.cancel,
            'close': this.close
        },
      },
    }
  },
  beforeUpdate() {
    switch (this.name) {
      case 'read-only-confirm-dialog':
        this.dialog.attributes = {
            ...this.dialog.attributes,
            height: '200px',
            width: '350px',
        },
        this.component.attributes = {
            project: this.item
        }
        break;
      case 'coordinate-update-dialog':
        this.dialog.attributes = {
            ...this.dialog.attributes,
            height: '200px',
            width: '500px',
        },
        this.component.attributes = {
            project: this.item
        }
        break;
      default:
        break;
    }
   },
   methods: {
     ok() {
       this.$emit('ok');
     },
     cancel() {
       this.$emit('cancel');
     },
     close() {
       this.$emit('close');
     },
   }
}
</script>