// 排他制御のstore
import { repositoryFactory } from "@/repositories/repository-factory";
import store from '@/store/index';
import * as constants from '@/constants/constants';
import * as cesiumCommon from '@/utils/cesium-common';
import * as utils from '@/utils/utils';
const lockRepository = repositoryFactory.get('lock');

const state = {
  // ロックされている現場とユーザーを保持
  lockState: []
};

const getters = {
  lockState: state => state.lockState,
  // siteIdを指定してロック状況を取得
  getLockBySiteId: (state) => (siteId) => {
    return state.lockState.find((lock) => lock.siteId === siteId);
  },
  /**
   * @param {*} state
   * @param {*} siteId 
   * @returns 現場のモード
   */
  currentMode: (state) => (siteId) => {
    const targetSite = state.lockState.find((lock) => lock.siteId === siteId);
    // constants.lockState.edit;
    if (targetSite) {
      // 自分自身がロックしている
      if (targetSite.userId == store.getters.loginData.user_id) {
        return constants.lockState.edit;
      } else {
        // 他ユーザーがロックしている
        return constants.lockState.view;
      }
    } else {
      // ロックされていない
      return constants.lockState.noEdit;
    }
  }
};

const mutations = {
  setLockState(state, lockState) {
    state.lockState = lockState;
  },
  setLockStateBySiteId(state, lockState) {
    let siteLockState = state.lockState.find((lock) => lock.siteId === lockState.siteId);
    if (siteLockState) {
    // ロック → ロックへの更新
      siteLockState.userId = lockState.userId
      siteLockState.userName = lockState.userName
    } else {
    // アンロック → ロックへの更新
      state.lockState.push(lockState);
    }
  },
  lock(state, { siteId, loginData }) {
    state.lockState.push({siteId: siteId, userId: loginData.user_id, userName: loginData.user_name});
  },
  unLock(state, siteId) {
    state.lockState = state.lockState.filter((lock) => lock.siteId !== siteId);
  },
};

const actions = {
  setLockState({ commit }, lockState) {
    commit("setLockState", lockState);
  },
  // ロック情報をサイト単位で更新する
  async setLockStateBySiteId({ commit }, siteId) {
    let errorMessage = "ロック情報の取得に失敗しました";

    // 開発用のmockデータ
    if (process.env.NODE_ENV === "development") {
        commit("setLockStateBySiteId", { siteId: siteId, userId: '200', userName: 'user-A' });
    }

    // siteIdを指定してサーバーからロック情報を取得
    try {
      const res = await lockRepository.getLockBySiteId(siteId);
      // const data = { locked_by: '200', user_name: 'user-A'}
      if ('locked_by' in res.data  && res.data.locked_by) {
        // ロックされている場合、stateのロック情報をアップデート
        const lockState = { siteId: siteId, userId: res.data.locked_by, userName: res.data.user_name };
        commit("setLockStateBySiteId", lockState);
      } else {
        // ロックされていない場合、stateもアンロックに更新
        commit("unLock", siteId);
      }
    } catch (e) {
      console.error(e);
      if (utils.createErrorMessage(e)) {
        errorMessage = utils.createErrorMessage(e);
      }
      utils.showSnackBar('error', errorMessage);
    }
  },

  // ロックを取得
  async lock({ commit }, { siteId, loginData }) {
    let successMessage = "ロックを取得しました";
    let errorMessage = "ロック取得に失敗しました";
    const payload = {
      user_id: loginData.user_id
    };
    // ロック取得APIを実行
    try {
      const res = await lockRepository.updateLockBySiteId(siteId, payload);
      if (res.status === 204) {
        utils.showSnackBar('success', successMessage);
        // ロック取得に成功したらstateを更新
        commit("lock", { siteId, loginData });
        utils.allClearTileset();
        utils.allClearLinearData();
        cesiumCommon.resetDistanceCalculation();
        // データリストを更新
        utils.refreshDataList(siteId);
      } else {
        utils.showSnackBar('error', errorMessage);
      }
    } catch (e) {
      console.error(e);
      if (utils.createErrorMessage(e)) {
        errorMessage = utils.createErrorMessage(e);
      }
      utils.showSnackBar('error', errorMessage);
    }
  },

  // 自分のロックを解除
  async unLockState({ commit },  { siteId, loginData }) {
    let successMessage = "ロックを解除しました";
    let errorMessage = "ロック解除に失敗しました";
    const payload = {
      is_forced: 0,
      user_id: loginData.user_id
    };
    // ロック解除APIを実行
    try {
      const res = await lockRepository.updateUnLockBySiteId(siteId, payload);
      if (res.status === 204) {
        utils.showSnackBar('success', successMessage);
        // ロック解除に成功したらstateを更新
        commit("unLock", siteId);
        utils.allClearTileset();
        utils.allClearLinearData();
        cesiumCommon.resetDistanceCalculation();
        // データリストを更新
        utils.refreshDataList(siteId);
      } else {
        utils.showSnackBar('error', errorMessage);
      }
    } catch (e) {
      console.error(e.message);
      if (utils.createErrorMessage(e)) {
        errorMessage = utils.createErrorMessage(e);
      }
      utils.showSnackBar('error', errorMessage);
    }
  },

  // 他人のロックを強制解除
  async forceUnLockState({ commit },  { siteId, loginData }) {
    let successMessage = "ロックを解除しました";
    let errorMessage = "ロック解除に失敗しました";
    const payload = {
      is_forced: 1,
      user_id: loginData.user_id
    };
    // ロック解除APIを実行
    try {
      const res = await lockRepository.updateUnLockBySiteId(siteId, payload);
      if (res.status === 204) {
        utils.showSnackBar('success', successMessage);
        // ロック解除に成功したらstateを更新
        commit("unLock", siteId);
      } else {
        utils.showSnackBar('error', errorMessage);
      }
    } catch (e) {
      console.error(e.message);
      if (utils.createErrorMessage(e)) {
        errorMessage = utils.createErrorMessage(e);
      }
      utils.showSnackBar('error', errorMessage);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}